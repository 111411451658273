export default function getNormalizedRenderConditions(conditions = {}) {
  const {
    show = true,
    start_time,
    expire_time,
    city_ids,
    ignored_city_ids,
    query_match_strings,
    query_ignore_strings,
    render_pathes,
    ignored_pathes,
    by_unauthorized,
    by_authorized
  } = conditions;

  return {
    show,
    ...(start_time && { startTime: start_time }),
    ...(expire_time && { expireTime: expire_time }),
    ...(city_ids && { cityIds: city_ids }),
    ...(ignored_city_ids && { ignoredCityIds: ignored_city_ids }),
    ...(render_pathes && { renderPathes: render_pathes }),
    ...(ignored_pathes && { ignoredPathes: ignored_pathes }),
    ...(by_unauthorized && { byUnauthorized: by_unauthorized }),
    ...(by_authorized && { byUnauthorized: by_authorized }),
    ...(query_match_strings && { queryMatchStrings: query_match_strings }),
    ...(query_ignore_strings && { queryIgnoreStrings: query_ignore_strings }),
  };
}
