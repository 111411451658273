export const byType = bonusType => transaction => {
  return transaction[bonusType] && transaction[bonusType].length !== 0;
};

export const byDate = durationFilter => transaction => {
  if (durationFilter === 'all') {
    return true;
  }

  const currentTime = new Date().getTime();
  const transactionTime = +new Date(transaction.date);

  const dayMilliseconds = 24 * 60 * 60 * 1000;

  if (durationFilter === 'month') {
    const dayAmount = 30;
    return currentTime - dayAmount * dayMilliseconds < transactionTime;
  }

  if (durationFilter === 'week') {
    const dayAmount = 7;
    return currentTime - dayAmount * dayMilliseconds < transactionTime;
  }
};
