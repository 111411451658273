import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { getProfileBonusHistory } from 'routes/links';

import Bonus from '../actions/bonuses';
import * as fromBonuses from '../ducks/bonuses';

import BonusHistoryScene from '../components/bonus-history-scene/bonus-history-scene';

const mapStateToProps = createStructuredSelector({
  transactions: fromBonuses.getTransactions,
  isTransactionsLoaded: fromBonuses.getIsTransactionsLoaded,
  isBonusesExist: fromBonuses.getIsBonusesExist,
  isActivated: fromBonuses.getIsActivated
});

const mapDispatchToProps = {
  showTransactions: Bonus.showTransactions,
  showOrder: Bonus.showOrder
};

class BonusHistorySceneContainer extends Component {
  state = {
    openedOutsideId: this.recieveOpenedOutsideId()
  };

  render() {
    const { transactions, isTransactionsLoaded, isBonusesExist, isActivated } = this.props;

    const { openedOutsideId } = this.state;

    const isTransactionsExist = transactions.length > 0;

    return (
      <BonusHistoryScene
        transactions={transactions}
        openedOutsideId={openedOutsideId}
        isTransactionsLoaded={isTransactionsLoaded}
        isTransactionsExist={isTransactionsExist}
        isBonusesExist={isBonusesExist}
        isActivated={isActivated}
        onTransactionClick={this.handleTransactionClick}
        onFilterChange={this.handleFilterChange}
      />
    );
  }

  componentDidMount() {
    const { isTransactionsLoaded, showTransactions } = this.props;

    if (!isTransactionsLoaded) {
      showTransactions();
      return;
    }

    const isOutsideMarkExists = this.checkIsOutsideMarkExists();

    if (isOutsideMarkExists) {
      this.resetHistory();
    }
  }

  componentDidUpdate() {
    const isOutsideMarkExists = this.checkIsOutsideMarkExists();

    if (isOutsideMarkExists) {
      this.resetHistory();
    }
  }

  checkIsOutsideMarkExists() {
    const { location } = this.props;

    return !!location.state && !!(typeof location.state.transactionId === 'number');
  }

  recieveOpenedOutsideId() {
    const { location } = this.props;

    const isOpenedOutside =
      !!location.state && !!(typeof location.state.transactionId === 'number');

    if (!isOpenedOutside) {
      return null;
    }

    return location.state.transactionId;
  }

  resetHistory() {
    const { history } = this.props;

    history.replace(getProfileBonusHistory(), {});
  }

  resetOutsideId() {
    this.setState({ openedOutsideId: null });
  }

  handleFilterChange = () => {
    this.resetOutsideId();
  };

  handleTransactionClick = orderId => {
    const { showOrder } = this.props;

    showOrder(orderId);
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BonusHistorySceneContainer)
);
