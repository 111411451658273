import { takeEvery, put, select, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { replace } from 'connected-react-router';

import { getError404Link } from 'routes/links';

// import { mixitApi } from 'services/mixit';

import SPECIAL_OFFERS from '../ducks/types/special-offers';
import SpecialOffers from '../ducks/actions/special-offers';
import * as fromSpecialOffers from '../ducks/special-offers';

import specialOfferList from '../containers/seeds/special-offers';
import { normalizeSpecialOffers } from 'services/mixit/adapters/special-offers';

export function* specialOffersWatcher() {
  yield takeEvery(SPECIAL_OFFERS.REQUEST, request);
  yield takeEvery(SPECIAL_OFFERS.SHOW_ITEM, showItem);
}

function* request() {
  try {
    // const response = yield call(mixitApi().specialOffers().all);
    // const specialOffers = response.data;

    yield delay(3000);
    const specialOffers = normalizeSpecialOffers(specialOfferList);

    yield put(SpecialOffers.responseSpecialOffers(specialOffers));
  } catch (error) {
    yield put(SpecialOffers.errorSpecialOffers(error));
  }
}

function* showItem(action) {
  const { slug } = action.payload;

  const specialOffer = yield select(fromSpecialOffers.getSpecialOfferBySlug, { slug });
  const isLoaded = yield select(fromSpecialOffers.getIsLoaded);

  if (isLoaded && !specialOffer) {
    yield put(replace(getError404Link()));
    return;
  }

  yield call(fetchItem, slug);
}

function* fetchItem(slug) {
  try {
    // const response = yield call(mixitApi().specialOffers().all);
    // const specialOffers = response.data;

    yield delay(3000);
    const specialOffers = normalizeSpecialOffers(specialOfferList);
    const specialOffer = specialOffers.all[slug];

    if (!specialOffer) {
      yield put(replace(getError404Link()));
    }

    yield put(SpecialOffers.responseSpecialOffers(specialOffers));
  } catch (error) {
    yield put(SpecialOffers.errorSpecialOffers(error));
    yield put(replace(getError404Link()));
  }
}
