import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './filter-option.css';

const { string, shape } = PropTypes;

class FilterOption extends Component {
  static propTypes = {
    option: shape({
      title: string.isRequired,
      link: string.isRequired
    })
  };

  render() {
    const { option } = this.props;

    const locationObject = this.getLocationObject(option.link);
    return (
      <Link className="FilterOption" to={{ ...locationObject, state: { hasScroll: true } }}>
        {option.title}
      </Link>
    );
  }

  getOptions = search => {};

  getLocationObject = path => {
    const separatorCharIndex = path.indexOf('?');

    if (separatorCharIndex < 0) {
      return {
        pathname: path,
        search: ''
      };
    }

    return {
      pathname: path.slice(0, separatorCharIndex),
      search: path.slice(separatorCharIndex)
    };
  };
}

export default FilterOption;
