import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromProducts from 'modules/product/ducks/products';

import TransactionOrderDetailsProduct from '../components/bonus-history-table/order-details/product';

const mapStateToProps = createStructuredSelector({
  product: fromProducts.getItemBySlug
});

class TransactionOrderDetailsProductContainer extends Component {
  render() {
    const { product, ...rest } = this.props;

    return (
      <TransactionOrderDetailsProduct
        title={product.title}
        categoryTitle={product.category.title}
        {...rest}
      />
    );
  }
}

export default connect(mapStateToProps)(TransactionOrderDetailsProductContainer);
