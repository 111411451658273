import React, { Component } from 'react';
import Dialog from 'modules/core/components/dialog/dialog';
import Button from 'modules/core/components/button/button';
import './review-submitted-dialog.css';

class ReviewSubmittedDialog extends Component {
  state = {};
  render() {
    const { showReviewSubmittedDialog, onReviewSubmittedDialogChange } = this.props;

    return (
      <Dialog
        show={showReviewSubmittedDialog}
        closeable
        onClose={() => onReviewSubmittedDialogChange(false)}
      >
        <div className="ReviewSubmittedDialog">
          <h2>Спасибо за ваш отзыв!</h2>
          <h3>Он будет опубликован после проверки модератором</h3>
          <Button
            title="Хорошо"
            variant="primary"
            onClick={() => onReviewSubmittedDialogChange(false)}
          />
        </div>
      </Dialog>
    );
  }
}

export default ReviewSubmittedDialog;
