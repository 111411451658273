import { takeEvery, put, call, select } from 'redux-saga/effects';

import { mixitApi } from 'services/mixit';

import * as BonusesAdapter from 'services/mixit/adapters/bonuses-adapter';

import * as fromAccount from 'modules/core/ducks/account';

import * as BONUSES from '../types/bonuses';
import Bonuses from '../actions/bonuses';
import * as fromBonuses from '../ducks/bonuses';

export function* bonusesWatcher() {
  yield takeEvery(BONUSES.ALL_REQUEST, requestAll);
  yield takeEvery(BONUSES.TRANSACTIONS_SHOW, showTransactions);
  yield takeEvery(BONUSES.TRANSACTIONS_REQUEST, requestTransactions);
}

function* requestAll(action) {
  const { withFollowingTransactionsLoaded } = action.payload;
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization)
        .profile()
        .bonusAccount().all
    );

    const normalized = BonusesAdapter.normalizeAll(response.data);

    yield put(Bonuses.responseAll(normalized));

    if (withFollowingTransactionsLoaded) {
      yield put(Bonuses.requestTransactions());
    }
  } catch (error) {
    yield put(Bonuses.errorAll());
  }
}

function* showTransactions() {
  const isBonusesLoaded = yield select(fromBonuses.getIsAllLoaded);

  if (!isBonusesLoaded) {
    yield put(Bonuses.requestAll(true));
    return;
  }

  yield put(Bonuses.requestTransactions());
}

function* requestTransactions() {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization)
        .profile()
        .bonusAccount().transactions
    );

    const normalized = BonusesAdapter.normalizeTransactions(response.data);

    yield put(Bonuses.responseTransactions(normalized));
  } catch (error) {
    yield put(Bonuses.errorTransactions());
  }
}
