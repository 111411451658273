import React, { Component } from 'react';

import BottomSheet from 'modules/core/components/bottom-sheet/bottom-sheet';
import Loader from 'modules/core/components/loader/loader';
import Button from 'modules/core/components/button/button';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import PromoProductContainer from '../../containers/promo-product';

import './gift-product-bottom-sheet.css';

class GiftProductBottomSheet extends Component {
  render() {
    const { show, promoOptions, isLoading, onSelect, onCancel, ...rest } = this.props;

    return (
      <BottomSheet
        opened={show}
        header={<h2 className="GiftProductBottomSheet-title">Выберите подарок</h2>}
        footer={<Button title="Отмена" size="small" expanded onClick={onCancel} />}
        scrollable
        withBorder
        {...rest}
      >
        {isLoading ? (
          <div className="GiftProductBottomSheet">
            <div className="GiftProductBottomSheet-loader">
              <Loader type="goo" />
            </div>
          </div>
        ) : (
          <div className="GiftProductBottomSheet">
            {promoOptions && (
              <ul className="GiftProductBottomSheet-list">
                {promoOptions.map(option => (
                  <HideErrorBoundary>
                    <li key={option.id} className="GiftProductBottomSheet-item">
                      <PromoProductContainer slug={option.goods.product} onSelect={onSelect} />
                    </li>
                  </HideErrorBoundary>
                ))}
              </ul>
            )}
          </div>
        )}
      </BottomSheet>
    );
  }
}

export default GiftProductBottomSheet;
