const specialOffers = {
  all: {
    'campaign-brush': {
      slug: 'campaign-brush',
      scenes: ['site', 'shop'],
      title: 'Щетка за 1495 рублей — бесплатно!',
      short_description:
        'Дарим щетку для сухого массажа при заказе от 3000 рублей. Не забудь свой промокод BRUSH. Акции и специальные предложения не суммируются.',
      description:
        'Дарим щетку для сухого массажа при заказе от 3000 рублей. Не забудь свой промокод BRUSH. Акции и специальные предложения не суммируются.Дарим щетку для сухого массажа при заказе от 3000 рублей. Не забудь свой промокод BRUSH. Акции и специальные предложения не суммируются.Дарим щетку для сухого массажа при заказе от 3000 рублей. Не забудь свой промокод BRUSH. Акции и специальные предложения не суммируются.',
      start_date: '2019-10-10T23:59:00',
      expire_date: '2019-10-30T23:59:00',
      promocode: 'cambrush',
      media: {
        id: 462468,
        type: 'MEDIA_TYPE/IMAGE',
        sources: [
          {
            dimensions: {
              width: 1136,
              height: 600
            },
            fallback: {
              normal: 'https://mixit.ru/upload/iblock/05a/05a84bb9efba281bc9c4bf8d870c8b5e.png'
            },
            main: {
              normal: 'https://mixit.ru/upload/iblock/05a/05a84bb9efba281bc9c4bf8d870c8b5e.png'
            }
          }
        ]
      }
    },

    'pacanki-show': {
      slug: 'pacanki-show',
      scenes: ['shop'],
      title: 'Пацанки вперед!',
      short_description:
        'При использовании промокода из шоу «Пацанки» подарок выдаётся при покупке от 500 рублей. Только в розничной сети магазинов Mixit. Акция действует с 29.08.2019 по 31.12.2019. Количество подарков ограничено!',
      description:
        'При использовании промокода из шоу «Пацанки» подарок выдаётся при покупке от 500 рублей. Только в розничной сети магазинов Mixit. Акция действует с 29.08.2019 по 31.12.2019. Количество подарков ограничено!При использовании промокода из шоу «Пацанки» подарок выдаётся при покупке от 500 рублей. Только в розничной сети магазинов Mixit. Акция действует с 29.08.2019 по 31.12.2019. Количество подарков ограничено!При использовании промокода из шоу «Пацанки» подарок выдаётся при покупке от 500 рублей. Только в розничной сети магазинов Mixit. Акция действует с 29.08.2019 по 31.12.2019. Количество подарков ограничено!',
      start_date: '2019-09-10T23:59:00',
      expire_date: '2019-09-25T23:00:00',
      promocode: '',
      media: {
        id: 977844,
        type: 'MEDIA_TYPE/IMAGE',
        sources: [
          {
            dimensions: {
              height: 600,
              width: 1136
            },
            fallback: {
              normal: 'https://mixit.ru/upload/iblock/dc3/dc3440b3d9b43e855a252a46993515e9.png'
            },
            main: {
              normal: 'https://mixit.ru/upload/iblock/dc3/dc3440b3d9b43e855a252a46993515e9.png'
            }
          }
        ]
      }
    },

    'beauty-box': {
      slug: 'beauty-box',
      scenes: ['site'],
      title: 'Дарим годовой запас косметики!',
      short_description:
        'Каждый понедельник мы разыгрываем годовой запас косметики стоимостью более 20 000 руб! Заходи к нам в инстаграм mixit_ru, подписывайся и следи за нашими обновлениями! Возможно бокс достанется именно тебе!',
      description:
        'Каждый понедельник мы разыгрываем годовой запас косметики стоимостью более 20 000 руб! Заходи к нам в инстаграм mixit_ru, подписывайся и следи за нашими обновлениями! Возможно бокс достанется именно тебе!Каждый понедельник мы разыгрываем годовой запас косметики стоимостью более 20 000 руб! Заходи к нам в инстаграм mixit_ru, подписывайся и следи за нашими обновлениями! Возможно бокс достанется именно тебе!Каждый понедельник мы разыгрываем годовой запас косметики стоимостью более 20 000 руб! Заходи к нам в инстаграм mixit_ru, подписывайся и следи за нашими обновлениями! Возможно бокс достанется именно тебе!',
      start_date: '2019-09-10T23:59:00',
      expire_date: '2019-09-24T21:59:00',
      promocode: 'bbox',
      media: {
        id: 1007442,
        type: 'MEDIA_TYPE/IMAGE',
        sources: [
          {
            dimensions: {
              height: 600,
              width: 1136
            },
            fallback: {
              normal: 'https://mixit.ru/upload/iblock/7dd/7dd5dca3e5a061e096f3342705a027cd.png'
            },
            main: {
              normal: 'https://mixit.ru/upload/iblock/7dd/7dd5dca3e5a061e096f3342705a027cd.png'
            }
          }
        ]
      }
    },

    'campaign-brush-copy': {
      slug: 'campaign-brush-copy',
      scenes: ['site', 'shop'],
      title: 'Щетка за 1495 рублей — бесплатно!',
      short_description:
        'Дарим щетку для сухого массажа при заказе от 3000 рублей. Не забудь свой промокод BRUSH. Акции и специальные предложения не суммируются.',
      description:
        'Дарим щетку для сухого массажа при заказе от 3000 рублей. Не забудь свой промокод BRUSH. Акции и специальные предложения не суммируются.Дарим щетку для сухого массажа при заказе от 3000 рублей. Не забудь свой промокод BRUSH. Акции и специальные предложения не суммируются.Дарим щетку для сухого массажа при заказе от 3000 рублей. Не забудь свой промокод BRUSH. Акции и специальные предложения не суммируются.',
      start_date: '2019-09-10T23:59:00',
      expire_date: '2019-09-22T16:00:00',
      promocode: 'cambrush',
      media: {
        id: 462468,
        type: 'MEDIA_TYPE/IMAGE',
        sources: [
          {
            dimensions: {
              width: 1136,
              height: 600
            },
            fallback: {
              normal: 'https://mixit.ru/upload/iblock/05a/05a84bb9efba281bc9c4bf8d870c8b5e.png'
            },
            main: {
              normal: 'https://mixit.ru/upload/iblock/05a/05a84bb9efba281bc9c4bf8d870c8b5e.png'
            }
          }
        ]
      }
    }
  },

  scenes: {
    site: {
      scene: 'site',
      title: 'Онлайн акции',
      list: ['campaign-brush', 'beauty-box', 'campaign-brush-copy']
    },

    shop: {
      scene: 'shop',
      title: 'Акции в магазинах',
      list: ['campaign-brush', 'pacanki-show', 'campaign-brush-copy']
    }
  }
};

export default specialOffers;
