export default function initReviewsRequest(createRequest) {
  return function _getReviewsRequest(slug) {
    return {
      item: createRequest(() => ({
        method: 'GET',
        path: `/reviews/${slug}`
      })),
      rate: () => ({
        add: createRequest(type => ({
          method: 'POST',
          path: `/reviews/${slug}/rate`,
          data: {
            type
          }
        })),
        delete: createRequest(() => ({
          method: 'DELETE',
          path: `/reviews/${slug}/rate`
        }))
      }),
      replies: () => ({
        add: createRequest(content => ({
          method: 'POST',
          path: `/reviews/${slug}/replies`,
          data: {
            content
          }
        }))
      })
    };
  };
}
