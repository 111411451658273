import React, { Component, Fragment } from 'react';

import Container from 'modules/core/components/container/container';
import Review from 'modules/review/components/review/review';

import './section-review-top.css';

class SectionReviewTop extends Component {
  render() {
    const { withoutContainer } = this.props;
    return (
      <section className="SectionReviewTop">
        {withoutContainer ? (
          this.renderTopReview()
        ) : (
          <Container narrow>{this.renderTopReview()}</Container>
        )}
      </section>
    );
  }

  renderTopReview() {
    const {
      title,
      review,
      isAuthorized,
      onReply,
      onReplyFormClose,
      onRate,
      onReplyFormSubmit,
      openAuthDialog
    } = this.props;

    const {
      id,
      author,
      rating,
      content,
      formatedCreateDate,
      replyList,
      showReplyForm,
      isRepliable,
      isRateable,
      rate,
      counters
    } = review;
    return (
      <Fragment>
        <div className="SectionReviewTop-title">{title}</div>
        <div className="SectionReviewTop-review">
          <Review
            id={id}
            author={author}
            rating={rating}
            content={content}
            createDate={formatedCreateDate}
            replyList={replyList}
            showReplyForm={showReplyForm}
            isRepliable={isRepliable}
            isRateable={isRateable}
            rate={rate}
            counters={counters}
            isAuthorized={isAuthorized}
            onReply={onReply}
            onRate={onRate}
            onReplyFormClose={onReplyFormClose}
            onReplyFormSubmit={onReplyFormSubmit}
            openAuthDialog={openAuthDialog}
          />
        </div>
      </Fragment>
    );
  }
}

export default SectionReviewTop;
