import React, { Component } from 'react';
import './link-text.css';

class LinkText extends Component {
  render() {
    return <span className="Link-text">{this.props.title}</span>;
  }
}

export default LinkText;
