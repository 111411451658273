import { takeEvery, put, call } from 'redux-saga/effects';
import { mixitApi } from 'services/mixit';

import * as CALLBACK from '../ducks/types/callback';

import { actions as Callback } from '../ducks/callback';

export function* callbackWatcher() {
  yield takeEvery(CALLBACK.REQUEST, handleRequest);
}

function* handleRequest({ payload }) {
  const { phone, name, email, action } = payload;

  try {
    if (action === 'send guide') {
      yield call(mixitApi().callback, phone, name, email, '/landing/guide');
    } else {
      yield call(mixitApi().callback, phone, name, email);
    }
    yield put(Callback.callbackResponse(name, action));
  } catch (e) {}
}
