import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { getIndexLink, getSpecialOffersLink } from 'routes/links';
import Helmet from 'react-helmet';

import { getSpecialOfferBySlug, getIsListLoaded } from '../ducks/deprecated-special-offers';
import { showDeprecatedSpecialOffers } from '../ducks/actions/deprecated-special-offers';

import { Page } from '../components/page/index';
import FeedbackCall from '../components/feedback-call/feedback-call';
import SectionDefault from '../components/section-default/section-default';
import Container from '../components/container/container';
import SpecialOfferCard from '../components/special-offer-card/special-offer-card';

import SubscribeContainer from '../containers/subscribe';

const mapStateToProps = createStructuredSelector({
  specialOffer: getSpecialOfferBySlug,
  isLoaded: getIsListLoaded
});

const mapDispatchToProps = {
  showSpecialOffer: showDeprecatedSpecialOffers
};

class SpecialOfferPage extends Component {
  static path = '/special-offers/:slug';
  static exact = true;

  render() {
    const { slug } = this.props.match.params;
    const { specialOffer } = this.props;

    const breadcrumbs = this.getBreadcrumbs();

    return (
      <Page breadcrumbs={breadcrumbs}>
        {specialOffer && (
          <Helmet>
            <title>{specialOffer.title}</title>

            <meta name="description" content={specialOffer.shortDescription} />
          </Helmet>
        )}

        <SpecialOfferCard slug={slug} isLoaded={this.props.isLoaded} />

        <SubscribeContainer />

        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Page>
    );
  }

  componentDidMount() {
    const { isLoaded, showSpecialOffer } = this.props;

    if (!isLoaded) {
      showSpecialOffer();
    }

    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  getBreadcrumbs() {
    const { specialOffer } = this.props;

    const breadcrumbs = [
      {
        name: 'Главная',
        link: getIndexLink()
      },
      {
        name: 'Акции',
        link: getSpecialOffersLink()
      }
    ];

    if (specialOffer) {
      breadcrumbs.push({
        name: specialOffer.title
      });
    }

    return breadcrumbs;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpecialOfferPage));
