import React, { Component, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconAngleArrowRight } from 'modules/core/components/icons/index';

import Price from 'modules/ordering/components/price-formatted/price-formatted';

import dateString from 'modules/utils/date-string';

import { getProfileBonusHistory } from 'routes/links';

import './transaction.css';

function checkExtraBonusesExistence(bonusList) {
  if (!bonusList) {
    return false;
  }

  const BONUS_EXTRA_TYPE = 'BONUS_TYPE/EXTRA';

  const filteredBonusList = bonusList.filter(bonus => bonus.type === BONUS_EXTRA_TYPE);

  return filteredBonusList.length > 0;
}

class Transaction extends Component {
  render() {
    const { transaction } = this.props;

    const ISOStringDate = new Date(transaction.date).toISOString();
    const formatedDate = dateString(transaction.date, true);
    const isOrderExist = transaction.orderId;

    const isExtraBonusesExist = checkExtraBonusesExistence(transaction.expense);

    return (
      <div className="Transaction">
        <div className="Transaction-info">
          <div className="Transaction-title">
            <RouterLink
              to={{
                pathname: getProfileBonusHistory(),
                state: { transactionId: transaction.id }
              }}
              className="Transaction-link"
            >
              {transaction.title}
              {transaction.orderTotal && (
                <Fragment>
                  {' '}
                  на сумму <Price number={transaction.orderTotal} />
                </Fragment>
              )}
            </RouterLink>
          </div>

          <div className="Transaction-metaInfo">
            <time className="Transaction-date" dateTime={ISOStringDate}>
              {formatedDate}
            </time>

            {isOrderExist && (
              <Fragment>
                <span className="Transaction-dot" role="presentation" />
                <span className="Transaction-orderNumber">№{transaction.orderId}</span>
              </Fragment>
            )}

            {isExtraBonusesExist && <span className="Transaction-extraBonuses">Экстрабонусы</span>}
          </div>
        </div>

        <div className="Transaction-bonusAmount">{transaction.incomeSummary}</div>

        <div className="Transaction-icon">
          <IconContainer size="xsmall">
            <IconAngleArrowRight />
          </IconContainer>
        </div>
      </div>
    );
  }
}

export default Transaction;
