import React from 'react';
import cn from "classnames"
// import Media from 'modules/core/components/media/media';

import "./reasons.css";
import "./reason.css";

export default function ProblemSkin_Reasons({data}) {
    return (
        <div id="ProblemSkin_Reasons" className="ProblemSkin_Reasons">
            <div className="ProblemSkin_Reasons-title">{data.title}</div>
            <ul className="ProblemSkin_Reasons-list">
                {
                    data.list.map((item, index)=>(
                        <li key={index} className="ProblemSkin_Reasons-item">
                            <ProblemSkinReason data={item} mirrored={index % 2} number={index+1}/>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
} 

function ProblemSkinReason({data, mirrored, number}) {
    return (
        <div className={cn("ProblemSkin_Reason", {
            "ProblemSkin_Reason--mirrored": mirrored
        })}>
            <div className="ProblemSkin_Reason-info">
                <div className="ProblemSkin_Reason-number">0{number}</div>
                <div className="ProblemSkin_Reason-title">{data.title}</div>
                <div className="ProblemSkin_Reason-description">{data.description}</div>
            </div>
            <div className="ProblemSkin_Reason-visual">
                <div className="ProblemSkin_Reason-imageHolder">
                    <img className="ProblemSkin_Reason-image" 
                        src={data.images[0].sources[0].main.normal} 
                        alt="" />
                </div>
            </div>
        </div>
    )
}