import { createReducer, handle } from 'modules/utils/dux';
import * as TYPES from './types/instagram-posts';
import { createSelector } from '../../utils/debug-reselect';

/* REDUCERS */

const reducer = createReducer(
  handle(TYPES.ERROR, (state, { message }) => {
    return {
      ...state,
      _error: message,
      _isLoading: false
    };
  }),

  handle(TYPES.RESPONSE, (state, { postList }) => {
    return {
      ...state,
      linkList: postList,
      _isLoading: false
    };
  })
);

export default reducer({
  _error: false,
  _isLoading: true,
  linkList: []
});

/* SELECTORS */

const getRoot = state => state.core.instagramPosts;

export const getError = createSelector(
  getRoot,
  root => root._error
);
export const getIsLoading = createSelector(
  getRoot,
  root => root._isLoading
);
export const getLinkList = createSelector(
  getRoot,
  root => root.linkList
);
