const DIVIDERS = {
  years: 1000 * 60 * 60 * 24 * 365,
  months: 1000 * 60 * 60 * 24 * 30,
  days: 1000 * 60 * 60 * 24,
  hours: 1000 * 60 * 60,
  minutes: 1000 * 60,
  seconds: 1000
};

function calcRest(mod, rest) {
  return mod ? rest % mod : rest;
}

function getTimeDifference(millisecondAmount) {
  const timeDifference = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };
  let rest = millisecondAmount;

  if (rest < 0) {
    return timeDifference;
  }

  for (let key in timeDifference) {
    timeDifference[key] = Math.floor(rest / DIVIDERS[key]);
    rest = calcRest(DIVIDERS[key], rest);
  }

  return timeDifference;
}

export default getTimeDifference;
