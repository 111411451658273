import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SectionReviewHeader from '../section-review-header/section-review-header';
import SectionReviewTop from '../section-review-top/section-review-top';
import SectionReviewList from '../section-review-list/section-review-list';
import SectionFeedbackForm from '../section-feedback-form/section-feedback-form';
import ReviewSubmittedDialog from './review-submitted-dialog';
import ReplySubmittedDialog from './reply-submitted-dialog';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import './product-review.css';

const { number, object, string, func, arrayOf, shape, bool } = PropTypes;

class ProductReview extends Component {
  static propTypes = {
    /** рейтинг текущего продукта */
    rating: number,
    /** отзыв в верхней секции */

    reviewTop: object,
    /**TODO:  */
    /** Отображать хедер */
    withHeader: bool,
    /**Выводить сортировку списка отзывов */
    enableSorting: bool,
    /** Отображать TopReview */
    showTopReview: bool,
    /** заголовок верхнего отзыва */
    reviewTopTitle: string,
    /** список отзывов */
    list: arrayOf(object),
    /** список типов сортировки */
    sortOptionList: arrayOf(
      shape({
        type: string,
        title: string
      })
    ),
    /** активная опция сортировки */
    activeSortOption: string,
    /** кол-во страниц для пагинации */
    pageAmount: number,
    /** номер текущей страница для пагинации */
    currentPage: number,
    /** колбек вызова формы ответа */
    onReply: func,
    /** колбек рейтинга (like/dislike). Возвращает идентификатор отзыва и тип рейтинга (что было нажато) */
    onRate: func,
    /** колбек смены типа сортировки. Возвращает тип сортировки*/
    onSortChange: func,
    /** колбек смены активной страницы. Возвращает номер страницы*/
    onPageChange: func,
    /** колбек закрытия формы ответа. Возвращает идентификатор отзыва*/
    onReplyFormClose: func,
    /** колбек сабмита ответа на отзыв. Возвращает идентификатор отзыва и объект полей ответа*/
    onReplyFormSubmit: func,
    /** колбек сабмита отзыва. Возвращает объект полей отзыва*/
    onReviewFormSubmit: func
  };

  render() {
    const {
      rating,
      reviewTop,
      reviewTopTitle,
      onReply,
      onRate,
      sortOptionList,
      activeSortOption,
      list,
      pageAmount,
      currentPage,
      withHeader,
      formRef,
      productReviewRef,
      enableSorting,
      showTopReview,
      isAuthorized,
      onSortChange,
      onPageChange,
      onReplyFormClose,
      onReplyFormSubmit,
      onReviewFormSubmit,
      onCreateReview,
      withoutContainer,
      showReviewSubmittedDialog,
      onReviewSubmittedDialogChange,
      showReplySubmittedDialog,
      onReplyConformationDialogChange,
      openAuthDialog
    } = this.props;

    return (
      <div className="ProductReview" id="reviews" ref={productReviewRef}>
        {list.length > 0 && withHeader && (
          <SectionReviewHeader
            rating={rating}
            onClick={onCreateReview}
            withoutContainer={withoutContainer}
          />
        )}

        {showTopReview && reviewTop && (
          <HideErrorBoundary>
            <SectionReviewTop
              review={reviewTop}
              title={reviewTopTitle}
              isAuthorized={isAuthorized}
              onReply={onReply}
              onRate={onRate}
              onReplyFormClose={onReplyFormClose}
              onReplyFormSubmit={onReplyFormSubmit}
              openAuthDialog={openAuthDialog}
              withoutContainer={withoutContainer}
            />
          </HideErrorBoundary>
        )}

        {list.length > 0 && (
          <SectionReviewList
            sortOptionList={sortOptionList}
            activeSortOption={activeSortOption}
            list={list}
            pageAmount={pageAmount}
            currentPage={currentPage}
            isAuthorized={isAuthorized}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            onReply={onReply}
            onRate={onRate}
            onReplyFormClose={onReplyFormClose}
            onReplyFormSubmit={onReplyFormSubmit}
            openAuthDialog={openAuthDialog}
            enableSorting={enableSorting}
            withoutContainer={withoutContainer}
          />
        )}

        <SectionFeedbackForm
          withTitle={!withoutContainer || list.length > 0}
          onSubmit={onReviewFormSubmit}
          formRef={formRef}
          withoutContainer={withoutContainer}
          isAuthorized={isAuthorized}
        />

        <ReviewSubmittedDialog
          showReviewSubmittedDialog={showReviewSubmittedDialog}
          onReviewSubmittedDialogChange={onReviewSubmittedDialogChange}
        />
        <ReplySubmittedDialog
          show={showReplySubmittedDialog}
          onClose={onReplyConformationDialogChange}
        />
      </div>
    );
  }
}

export default ProductReview;
