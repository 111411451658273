import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Pagination from 'modules/core/components/pagination/pagination';

import Review from '../review/review';
import SortOptions from './sort-options';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import './list-review.css';

const { arrayOf, shape, string, number, func, object } = PropTypes;

class ListReview extends Component {
  static propTypes = {
    list: arrayOf(object),
    sortOptionList: arrayOf(
      shape({
        type: string,
        title: string
      })
    ),
    pageAmount: number,
    currentPage: number,
    onSortChange: func,
    onPageChange: func,
    activeSortOption: string
  };
  render() {
    const {
      list,
      sortOptionList,
      activeSortOption,
      pageAmount,
      currentPage,
      enableSorting,
      isAuthorized,
      onSortChange,
      onPageChange,
      onReply,
      onReplyFormClose,
      onRate,
      onReplyFormSubmit,
      openAuthDialog
    } = this.props;

    return (
      <div className="ListReview">
        {/* <div
          style={{ display: 'none' }}
          itemprop="aggregateRating"
          itemscope=""
          itemtype="http://schema.org/AggregateRating"
        >
          <meta itemprop="bestRating" content="5" />
          <meta itemprop="ratingValue" content="5" />
          <meta itemprop="ratingCount" content={list.length} />
        </div> */}

        {enableSorting && (
          <div className="ListReview-sortOptions">
            <SortOptions
              sortOptionList={sortOptionList}
              activeSortOption={activeSortOption}
              onSortChange={onSortChange}
            />
          </div>
        )}

        <div className="ListReview-list">
          {list.map(review => {
            const {
              author,
              rating,
              content,
              formatedCreateDate,
              replyList,
              showReplyForm,
              isRepliable,
              isRateable,
              userRate,
              id,
              counters
            } = review;

            return (
              <HideErrorBoundary key={id}>
                <div className="ListReview-listItem">
                  <Review
                    id={id}
                    author={author}
                    rating={rating}
                    content={content}
                    createDate={formatedCreateDate}
                    replyList={replyList}
                    isRepliable={isRepliable}
                    isRateable={isRateable}
                    showReplyForm={showReplyForm}
                    userRate={userRate}
                    counters={counters}
                    isAuthorized={isAuthorized}
                    onReply={onReply}
                    onRate={onRate}
                    onReplyFormClose={onReplyFormClose}
                    onReplyFormSubmit={onReplyFormSubmit}
                    openAuthDialog={openAuthDialog}
                  />
                </div>
              </HideErrorBoundary>
            );
          })}
        </div>
        {pageAmount > 1 && (
          <div className="ListReview-pagination">
            <Pagination amount={pageAmount} current={currentPage} onChange={onPageChange} />
          </div>
        )}
      </div>
    );
  }
}

export default ListReview;
