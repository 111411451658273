import React, { Component } from 'react';

import Media from 'modules/core/components/media/media';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconPlus } from 'modules/core/components/icons';
import { Link } from 'react-router-dom';

import { getProductLink } from 'routes/links';

import './promo-product.css';

class PromoProduct extends Component {
  render() {
    const { product } = this.props;

    return (
      <div className="PromoProduct">
        <div className="PromoProduct-imageCol">
          <Media
            className="PromoProduct-image"
            media={product.announcementMedia}
            resizeWidth={80}
            stretch="horizontal"
            nocaption
          />
        </div>

        <div className="PromoProduct-contentCol">
          <div className="PromoProduct-description">{product.description}</div>

          <h3 className="PromoProduct-title">
            <Link to={getProductLink(product)} className="PromoProduct-link">
              {product.title}
            </Link>
          </h3>

          {product.variantOptionsAll &&
            product.variantOptionsAll.map(item => (
              <div className="PromoProduct-selectedVariant">
                {item.variantTitle}: {item.value} {(item.payload || {}).unit || ''}
              </div>
            ))}
        </div>

        <div className="PromoProduct-actionCol">
          <div className="PromoProduct-buttonHolder">
            <button className="PromoProduct-button" onClick={this.handleButtonClick}>
              <IconContainer inline>
                <IconPlus />
              </IconContainer>
            </button>
          </div>
        </div>
      </div>
    );
  }

  handleButtonClick = () => {
    const { product, onSelect } = this.props;

    onSelect(product);
  };
}

export default PromoProduct;
