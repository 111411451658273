import React, { Component } from 'react';
import Media from 'react-media';

import Dialog from '../dialog/dialog';
import IconContiner from '../icon-container/icon-container';
import { IconEmailSucces } from '../icons';
import Button from '../button/button';

import './email-confirm-dialog.css';

class DOI_EmailConfirmDialog extends Component {
  render() {
    const { onClose, ...rest } = this.props;

    return (
      <Media query="(max-width:840px)">
        {isMobile => (
          <Dialog onClose={onClose} {...rest} closeable>
            <div className="EmailConfirmDialog">
              <div className="EmailConfirmDialog-icon">
                <IconContiner inline>
                  <IconEmailSucces />
                </IconContiner>
              </div>

              <h3 className="EmailConfirmDialog-title">
                Спасибо!
                <br />
                Твой e-mail подтвержден
              </h3>

              <div className="EmailConfirmDialog-action">
                <Button
                  title="Закрыть"
                  variant="primary"
                  onClick={onClose}
                  size={isMobile ? 'small' : 'normal'}
                />
              </div>
            </div>
          </Dialog>
        )}
      </Media>
    );
  }
}

export default DOI_EmailConfirmDialog;
