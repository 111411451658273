import { createSelector } from 'reselect';
import * as REVIEW from '../types/review';
import * as PRODUCT from 'modules/product/ducks/types/products';
import he from 'he';
import stripTags from 'modules/utils/strip-tags';
import produce from 'immer';
import * as fromAccount from 'modules/core/ducks/account';

import { createReducer, handle } from 'modules/utils/dux';

import {
  // createReply,
  getFormattedDateObject,
  compareDates,
  addUserRating
  // createNewReview
} from './helpers';

import { RATE_ACTION_TO_PROP } from '../constants/review';

const reviews = createReducer(
  handle(
    REVIEW.RATE,
    produce((state, { id, type }) => {
      if (state[id].userRate === type) {
        return;
      }
      const RATE_TYPE = RATE_ACTION_TO_PROP[type];
      if (!RATE_TYPE || !state[id]) {
        return;
      }

      const PREVIOUS_RATE = RATE_ACTION_TO_PROP[state[id].userRate];
      if (PREVIOUS_RATE) {
        state[id].counters[PREVIOUS_RATE] -= 1;
      }

      state[id].userRate = type;
      state[id].counters[RATE_TYPE] += 1;
    })
  ),

  // handle(REVIEW.REPLY, (state, { id, reply }) => {
  //   const newReply = createReply(reply.text);
  //   return {
  //     ...state,
  //     [id]: {
  //       ...state[id],
  //       replyList: [...state[id].replyList, newReply]
  //     }
  //   };
  // }),

  // handle(REVIEW.CREATE, (state, { review }) => {
  //   const newReview = createNewReview(review);
  //   return {
  //     ...state,
  //     [newReview.id]: newReview
  //   };
  // }),

  handle(PRODUCT.ADD_DETAILS, (state, { details }) => {
    const { reviews } = details;
    if (!reviews) {
      return state;
    }
    const reviewTable = reviews.map(normalizeReview).reduce(byId, {});
    return {
      ...state,
      ...reviewTable
    };
  })
);

export default reviews({});

// SELECTORS

export const getReviews = state => state.review.reviews;

// const createGetReviewById = reviews => id => {
//   return reviews[id];
// };

const getFindReviewById = createSelector(
  getReviews,
  function _getFindReviewById(reviews) {
    return id => {
      return reviews[id];
    };
  }
);

// const findReviewById = getFindReviewById(reviews);
const getAddUserProperties = createSelector(
  fromAccount.getIsAuthorized,
  isAuthorized => {
    return review => {
      return {
        ...review,
        isRepliable: isAuthorized,
        isRateable: isAuthorized
      };
    };
  }
);
/** TODO: replace temporary solution with real data start */

export const makeGetListByIds = idsSelector => {
  return createSelector(
    idsSelector,
    getFindReviewById,
    getAddUserProperties,
    function _getListById(idList, findReviewById, addUsesProperties) {
      const sortReplyList = makeReplyListSorted(compareDates);
      const addFormatedDate = makeAddFormatedDate(getFormattedDateObject);

      return idList
        .map(findReviewById)
        .map(sortReplyList)
        .map(addUserRating)
        .map(addFormatedDate)
        .map(addUsesProperties);
    }
  );
};

// HELPERS

function makeReplyListSorted(comparator) {
  return review => {
    if (!review.replyList) {
      return review;
    }
    const replyList = review.replyList.sort(comparator);
    return {
      ...review,
      replyList
    };
  };
}

function makeAddFormatedDate(getFormattedDateObject) {
  return review => {
    const createDateString = review.createDate;
    const replyListWithDates = (review.replyList || []).map(reply => {
      return {
        ...reply,
        formatedCreateDate: getFormattedDateObject(reply.create_date)
      };
    });
    return {
      ...review,
      formatedCreateDate: getFormattedDateObject(createDateString),
      replyList: replyListWithDates
    };
  };
}

function normalizeReview(apiReview) {
  if (!apiReview) {
    return null;
  }

  const {
    author,
    is_valuable,
    content: { media, text, advantages, disadvantages },
    create_date,
    counters: { likes, dislikes },
    user_rate,
    replies,
    ...rest
  } = apiReview;

  return {
    ...rest,
    isValuable: is_valuable,
    userRate: user_rate || null,
    author: normalizeAuthor(author),
    content: {
      media,
      text: he.decode(stripTags(text)).trim(),
      advantages,
      disadvantages
    },
    createDate: Date.parse(create_date),
    counters: {
      likes,
      dislikes
    },
    replyList: normalizeReplies(replies)
  };
}

function normalizeReplies(replies) {
  if (!replies) {
    return null;
  }

  return replies.map(reply => ({
    ...reply,
    author: normalizeAuthor(reply.author),
    content: {
      ...reply.content,
      text: he.decode(stripTags(reply.content.text)).trim()
    }
  }));
}

function normalizeAuthor(author) {
  if (!author) {
    return null;
  }

  let fullNameArr = [];

  if (author.first_name) {
    fullNameArr.push(author.first_name);
  }

  if (author.last_name) {
    fullNameArr.push(author.last_name);
  }

  return {
    fullName: fullNameArr.join(' ')
  };
}

// function normalizeAuthor(author) {
//   if (!author) {
//     return null;
//   }

//   const { first_name } = author;

//   return {
//     firstName: first_name
//   };
// }

function byId(map, item) {
  return {
    ...map,
    [item.id]: item
  };
}
