import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MediaQuery from 'react-media';
import PromoCode from 'modules/core/components/promo-code/promo-code';

// import Media from 'modules/core/components/media/media';
import Container from 'modules/core/components/container/container';
import Price from 'modules/ordering/components/price-formatted/price-formatted';
// import Media from 'modules/core/components/media/media';
// import ButtonAddToBasket from 'modules/product/components/product/button-add-to-basket.jsx';
import AddToBasketWithToastConsumer from 'modules/product/components/product/AddToBasketWithToastConsumer';

import * as fromProducts from 'modules/product/ducks/products';
import * as fromBasket from 'modules/ordering/ducks/basket';

import "./product-plus-gift.css";

const mapStateToProps = createStructuredSelector({
    product: fromProducts.getBySlugArgument("aha-revolution-set"),
  });
  
  const mapDispatchToProps = {
    // requestProduct: LandingActions.requestLendingProducts
    addProductToBasket: fromBasket.actions.addProduct
  };

  function ProblemSkin_ProductPlusGift({data, product, addProductToBasket }) {
    if(!product) {
        return "Loading..."
    }

    return (
        <MediaQuery query="(max-width:1200px)">
        {isTablet => (
        <div className="ProblemSkin_ProductPlusGift" style={{...data.style}}>
            <Container>
                <div className="ProblemSkin_ProductPlusGift-title">
                    Покупай весь набор прямо сейчас,<br/> и мы подарим тебе 
                    <div className="ProblemSkin_ProductPlusGift-giftLink" dangerouslySetInnerHTML= {{ __html: data.gift_product.title }}/>
                </div>
                <div className="ProblemSkin_ProductPlusGift-main">
                    <div className="ProblemSkin_ProductPlusGift-visual">
                        <div className="ProblemSkin_ProductPlusGift-imageHolder">
                            <img className="ProblemSkin_ProductPlusGift-image" src={data.image.sources[0].main.normal} alt=""/>
                        </div>
                        <div className="ProblemSkin_ProductPlusGift-promo">
                            <div className="ProblemSkin_ProductPlusGift-promoInfo">{data.image_description}</div>
                            <div className="ProblemSkin_ProductPlusGift-promoCode">
                                <PromoCode
                                    code={data.promocode}
                                    // inverted={'light'}
                                    size={'normal'}
                                    options={["dashed"]}
                                    // themeColor={"coral"}
                                    // withOverlay
                                    buttonStyle={{borderColor: "#8e3387", backgroundColor:"rgb(142 51 135 / 20%)"}}
                                    textStyle={{color:""}}
                                    codeStyle={{color:"#8e3387"}}
                                />
                                {}</div>
                        </div>
                    </div>
                    <div className="ProblemSkin_ProductPlusGift-info">
                        <div className="ProblemSkin_ProductPlusGift-tile">
                            <div className="ProblemSkin_ProductPlusGift-titleGift"
                                dangerouslySetInnerHTML={{__html:`${data.gift_product.title_2} <br/> + <br/> набор ${data.set_title} <br/> = `}}>
                            </div>
                            <div className="ProblemSkin_ProductPlusGift-price">
                                <Price number={product.price.current} />
                            </div>
                            <div className="ProblemSkin_ProductPlusGift-addToBasket">
                                <AddToBasketWithToastConsumer  
                                    product={product}
                                    expanded={false} 
                                    size={isTablet ?   "small" : "medium"} 
                                    variant="black" 
                                    title={product.isInBasket ? "Добавить еще" :"Добавить в корзину"} 
                                    addProductToBasket={addProductToBasket} 
                                    // onClick={()=>{}} 
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
                        )}
                        </MediaQuery>
    )
} 

const ConnectedProblemSkin_ProductPlusGift = connect(mapStateToProps, mapDispatchToProps)(ProblemSkin_ProductPlusGift);


export default ConnectedProblemSkin_ProductPlusGift;