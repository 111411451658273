import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import Dialog from 'modules/core/components/dialog/dialog';
// import MainCarousel from 'modules/core/components/main-carousel/main-carousel';
import Loader from 'modules/core/components/loader/loader';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import GiftProductContainer from 'modules/product/containers/gift-product';

import './gift-product-dialog.css';

class GiftProductDialog extends Component {
  render() {
    const { promoOptions, isLoading, onSelect, onCancel, ...rest } = this.props;

    return (
      <Dialog title="Выберите подарок" onClose={onCancel} closeable {...rest}>
        <div
          className={cn('GiftProductDialog', {
            'GiftProductDialog--static': promoOptions && promoOptions.length <= 4,
            'GiftProductDialog--single': promoOptions && promoOptions.length === 1
          })}
        >
          {isLoading ? (
            <div className="GiftProductDialog-loader">
              <Loader type="spin" />
            </div>
          ) : (
            promoOptions && (
              <Fragment>
                {/* {!promoOptions.length > 4 ? (
                  <MainCarousel>
                    {promoOptions.map(option => (
                      <MainCarousel.Item key={option.id}>
                        <GiftProductContainer
                          slug={option.goods.product}
                          onSelect={onSelect}
                          isGift
                        />
                      </MainCarousel.Item>
                    ))}
                  </MainCarousel>
                ) : ( */}
                <div className="GiftProductDialog-static">
                  {promoOptions.map(option => (
                    <HideErrorBoundary key={option.id}>
                      <div className="GiftProductDialog-staticItem">
                        <GiftProductContainer
                          slug={option.goods.product}
                          onSelect={onSelect}
                          isGift
                        />
                      </div>
                    </HideErrorBoundary>
                  ))}
                </div>
                {/*  )} */}
              </Fragment>
            )
          )}
        </div>
      </Dialog>
    );
  }
}

export default GiftProductDialog;
