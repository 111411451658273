const navigationConfig = {
  options: { version: 2, from: 'FRONT' },
  main_catalog_navigation: {
    list: [
      {
        id: 1,
        title: 'Лицо',
        slug: 'face',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Декоративная косметика',
                path: '/category/decorate'
              },
              {
                title: 'Кремы',
                slug: 'creme-face'
              },
              {
                title: 'Средства с SPF',
                slug: 'spf-face'
              },
              {
                title: 'Маски',
                slug: 'masks-face'
              },
              {
                title: 'Скрабы и пилинги',
                slug: 'scrub-face'
              },
              {
                title: 'Очищающие средства',
                slug: 'clean'
              },
              {
                title: 'Тоники и лосьоны',
                slug: 'lotion'
              },
              {
                title: 'Сыворотки и концентраты',
                slug: 'serums'
              },
              {
                title: 'Уход за кожей вокруг глаз',
                slug: 'eyes'
              },
              {
                title: 'Уход за кожей губ',
                slug: 'lips'
              },
              {
                title: 'Хайлайтеры',
                slug: 'highlighters'
              },
              {
                title: 'Аксессуары',
                slug: 'accessories'
              }
            ]
          },
          {
            slug: 'skin_type',
            title: 'Тип кожи',
            list: [
              {
                title: 'Все типы кожи лица',
                path: '/group/for-all-skin-type'
              },
              {
                title: 'Комбинированная кожа',
                path: '/group/combined-skin-type'
              },
              {
                title: 'Сухая кожа',
                path: '/group/dry-skin-type'
              },
              {
                title: 'Жирная кожа',
                path: '/group/oily-skin-type'
              },
              {
                title: 'Нормальная кожа лица',
                path: '/group/normal-skin-type'
              },
              {
                title: 'Чувствительная кожа',
                path: '/group/sensitive-skin-type'
              },
              {
                title: 'Проблемная кожа лица',
                path: '/group/problem-skin-type'
              },
              {
                title: 'Обезвоженная кожа лица',
                path: '/group/dehydrated-skin-type'
              },
              {
                title: 'Возрастная кожа лица',
                path: '/group/aged-skin-type'
              }
            ]
          },
          {
            slug: 'propose',
            title: 'Цель применения',
            list: [
              {
                title: 'Увлажнение',
                filter: 'skin-needs-moisturizing'
              },
              {
                title: 'Питание и восстановление',
                filter: 'skin-needs-recovery'
              },
              {
                title: 'Сияние и энергия',
                filter: 'skin-needs-energy'
              },
              {
                title: 'Сужение пор, матирование',
                filter: 'skin-needs-matting'
              },
              {
                title: 'Лифтинг и омоложение',
                filter: 'skin-needs-rejuvenation'
              },
              {
                title: 'Очищение',
                filter: 'skin-needs-cleansing'
              },
              {
                title: 'Пилинг, oтшелушивание',
                filter: 'skin-needs-peeling'
              },
              {
                title: 'Контроль над проблемной кожей',
                filter: 'skin-needs-control'
              },
              {
                title: 'Ровный тон и цвет лица',
                filter: 'skin-needs-complexion'
              }
            ]
          }
        ]
      },
      {
        id: 2,
        title: 'Тело',
        slug: 'body',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Антицеллюлитная косметика',
                slug: 'correction'
              },
              {
                title: 'Средства с SPF',
                slug: 'body-spf'
              },
              {
                title: 'Хайлайтеры для тела',
                slug: 'highlighters-body'
              },
              {
                title: 'Гигиена (гели и мыло)',
                slug: 'gels'
              },
              {
                title: 'Кремы, бальзамы и масла',
                slug: 'creme-body'
              },
              {
                title: 'Скрабы и пилинги',
                slug: 'scrub-body'
              },
              {
                title: 'Уход для рук и ног',
                slug: 'hands'
              },
              {
                title: 'Соли, бомбы, пена для ванн',
                slug: 'salts'
              },
              {
                title: 'Дезодоранты и парфюм',
                slug: 'parfume'
              },
              {
                title: 'Косметика для мужчин',
                slug: 'man-body'
              },
              {
                title: 'Аксессуары',
                slug: 'accessories'
              }
            ]
          },
          {
            slug: 'skin_type',
            title: 'Тип кожи',
            list: [
              {
                title: 'Нормальная',
                filter: 'skin-type-normal'
              },
              {
                title: 'Комбинированная',
                filter: 'skin-type-combined'
              },
              {
                title: 'Жирная',
                filter: 'skin-type-fatty'
              },
              {
                title: 'Сухая и чувствительная',
                filter: 'skin-type-dry-and-sensitive'
              },
              {
                title: 'Проблемная',
                filter: 'skin-type-problem'
              },
              {
                title: 'Возрастная',
                filter: 'skin-type-aged'
              }
            ]
          },
          {
            slug: 'purpose',
            title: 'Цель применения',
            list: [
              {
                title: 'Увлажнение',
                filter: 'skin-needs-moisturizing'
              },
              {
                title: 'Питание и восстановление',
                filter: 'skin-needs-recovery'
              },
              {
                title: 'Лифтинг и омоложение',
                filter: 'skin-needs-rejuvenation'
              },
              {
                title: 'Очищение',
                filter: 'skin-needs-cleansing'
              },
              {
                title: 'Пилинг, oтшелушивание',
                filter: 'skin-needs-peeling'
              },
              {
                title: 'Тонизирование',
                filter: 'skin-needs-toning'
              },
              {
                title: 'Борьба с целлюлитом и растяжками',
                filter: 'skin-needs-cellulite'
              },
              {
                title: 'Массаж',
                filter: 'skin-needs-massage'
              }
            ]
          }
        ]
      },
      {
        id: 3,
        title: 'Волосы',
        slug: 'hair',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Шампуни',
                slug: 'shampoo'
              },
              {
                title: 'Бальзамы и кондиционеры',
                slug: 'balm'
              },
              {
                title: 'Маски',
                slug: 'masks-hair'
              },
              {
                title: 'Масла и эликсиры',
                slug: 'elixirs'
              },
              {
                title: 'Для мужчин',
                slug: 'man-hair'
              },
              {
                title: 'Аксессуары',
                slug: 'accessories'
              }
            ]
          },
          {
            slug: 'hair_type',
            title: 'Тип волос',
            list: [
              {
                title: 'Сухие',
                filter: 'hair-type-dry'
              },
              {
                title: 'Нормальные',
                filter: 'hair-type-normal'
              },
              {
                title: 'Комбинированные',
                filter: 'hair-type-combined'
              },
              {
                title: 'Жирные',
                filter: 'hair-type-oily'
              },
              {
                title: 'Окрашенные',
                filter: 'hair-type-dyed'
              }
            ]
          },
          {
            slug: 'purpose',
            title: 'Потребности волос',
            list: [
              {
                title: 'Очищение',
                filter: 'hair-needs-cleansing'
              },
              {
                title: 'Питание и восстановление',
                filter: 'hair-needs-nutrition'
              },
              {
                title: 'Увлажнение и кондиционирование',
                filter: 'hair-needs-moisturizing'
              },
              {
                title: 'Рост и укрепление волос',
                filter: 'hair-needs-сrowth'
              },
              {
                title: 'Сохранение цвета волос',
                filter: 'hair-needs-color-preservation'
              },
              {
                title: 'Облегчение укладки волос',
                filter: 'hair-needs-styling'
              }
            ]
          }
        ]
      },
      {
        id: 7,
        title: 'Здоровье',
        slug: 'health',
        type: 'card',
        props: {
          style: {
            color: '#25bc8b'
          }
        },
        submenu: [
          {
            slug: 'vitamins',
            title: 'Витамины',
            type: 'card',
            cardProps: {
              imageHolderStyle: {},
              imageHolderHoverStyle: {
                backgroundColor: '#f6f8fb'
              },
              titleStyle: {},
              showTitle: true,
              media: {
                src: 'https://mixit.ru/upload/iblock/758/758e10060c418a49137b49872e6bab32.png',
                srcset: 'https://mixit.ru/upload/iblock/758/758e10060c418a49137b49872e6bab32.png'
              }
            }
          },
          {
            slug: 'antiseptic',
            title: 'Антисептики',
            type: 'card',
            cardProps: {
              imageHolderStyle: {},
              imageHolderHoverStyle: {
                backgroundColor: '#f6f8fb'
              },
              showTitle: true,
              media: {
                src: 'https://mixit.ru/upload/iblock/4d8/4d8f55395e9536a80582512bdb62279b.png',
                srcset: 'https://mixit.ru/upload/iblock/4d8/4d8f55395e9536a80582512bdb62279b.png'
              }
            }
          },
          {
            slug: 'masks-health',
            title: 'Маски',
            type: 'card',
            cardProps: {
              imageHolderStyle: {},
              imageHolderHoverStyle: {
                backgroundColor: '#f6f8fb'
              },
              showTitle: true,
              media: {
                src: 'https://mixit.ru/upload/iblock/849/8498c93dd7565151a37fa82515065811.png',
                srcset: 'https://mixit.ru/upload/iblock/849/8498c93dd7565151a37fa82515065811.png'
              }
            }
          },
          {
            path: '/group/safe-complect',
            title: 'Защитные комплекты',
            type: 'card',
            cardProps: {
              imageHolderStyle: {},
              imageHolderHoverStyle: {
                backgroundColor: '#f6f8fb'
              },
              showTitle: true,
              media: {
                src: 'https://mixit.ru/upload/iblock/319/319907143fe813467421f0866890718f.png',
                srcset: 'https://mixit.ru/upload/iblock/319/319907143fe813467421f0866890718f.png'
              }
            }
          },

          {
            path: 'category/for-pets',
            title: 'Для животных',
            type: 'card',
            cardProps: {
              imageHolderStyle: {},
              imageHolderHoverStyle: {
                backgroundColor: '#f6f8fb'
              },
              showTitle: true,
              media: {
                src: 'https://mixit.ru/upload/iblock/ed0/ed07c4642738785b1f5ea77a58db42cc.png',
                srcset: 'https://mixit.ru/upload/iblock/ed0/ed07c4642738785b1f5ea77a58db42cc.png'
              }
            }
          }
        ]
      },
      {
        id: 11,
        title: 'Аксессуары',
        slug: 'accessories-mixit'
      },
      {
        id: 4,
        title: 'Подарочные наборы',
        slug: 'gift-sets',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Эл. сертификаты',
                slug: 'certificates'
              },
              {
                filter: 'price-under-500',
                title: 'До 500 руб'
              },
              {
                filter: 'price-from-500-to-1000',
                title: 'До 1000 руб'
              },
              {
                filter: 'price-1k-to-2k',
                title: 'До 2000 руб'
              },
              {
                filter: 'price-2k-to-3k',
                title: 'До 3000 руб'
              },
              {
                filter: 'price-upper-3k',
                title: 'Более 3000 руб'
              }
            ]
          },
          {
            slug: 'certificate1000',
            title: 'Подарочный сертификат на 1000 рублей',
            path: '/product/novogodniy-podarochnyy-sertifikat-na-1000-rubley',
            type: 'card',
            media: {
              src: 'https://mixit.ru/static/media/certificate-1000.dcba6385.png',
              srcset: 'https://mixit.ru/static/media/certificate-1000.dcba6385.png'
            },
            cardProps: {
              showTitle: false,
              media: {
                src: 'https://mixit.ru/static/media/certificate-1000.dcba6385.png',
                srcset: 'https://mixit.ru/static/media/certificate-1000.dcba6385.png'
              }
            }
          },
          {
            slug: 'certificate3000',
            title: 'Подарочный сертификат на 3000 рублей',
            path: '/product/novogodniy-podarochnyy-sertifikat-na-3000-rubley',
            type: 'card',
            media: {
              src: 'https://mixit.ru/static/media/certificate-3000.40cc8745.png',
              srcset: 'https://mixit.ru/static/media/certificate-3000.40cc8745.png'
            },
            cardProps: {
              showTitle: false,
              media: {
                src: 'https://mixit.ru/static/media/certificate-3000.40cc8745.png',
                srcset: 'https://mixit.ru/static/media/certificate-3000.40cc8745.png'
              }
            }
          },
          {
            slug: 'certificate5000',
            title: 'Подарочный сертификат на 5000 рублей',
            path: '/product/podarochnyy-sertifikat-k-8-marta-na-5000-rubley',
            type: 'card',
            media: {
              src: 'https://mixit.ru/static/media/certificate-5000.f312e070.png',
              srcset: 'https://mixit.ru/static/media/certificate-5000.f312e070.png'
            },
            cardProps: {
              showTitle: false,
              media: {
                src: 'https://mixit.ru/static/media/certificate-5000.f312e070.png',
                srcset: 'https://mixit.ru/static/media/certificate-5000.f312e070.png'
              }
            }
          }
        ]
      },
      {
        id: 6,
        title: 'Эффективные комплексы',
        slug: 'effective',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Проблемная кожа',
                slug: 'problem-skin'
              },
              {
                title: 'Антивозраст',
                slug: 'anti-age'
              },
              {
                title: 'Для сухой кожи',
                slug: 'soft-skin'
              },
              {
                title: 'Wow эффект',
                slug: 'wow-effect'
              },
              {
                title: 'Коррекция фигуры',
                slug: 'body-correction'
              },
              {
                title: 'Для волос',
                slug: 'for-hair'
              },
              {
                title: 'Для всех типов кожи',
                slug: 'all-skin-types'
              }
            ]
          }
        ]
      },
      {
        id: 8,
        title: 'Акции',
        slug: 'special-offers',
        path: '/special-offers',
        props: {
          style: {
            color: '#e95958'
          }
        }
      }
    ]
  },
  drawer_catalog_navigation: {
    list: [
      {
        id: 8,
        title: 'Акции',
        slug: 'special-offers',
        path: '/special-offers',
        props: {
          style: {
            color: '#e95958'
          }
        }
      },
      {
        id: 9,
        title: 'Новинки',
        path: '/group/new',
        props: {
          style: {
            color: '#e95958'
          }
        }
      },
      {
        id: 1,
        title: 'Лицо',
        slug: 'face',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                id: 12,
                perentId: 1,
                slug: 'skin_type',
                title: 'Тип кожи',
                submenu: [
                  {
                    slug: 'subcategory',
                    title: 'Категории',
                    list: [
                      {
                        title: 'Все типы кожи лица',
                        path: '/group/for-all-skin-type'
                      },
                      {
                        title: 'Комбинированная кожа',
                        path: '/group/combined-skin-type'
                      },
                      {
                        title: 'Сухая кожа',
                        path: '/group/dry-skin-type'
                      },
                      {
                        title: 'Жирная кожа',
                        path: '/group/oily-skin-type'
                      },
                      {
                        title: 'Нормальная кожа лица',
                        path: '/group/normal-skin-type'
                      },
                      {
                        title: 'Чувствительная кожа',
                        path: '/group/sensitive-skin-type'
                      },
                      {
                        title: 'Проблемная кожа лица',
                        path: '/group/problem-skin-type'
                      },
                      {
                        title: 'Обезвоженная кожа лица',
                        path: '/group/dehydrated-skin-type'
                      },
                      {
                        title: 'Возрастная кожа лица',
                        path: '/group/aged-skin-type'
                      }
                    ]
                  }
                ]
              },
              {
                id: 13,
                perentId: 1,
                slug: 'decorate',
                title: 'Декоративная косметика',
                submenu: [
                  {
                    slug: 'subcategory',
                    title: 'Категории',
                    list: [
                      {
                        title: 'Помады и блески',
                        path: '/category/decorate/lipstick'
                      },
                      {
                        title: 'Тональные средства',
                        path: '/category/decorate/tone'
                      },
                      {
                        title: 'Основа под макияж',
                        path: '/category/decorate/base'
                      },
                      {
                        title: 'Тушь для ресниц',
                        path: '/category/decorate/mascara'
                      },
                      {
                        title: 'Средства для бровей',
                        path: '/category/decorate/brows'
                      },
                      {
                        title: 'Пудры и румяна',
                        path: '/category/decorate/powder'
                      },
                      {
                        title: 'Тени для век',
                        path: '/category/decorate/shadow'
                      },
                      {
                        title: 'Карандаши и подводки для глаз',
                        path: '/category/decorate/pencil'
                      },
                      {
                        title: 'Хайлайтеры для лица',
                        path: '/category/decorate/face-highlighters'
                      },
                      {
                        title: 'Защитные комплекты',
                        path: '/category/decorate/safe-complect'
                      }
                    ]
                  }
                ]
              },
              {
                title: 'Кремы',
                slug: 'creme-face'
              },
              {
                title: 'Средства с SPF',
                slug: 'spf-face'
              },
              {
                title: 'Маски',
                slug: 'masks-face'
              },
              {
                title: 'Скрабы и пилинги',
                slug: 'scrub-face'
              },
              {
                title: 'Очищающие средства',
                slug: 'clean'
              },
              {
                title: 'Тоники и лосьоны',
                slug: 'lotion'
              },
              {
                title: 'Сыворотки и концентраты',
                slug: 'serums'
              },
              {
                title: 'Уход за кожей вокруг глаз',
                slug: 'eyes'
              },
              {
                title: 'Уход за кожей губ',
                slug: 'lips'
              },
              {
                title: 'Хайлайтеры',
                slug: 'highlighters'
              },
              {
                title: 'Аксессуары',
                slug: 'accessories'
              }
            ]
          }
        ]
      },
      {
        id: 2,
        title: 'Тело',
        slug: 'body',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Антицеллюлитная косметика',
                slug: 'correction'
              },
              {
                title: 'Средства с SPF',
                slug: 'body-spf'
              },
              {
                title: 'Хайлайтеры для тела',
                slug: 'highlighters-body'
              },
              {
                title: 'Гигиена (гели и мыло)',
                slug: 'gels'
              },
              {
                title: 'Кремы, бальзамы и масла',
                slug: 'creme-body'
              },
              {
                title: 'Скрабы и пилинги',
                slug: 'scrub-body'
              },
              {
                title: 'Уход для рук и ног',
                slug: 'hands'
              },
              {
                title: 'Соли, бомбы, пена для ванн',
                slug: 'salts'
              },
              {
                title: 'Дезодоранты и парфюм',
                slug: 'parfume'
              },
              {
                title: 'Косметика для мужчин',
                slug: 'man-body'
              },
              {
                title: 'Аксессуары',
                slug: 'accessories'
              }
            ]
          }
        ]
      },
      {
        id: 3,
        title: 'Волосы',
        slug: 'hair',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Шампуни',
                slug: 'shampoo'
              },
              {
                title: 'Бальзамы и кондиционеры',
                slug: 'balm'
              },
              {
                title: 'Маски',
                slug: 'masks-hair'
              },
              {
                title: 'Масла и эликсиры',
                slug: 'elixirs'
              },
              {
                title: 'Для мужчин',
                slug: 'man-hair'
              },
              {
                title: 'Аксессуары',
                slug: 'accessories'
              }
            ]
          }
        ]
      },
      {
        id: 11,
        title: 'Аксессуары',
        slug: 'accessories-mixit'
      },
      {
        id: 7,
        title: 'Здоровье',
        slug: 'health',
        type: 'card',
        props: {
          style: {
            color: '#25bc8b'
          }
        },
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Витамины',
                slug: 'vitamins'
              },
              {
                title: 'Антисептики',
                slug: 'antiseptic'
              },
              {
                title: 'Маски',
                slug: 'masks-health'
              },
              {
                title: 'Защитные комплекты',
                path: '/group/safe-complect'
              }
            ]
          }
        ]
      },
      {
        id: 10,
        title: 'Для животных',
        slug: 'for-pets',
        props: {
          style: {
            color: '#25bc8b'
          }
        }
      },
      {
        id: 4,
        title: 'Подарочные наборы',
        slug: 'gift-sets',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Эл. сертификаты',
                slug: 'certificates'
              },
              {
                filter: 'price-under-500',
                title: 'До 500 руб'
              },
              {
                filter: 'price-from-500-to-1000',
                title: 'До 1000 руб'
              },
              {
                filter: 'price-1k-to-2k',
                title: 'До 2000 руб'
              },
              {
                filter: 'price-2k-to-3k',
                title: 'До 3000 руб'
              },
              {
                filter: 'price-upper-3k',
                title: 'Более 3000 руб'
              }
            ]
          }
        ]
      },
      {
        id: 6,
        title: 'Эффективные комплексы',
        slug: 'effective',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Проблемная кожа',
                slug: 'problem-skin'
              },
              {
                title: 'Антивозраст',
                slug: 'anti-age'
              },
              {
                title: 'Для сухой кожи',
                slug: 'soft-skin'
              },
              {
                title: 'Wow эффект',
                slug: 'wow-effect'
              },
              {
                title: 'Коррекция фигуры',
                slug: 'body-correction'
              },
              {
                title: 'Для волос',
                slug: 'for-hair'
              },
              {
                title: 'Для всех типов кожи',
                slug: 'all-skin-types'
              }
            ]
          }
        ]
      }
    ]
  },
  footer_first_column: {
    title: 'Каталог',
    list: [
      {
        id: 1,
        title: 'Лицо',
        slug: 'face'
      },

      {
        id: 2,
        title: 'Тело',
        slug: 'body'
      },

      {
        id: 3,
        title: 'Волосы',
        slug: 'hair'
      },

      {
        id: 7,
        title: 'Здоровье',
        slug: 'health',
        type: 'card'
      },

      {
        id: 7.1,
        title: 'Для животных',
        slug: 'for-pets',
        type: 'card'
      },

      {
        id: 4,
        title: 'Подарочные наборы',
        slug: 'gift-sets'
      },

      {
        id: 6,
        title: 'Эффективные комплексы',
        slug: 'effective'
      },

      {
        id: 5,
        title: 'Декоративная косметика',
        slug: 'decorate'
      },

      {
        id: 18,
        title: 'Collaboration',
        path: 'group/collaboration/'
      },

      {
        id: 19,
        title: 'Retail',
        path: '/group/retail'
      }
    ]
  },
  footer_second_column: {
    title: 'Клиенту',
    list: [
      {
        id: 1,
        title: 'Доставка и оплата',
        path: '/payment-and-delivery'
      },
      {
        id: 3,
        title: 'Бонусная программа',
        href: 'https://mixit.ru/top/loyalty-program/'
      },
      {
        id: 4,
        title: 'Пользовательские соглашения',
        path: '/agreements'
      },
      {
        id: 5,
        title: 'Сертификаты',
        path: '/gift-sertifikat-rules'
      },
      {
        id: 6,
        title: 'Политика использования cookie',
        path: '/cookie'
      },
      {
        id: 7,
        title: 'Обработка персональных данных',
        href: 'https://mixit.ru/upload/personal_data.pdf'
      },
      {
        id: 8,
        title: 'Условия программы лояльности',
        path: '/bonus-conditions'
      },
      {
        id: 8,
        title: 'Вопросы и ответы',
        path: '/faq'
      },
    ]
  },
  footer_third_column: {
    title: 'Разное',
    list: [
      {
        id: 1,
        title: 'Новинки',
        path: '/group/new'
      },
      {
        id: 3,
        title: 'Бестселлер',
        path: '/group/bestsellers'
      },
      {
        id: 4,
        title: 'Акции',
        path: '/special-offers'
      },
      {
        id: 5,
        title: 'Beauty-блог',
        path: '/magazine'
      },
      {
        id: 6,
        title: 'Политика использования cookie',
        path: '/cookie'
      },
      {
        id: 8,
        title: 'Пресса о нас',
        path: '/mass-media-about-us'
      },
      {
        id: 7,
        title: 'Оптовые продажи',
        href: 'https://mixit.ru/top/health-opt/'
      },
      {
        id: 14,
        title: 'Насилию.нет',
        href: 'https://t.me/nasiliu_net_bot'
      }
    ]
  },
  footer_fourth_column: {
    title: 'Компания',
    list: [
      {
        id: 1,
        title: 'О компании',
        path: '/about'
      },
      {
        id: 3,
        title: 'Контакты',
        path: '/contacts'
      },
      {
        id: 4,
        title: 'Магазины',
        path: '/shops'
      },
      {
        id: 5,
        title: 'Франшиза',
        href: 'http://franchise.mixit.ru/'
      },
      {
        id: 6,
        title: 'Декларации',
        path: '/reward'
      },
      {
        id: 7,
        title: 'Карьера',
        href: 'http://hr.mixit.ru/'
      },
      // {
      //   id: 15,
      //   title: 'Директор по счастью',
      //   path: '/happy-master'
      // },
      {
        id: 16,
        title: 'Веб-мастерам',
        href: 'https://advcake.com/lp/mixit/'
      }
    ]
  },
  footer_mobile: {
    list: [
      {
        id: 1,
        title: 'О нас',
        path: '/about'
      },
      {
        id: 2,
        title: 'Контакты',
        path: '/contacts'
      },
      {
        id: 3,
        title: 'Доставка и оплата',
        path: '/payment-and-delivery'
      },
      {
        id: 4,
        title: 'Магазины',
        path: '/shops'
      },
      {
        id: 5,
        title: 'Сертификаты',
        path: '/gift-sertifikat-rules'
      },
      {
        id: 6,
        title: 'Франшиза',
        href: 'http://franchise.mixit.ru/'
      },
      {
        id: 7,
        title: 'Бонусная программа',
        href: 'https://mixit.ru/top/loyalty-program/'
      },
      {
        id: 14,
        title: 'Насилию.нет',
        href: 'https://t.me/nasiliu_net_bot'
      },
      // {
      //   id: 15,
      //   title: 'Директор по счастью',
      //   path: '/happy-master'
      // },
      {
        id: 16,
        title: 'Веб-мастерам',
        href: 'https://advcake.com/lp/mixit/'
      }
    ]
  },
  category_filter_panel: {
    list: [
      {
        id: 1,
        title: 'Для лица',
        slug: 'face'
      },
      {
        id: 2,
        title: 'Для тела',
        slug: 'body'
      },
      {
        id: 3,
        title: 'Для волос',
        slug: 'hair'
      },
      {
        id: 7,
        title: 'Здоровье',
        slug: 'health'
      },
      {
        id: 4,
        title: 'Наборы и подарки',
        slug: 'gift-sets'
      },
      {
        id: 6,
        title: 'Эффективные комплексы',
        slug: 'effective'
      }
    ]
  },
  global_mobile_navigation: {
    list: [
      {
        id: 1,
        title: 'Магазины',
        path: '/shops'
      },
      {
        id: 2,
        title: 'Beauty-блог',
        path: '/magazine'
      },
      // {
      //   id: 3,
      //   title: 'MIXIT.Care',
      //   href: 'https://mixit.ru/care'
      // },
      {
        id: 4,
        title: 'Доставка и оплата',
        path: '/payment-and-delivery'
      },
      {
        id: 5,
        title: 'Франшиза',
        href: 'http://franchise.mixit.ru/'
      },
      {
        id: 6,
        title: 'Бонусная программа',
        href: 'https://mixit.ru/top/loyalty-program/'
      },
      {
        id: 7,
        title: 'Контакты',
        path: '/contacts'
      },
      {
        id: 8,
        title: 'Карьера',
        href: 'http://hr.mixit.ru/'
      },
      {
        id: 9,
        title: 'Оптовые продажи',
        href: 'https://mixit.ru/top/health-opt/'
      },
      {
        id: 10,
        title: 'Декларации',
        path: '/reward'
      },
      {
        id: 11,
        title: 'Вопросы и ответы',
        path: '/faq'
      },

      {
        id: 18,
        title: 'Collaboration',
        path: 'group/collaboration/'
      },

      {
        id: 19,
        title: 'Retail',
        path: '/group/retail'
      }
      // {
      //   id: 15,
      //   title: 'Директор по счастью',
      //   path: '/happy-master'
      // }
    ]
  }
};

export default navigationConfig;

export const navigationConfig2 = {
  options: { version: 5, from: 'BACK' },
  main_catalog_navigation: {
    list: [
      {
        id: 1,
        title: 'Лицо',
        slug: 'face',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Кремы',
                slug: 'creme-face'
              },
              {
                title: 'Маски',
                slug: 'masks-face'
              },
              {
                title: 'Скрабы и пилинги',
                slug: 'scrub-face'
              },
              {
                title: 'Очищающие средства',
                slug: 'clean'
              },
              {
                title: 'Тоники и лосьоны',
                slug: 'lotion'
              },
              {
                title: 'Сыворотки и концентраты',
                slug: 'serums'
              },
              {
                title: 'Уход за кожей вокруг глаз',
                slug: 'eyes'
              },
              {
                title: 'Уход за кожей губ',
                slug: 'lips'
              },
              {
                title: 'Хайлайтеры',
                slug: 'highlighters'
              }
            ]
          },
          {
            slug: 'skin_type',
            title: 'Тип кожи',
            list: [
              {
                title: 'Нормальная',
                filter: 'skin-type-normal'
              },
              {
                title: 'Комбинированная',
                filter: 'skin-type-combined'
              },
              {
                title: 'Жирная',
                filter: 'skin-type-fatty'
              },
              {
                title: 'Сухая и чувствительная',
                filter: 'skin-type-dry-and-sensitive'
              },
              {
                title: 'Проблемная',
                filter: 'skin-type-problem'
              },
              {
                title: 'Возрастная',
                filter: 'skin-type-aged'
              }
            ]
          },
          {
            slug: 'propose',
            title: 'Цель применения',
            list: [
              {
                title: 'Увлажнение',
                filter: 'skin-needs-moisturizing'
              },
              {
                title: 'Питание и восстановление',
                filter: 'skin-needs-recovery'
              },
              {
                title: 'Сияние и энергия',
                filter: 'skin-needs-energy'
              },
              {
                title: 'Сужение пор, матирование',
                filter: 'skin-needs-matting'
              },
              {
                title: 'Лифтинг и омоложение',
                filter: 'skin-needs-rejuvenation'
              },
              {
                title: 'Очищение',
                filter: 'skin-needs-cleansing'
              },
              {
                title: 'Пилинг, oтшелушивание',
                filter: 'skin-needs-peeling'
              },
              {
                title: 'Контроль над проблемной кожей',
                filter: 'skin-needs-control'
              },
              {
                title: 'Ровный тон и цвет лица',
                filter: 'skin-needs-complexion'
              }
            ]
          }
        ]
      },
      {
        id: 2,
        title: 'Тело',
        slug: 'body',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Гели для душа',
                slug: 'gels'
              },
              {
                title: 'Кремы, бальзамы и масла',
                slug: 'creme-body'
              },
              {
                title: 'Скрабы и пилинги',
                slug: 'scrub-body'
              },
              {
                title: 'Мыло',
                slug: 'soap'
              },
              {
                title: 'Соли и сливки для ванн',
                slug: 'salts'
              },
              {
                title: 'Коррекция фигуры',
                slug: 'correction'
              },
              {
                title: 'Средства для рук и ног',
                slug: 'hands'
              },
              {
                title: 'Для мужчин',
                slug: 'man-body'
              },
              {
                title: 'Арома-средства',
                slug: 'parfume'
              },
              {
                title: 'Хайлайтеры',
                slug: 'highlighters-body'
              }
            ]
          },
          {
            slug: 'skin_type',
            title: 'Тип кожи',
            list: [
              {
                title: 'Нормальная',
                filter: 'skin-type-normal'
              },
              {
                title: 'Комбинированная',
                filter: 'skin-type-combined'
              },
              {
                title: 'Жирная',
                filter: 'skin-type-fatty'
              },
              {
                title: 'Сухая и чувствительная',
                filter: 'skin-type-dry-and-sensitive'
              },
              {
                title: 'Проблемная',
                filter: 'skin-type-problem'
              },
              {
                title: 'Возрастная',
                filter: 'skin-type-aged'
              }
            ]
          },
          {
            slug: 'purpose',
            title: 'Цель применения',
            list: [
              {
                title: 'Увлажнение',
                filter: 'skin-needs-moisturizing'
              },
              {
                title: 'Питание и восстановление',
                filter: 'skin-needs-recovery'
              },
              {
                title: 'Лифтинг и омоложение',
                filter: 'skin-needs-rejuvenation'
              },
              {
                title: 'Очищение',
                filter: 'skin-needs-cleansing'
              },
              {
                title: 'Пилинг, oтшелушивание',
                filter: 'skin-needs-peeling'
              },
              {
                title: 'Тонизирование',
                filter: 'skin-needs-toning'
              },
              {
                title: 'Борьба с целлюлитом и растяжками',
                filter: 'skin-needs-cellulite'
              },
              {
                title: 'Массаж',
                filter: 'skin-needs-massage'
              }
            ]
          }
        ]
      },
      {
        id: 3,
        title: 'Волосы',
        slug: 'hair',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Шампуни',
                slug: 'shampoo'
              },
              {
                title: 'Бальзамы и кондиционеры',
                slug: 'balm'
              },
              {
                title: 'Маски',
                slug: 'masks-hair'
              },
              {
                title: 'Масла и эликсиры',
                slug: 'elixirs'
              },
              {
                title: 'Для мужчин',
                slug: 'man-hair'
              }
            ]
          },
          {
            slug: 'hair_type',
            title: 'Тип волос',
            list: [
              {
                title: 'Сухие',
                filter: 'hair-type-dry'
              },
              {
                title: 'Нормальные',
                filter: 'hair-type-normal'
              },
              {
                title: 'Комбинированные',
                filter: 'hair-type-combined'
              },
              {
                title: 'Жирные',
                filter: 'hair-type-oily'
              },
              {
                title: 'Окрашенные',
                filter: 'hair-type-dyed'
              }
            ]
          },
          {
            slug: 'purpose',
            title: 'Потребности волос',
            list: [
              {
                title: 'Очищение',
                filter: 'hair-needs-cleansing'
              },
              {
                title: 'Питание и восстановление',
                filter: 'hair-needs-nutrition'
              },
              {
                title: 'Увлажнение и кондиционирование',
                filter: 'hair-needs-moisturizing'
              },
              {
                title: 'Рост и укрепление волос',
                filter: 'hair-needs-сrowth'
              },
              {
                title: 'Сохранение цвета волос',
                filter: 'hair-needs-color-preservation'
              },
              {
                title: 'Облегчение укладки волос',
                filter: 'hair-needs-styling'
              }
            ]
          }
        ]
      },
      {
        id: 7,
        title: 'Здоровье',
        slug: 'health',
        type: 'card',
        props: {
          style: {
            color: '#25bc8b'
          }
        },
        submenu: [
          {
            slug: 'vitamins',
            title: 'Витамины',
            type: 'card',
            cardProps: {
              imageHolderStyle: {},
              imageHolderHoverStyle: {
                backgroundColor: '#f6f8fb'
              },
              titleStyle: {},
              showTitle: true,
              media: {
                src: 'https://mixit.ru/upload/iblock/758/758e10060c418a49137b49872e6bab32.png',
                srcset: 'https://mixit.ru/upload/iblock/758/758e10060c418a49137b49872e6bab32.png'
              }
            }
          },
          {
            slug: 'antiseptic',
            title: 'Антисептики',
            type: 'card',
            cardProps: {
              imageHolderStyle: {},
              imageHolderHoverStyle: {
                backgroundColor: '#f6f8fb'
              },
              showTitle: true,
              media: {
                src: 'https://mixit.ru/upload/iblock/4d8/4d8f55395e9536a80582512bdb62279b.png',
                srcset: 'https://mixit.ru/upload/iblock/4d8/4d8f55395e9536a80582512bdb62279b.png'
              }
            }
          },
          {
            slug: 'masks-health',
            title: 'Маски',
            type: 'card',
            cardProps: {
              imageHolderStyle: {},
              imageHolderHoverStyle: {
                backgroundColor: '#f6f8fb'
              },
              showTitle: true,
              media: {
                src: 'https://mixit.ru/upload/iblock/849/8498c93dd7565151a37fa82515065811.png',
                srcset: 'https://mixit.ru/upload/iblock/849/8498c93dd7565151a37fa82515065811.png'
              }
            }
          },
          {
            path: '/group/safe-complect',
            title: 'Защитные комплекты',
            type: 'card',
            cardProps: {
              imageHolderStyle: {},
              imageHolderHoverStyle: {
                backgroundColor: '#f6f8fb'
              },
              showTitle: true,
              media: {
                src: 'https://mixit.ru/upload/iblock/319/319907143fe813467421f0866890718f.png',
                srcset: 'https://mixit.ru/upload/iblock/319/319907143fe813467421f0866890718f.png'
              }
            }
          }
        ]
      },
      {
        id: 5,
        title: 'Декоративная косметика',
        slug: 'decorate'
      },
      {
        id: 4,
        title: 'Подарочные наборы',
        slug: 'gift-sets',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Эл. сертификаты',
                slug: 'certificates'
              },
              {
                filter: 'price-under-500',
                title: 'До 500 руб'
              },
              {
                filter: 'price-from-500-to-1000',
                title: 'До 1000 руб'
              },
              {
                filter: 'price-1k-to-2k',
                title: 'До 2000 руб'
              },
              {
                filter: 'price-2k-to-3k',
                title: 'До 3000 руб'
              },
              {
                filter: 'price-upper-3k',
                title: 'Более 3000 руб'
              }
            ]
          },
          {
            slug: 'certificate1000',
            title: 'Подарочный сертификат на 1000 рублей',
            path: '/product/novogodniy-podarochnyy-sertifikat-na-1000-rubley',
            type: 'card',
            media: {
              src: 'https://mixit.ru/static/media/certificate-1000.dcba6385.png',
              srcset: 'https://mixit.ru/static/media/certificate-1000.dcba6385.png'
            },
            cardProps: {
              showTitle: false,
              media: {
                src: 'https://mixit.ru/static/media/certificate-1000.dcba6385.png',
                srcset: 'https://mixit.ru/static/media/certificate-1000.dcba6385.png'
              }
            }
          },
          {
            slug: 'certificate3000',
            title: 'Подарочный сертификат на 3000 рублей',
            path: '/product/novogodniy-podarochnyy-sertifikat-na-3000-rubley',
            type: 'card',
            media: {
              src: 'https://mixit.ru/static/media/certificate-3000.40cc8745.png',
              srcset: 'https://mixit.ru/static/media/certificate-3000.40cc8745.png'
            },
            cardProps: {
              showTitle: false,
              media: {
                src: 'https://mixit.ru/static/media/certificate-3000.40cc8745.png',
                srcset: 'https://mixit.ru/static/media/certificate-3000.40cc8745.png'
              }
            }
          },
          {
            slug: 'certificate5000',
            title: 'Подарочный сертификат на 5000 рублей',
            path: '/product/podarochnyy-sertifikat-k-8-marta-na-5000-rubley',
            type: 'card',
            media: {
              src: 'https://mixit.ru/static/media/certificate-5000.f312e070.png',
              srcset: 'https://mixit.ru/static/media/certificate-5000.f312e070.png'
            },
            cardProps: {
              showTitle: false,
              media: {
                src: 'https://mixit.ru/static/media/certificate-5000.f312e070.png',
                srcset: 'https://mixit.ru/static/media/certificate-5000.f312e070.png'
              }
            }
          }
        ]
      },
      {
        id: 6,
        title: 'Эффективные комплексы',
        slug: 'effective',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Проблемная кожа',
                slug: 'problem-skin'
              },
              {
                title: 'Антивозраст',
                slug: 'anti-age'
              },
              {
                title: 'Для сухой кожи',
                slug: 'soft-skin'
              },
              {
                title: 'Wow эффект',
                slug: 'wow-effect'
              },
              {
                title: 'Коррекция фигуры',
                slug: 'body-correction'
              },
              {
                title: 'Для волос',
                slug: 'for-hair'
              },
              {
                title: 'Для всех типов кожи',
                slug: 'all-skin-types'
              }
            ]
          }
        ]
      },
      {
        id: 8,
        title: 'Акции',
        slug: 'special-offers',
        path: '/special-offers',
        props: {
          style: {
            color: '#e95958'
          }
        }
      }
    ]
  },
  drawer_catalog_navigation: {
    list: [
      {
        id: 1,
        title: 'Лицо',
        slug: 'face',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Кремы',
                slug: 'creme-face'
              },
              {
                title: 'Маски',
                slug: 'masks-face'
              },
              {
                title: 'Скрабы и пилинги',
                slug: 'scrub-face'
              },
              {
                title: 'Очищающие средства',
                slug: 'clean'
              },
              {
                title: 'Тоники и лосьоны',
                slug: 'lotion'
              },
              {
                title: 'Сыворотки и концентраты',
                slug: 'serums'
              },
              {
                title: 'Уход за кожей вокруг глаз',
                slug: 'eyes'
              },
              {
                title: 'Уход за кожей губ',
                slug: 'lips'
              },
              {
                title: 'Хайлайтеры',
                slug: 'highlighters'
              }
            ]
          }
        ]
      },
      {
        id: 2,
        title: 'Тело',
        slug: 'body',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Гели для душа',
                slug: 'gels'
              },
              {
                title: 'Кремы, бальзамы и масла',
                slug: 'creme-body'
              },
              {
                title: 'Скрабы и пилинги',
                slug: 'scrub-body'
              },
              {
                title: 'Мыло',
                slug: 'soap'
              },
              {
                title: 'Соли и сливки для ванн',
                slug: 'salts'
              },
              {
                title: 'Коррекция фигуры',
                slug: 'correction'
              },
              {
                title: 'Средства для рук и ног',
                slug: 'hands'
              },
              {
                title: 'Для мужчин',
                slug: 'man-body'
              },
              {
                title: 'Арома-средства',
                slug: 'parfume'
              },
              {
                title: 'Хайлайтеры',
                slug: 'highlighters-body'
              }
            ]
          }
        ]
      },
      {
        id: 3,
        title: 'Волосы',
        slug: 'hair',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Шампуни',
                slug: 'shampoo'
              },
              {
                title: 'Бальзамы и кондиционеры',
                slug: 'balm'
              },
              {
                title: 'Маски',
                slug: 'masks-hair'
              },
              {
                title: 'Масла и эликсиры',
                slug: 'elixirs'
              },
              {
                title: 'Для мужчин',
                slug: 'man-hair'
              }
            ]
          }
        ]
      },
      {
        id: 7,
        title: 'Здоровье',
        slug: 'health',
        type: 'card',
        props: {
          style: {
            color: '#25bc8b'
          }
        },
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Витамины',
                slug: 'vitamins'
              },
              {
                title: 'Антисептики',
                slug: 'antiseptic'
              },
              {
                title: 'Маски',
                slug: 'masks-health'
              },
              {
                title: 'Защитные комплекты',
                path: '/group/safe-complect'
              }
            ]
          }
        ]
      },
      {
        id: 4,
        title: 'Подарочные наборы',
        slug: 'gift-sets',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Эл. сертификаты',
                slug: 'certificates'
              },
              {
                filter: 'price-under-500',
                title: 'До 500 руб'
              },
              {
                filter: 'price-from-500-to-1000',
                title: 'До 1000 руб'
              },
              {
                filter: 'price-1k-to-2k',
                title: 'До 2000 руб'
              },
              {
                filter: 'price-2k-to-3k',
                title: 'До 3000 руб'
              },
              {
                filter: 'price-upper-3k',
                title: 'Более 3000 руб'
              }
            ]
          }
        ]
      },
      {
        id: 6,
        title: 'Эффективные комплексы',
        slug: 'effective',
        submenu: [
          {
            slug: 'subcategory',
            title: 'Категории',
            list: [
              {
                title: 'Проблемная кожа',
                slug: 'problem-skin'
              },
              {
                title: 'Антивозраст',
                slug: 'anti-age'
              },
              {
                title: 'Для сухой кожи',
                slug: 'soft-skin'
              },
              {
                title: 'Wow эффект',
                slug: 'wow-effect'
              },
              {
                title: 'Коррекция фигуры',
                slug: 'body-correction'
              },
              {
                title: 'Для волос',
                slug: 'for-hair'
              },
              {
                title: 'Для всех типов кожи',
                slug: 'all-skin-types'
              }
            ]
          }
        ]
      },
      {
        id: 5,
        title: 'Декоративная косметика',
        slug: 'decorate'
      },
      {
        id: 8,
        title: 'Акции',
        slug: 'special-offers',
        path: '/special-offers',
        props: {
          style: {
            color: '#e95958'
          }
        }
      }
    ]
  },
  footer_first_column: {
    title: 'Каталог',
    list: [
      {
        id: 1,
        title: 'Лицо',
        slug: 'face'
      },
      {
        id: 2,
        title: 'Тело',
        slug: 'body'
      },
      {
        id: 3,
        title: 'Волосы',
        slug: 'hair'
      },
      {
        id: 7,
        title: 'Здоровье',
        slug: 'health',
        type: 'card'
      },
      {
        id: 4,
        title: 'Подарочные наборы',
        slug: 'gift-sets'
      },
      {
        id: 6,
        title: 'Эффективные комплексы',
        slug: 'effective'
      },
      {
        id: 5,
        title: 'Декоративная косметика',
        slug: 'decorate'
      }
    ]
  },
  footer_second_column: {
    title: 'Клиенту',
    list: [
      {
        id: 1,
        title: 'Доставка и оплата',
        path: '/payment-and-delivery'
      },
      {
        id: 3,
        title: 'Бонусная программа',
        href: 'https://mixit.ru/top/loyalty-program/'
      },
      {
        id: 4,
        title: 'Пользовательские соглашения',
        path: '/agreements'
      },
      {
        id: 5,
        title: 'Сертификаты',
        path: '/gift-sertifikat-rules'
      },
      {
        id: 6,
        title: 'Политика использования cookie',
        path: '/cookie'
      },
      {
        id: 7,
        title: 'Обработка персональных данных',
        href: 'https://mixit.ru/upload/personal_data.pdf'
      }
    ]
  },
  footer_third_column: {
    title: 'Разное',
    list: [
      {
        id: 1,
        title: 'Новинки',
        path: '/group/new'
      },
      {
        id: 3,
        title: 'Бестселлер',
        path: '/group/bestsellers'
      },
      {
        id: 4,
        title: 'Акции',
        path: '/special-offers'
      },
      {
        id: 5,
        title: 'Beauty-блог',
        path: '/magazine'
      },
      {
        id: 6,
        title: 'Политика использования cookie',
        path: '/cookie'
      },
      {
        id: 7,
        title: 'MIXIT.Care',
        href: 'https://mixit.ru/care'
      }
    ]
  },
  footer_fourth_column: {
    title: 'Компания',
    list: [
      {
        id: 1,
        title: 'О компании',
        path: '/about'
      },
      {
        id: 3,
        title: 'Контакты',
        path: '/contacts'
      },
      {
        id: 4,
        title: 'Магазины',
        path: '/shops'
      },
      {
        id: 5,
        title: 'Франшиза',
        href: 'http://franchise.mixit.ru/'
      },
      {
        id: 6,
        title: 'Декларации',
        path: '/reward'
      },
      {
        id: 7,
        title: 'Карьера',
        href: 'http://hr.mixit.ru/'
      }
    ]
  },
  footer_mobile: {
    list: [
      {
        id: 1,
        title: 'О нас',
        path: '/about'
      },
      {
        id: 2,
        title: 'Контакты',
        path: '/contacts'
      },
      {
        id: 3,
        title: 'Доставка и оплата',
        path: '/payment-and-delivery'
      },
      {
        id: 4,
        title: 'Магазины',
        path: '/shops'
      },
      {
        id: 5,
        title: 'Сертификаты',
        path: '/gift-sertifikat-rules'
      },
      {
        id: 6,
        title: 'Франшиза',
        href: 'http://franchise.mixit.ru/'
      },
      {
        id: 7,
        title: 'Бонусная программа',
        href: 'https://mixit.ru/top/loyalty-program/'
      }
    ]
  },
  category_filter_panel: {
    list: [
      {
        id: 1,
        title: 'Для лица',
        slug: 'face'
      },
      {
        id: 2,
        title: 'Для тела',
        slug: 'body'
      },
      {
        id: 3,
        title: 'Для волос',
        slug: 'hair'
      },
      {
        id: 7,
        title: 'Здоровье',
        slug: 'health'
      },
      {
        id: 4,
        title: 'Наборы и подарки',
        slug: 'gift-sets'
      },
      {
        id: 6,
        title: 'Эффективные комплексы',
        slug: 'effective'
      }
    ]
  },
  global_mobile_navigation: {
    list: [
      {
        id: 1,
        title: 'Магазины',
        path: '/shops'
      },
      {
        id: 2,
        title: 'Beauty-блог',
        path: '/magazine'
      },
      {
        id: 3,
        title: 'MIXIT.Care',
        href: 'https://mixit.ru/care'
      },
      {
        id: 4,
        title: 'Доставка и оплата',
        path: '/payment-and-delivery'
      },
      {
        id: 5,
        title: 'Франшиза',
        href: 'http://franchise.mixit.ru/'
      },
      {
        id: 6,
        title: 'Бонусная программа',
        href: 'https://mixit.ru/top/loyalty-program/'
      },
      {
        id: 7,
        title: 'Контакты',
        path: '/contacts'
      },
      {
        id: 8,
        title: 'Вакансии',
        href: 'http://hr.mixit.ru/'
      },
      {
        id: 10,
        title: 'Декларации',
        path: '/reward'
      }
    ]
  }
};
