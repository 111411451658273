import React, { Component } from 'react';
import SubscribeContainer from 'modules/core/containers/subscribe';

class SubscriptionSection extends Component {
  state = {};
  render() {
    return (
      <SubscribeContainer />
    );
  }
}

export default SubscriptionSection;
