import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReviewRating from 'modules/review/components/review-rating/review-rating';

import { formatRating } from './helpers';

import './review-summary.css';

const { number } = PropTypes;

class ReviewSummary extends Component {
  static propTypes = {
    rating: number
  };

  render() {
    const { rating } = this.props;
    const formatedRating = formatRating(rating);

    return (
      <div className="ReviewSummary">
        <div className="ReviewSummary-stars">
          <ReviewRating rating={rating} big />
        </div>
        <div className="ReviewSummary-number">
          <span className="ReviewSummary-amount">{formatedRating}</span> из 5
        </div>
      </div>
    );
  }
}

export default ReviewSummary;
