import * as account from './account-saga';
import * as vkAuth from './vk-auth-saga';
import * as facebookAuth from './facebook-auth-saga';
import * as callback from './callback-saga';
import * as banner from './banner-saga';
import * as recoveryPassword from './recovery-password-saga';
import * as instagramPosts from './instagram-posts-saga';
import * as shopList from './shop-list-saga';
import * as subscribe from './subscribe-saga';
import * as deprecatedSpecialOffers from './deprecated-special-offers-saga';
import * as specialOffers from './special-offers-saga';
import * as UTMBanner from './utm-banner-saga';
import * as UTMTags from './utm-tags-cookie';
import * as ecommerce from './ecommerce';
// import * as dynamicRemarketing from './dynamic-remarketing';
import * as feedback from './feedback-saga';
import * as emailFeedbackSaga from './email-feedback-saga';
import * as toasts from './toasts-saga';
import * as navigations from './navigations-saga';
import * as abtest from './a-b-test';
import * as faq from './faq-saga';
import * as DrawRollUp from './draw-roll-up-saga';
import * as EmailConfirmDialog from './email-confirm-dialog-saga';
import * as MatchQueryParams from './match-query-params';

const sagas = {
  ...account,
  ...vkAuth,
  ...facebookAuth,
  ...callback,
  ...banner,
  ...recoveryPassword,
  ...instagramPosts,
  ...shopList,
  ...subscribe,
  ...deprecatedSpecialOffers,
  ...specialOffers,
  ...UTMBanner,
  ...ecommerce,
  ...feedback,
  ...emailFeedbackSaga,
  ...toasts,
  ...UTMTags,
  ...navigations,
  ...abtest,
  ...faq,
  ...DrawRollUp,
  ...EmailConfirmDialog,
  ...MatchQueryParams
  // ...dynamicRemarketing
};

export default function register(middleware) {
  return () => Object.values(sagas).map(middleware.run);
}
