import React, { Component } from 'react';

import Trigger from 'modules/core/components/trigger/trigger';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';
import { Select } from 'modules/form/index';

import BonusHistoryTableMobile from '../../bonus-history-table-mobile/bonus-history-table-mobile';

import { byType, byDate } from './helpers/sort';

import './mobile.css';

const dateFilters = {
  all: { title: 'Все', name: 'Все', value: 'all' },
  week: { title: 'За 7 дней', name: 'За 7 дней', value: 'week' },
  month: { title: 'За 30 дней', name: 'За 30 дней', value: 'month' }
};

const bonusTypes = {
  income: 'начисленных',
  expense: 'списанных'
};

class BonusHistorySceneMobile extends Component {
  state = {
    bonusType: 'income',
    date: 'all'
  };

  render() {
    const { transactions, openedOutsideId } = this.props;
    const { bonusType, date } = this.state;

    const durationFilterList = Object.values(dateFilters);

    const transactionList = transactions.filter(byType(bonusType)).filter(byDate(date));

    const filteredBonusesExist = transactionList.length > 0;
    const updateKey = bonusType + date;

    return (
      <div className="BonusHistoryScene_Mobile">
        <h2 className="BonusHistoryScene_Mobile-title">История бонусов</h2>

        <div className="BonusHistoryScene_Mobile-typeFilter">
          <Trigger
            expanded
            size="small"
            value={bonusType}
            onChange={this.handleBonusTypeFilterChange}
          >
            <Trigger.Item value="income">Начисленные</Trigger.Item>
            <Trigger.Item value="expense">Списанные</Trigger.Item>
          </Trigger>
        </div>

        <div className="BonusHistoryScene_Mobile-durationFilter">
          <Select
            options={durationFilterList}
            value={date}
            onChange={this.handleDateFilterChange}
          />
        </div>

        <HideErrorBoundary>
          <div className="BonusHistoryScene_Mobile-table">
            {filteredBonusesExist ? (
              <BonusHistoryTableMobile
                key={updateKey}
                transactions={transactionList}
                bonusType={bonusType}
                openedOutsideId={openedOutsideId}
              />
            ) : (
              `Нет ${bonusTypes[bonusType]} бонусов за данный период`
            )}
          </div>
        </HideErrorBoundary>
      </div>
    );
  }

  setBonusType(bonusType) {
    this.setState({
      bonusType
    });
  }

  setDateFilter(date) {
    this.setState({
      date
    });
  }

  handleBonusTypeFilterChange = bonusType => {
    const { onFilterChange } = this.props;

    this.setBonusType(bonusType);
    onFilterChange();
  };

  handleDateFilterChange = e => {
    const { value } = e.target;
    const { onFilterChange } = this.props;

    this.setDateFilter(value);
    onFilterChange();
  };
}

export default BonusHistorySceneMobile;
