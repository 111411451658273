import React, { Component } from 'react';

import Link from '../link/short-link';
import FavouriteIcon from 'modules/product/components/favourite-icon/favourite-icon';

class ButtonAddFavorite extends Component {
  render() {
    const { inFavorite, ...rest } = this.props;

    return (
      <Link
        prependedIcon={<FavouriteIcon active={inFavorite} />}
        component="button"
        type="button"
        {...rest}
      >
        {inFavorite ? 'В избранном' : 'В избранное'}
      </Link>
    );
  }
}

export default ButtonAddFavorite;
