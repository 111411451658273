import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import qs from 'qs';
import { createReducer, createAction, handle as on } from 'modules/utils/dux';

import { getProductsModule } from '../meta';
import * as fromProducts from './products';
import * as fromFilters from './filters';
import * as TYPES from './types/categories';
import * as SEARCH from './types/search';

/* TYPES */

/* ACTIONS */

export const actions = {
  showItem(slug, nestedSlug, filters = []) {
    return createAction(TYPES.SHOW_ITEM, { slug, nestedSlug, filters });
  },
  addItem(slug, category) {
    return createAction(TYPES.ADD_ITEM, { slug, category });
  },
  addProducts(slug, products = [], totalAmount, skipCount = 0) {
    return createAction(TYPES.ADD_PRODUCTS, {
      slug,
      products,
      totalAmount,
      skipCount
    });
  },
  loadProducts(slug, amount = 0, filters = [], parent = null, sort) {
    return createAction(TYPES.PRODUCTS_LOAD, {
      slug,
      amount,
      filters,
      parent,
      sort
    });
  },
  loadMoreProducts(slug, amount, filters = [], parent = null, sort) {
    return createAction(TYPES.LOAD_MORE, {
      slug,
      amount,
      filters,
      parent,
      sort
    });
  },
  sort(slug, sortOptionId, { nestedSlug, appliedFilters } = {}) {
    return createAction(TYPES.SORT, {
      slug,
      sortOptionId,
      nestedSlug,
      appliedFilters
    });
  },
  setFilter(slug, nestedSlug, appliedFilters) {
    return createAction(TYPES.SET_FILTER, {
      slug,
      nestedSlug,
      appliedFilters
    });
  },
  resetFilter(slug, nestedSlug) {
    return createAction(TYPES.RESET_FILTER, { slug, nestedSlug });
  },
  loadCategory(slug, parent) {
    return createAction(TYPES.LOAD_CATEGORY, { slug, parent });
  }
};

/* REDUCERS */

const bySlug = byHash(item => item.slug);

const all = createReducer(
  on(TYPES.LOAD_CATEGORY, (state, { slug }) => {
    const category = state[slug];

    return {
      ...state,
      [slug]: {
        ...category,
        isLoaded: false
      }
    };
  }),

  on(TYPES.ADD_ITEM, (state, payload) => {
    const nested =
      payload.category.nested && payload.category.nested.length > 0 ? payload.category.nested : [];

    const nestedBySlug = nested.reduce(bySlug, state);
    const category = normalizeCategory(payload.category);

    return {
      ...state,
      ...nestedBySlug,
      [payload.slug]: {
        ...state[payload.slug],
        ...category,
        isLoaded: true
      }
    };
  }),

  on(TYPES.LOAD_MORE, (state, { slug }) => {
    const category = state[slug];

    return {
      ...state,
      [slug]: {
        ...category,
        isProductsLoading: true
      }
    };
  }),

  on(TYPES.PRODUCTS_LOAD, (state, { slug }) => {
    const category = state[slug];

    return {
      ...state,
      [slug]: {
        ...category,

        isProductsLoading: true
      }
    };
  }),

  on(TYPES.ADD_PRODUCTS, (state, { slug, products, totalAmount }) => {
    const previousProducts = state[slug].products ? state[slug].products : [];
    const currentProducts = getSlugs(products).filter(
      slug => previousProducts.indexOf(slug) === -1
    );

    return {
      ...state,
      [slug]: {
        ...state[slug],
        amount: totalAmount,
        isProductsLoading: false,
        isProductsLoaded: true,
        products: [...previousProducts, ...currentProducts]
      }
    };
  }),

  on(TYPES.SET_FILTER, (state, { slug, nestedSlug }) => {
    const id = nestedSlug || slug;
    return {
      ...state,
      [id]: {
        ...state[id],
        products: [],
        isProductsLoading: true,
        isProductsLoaded: false
      }
    };
  }),

  on(TYPES.RESET_FILTER, (state, { slug, nestedSlug }) => {
    const id = nestedSlug || slug;
    return {
      ...state,
      [id]: {
        ...state[id],
        products: [],
        isProductsLoading: true,
        isProductsLoaded: false
      }
    };
  }),

  on(TYPES.SORT, (state, { slug, sortOptionId, nestedSlug }) => {
    const currentSlug = nestedSlug || slug;
    return {
      ...state,
      [currentSlug]: {
        ...state[currentSlug],
        sort: sortOptionId,
        products: [],
        isProductsLoading: true,
        isProductsLoaded: false
      }
    };
  }),

  on(SEARCH.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.category) {
      return state;
    }

    return {
      ...state,
      ...entities.category
    };
  })
);

export default combineReducers({
  all: all({})
});

/* SELECTORS */

export const getSlugFromProps = (_, { slug }) => slug;
export const getSlugFromRouteParams = (_, props) => props.match.params.slug;
export const getItemFromRouteParams = (_, props) => props.match.params.item;
export const getNestedItemFromRouteParams = (_, props) => props.match.params.nested;
export const getQueryFromRouteParams = ({ router }) => {
  return qs.parse(router.location.search, { ignoreQueryPrefix: true });
};

export const getCategories = state => getProductsModule(state).categories;

export const getAll = createSelector(getCategories, categories => categories.all);

export const getAccessItem = createSelector(getAll, function _accessItem(categories) {
  return slug => {
    if (!slug || !categories[slug]) {
      return null;
    }

    return categories[slug];
  };
});

export const makeGetItem = getSlug =>
  createSelector(getAccessItem, getSlug, function _getItem(accessItem, slug) {
    return accessItem(slug);
  });

export const getCategoryBySlug = makeGetItem(getSlugFromProps);

export const getNested = getItemSlug =>
  createSelector(getAll, getItemSlug, getNestedItemAll, function _getItem(
    categories,
    slug,
    itemAll
  ) {
    if (!slug || !categories) {
      return itemAll;
    }

    return categories[slug];
  });

export const getNestedItemAll = createSelector(getCategoryBySlug, function _getNestedItemAll(
  category
) {
  if (!category) {
    return null;
  }

  return {
    slug: null,
    title: 'Все',
    amount: category.amount,
    banner: category.banner
  };
});

export const getNestedCategoriesBySlug = createSelector(
  getCategoryBySlug,
  getAccessItem,
  getNestedItemAll,
  function _getNestedCategoriesBySlug(category, selectCategory, nestedAll) {
    if (!category.nested) {
      return null;
    }

    const nested = category.nested.map(selectCategory).filter(Boolean);

    if (!nested.length) {
      return null;
    }
    return [nestedAll, ...nested];
  }
);

export const getCurrentNestedCategory = () => {
  return null;
};

export const makeGetIsItemLoaded = slug =>
  createSelector(
    getAccessItem,

    accessItem => {
      const category = accessItem(slug);

      if (!category) {
        return false;
      }

      return category.isLoaded;
    }
  );

export const makeGetIsItemLoadedBySelector = getSlug =>
  createSelector(makeGetItem(getSlug), category => {
    if (!category) {
      return false;
    }

    return category.isLoaded;
  });

export const getIsItemLoaded = createSelector(getCategoryBySlug, function _getIsItemLoaded(
  category
) {
  if (!category) {
    return false;
  }

  return category.isLoaded;
});

export const makeGetPossibleFilters = getSlug =>
  createSelector(makeGetItem(getSlug), function _getPossibleFilters(category) {
    if (!category) {
      return null;
    }
    return category.filters;
  });

export const getPossibleFilters = makeGetPossibleFilters(getSlugFromProps);

export const getQuickFilters = createSelector(getCategoryBySlug, () => []);
export const getSortBySlug = createSelector(getCategoryBySlug, category => {
  if (!category) {
    return 1;
  }

  return category.sort || 1;
});

export const getAppliedFiltersFromRoute = createSelector(
  getQueryFromRouteParams,

  function _getAppliedFiltersFromRoute(query) {
    if (!query.filter) {
      return [];
    }

    if (typeof query.filter === 'string') {
      return [query.filter];
    }

    return query.filter;
  }
);

export const getValidAppliedFilters = createSelector(
  getAppliedFiltersFromRoute,
  fromFilters.getFilters,

  function _getValidAppliedFilters(appliedFilters, possibleFilters) {
    const filterList = Object.values(possibleFilters);

    const filterTitleList = filterList.reduce((prev, current) => prev.concat(current.options), []);

    if (!appliedFilters.length) {
      return [];
    }

    const validFilterList = appliedFilters.filter(item => filterTitleList.indexOf(item) !== -1);

    return validFilterList;
  }
);

export const getAppliedFilters = createSelector(
  getCategoryBySlug,

  () => []
);

export const getFilters = createSelector(
  getAppliedFilters,
  getQuickFilters,
  getPossibleFilters,

  function _getFilters(applied, quick, all) {
    return {
      all,
      applied,
      quick
    };
  }
);

export const getHasAppliedFilters = createSelector(
  getAppliedFilters,

  function _getHasAppliedFilters(appliedFilters) {
    if (!appliedFilters || appliedFilters.length) {
      return false;
    }
    return true;
  }
);

export const makeGetProductSlugList = getSlug =>
  createSelector(
    makeGetItem(getSlug),

    function _getProductSlugList(category) {
      if (!category) {
        return null;
      }

      if (!category.products) {
        return [];
      }

      return category.products;
    }
  );

export const getProductSlugList = makeGetProductSlugList(getSlugFromProps);

export const getAvailableProductSlugList = createSelector(
  getProductSlugList,
  fromProducts.getProducts,
  function _getAvailableProductSlugList(list, products) {
    if (!list) {
      return [];
    }
    return list.filter(item => products[item].isAvailable);
  }
);

export const getProductList = createSelector(
  fromProducts.getFindProductBySlug,
  getProductSlugList,
  function _getProductList(getProductBySlug, slugList) {
    if (!slugList) {
      return null;
    }

    return slugList.map(getProductBySlug);
  }
);

export const makeGetIsProductsLoaded = getSlug =>
  createSelector(
    makeGetItem(getSlug),

    category => {
      if (!category) {
        return false;
      }
      return category.isProductsLoaded;
    }
  );

export const getIsProductsLoaded = makeGetIsProductsLoaded(getSlugFromProps);

export const getIsProductListLoading = createSelector(getCategoryBySlug, category => {
  if (!category) {
    return false;
  }
  return category.isProductsLoading;
});

export const makeGetProductsAmount = getSlug =>
  createSelector(makeGetItem(getSlug), function _getProductsAmount(category) {
    if (!category || !category.products) {
      return null;
    }

    return category.products.length;
  });

export const makeGetTotalAmount = getSlug =>
  createSelector(makeGetItem(getSlug), function _getProductsAmount(category) {
    if (!category) {
      return null;
    }

    return category.amount;
  });

export const getProductsAmount = makeGetProductsAmount(getSlugFromProps);
export const getTotalAmount = makeGetTotalAmount(getSlugFromProps);

/* HELPERS */

function getSlug(category) {
  return category.slug;
}

function normalizeCategory(category) {
  const filters = category.filters.map(item => category.slug + ':' + item.id);
  const nested = category.nested ? category.nested.map(getSlug) : [];
  const meta =
    Object.prototype.toString.call(category.meta) === '[object Object]' ? category.meta : undefined;

  return {
    ...category,
    filters,
    nested,
    meta
  };
}

function byHash(hashFn) {
  return (table, item) => {
    const saved = table[hashFn(item)] || {};
    return {
      ...table,
      [hashFn(item)]: {
        ...saved,
        ...item
      }
    };
  };
}

function getSlugs(products) {
  return products.map(({ slug }) => slug);
}
