import React, { Component } from 'react';
import Dialog from 'modules/core/components/dialog/dialog';
import Button from 'modules/core/components/button/button';
import './reply-submitted-dialog.css';

class ReplySubmittedDialog extends Component {
  state = {};
  render() {
    const { show, onClose } = this.props;

    return (
      <Dialog show={show} closeable onClose={onClose}>
        <div className="ReplySubmittedDialog">
          <h2>Спасибо за ваш ответ!</h2>
          <h3>Он будет опубликован после проверки модератором</h3>
          <Button title="Хорошо" variant="primary" onClick={onClose} />
        </div>
      </Dialog>
    );
  }
}

export default ReplySubmittedDialog;
