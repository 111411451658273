import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconEducation } from 'modules/core/components/icons';

import PriceFormatted from 'modules/ordering/components/price-formatted/price-formatted';

import * as fromLocation from 'modules/geography/ducks/location';

import './card-delivery.css';

const mapStateToProps = createStructuredSelector({
  freeDeliveryFrom: fromLocation.getFreeDeliveryFrom
});

class CardDelivery extends Component {
  state = {};
  render() {
    const { freeDeliveryFrom
      // , deliveryDateForMoscov 
    } = this.props;
    return (
      <div className="CardDelivery">
        <div className="CardDelivery-item">
          <span className="CardDelivery-icon">
            <IconContainer size="small">
              <IconEducation />
            </IconContainer>
          </span>
          <span className="CardDelivery-text">
            Бесплатная доставка от <PriceFormatted number={freeDeliveryFrom} />
          </span>
        </div>

        {/* {deliveryDateForMoscov && (
          <div className="CardDelivery-deliveryLabel">
            Доставим <b>послезавтра, {deliveryDateForMoscov}
            </b>
          </div>
        )} */}
      </div>
    );
  }
}

export default connect(mapStateToProps)(CardDelivery);
