import { combineReducers } from 'redux';
import { createReducer, handle as on } from 'modules/utils/dux';
import { createSelector } from 'reselect';

import calculateBonuses from './helpers/calculate-bonuses';

import * as BONUSES from '../types/bonuses';
import * as ACCOUNT from 'modules/core/ducks/types/account';

const BONUS_TYPES = {
  default: 'BONUS_TYPE/DEFAULT',
  extra: 'BONUS_TYPE/EXTRA'
};

const allInitialState = {
  id: null,
  isActivated: null,
  summary: [],
  bonuses: [],
  transactions: [],
  _isExist: null
};

const loadStatusesInitialState = {
  isAllLoaded: false,
  isTransactionsLoaded: false
};

const all = createReducer(
  on(BONUSES.ALL_RESPONSE, (state, { id, isActivated, summary, bonuses, transactions }) => {
    return {
      ...state,
      id,
      isActivated,
      summary,
      bonuses,
      transactions,
      _isExist: true
    };
  }),

  on(BONUSES.TRANSACTIONS_RESPONSE, (state, { transactions, totalTransactionAmount }) => {
    return {
      ...state,
      transactions,
      totalTransactionAmount,
      _isError: false
    };
  }),

  on(BONUSES.ALL_ERROR, state => {
    return {
      ...state,
      _isExist: false
    };
  }),

  on(BONUSES.TRANSACTIONS_ERROR, state => {
    return {
      ...state,
      _isError: true
    };
  }),

  on(ACCOUNT.SIGN_OUT, state => {
    return allInitialState;
  })
);

const loadStatuses = createReducer(
  on(BONUSES.ALL_REQUEST, state => {
    return {
      ...state,
      isAllLoaded: false
    };
  }),

  on(BONUSES.TRANSACTIONS_REQUEST, state => {
    return {
      ...state,
      isTransactionsLoaded: false
    };
  }),

  on(BONUSES.ALL_RESPONSE, state => {
    return {
      ...state,
      isAllLoaded: true,
      isTransactionsLoaded: false
    };
  }),

  on(BONUSES.TRANSACTIONS_RESPONSE, state => {
    return {
      ...state,
      isTransactionsLoaded: true
    };
  }),

  on(BONUSES.ALL_ERROR, state => {
    return {
      ...state,
      isAllLoaded: true
    };
  }),

  on(BONUSES.TRANSACTIONS_ERROR, state => {
    return {
      ...state,
      isTransactionsLoaded: true
    };
  }),

  on(ACCOUNT.SIGN_OUT, state => {
    return loadStatusesInitialState;
  })
);

export default combineReducers({
  all: all(allInitialState),
  loadStatuses: loadStatuses(loadStatusesInitialState)
});

const getAll = state => state.profile.bonuses.all;
const getLoadStatuses = state => state.profile.bonuses.loadStatuses;

// TODO: DELETE
export const getIsBonusesCardExists = () => true;

export const getIsAllLoaded = createSelector(
  getLoadStatuses,
  function _getIsBonusesLoaded(loadStatuses) {
    return loadStatuses.isAllLoaded;
  }
);

export const getIsTransactionsLoaded = createSelector(
  getLoadStatuses,
  function _getIsBonusesLoaded(loadStatuses) {
    return loadStatuses.isTransactionsLoaded;
  }
);

export const getAccountNumber = createSelector(
  getAll,
  function _getAccountNumber(all) {
    return all.id;
  }
);

export const getIsActivated = createSelector(
  getAll,
  function _getIsActivated(all) {
    return all.isActivated;
  }
);

const getBonuses = createSelector(
  getAll,
  function _getBonuses(all) {
    return all.bonuses;
  }
);

const getSummary = createSelector(
  getAll,
  function _getSummary(all) {
    return all.summary;
  }
);

const getBonusListByType = bonusType =>
  createSelector(
    getBonuses,
    function _getDefaultBonuses(bonuses) {
      const bonusList = bonuses.filter(bonus => bonus.type === bonusType);

      return bonusList;
    }
  );

const getBonusesByType = bonusType =>
  createSelector(
    getBonusListByType(bonusType),
    getSummary,
    function _getDefaultBonuses(bonusList, summary) {
      const defaultBonusesSummary = summary.find(summary => summary.type === bonusType);

      if (!defaultBonusesSummary) {
        return null;
      }

      const bonusWithExpireList = bonusList.filter(bonus => bonus.expire);

      return {
        summary: defaultBonusesSummary.value,
        bonusList: bonusWithExpireList
      };
    }
  );

export const getDefaultBonusList = getBonusListByType(BONUS_TYPES.default);
export const getExtraBonusList = getBonusListByType(BONUS_TYPES.extra);

export const getDefaultBonuses = getBonusesByType(BONUS_TYPES.default);
export const getExtraBonuses = getBonusesByType(BONUS_TYPES.extra);

export const getTransactions = createSelector(
  getAll,
  function _getIsActivated(all) {
    const transactions = all.transactions;

    const transactionsCalculatedBonuses = transactions.map(transaction => {
      const isIncomeExist = !!transaction.income && transaction.income.length > 0;

      const isExpenseExist = !!transaction.expense && transaction.expense.length > 0;

      if (isIncomeExist) {
        const incomeSummary = calculateBonuses(transaction.income);

        transaction = {
          ...transaction,
          incomeSummary
        };
      }

      if (isExpenseExist) {
        const expenseSummary = calculateBonuses(transaction.expense);

        transaction = {
          ...transaction,
          expenseSummary
        };
      }

      return transaction;
    });

    return transactionsCalculatedBonuses;
  }
);

export const getIsBonusesExist = createSelector(
  getAll,
  function _getIsBonusesExist(all) {
    return all._isExist;
  }
);
