export const msk = {
  page: {
    title: 'Клиентские дни'
  },
  colors: ['#DC296A', '#3D62D7'],
  light: false,
  background: {
    style: { opacity: 0.15 },
    gradient:
      'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
  },
  components: [
    {
      type: 'top_banner',
      show: true,
      hasContainer: true,
      images: [
        {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'IWjas4ShAb8.jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              }
            }
          ]
        },
        {
          id: 414131,
          type: 'MEDIA_TYPE/IMAGE',
          title: '8447 (1).jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              }
            }
          ]
        }
      ],
      label: '28-29 февраля',
      title: ['Клиентские', 'дни'],
      address: {
        title: 'москва',
        date_list: [
          { date: '28/02', location: 'ТЦ "Авеню", пр-кт Вернадского, дом № 86 А' },
          {
            date: '29/02',
            location: 'ТЦ "БУМ", ул. Перерва, дом № 43'
          }
        ]
      },
      action: {
        show: false,
        description: 'Принеси этот купон на открытие и получи классный подарок!',
        title: 'Расспечатать купон'
      }
    },
    {
      type: 'gifts',
      show: true,
      hasContainer: true,
      list: [
        {
          title: 'Скидки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                },
                fallback: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'подарки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'двойные бонусы на карту',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'rules',
      title: ['Акции', 'на открытии'],
      show: false,
      list: [
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'activity',
      show: true,
      hasContainer: true,
      list: [
        {
          date: '28 февраля',
          specialist: 'визажист',
          schedule: '16:00 – 20:00',
          location: 'ТЦ "Авеню"',
          list: [
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/lipstickIcon_@1x.png',
                      double: '/content/landing/lipstickIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/lipstickIcon_@1x.png',
                      double: '/content/landing/lipstickIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'роскошный мейк-ап от визажиста Mixit'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'скидка 20% на коллекцию Mixit ART'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/sertificatesIcon_@1x.png',
                      double: '/content/landing/sertificatesIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/sertificatesIcon_@1x.png',
                      double: '/content/landing/sertificatesIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'двойные бонусы на карту лояльности'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/giftIcon_@1x.png',
                      double: '/content/landing/giftIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/giftIcon_@1x.png',
                      double: '/content/landing/giftIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'подарки каждому'
            }
          ]
        },
        {
          date: '29 февраля',
          specialist: 'косметолог',
          schedule: '14:00 – 19:00',
          location: 'ТЦ "БУМ"',
          list: [
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/faceIcon_@1x.png',
                      double: '/content/landing/faceIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/faceIcon_@1x.png',
                      double: '/content/landing/faceIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'диагностика состояния кожи с помощью дерматоскопа'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/goodsIcon_@1x.png',
                      double: '/content/landing/goodsIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/goodsIcon_@1x.png',
                      double: '/content/landing/goodsIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'подбор средств по типу кожи от нашего косметолога'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'скидка 15% на уходовые продукты'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/vvv_@1x.png',
                      double: '/content/landing/vvv_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/vvv_@1x.png',
                      double: '/content/landing/vvv_@2x.png'
                    }
                  }
                ]
              },
              title: 'двойные бонусы на карту лояльности и подарки каждому'
            }
          ]
        }
      ]
    },
    {
      type: 'group',
      show: false,
      title: ['товары', 'со скидкой'],
      slug: 'new'
    },
    {
      type: 'bottom_banner',
      show: true,
      hasContainer: true,
      image: {
        id: 749762,
        type: 'MEDIA_TYPE/IMAGE',
        title: 'Молочко для тела',
        sources: [
          {
            dimensions: {
              width: 3200,
              height: 3200
            },
            main: {
              normal: '/content/landing/girl_3_@1x.jpg',
              double: '/content/landing/girl_3_@2x.jpg'
            },
            fallback: {
              normal: '/content/landing/girl_3_@1x.jpg',
              double: '/content/landing/girl_3_@2x.jpg'
            }
          }
        ]
      },
      title: ['Приходите за', 'яркими эмоциями'],
      main_title: 'в Mixit!'
    },
    {
      type: 'bottom_info',
      show: true,
      hasContainer: true,
      text: '*все предложения действуют в указанное время в указанных магазинах'
    }
  ]
};


export const spb = {
  page: {
    title: 'Клиентские дни'
  },
  colors: ['#DC296A', '#3D62D7'],
  light: true,
  background: {
    style: { opacity: 0.15 },
    gradient:
      'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
  },
  components: [
    {
      type: 'top_banner',
      show: true,
      hasContainer: true,
      images: [
        {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'IWjas4ShAb8.jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              }
            }
          ]
        },
        {
          id: 414131,
          type: 'MEDIA_TYPE/IMAGE',
          title: '8447 (1).jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              }
            }
          ]
        }
      ],
      label: '28-29 февраля',
      title: {
        top: 'Клиентские',
        bottom: 'дни',
        light: true,
        fontSize: '',
        topStyle: {},
        bottomStyle: {}
      },
      address: {
        title: 'Санкт Петербург',
        date_list: [
          { date: '28/02', location: 'ТЦ "Галерея", пр-кт Лиговский, дом № 30, литера А' },
          {
            date: '29/02',
            location: 'ТЦ "Жемчужная Плаза", Петергофское ш, дом № 51, литера А'
          }
        ]
      },
      action: {
        show: false,
        description: 'Принеси этот купон на открытие и получи классный подарок!',
        title: 'Расспечатать купон'
      }
    },
    {
      type: 'gifts',
      show: true,
      hasContainer: true,
      list: [
        {
          title: 'Скидки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                },
                fallback: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'подарки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'двойные бонусы на карту',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'rules',
      title: ['Акции', 'на открытии'],
      show: false,
      list: [
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'activity',
      show: true,
      hasContainer: true,
      list: [
        {
          date: '28 февраля',
          specialist: 'визажист',
          schedule: '16:00 – 20:00',
          location: 'ТЦ "Галерея"',
          list: [
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/lipstickIcon_@1x.png',
                      double: '/content/landing/lipstickIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/lipstickIcon_@1x.png',
                      double: '/content/landing/lipstickIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'роскошный мейк-ап от визажиста Mixit'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'скидка 20% на коллекцию Mixit ART'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/sertificatesIcon_@1x.png',
                      double: '/content/landing/sertificatesIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/sertificatesIcon_@1x.png',
                      double: '/content/landing/sertificatesIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'двойные бонусы на карту лояльности'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/giftIcon_@1x.png',
                      double: '/content/landing/giftIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/giftIcon_@1x.png',
                      double: '/content/landing/giftIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'подарки каждому'
            }
          ]
        },
        {
          date: '29 февраля',
          specialist: 'косметолог',
          schedule: '14:00 – 19:00',
          location: 'ТЦ "Жемчужная Плаза"',
          list: [
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/faceIcon_@1x.png',
                      double: '/content/landing/faceIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/faceIcon_@1x.png',
                      double: '/content/landing/faceIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'диагностика состояния кожи  + рекомендации по уходу за кожей'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/goodsIcon_@1x.png',
                      double: '/content/landing/goodsIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/goodsIcon_@1x.png',
                      double: '/content/landing/goodsIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'подбор средств по типу кожи от нашего косметолога'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'скидка 15% на уходовые продукты'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/vvv_@1x.png',
                      double: '/content/landing/vvv_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/vvv_@1x.png',
                      double: '/content/landing/vvv_@2x.png'
                    }
                  }
                ]
              },
              title: 'двойные бонусы на карту лояльности и подарки каждому'
            }
          ]
        }
      ]
    },
    {
      type: 'group',
      show: false,
      title: ['товары', 'со скидкой'],
      slug: 'new'
    },
    {
      type: 'bottom_banner',
      show: true,
      hasContainer: false,
      image: {
        id: 749762,
        type: 'MEDIA_TYPE/IMAGE',
        title: 'Молочко для тела',
        sources: [
          {
            dimensions: {
              width: 3200,
              height: 3200
            },
            main: {
              normal: '/content/landing/girl_3_@1x.jpg',
              double: '/content/landing/girl_3_@2x.jpg'
            },
            fallback: {
              normal: '/content/landing/girl_3_@1x.jpg',
              double: '/content/landing/girl_3_@2x.jpg'
            }
          }
        ]
      },
      title: ['Приходите за', 'яркими эмоциями'],
      main_title: 'в Mixit!'
    },
    {
      type: 'bottom_info',
      show: true,
      hasContainer: true,
      text: '*все предложения действуют в указанное время в указанных магазинах'
    }
  ]
};

export const new_shops = {
  page: {
    title: 'Новые магазины'
  },
  render_conditions: {
    start_time: '2020-01-23T19:58:00+03:00',
    expire_time: '2020-09-23T19:58:00+03:00'
  },
  background: {
    style: { opacity: 0.15 },
    gradient:
      'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
  },
  colors: ['#DC296A', '#3D62D7'],
  light: false,
  components: [
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'html'
    },
    {
      type: 'html',
      show: true,
      render_conditions: {
        show: true,
        start_time: '2020-01-23T19:58:00+03:00',
        expire_time: '2020-09-23T19:58:00+03:00'
        // city_ids: { list: [1166] },
        // render_pathes: { list: [{ path: '/', exact: false }] },
        // ignored_pathes: {
        //   list: [{ path: '/basket' }, { path: '/ordering', exact: false }]
        // }
      },
      hasContainer: true,
      wraper_style: {
        backgroundColor: 'lightBlue'
      },
      html: '<h1 style="color:blue">Привет</h1><input type="number"/>'
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'plain_text'
    },
    {
      type: 'plain_text',
      show: true,
      hasContainer: true,
      wraper_style: {
        marginTop: '16px',
        marginBottom: '25px',
        padding: '20px',
        backgroundColor: 'pink'
      },
      style: {
        color: 'red',
        fontWeight: 'bold'
      },
      text: 'ВЫЛОЖИ ФОТО В ДЕНЬ ОТКРЫТИЯ В INSTAGRAM - ПОЛУЧИ РАСПЕЧАТАННОЕ ФОТО В ПОДАРОК!'
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'title_section'
    },
    {
      type: 'title_section',
      show: true,
      hasContainer: true,

      wraper_style: {
        marginTop: '16px',
        padding: '20px',
        backgroundColor: 'coral'
      },
      title: {
        top: 'Клиентские',
        bottom: 'дни',
        light: true,
        fontSize: '30px',
        color: 'red',
        topStyle: { lineHeight: 0.8, color: 'blue' },
        bottomStyle: { lineHeight: 1 },
        noGap: true,
        centered: false,
        reverse: true,
        verticalReverse: true
      }
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'top_banner'
    },
    {
      type: 'top_banner',
      show: true,
      hasContainer: true,
      images: [
        {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'IWjas4ShAb8.jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              }
            }
          ]
        },
        {
          id: 414131,
          type: 'MEDIA_TYPE/IMAGE',
          title: '8447 (1).jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              }
            }
          ]
        }
      ],
      label: '21-22 февраля',
      title: ['Клиентские', 'дни'],
      // title: {
      //   top: 'Клиентские',
      //   bottom: 'дни',
      //   light: true,
      //   fontSize: '50px',
      //   topStyle: {},
      //   bottomStyle: {}
      // },
      address: {
        title: 'москва',
        date_list: [
          { date: '21/02', location: 'ТЦ Охотный ряд, Манежная площадь д. 1, стр. 2' },
          {
            date: '22/02',
            location: 'ТЦ Мега Белая дача, г. Котельники, 1 Покровский проезд, д.1  '
          }
        ]
      },
      action: {
        show: true,
        description: 'Принеси этот купон на открытие и получи классный подарок!',
        title: 'Расспечатать купон',
        link: { href: 'http://mixit.ru', target: '_blank' }
      }
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'opening_shops_banner'
    },
    {
      type: 'opening_shops_banner',
      show: true,
      hasContainer: true,
      title: ['Новые', 'магазины'],
      labels: ['подарки', 'макияж', 'скидки'],
      images: [
        {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'IWjas4ShAb8.jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_4_@1x.jpg',
                double: '/content/landing/girl_4_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_4_@1x.jpg',
                double: '/content/landing/girl_4_@2x.jpg'
              }
            }
          ]
        },
        {
          id: 414131,
          type: 'MEDIA_TYPE/IMAGE',
          title: '8447 (1).jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_5_@1x.jpg',
                double: '/content/landing/girl_5_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_5_@1x.jpg',
                double: '/content/landing/girl_5_@2x.jpg'
              }
            }
          ]
        }
      ]
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'bottom_banner'
    },
    {
      type: 'bottom_banner',
      show: true,
      hasContainer: false,
      image: {
        id: 749762,
        type: 'MEDIA_TYPE/IMAGE',
        title: 'Молочко для тела',
        sources: [
          {
            dimensions: {
              width: 3200,
              height: 3200
            },
            main: {
              normal: '/content/landing/girl_3_@1x.jpg',
              double: '/content/landing/girl_3_@2x.jpg'
            },
            fallback: {
              normal: '/content/landing/girl_3_@1x.jpg',
              double: '/content/landing/girl_3_@2x.jpg'
            }
          }
        ]
      },
      // title: ['Приходите за', 'яркими эмоциями'],
      title: {
        top: 'Клиентские',
        bottom: 'дни',
        light: true,
        // fontSize: '50px',
        topStyle: {},
        bottomStyle: {}
      },
      main_title: 'в Mixit!',
      main_title_props: {
        color: 'red',
        fontSize: '48px'
      }
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'rules'
    },
    {
      type: 'rules',
      title: ['Акции', 'на открытии'],
      hasContainer: true,
      show: true,
      list: [
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'gifts'
    },
    {
      type: 'gifts',
      show: true,
      hasContainer: true,
      list: [
        {
          title: 'Скидки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                },
                fallback: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'подарки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'двойные бонусы на карту',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'opening_timetable'
    },
    {
      type: 'opening_timetable',
      show: true,
      hasContainer: true,
      title: ['Даты открытия', 'магазинов'],
      list: [
        {
          date: '8 февраля',
          slug: 'all',
          location: {
            city: 'г. Екатеринбург',
            place: 'ТЦ “Комсомолл”',
            address: 'ул. Большая Воскресенская, д. 2'
          }
        },
        {
          date: '12 февраля',
          slug: 'all',
          location: {
            city: 'г. санкт-петербург ',
            place: 'ТЦ “Питер”',
            address: 'ул. Типанова, д. 21'
          }
        },
        {
          date: '28 февраля',
          slug: 'all',
          location: {
            city: 'г. нижний новгород ',
            place: 'ТЦ “фантастика”',
            address: 'ул. Набережная Верхневолжская, д. 235'
          }
        },
        {
          date: '6 марта',
          slug: 'all',
          location: {
            city: 'г. Москва',
            place: 'ТЦ “Европейский”',
            address: 'ул. Никольская улица, д. 21'
          }
        },
        {
          date: '17 марта',
          slug: 'all',
          location: {
            city: 'г. волгоград',
            place: 'ТЦ “акварель”',
            address: 'просп. Университетский, д. 107'
          }
        },
        {
          date: '28 марта',
          slug: 'all',
          location: {
            city: 'г. Тюмень',
            place: 'ТЦ “Кристалл”',
            address: 'ул. Дмитрия Менделеева, д. 1А'
          }
        }
      ]
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'group'
    },
    {
      type: 'group',
      show: true,
      hasContainer: true,
      title: ['товары', 'со скидкой'],
      slug: 'new'
    },
    {
      type: 'desctiption_section',
      show: true,
      hasContainer: false,
      text: 'bottom_info'
    },
    {
      type: 'bottom_info',
      show: true,
      hasContainer: true,
      text: '*все предложения действуют в указанное время в указанных магазинах'
    }
  ]
};

export const all = {
  page: {
    title: 'Клиентские дни'
  },
  colors: ['#DC296A', '#3D62D7'],
  top_banner: {
    images: [
      {
        id: 432490,
        type: 'MEDIA_TYPE/IMAGE',
        title: 'IWjas4ShAb8.jpg',
        sources: [
          {
            dimensions: {
              width: 639,
              height: 960
            },
            main: {
              normal: '/content/landing/girl_1_@1x.jpg',
              double: '/content/landing/girl_1_@2x.jpg'
            },
            fallback: {
              normal: '/content/landing/girl_1_@1x.jpg',
              double: '/content/landing/girl_1_@2x.jpg'
            }
          }
        ]
      },
      {
        id: 414131,
        type: 'MEDIA_TYPE/IMAGE',
        title: '8447 (1).jpg',
        sources: [
          {
            dimensions: {
              width: 639,
              height: 960
            },
            main: {
              normal: '/content/landing/girl_2_@1x.jpg',
              double: '/content/landing/girl_2_@2x.jpg'
            },
            fallback: {
              normal: '/content/landing/girl_2_@1x.jpg',
              double: '/content/landing/girl_2_@2x.jpg'
            }
          }
        ]
      }
    ],
    label: '21-22 февраля',
    title: ['Клиентские', 'дни'],
    address: ['г. МОСКВА', 'ул. Большая Воскресенская, д. 2 ТЦ “Тетрис”'],
    _address: {
      mosсow: {
        title: 'москва',
        date_list: [
          { date: '21/02', location: 'ТЦ Охотный ряд, Манежная площадь д. 1, стр. 2' },
          {
            date: '22/02',
            location: 'ТЦ Мега Белая дача, г. Котельники, 1 Покровский проезд, д.1  '
          }
        ]
      },
      saint_petersburg: {}
    },
    action: {
      show: true,
      description: 'Принеси этот купон на открытие и получи классный подарок!',
      title: 'Расспечатать купон'
    }
  },
  gifts: {
    show: true,
    hasContainer: true,
    list: [
      {
        title: 'Скидки',
        media: {
          id: 749762,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'Молочко для тела',
          sources: [
            {
              dimensions: {
                width: 3200,
                height: 3200
              },
              main: {
                normal: '/content/landing/ddd_1x.png',
                double: '/content/landing/ddd_2x.png'
              },
              fallback: {
                normal: '/content/landing/ddd_1x.png',
                double: '/content/landing/ddd_2x.png'
              }
            }
          ]
        }
      },
      {
        title: 'подарки',
        media: {
          id: 749762,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'Молочко для тела',
          sources: [
            {
              dimensions: {
                width: 3200,
                height: 3200
              },
              main: {
                normal: '/content/landing/qqq_@1x.png',
                double: '/content/landing/qqq_@2x.png'
              },
              fallback: {
                normal: '/content/landing/qqq_@1x.png',
                double: '/content/landing/qqq_@2x.png'
              }
            }
          ]
        }
      },
      {
        title: 'двойные бонусы на карту',
        media: {
          id: 749762,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'Молочко для тела',
          sources: [
            {
              dimensions: {
                width: 3200,
                height: 3200
              },
              main: {
                normal: '/content/landing/bbb_@1x.png',
                double: '/content/landing/bbb_@2x.png'
              },
              fallback: {
                normal: '/content/landing/bbb_@1x.png',
                double: '/content/landing/bbb_@2x.png'
              }
            }
          ]
        }
      }
    ]
  },
  rules: {
    title: ['Акции', 'на открытии'],
    show: false,
    list: [
      {
        title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
        media: {
          id: 749762,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'Молочко для тела',
          sources: [
            {
              dimensions: {
                width: 3200,
                height: 3200
              },
              main: {
                normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
              },
              fallback: {
                normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
              }
            }
          ]
        }
      },
      {
        title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
        media: {
          id: 749762,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'Молочко для тела',
          sources: [
            {
              dimensions: {
                width: 3200,
                height: 3200
              },
              main: {
                normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
              },
              fallback: {
                normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
              }
            }
          ]
        }
      },
      {
        title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
        media: {
          id: 749762,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'Молочко для тела',
          sources: [
            {
              dimensions: {
                width: 3200,
                height: 3200
              },
              main: {
                normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
              },
              fallback: {
                normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
              }
            }
          ]
        }
      },
      {
        title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
        media: {
          id: 749762,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'Молочко для тела',
          sources: [
            {
              dimensions: {
                width: 3200,
                height: 3200
              },
              main: {
                normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
              },
              fallback: {
                normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
              }
            }
          ]
        }
      }
    ]
  },
  activity: {
    show: true,
    hasContainer: true,
    list: [
      {
        date: '21 февраля',
        specialist: 'визажист',
        schedule: '16:00 – 20:00',
        location: 'ТЦ «Охотный ряд»',
        list: [
          {
            image: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/lipstickIcon_@1x.png',
                    double: '/content/landing/lipstickIcon_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/lipstickIcon_@1x.png',
                    double: '/content/landing/lipstickIcon_@2x.png'
                  }
                }
              ]
            },
            title: 'роскошный мейк-ап от визажиста Mixit'
          },
          {
            image: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/saleIcon_@1x.png',
                    double: '/content/landing/saleIcon_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/saleIcon_@1x.png',
                    double: '/content/landing/saleIcon_@2x.png'
                  }
                }
              ]
            },
            title: 'скидка 20% на коллекцию Mixit ART'
          },
          {
            image: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/sertificatesIcon_@1x.png',
                    double: '/content/landing/sertificatesIcon_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/sertificatesIcon_@1x.png',
                    double: '/content/landing/sertificatesIcon_@2x.png'
                  }
                }
              ]
            },
            title: 'двойные бонусы на карту лояльности'
          },
          {
            image: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/giftIcon_@1x.png',
                    double: '/content/landing/giftIcon_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/giftIcon_@1x.png',
                    double: '/content/landing/giftIcon_@2x.png'
                  }
                }
              ]
            },
            title: 'подарки каждому'
          }
        ]
      },
      {
        date: '22 февраля',
        specialist: 'косметолог',
        schedule: '14:00 – 19:00',
        location: 'ТЦ «Мега Белая»',
        list: [
          {
            image: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/faceIcon_@1x.png',
                    double: '/content/landing/faceIcon_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/faceIcon_@1x.png',
                    double: '/content/landing/faceIcon_@2x.png'
                  }
                }
              ]
            },
            title: 'диагностика состояния кожи с помощью дерматоскопа'
          },
          {
            image: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/goodsIcon_@1x.png',
                    double: '/content/landing/goodsIcon_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/goodsIcon_@1x.png',
                    double: '/content/landing/goodsIcon_@2x.png'
                  }
                }
              ]
            },
            title: 'подбор средств по типу кожи от нашего косметолога'
          },
          {
            image: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/saleIcon_@1x.png',
                    double: '/content/landing/saleIcon_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/saleIcon_@1x.png',
                    double: '/content/landing/saleIcon_@2x.png'
                  }
                }
              ]
            },
            title: 'скидка 15% на уходовые продукты'
          },
          {
            image: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/vvv_@1x.png',
                    double: '/content/landing/vvv_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/vvv_@1x.png',
                    double: '/content/landing/vvv_@2x.png'
                  }
                }
              ]
            },
            title: 'двойные бонусы на карту лояльности и подарки каждому'
          }
        ]
      }
    ]
  },
  group: {
    show: false,
    title: ['товары', 'со скидкой'],
    slug: 'new'
  },
  bottom_banner: {
    show: true,
    image: {
      id: 749762,
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Молочко для тела',
      sources: [
        {
          dimensions: {
            width: 3200,
            height: 3200
          },
          main: {
            normal: '/content/landing/girl_3_@1x.jpg',
            double: '/content/landing/girl_3_@2x.jpg'
          },
          fallback: {
            normal: '/content/landing/girl_3_@1x.jpg',
            double: '/content/landing/girl_3_@2x.jpg'
          }
        }
      ]
    },
    title: ['Приходите за', 'яркими эмоциями'],
    main_title: 'в Mixit!'
  },
  bottom_info: {
    show: true,
    hasContainer: true,
    text: '*все предложения действуют в указанное время в указанных магазинах'
  },
  background: {
    style: { opacity: 0.15 },
    gradient:
      'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
  },
  opening_timetable: {
    show: true,
    title: ['Даты открытия', 'магазинов'],
    list: [
      {
        date: '8 февраля',
        slug: 'www',
        location: {
          city: 'г. Екатеринбург',
          place: 'ТЦ “Комсомолл”',
          address: 'ул. Большая Воскресенская, д. 2'
        }
      },
      {
        date: '12 февраля',
        slug: 'qqq',
        location: {
          city: 'г. санкт-петербург ',
          place: 'ТЦ “Питер”',
          address: 'ул. Типанова, д. 21'
        }
      },
      {
        date: '28 февраля',
        slug: 'eee',
        location: {
          city: 'г. нижний новгород ',
          place: 'ТЦ “фантастика”',
          address: 'ул. Набережная Верхневолжская, д. 235'
        }
      },
      {
        date: '6 марта',
        slug: 'rrr',
        location: {
          city: 'г. Москва',
          place: 'ТЦ “Европейский”',
          address: 'ул. Никольская улица, д. 21'
        }
      },
      {
        date: '17 марта',
        slug: 'ttt',
        location: {
          city: 'г. волгоград',
          place: 'ТЦ “акварель”',
          address: 'просп. Университетский, д. 107'
        }
      },
      {
        date: '28 марта',
        slug: 'yyy',
        location: {
          city: 'г. Тюмень',
          place: 'ТЦ “Кристалл”',
          address: 'ул. Дмитрия Менделеева, д. 1А'
        }
      }
    ]
  }
};

export const msk_1 = {
  page: {
    title: 'Кdsdsdsdsлиентские дни'
  },
  colors: ['#DC296A', '#3D62D7'],
  light: false,
  background: {
    style: { opacity: 0.15 },
    gradient:
      'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
  },
  components: [
    {
      type: 'top_banner',
      show: true,
      hasContainer: true,
      images: [
        {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'IWjas4ShAb8.jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              }
            }
          ]
        },
        {
          id: 414131,
          type: 'MEDIA_TYPE/IMAGE',
          title: '8447 (1).jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              }
            }
          ]
        }
      ],
      label: '28-29 февраля',
      title: ['Клиентские', 'дни'],
      address: {
        title: 'москва',
        date_list: [
          { date: '28/02', location: 'ТЦ "Авеню", пр-кт Вернадского, дом № 86 А' },
          {
            date: '29/02',
            location: 'ТЦ "БУМ", ул. Перерва, дом № 43'
          }
        ]
      },
      action: {
        show: false,
        description: 'Принеси этот купон на открытие и получи классный подарок!',
        title: 'Расспечатать купон'
      }
    },
    {
      type: 'gifts',
      show: true,
      hasContainer: true,
      list: [
        {
          title: 'Скидки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                },
                fallback: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'подарки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'двойные бонусы на карту',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'rules',
      title: ['Акции', 'на открытии'],
      show: false,
      list: [
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'activity',
      show: true,
      hasContainer: true,
      list: [
        {
          date: '28 февраля',
          specialist: 'визажист',
          schedule: '16:00 – 20:00',
          location: 'ТЦ "Авеню"',
          list: [
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/lipstickIcon_@1x.png',
                      double: '/content/landing/lipstickIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/lipstickIcon_@1x.png',
                      double: '/content/landing/lipstickIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'роскошный мейк-ап от визажиста Mixit'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'скидка 20% на коллекцию Mixit ART'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/sertificatesIcon_@1x.png',
                      double: '/content/landing/sertificatesIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/sertificatesIcon_@1x.png',
                      double: '/content/landing/sertificatesIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'двойные бонусы на карту лояльности'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/giftIcon_@1x.png',
                      double: '/content/landing/giftIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/giftIcon_@1x.png',
                      double: '/content/landing/giftIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'подарки каждому'
            }
          ]
        },
        {
          date: '29 февраля',
          specialist: 'косметолог',
          schedule: '14:00 – 19:00',
          location: 'ТЦ "БУМ"',
          list: [
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/faceIcon_@1x.png',
                      double: '/content/landing/faceIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/faceIcon_@1x.png',
                      double: '/content/landing/faceIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'диагностика состояния кожи с помощью дерматоскопа'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/goodsIcon_@1x.png',
                      double: '/content/landing/goodsIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/goodsIcon_@1x.png',
                      double: '/content/landing/goodsIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'подбор средств по типу кожи от нашего косметолога'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/saleIcon_@1x.png',
                      double: '/content/landing/saleIcon_@2x.png'
                    }
                  }
                ]
              },
              title: 'скидка 15% на уходовые продукты'
            },
            {
              image: {
                id: 749762,
                type: 'MEDIA_TYPE/IMAGE',
                title: 'Молочко для тела',
                sources: [
                  {
                    dimensions: {
                      width: 3200,
                      height: 3200
                    },
                    main: {
                      normal: '/content/landing/vvv_@1x.png',
                      double: '/content/landing/vvv_@2x.png'
                    },
                    fallback: {
                      normal: '/content/landing/vvv_@1x.png',
                      double: '/content/landing/vvv_@2x.png'
                    }
                  }
                ]
              },
              title: 'двойные бонусы на карту лояльности и подарки каждому'
            }
          ]
        }
      ]
    },
    {
      type: 'group',
      show: false,
      title: ['товары', 'со скидкой'],
      slug: 'new'
    },
    {
      type: 'bottom_banner',
      show: true,
      hasContainer: true,
      image: {
        id: 749762,
        type: 'MEDIA_TYPE/IMAGE',
        title: 'Молочко для тела',
        sources: [
          {
            dimensions: {
              width: 3200,
              height: 3200
            },
            main: {
              normal: '/content/landing/girl_3_@1x.jpg',
              double: '/content/landing/girl_3_@2x.jpg'
            },
            fallback: {
              normal: '/content/landing/girl_3_@1x.jpg',
              double: '/content/landing/girl_3_@2x.jpg'
            }
          }
        ]
      },
      title: ['Приходите за', 'яркими эмоциями'],
      main_title: 'в Mixit!'
    },
    {
      type: 'bottom_info',
      show: true,
      hasContainer: true,
      text: '*все предложения действуют в указанное время в указанных магазинах'
    }
  ]
};
