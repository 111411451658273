import React, { Component } from 'react';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import TemporaryLabelIcon from '../../../product/temporary-label-icon';

import './two-column-grid.css';

class MainCard_TwoColumnGrid extends Component {
  render() {
    const {
      breadcrumbs,
      description,
      properties,
      slider,
      labels,
      price,

      weightChoice,
      colorChoice,
      sizeChoice,

      code,
      availability,
      reviews,
      buyForm,
      // buyOneClick,
      addToFavorite,
      delivery,
      IsBfkFeature,
      starsRating
    } = this.props;

    return (
      <div className="MainCard_TwoColumnGrid">
        <div className=" MainCard_TwoColumnGrid-col">
          <HideErrorBoundary>
            <div className="MainCard_TwoColumnGrid-slider">{slider}</div>
          </HideErrorBoundary>
          {IsBfkFeature ? (
            <div className="MainCard_TwoColumnGrid-temporaryLabelIcon">
              <TemporaryLabelIcon size="medium" />
            </div>
          ) : null}
        </div>
        <div className="MainCard_TwoColumnGrid-colWide">
          <div className="MainCard_TwoColumnGrid-markers">{labels}</div>
          <div className="MainCard_TwoColumnGrid-breadcrumbs">{breadcrumbs}</div>
          <div className="MainCard_TwoColumnGrid-title">{description}</div>
          <div className="MainCard_TwoColumnGrid-review">
            <div className="MainCard_TwoColumnGrid-reviewHolder">
              {reviews}
              {Boolean(starsRating) && (
                <div className="MainCard_TwoColumnGrid-starRating">{starsRating}</div>
              )}
            </div>
          </div>

          <div className="MainCard_TwoColumnGrid-priceHolder">{price}</div>

          {weightChoice ? (
            <div className="MainCard_TwoColumnGrid-switchGroup">
              <div className="MainCard_TwoColumnGrid-switchHolder">
                <div className="MainCard_TwoColumnGrid-switch">{weightChoice}</div>
              </div>
              <div className="MainCard_TwoColumnGrid-infoHolder">
                <div className="MainCard_TwoColumnGrid-code">{code}</div>

                <div className="MainCard_TwoColumnGrid-availability">{availability}</div>
              </div>
            </div>
          ) : (
            [
              <div className="MainCard_TwoColumnGrid-codeHolder">
                <div className="MainCard_TwoColumnGrid-code">{code}</div>
              </div>,

              <div className="MainCard_TwoColumnGrid-availabilityHolder">
                <div className="MainCard_TwoColumnGrid-availability">{availability}</div>
              </div>
            ]
          )}

          {sizeChoice && (
            <div className="MainCard_TwoColumnGrid-options">
              <div className="MainCard_TwoColumnGrid-switch">{sizeChoice}</div>
            </div>
          )}

          {colorChoice && (
            <div className="MainCard_TwoColumnGrid-options">
              <div className="MainCard_TwoColumnGrid-switch">{colorChoice}</div>
            </div>
          )}

          <div className="MainCard_TwoColumnGrid-buy">{buyForm}</div>

          <div className="MainCard_TwoColumnGrid-row">
            <div className="MainCard_TwoColumnGrid-rowLink">
              <div className="MainCard-link">{addToFavorite}</div>
            </div>
            {/* <div className="MainCard_TwoColumnGrid-rowLink">
              <div className="MainCard-link">{buyOneClick}</div>
            </div> */}
          </div>

          <div className="MainCard_TwoColumnGrid-delivery">{delivery}</div>

          <div className="MainCard_TwoColumnGrid-description">{properties}</div>
        </div>
      </div>
    );
  }
}

export default MainCard_TwoColumnGrid;
