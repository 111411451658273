import React, { Children, Component } from 'react';
import Media from 'react-media';
import cn from "classnames"

import IconContainer from '../icon-container/icon-container';
import { IconArrowLeft, IconArrowRight, IconAngleArrowLeft, IconAngleArrowRight } from '../icons';
import ScrollBar from '../scroll-bar/scroll-bar';
import Carousel from '../carousel/carousel';
import Item from './item';

import './instagram-carousel.css';

const PER_PAGE = 4;

class InstagramCarousel extends Component {
  state = {
    currentPage: 0
  };

  render() {
    const { children, inLanding } = this.props;
    const { currentPage } = this.state;

    const hasPages = Children.count(children) > PER_PAGE;

    return (
      <Media query="(max-width: 1100px)">
        {isMobile => (
          <div className={cn("InstagramCarousel", {
            "InstagramCarousel--inLanding": inLanding
          })}>
            <div className="InstagramCarousel-content">
              {isMobile ? (
                children
              ) : (
                <Carousel
                  perPage={PER_PAGE}
                  currentPage={currentPage}
                  overflowVisible={true}
                  onChange={this.handlePageChange}
                >
                  {children}
                </Carousel>
              )}
            </div>
            {hasPages && !isMobile && this.renderUnderControls()}
            {/* {hasPages && inLanding && !isMobile && this.renderControls()} */}
          </div>
        )}
      </Media>
    );
  }

  renderUnderControls() {
    const { children, inLanding } = this.props;

    const { currentPage } = this.state;

    const productAmount = Children.count(children);

    const pagesAmount = productAmount - PER_PAGE;

    const isFirstSlide = currentPage === 0;
    const isLastSlide = currentPage === pagesAmount;

    // const progress = (PER_PAGE + currentPage) / productAmount;

    return (
      <div className="ProductCarousel-underControls">
        <button
          className="ProductCarousel-underControl"
          onClick={this.handlePrevButtonClick}
          type="button"
          disabled={isFirstSlide}
        >
          <IconContainer size="xsmall">
            { inLanding ? <IconAngleArrowLeft/> : <IconArrowLeft />}
          </IconContainer>
        </button>
        {!inLanding && (
          <div className="ProductCarousel-progress">
            <div className="ProductCarousel-progressHolder">
              <ScrollBar
                amount={PER_PAGE}
                totalAmount={productAmount}
                scrolled={currentPage}
                onBeforeClick={this.handlePrevButtonClick}
                onAfterClick={this.handleNextButtonClick}
                activeBar
              />
            </div>
          </div>
        )}

        <div className="ProductCarousel-rightHolder">
          {/* <div className="ProductCarousel-counter">
              {this.getSliderInfo(productAmount)}
            </div> */}
          <button
            className="ProductCarousel-underControl"
            onClick={this.handleNextButtonClick}
            type="button"
            disabled={isLastSlide}
          >
            <IconContainer size="xsmall">
            { inLanding ? <IconAngleArrowRight/> : <IconArrowRight />}
            </IconContainer>
          </button>
        </div>
      </div>
    );
  }

  renderControls(isMobile) {
    return (
      !isMobile && (
        <div className="InstagramCarousel-controls">
          <button
            type="button"
            className="InstagramCarousel-prev"
            onClick={this.handlePrevButtonClick}
          >
            <IconContainer size="xsmall">
              <IconArrowLeft />
            </IconContainer>
          </button>
          <button
            type="button"
            className="InstagramCarousel-next"
            onClick={this.handleNextButtonClick}
          >
            <IconContainer size="xsmall">
              <IconArrowRight />
            </IconContainer>
          </button>
        </div>
      )
    );
  }

  handleNextButtonClick = e => {
    e.preventDefault();
    this.goToNextPage();
  };

  handlePrevButtonClick = e => {
    e.preventDefault();
    this.goToPrevPage();
  };

  handlePageChange = data => {
    this.changePage(data.page);
  };

  changePage(page) {
    const { children } = this.props;
    const childrenCount = Children.count(children);
    const lastPage = childrenCount - PER_PAGE;
    const firstPage = 0;

    if (page >= lastPage) {
      this.setState({
        currentPage: lastPage
      });
      return;
    }

    if (page <= firstPage) {
      this.setState({
        currentPage: firstPage
      });
      return;
    }

    this.setState({
      currentPage: page
    });
  }

  goToNextPage() {
    const { children } = this.props;
    const childrenCount = Children.count(children);
    if (this.state.currentPage >= childrenCount - PER_PAGE) {
      return;
    }
    this.setState(state => ({
      currentPage: state.currentPage + 1
    }));
  }

  goToPrevPage() {
    if (this.state.currentPage <= 0) {
      return;
    }

    this.setState(state => ({
      currentPage: state.currentPage - 1
    }));
  }
}

InstagramCarousel.Item = Item;

export default InstagramCarousel;
