import { DEPRECATED_SPECIAL_OFFERS } from '../types';
import { createAction } from 'modules/utils/dux';

export function showDeprecatedSpecialOffers() {
  return createAction(DEPRECATED_SPECIAL_OFFERS.SHOW_LIST);
}

export function addDeprecatedSpecialOffers(list) {
  return createAction(DEPRECATED_SPECIAL_OFFERS.ADD_LIST, { list });
}
