import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Media from 'react-media';

import { getGroupLink } from 'routes/links';

import FilterMenu from '../filter-menu/filter-menu';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';
import Button from 'modules/core/components/button/button';
import ProductContainer from 'modules/product/containers/product';
import Loader from 'modules/core/components/loader/loader';

import './favourite-scene.css';

const { func, object, number, oneOfType, shape, arrayOf, string } = PropTypes;

export default class FavouriteScene extends Component {
  static propTypes = {
    /** active filter */
    activeFilter: oneOfType([object, number]),
    /** array of products */
    products: arrayOf(object),
    /** array of filters */
    filters: arrayOf(
      shape({
        id: number,
        value: string,
        title: string
      })
    ),
    /** callback of change filter option */
    onFilterChange: func,
    /** callback of product deleting */
    onDeleteProduct: func
  };

  render() {
    const {
      filters,
      products,
      activeFilter,
      isFavoritesExist,
      onFilterChange,
      isReady
    } = this.props;

    if (!isReady) {
      return (
        <div className="FavouriteScene">
          <div className="FavouriteScene-loader">
            <Loader />
          </div>
        </div>
      );
    }

    if (!isFavoritesExist) {
      return (
        <div className="FavouriteScene">
          <div className="FavouriteScene-emptyComment">
            Упс, cписок желаний пока пуст :(
            <br /> Возможно, тебе понравится что-то из наших новинок!
          </div>

          <div className="FavouriteScene-emptyButton">
            <Media query="(max-width: 480px)">
              {isLowMobile => (
                <Button
                  variant="primary"
                  title="Перейти к новинкам"
                  component={Link}
                  to={getGroupLink({ slug: 'new' })}
                  size={isLowMobile ? 'small' : 'normall'}
                  expanded={isLowMobile}
                />
              )}
            </Media>
          </div>
        </div>
      );
    }

    return (
      <div className="FavouriteScene">
        <div className="FavouriteScene-menu">
          <FilterMenu filters={filters} activeFilter={activeFilter} onChange={onFilterChange} />
        </div>

        <div className="FavouriteScene-content">
          {products.map(slug => (
            <HideErrorBoundary>
              <div key={slug} className="FavouriteScene-item">
                <ProductContainer slug={slug} variant="favourite" listId="favourite" />
              </div>
            </HideErrorBoundary>
          ))}
        </div>
      </div>
    );
  }
}
