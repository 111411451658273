import React, { Component } from 'react';

import getTimeDifference from 'modules/utils/get-time-difference';

import CountDown from '../components/count-down/count-down.jsx';

class CountDownContainer extends Component {
  state = {
    expiredMilliseconds: this.calcInitialExpiredMilliseconds()
  };

  render() {
    const isDataExpire = this.getIsDateExpired();
    const { detalized, ...rest } = this.props;

    if (isDataExpire) {
      return null;
    }

    const expireTime = this.renderExpireTime();
    return <CountDown expireTime={expireTime} detalized={detalized} {...rest} />;
  }

  calcInitialExpiredMilliseconds() {
    const { endData } = this.props;

    const endDataObject = new Date(endData);
    const currentDataObject = Date.now();
    const expireMillisecond = endDataObject - currentDataObject;

    return expireMillisecond;
  }

  getIsDateExpired() {
    const { expiredMilliseconds } = this.state;
    return expiredMilliseconds < 0;
  }

  renderExpireTime() {
    const { expiredMilliseconds } = this.state;

    return getTimeDifference(expiredMilliseconds);
  }

  updateTime = () => {
    this.setState(state => {
      return {
        ...state,
        expiredMilliseconds: state.expiredMilliseconds - 1000
      };
    });
  };

  componentDidMount() {
    const isDataExpired = this.getIsDateExpired();

    if (isDataExpired) {
      return;
    }

    this.intervalId = setInterval(this.updateTime, 1000);
  }

  componentWillUnmount() {
    const isDataExpired = this.getIsDateExpired();

    if (isDataExpired) {
      return;
    }

    clearInterval(this.intervalId);
  }
}

export default CountDownContainer;
