import React, { Component } from 'react';

import Media from 'modules/core/components/media/media';
import IconInstagram from 'modules/core/components/icons/icon-instagram2/icon-instagram2';
import IconContainer from 'modules/core/components/icon-container/icon-container';

import './instagram-gallery-tile.css';

class InstagramGalleryTile extends Component {
  render() {
    const { post } = this.props;

    return (
      <a
        className="InstagramGalleryTile"
        alt="фото с Instagram"
        href={post.link}
        title="Перейти на страничку поста в Instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="InstagramGalleryTile-image">
          <Media
            media={post.media}
            alt={`Изображение к отзыву ${post.link}`}
            stretch="horizontal"
            ratio={1}
            resizeWidth={280}
            background="#fff"
            nocaption
            lazy
          />
        </div>
        <div className="InstagramGalleryTile-icon">
          <IconContainer>
            <IconInstagram />
          </IconContainer>
        </div>
        <div className="InstagramGalleryTile-userName">{post.instagram_author.name}</div>
      </a>
    );
  }
}

export default InstagramGalleryTile;
