import React, { Component } from 'react';

import FilterOption from '../filter-option/filter-option';

import './filter-options-menu.css';

class FilterOptionsMenu extends Component {
  render() {
    const { filterOptionList } = this.props;

    return <ul className="FilterOptionsMenu">{filterOptionList.map(this.renderOption)}</ul>;
  }

  renderOption = (option, index) => {
    return (
      <li key={index} className="FilterOptionsMenu-item">
        <FilterOption option={option} />
      </li>
    );
  };
}

export default FilterOptionsMenu;
