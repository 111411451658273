import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromLocation from 'modules/geography/ducks/location';
import * as fromBonuses from 'modules/profile/ducks/bonuses';
import { actions as Dialogs } from 'modules/core/ducks/dialogs';
import areaCodes from 'modules/core/components/input-phone-with-area-code/area-codes';
import getSeparatedPhone from 'modules/core/components/input-phone-with-area-code/helpers/get-separated-phone';

import * as fromProfile from '../ducks/profile';
import * as fromPhysicalTraits from '../ducks/physical-traits';
import Profile from '../actions/profile';

import GeneralScene from '../components/general-scene/general-scene';

const mapState = createStructuredSelector({
  profile: fromProfile.getAll,
  delivery: fromProfile.getDelivery,
  isLoading: fromProfile.getIsLoading,
  checkedPhysicalTraits: fromPhysicalTraits.getCheckedPhysicalTraits,
  isPhysicalTraitsInitialLoading: fromPhysicalTraits.getIsInitialLoading,
  isPhysicalTraitsChangesLoading: fromPhysicalTraits.getIsChangesLoading,
  physicalTraitsErrorMessage: fromPhysicalTraits.getErrorMessage,
  currentCity: fromLocation.getCurrentCity,
  suggestedCity: fromLocation.getSuggestedCity,
  isBonusCardActivated: fromBonuses.getIsActivated
});

const mapActions = {
  changePhone: Profile.phoneChange,
  changeFullName: Profile.changeFullName,
  changeBirthDate: Profile.birthDateChange,
  changeAddress: Profile.addressChange,
  onPhysicalTraitsChange: Profile.physicalTraitsChange,
  onPhysicalTraitsShow: Profile.physicalTraitsShow,
  openCityDialog: Dialogs.openCityDialog,
  openCallbackDialog: Dialogs.openCallbackDialog
};

class GeneralSceneContainer extends Component {
  state = {
    phone: ''
  };

  render() {
    const {
      profile,
      delivery,
      isLoading,
      onPhysicalTraitsChange,
      checkedPhysicalTraits,
      onPhysicalTraitsShow,
      openCityDialog,
      isPhysicalTraitsInitialLoading,
      isPhysicalTraitsChangesLoading,
      isBonusCardActivated,
      physicalTraitsErrorMessage,
      currentCity,
      suggestedCity
    } = this.props;

    const phoneLength =
      this.state.phone &&
      this.state.phone.length &&
      getSeparatedPhone(this.state.phone, areaCodes).code
        ? getSeparatedPhone(this.state.phone, areaCodes).code.phoneLength
        : areaCodes[0].phoneLength;

    const isPhoneLengthCorrect =
      this.state.phone && this.state.phone.replace(/\D/g, '').length === phoneLength;
    let PHONE_SHORT_ERROR = !isPhoneLengthCorrect
      ? `Номер должен содержать ${phoneLength} цифр`
      : null;

    if (!this.state.phone) {
      PHONE_SHORT_ERROR = 'Укажите свой номер';
    }

    return (
      <GeneralScene
        isLoading={isLoading}
        profile={profile}
        delivery={delivery}
        currentCity={currentCity}
        suggestedCity={suggestedCity}
        onChangeName={this.handleChangeName}
        onChangePhoto={this.handleChangePhoto}
        onPhoneChange={this.handlePhoneChange}
        onPhoneStateChange={this.handlePhoneStateChange}
        onPhoneStateReset={this.handlePhoneStateReset}
        phoneError={PHONE_SHORT_ERROR}
        onDateChange={this.handleDateChange}
        onAddressChange={this.handelAddressChange}
        onCallbackDialogOpen={this.handleCallbackDialogOpen}
        onPhysicalTraitsChange={onPhysicalTraitsChange}
        openCityDialog={openCityDialog}
        checkedPhysicalTraits={checkedPhysicalTraits}
        onPhysicalTraitsShow={onPhysicalTraitsShow}
        isPhysicalTraitsInitialLoading={isPhysicalTraitsInitialLoading}
        isPhysicalTraitsChangesLoading={isPhysicalTraitsChangesLoading}
        isPhoneEditable={!isBonusCardActivated}
        physicalTraitsErrorMessage={physicalTraitsErrorMessage}
      />
    );
  }

  componentDidMount() {
    this.setInitialPhoneState();
  }

  componentDidUpdate(prevProps) {
    const { phone } = this.props.profile;
    if (phone !== prevProps.profile.phone) {
      this.setInitialPhoneState();
    }
  }

  setInitialPhoneState() {
    const { phone } = this.props.profile;
    this.setPhoneState(phone);
  }

  setPhoneState(phone) {
    this.setState({
      phone
    });
  }

  handlePhoneStateChange = value => {
    const _value = '+' + value.replace(/\D/g, '');
    this.setPhoneState(_value);
  };

  handlePhoneStateReset = () => {
    const { profile } = this.props;

    if (profile.phone) {
      this.setPhoneState(profile.phone);
    }
  };

  changeName(name) {
    console.log("GeneralSceneContainer -> changeName -> name", name)
    this.props.changeFullName(name);
  }

  changePhone(phone) {
    this.props.changePhone(phone);
  }

  changeDate(birthDate) {
    this.props.changeBirthDate(birthDate);
  }

  changeAddress(address) {
    this.props.changeAddress(address);
  }

  handleChangeName = name => {
    this.changeName(name);
  };

  handleChangePhoto = photo => {
    console.info('Photo Changed', photo);
  };

  handlePhoneChange = phone => {
    this.changePhone(phone);
  };

  handelAddressChange = address => {
    this.changeAddress(address);
  };

  handleDateChange = date => {
    this.changeDate(date);
  };

  handleCallbackDialogOpen = () => {
    const { openCallbackDialog } = this.props;

    openCallbackDialog();
  };
}

export default connect(
  mapState,
  mapActions
)(GeneralSceneContainer);
