import React, { Component } from 'react';

import './email-succes-icon.css';

class IconEmailSucces extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="IconEmailSucces">
        <path d="M25.6 9.3l-4.1 3.3-1.6-1.9c-.1-.2-.4-.2-.6 0-.2.1-.2.4 0 .6l1.9 2.2c.1.2.4.2.6.1l4.4-3.5c.2-.1.2-.4.1-.6-.3-.3-.5-.4-.7-.2z" />
        <path d="M23.3 4.2c-3.8 0-7 3-7.2 6.8h-11c-1.3 0-2.5 1-2.6 2.3v11c0 .6.2 1.2.6 1.7 0 .1.1.1.1.1.5.5 1.2.8 1.9.8H21c.7 0 1.4-.3 1.9-.8h.1l.1-.1c.4-.5.6-1.1.6-1.7v-5.7c3.8-.2 6.8-3.4 6.8-7.2 0-4-3.2-7.2-7.2-7.2zM5.2 11.7h10.9c.1 1.7.8 3.3 2 4.6.2.2.4.4.6.5l-4.5 3.8c-.6.5-1.5.5-2.1 0l-8.7-7.4c.1-.8.9-1.5 1.8-1.5zm0 14.4c-.4 0-.8-.1-1.1-.4l4.8-4.9c.1-.1.1-.2.1-.4 0-.1-.1-.2-.3-.3-.1 0-.3 0-.4.1L3.6 25c-.1-.3-.2-.6-.2-.9v-10l8.2 7c.9.8 2.2.8 3.2 0l4.6-3.9c1.1.7 2.3 1.2 3.6 1.2v5.7c0 .3-.1.6-.2.9L18 20.1c-.2-.2-.4-.2-.6 0-.2.2-.2.4 0 .6l4.8 4.9c-.3.2-.7.4-1.1.4H5.2zm18.1-8.3c-1.4 0-2.7-.4-3.8-1.2h-.1c-.3-.2-.6-.5-.8-.7-2.1-2.2-2.3-5.6-.6-8.1 1.7-2.5 5-3.4 7.8-2.2 2.8 1.2 4.4 4.2 3.7 7.2-.5 2.8-3.2 5-6.2 5z" />
      </svg>
    );
  }
}

export default IconEmailSucces;
