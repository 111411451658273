import { takeEvery, select, put, call } from 'redux-saga/effects';
import * as fromCore from 'modules/core/ducks';
import * as FAVORITES from '../types/favorites';
import { mixitApi } from 'services/mixit';
import FavoritesAction from '../actions/favorites';

export function* favoritesWatcher() {
  yield takeEvery(FAVORITES.ADD_REQUEST, addToFavorites);
  yield takeEvery(FAVORITES.REMOVE_REQUEST, removeFromFavorites);
}

function* addToFavorites(action) {
  const { product } = action.payload;
  const authorization = yield select(fromCore.getAuthorization);
  try {
    const response = yield call(
      mixitApi(authorization)
        .profile()
        .favorites().add,
      {
        product_slug: product.slug
      }
    );

    yield put(FavoritesAction.addResponse(response));
  } catch (error) {
    yield put(FavoritesAction.addResponseError(product, error));
  }
}

function* removeFromFavorites(action) {
  const { product } = action.payload;
  const authorization = yield select(fromCore.getAuthorization);
  try {
    const response = yield call(
      mixitApi(authorization)
        .profile()
        .favorites(product.slug).remove
    );
    yield put(FavoritesAction.addResponse(response));
  } catch (error) {
    yield put(FavoritesAction.addResponseError(product, error));
  }
}
