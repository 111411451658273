import React, { Component, Fragment } from 'react';
import cn from 'classnames';
import Media from 'react-media';

import Button from 'modules/core/components/button/button';
import Coupon from 'modules/ordering/components/coupon/coupon';
import ProductPreview from 'modules/product/components/product-preview/product-preview';
import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';
import Link from 'modules/core/components/link/short-link';
import { IconGift } from 'modules/core/components/icons';

import Price from '../price-formatted/price-formatted';
import FreeDeliveryNotification from '../basket-note/basket-note';
import PackingDialogContainer from '../../containers/packing-dialog';

import Row from './row';
import Cell from './cell';
import BasketProduct from './basket-product';
import RemovedProduct from './removed-product';

import GiftProductDialog from '../gift-product-dialog/gift-product-dialog';
import GiftProductBottomSheet from 'modules/product/components/gift-product-bottom-sheet/gift-product-bottom-sheet';

import BonusInput from 'modules/core/components/bonus-input/bonus-input';
import BonusHeader from 'modules/core/components/bonus-input/bonus-input-heder/bonus-input-heder';

import './basket.css';

function BonusInputRow({
  acceptedBonus,
  available,
  onApply,
  onUnApply,
  disabled,
  isLoading,
  editing,
  isConfirmationPending
}) {
  const value = acceptedBonus || 0;

  return (
    <div className="Basket-inputRow">
      <BonusInput
        // key={isConfirmationPending}
        editing={editing}
        value={value}
        available={available}
        onApply={onApply}
        onUnApply={onUnApply}
        disabled={disabled}
        isLoading={isLoading}
        isConfirmationPending={isConfirmationPending}
        alwaysShowSlider
      />
    </div>
  );
}

function PromoRow({
  coupon,
  total,
  bonus,
  isVerified,
  isPromoLoading,
  onPromoCodeSubmit,
  onPromoCodeReset,
  optionId,
  disabled,
  openApplyAnotherGiftDialog
}) {
  return (
    <Coupon
      openApplyAnotherGiftDialog={openApplyAnotherGiftDialog}
      coupon={coupon}
      optionId={optionId}
      onSubmit={onPromoCodeSubmit}
      onReset={onPromoCodeReset}
      isLoading={isPromoLoading}
      isVerified={isVerified}
      disabled={disabled}
      render={({ children }) => (
        <Fragment>
          <div className="Basket-promoHolder">
            <div className="Basket-promo">{children}</div>
          </div>
        </Fragment>
      )}
    />
  );
}

function TotalCost({ number }) {
  return (
    <div className="Basket-receiptTotalEntry">
      <div className="Basket-receiptCaptionTotal">Итого:</div>
      <div className="Basket-receiptValueTotal">
        <Price number={number} />
      </div>
    </div>
  );
}

class Basket extends Component {
  render() {
    const {
      productsCost,
      promoOptions,
      isPlacing,
      isGiftChoiseShowed,
      discount,
      promo,
      coupon,
      promoOptionId,
      isPromoVerified,
      isPromoLoading,
      onPromoCodeSubmit,
      onPromoOptionChose,
      onPromoCodeReset,
      onApplyBonus,
      onRemoveBonus,
      bonus,
      isAuthorized,
      maxDeliveryPrice,
      closeApplyAnotherGiftDialog,
      openApplyAnotherGiftDialog,
      isNoRussia
    } = this.props;

    const showDiscount = discount > 0;

    const deliveryCost = this.getDeliveryCost();
    const showDeliveryCost = deliveryCost !== null && !isNoRussia;

    const showProductCost = showDiscount || showDeliveryCost;

    return (
      <Fragment>
        <Media query="(max-width: 840px)">
          {isMobile => (
            <div
              className={cn('Basket', {
                'Basket--dimmed': isPlacing
              })}
            >
              {maxDeliveryPrice !== 0 && this.renderFreeDeliveryAlert({ isMobile })}

              {this.renderProductTable({ isMobile })}

              {isMobile ? (
                <Fragment>
                  <div className="Basket-section">{this.renderGiftChoice()}</div>
                  <div className="Basket-section">
                    <div className="Basket-actionActionsHolder">
                      <div className="Basket-actionActions">
                        <div className="Basket-actionAction">
                          <Coupon
                            openApplyAnotherGiftDialog={openApplyAnotherGiftDialog}
                            coupon={coupon}
                            optionId={promoOptionId}
                            onSubmit={onPromoCodeSubmit}
                            onReset={onPromoCodeReset}
                            isLoading={isPromoLoading}
                            isVerified={isPromoVerified}
                            disabled={bonus.isApplied}
                          />
                        </div>
                        {bonus.available && isAuthorized && (
                          <div className="Basket-actionAction">
                            <div className="Basket-bonusHeader">
                              <BonusHeader
                                editing={bonus.isApplied}
                                value={bonus.applied}
                                available={bonus.available}
                                onUnApply={onRemoveBonus}
                                disabled={this.isBonusesDisabled(promo)}
                                isLoading={bonus.isLoading}
                              />
                            </div>
                            {!bonus.isApplied && (
                              <BonusInput
                                // key={acceptedBonus}
                                value={bonus.applied}
                                editing={bonus.isApplied}
                                onApply={onApplyBonus}
                                onUnApply={onRemoveBonus}
                                available={bonus.available}
                                isLoading={bonus.isLoading}
                                isConfirmationPending={bonus.isConfirmationPending}
                                // disabled={!!promo && promo.isApplied}
                                disabled={this.isBonusesDisabled(promo)}
                                // alwaysShowSlider
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="Basket-section Basket-section--noUnderline">
                    {showProductCost && (
                      <div className="Basket-line">{this.renderProductsCost()}</div>
                    )}
                    {/* {bonus &&
                    bonus.isApplied && (
                      <div className="Basket-line">
                        {this.renderBonusDiscount(bonus.applied)}
                      </div>
                    )} */}
                    {showDiscount && (
                      <div className="Basket-line">{this.renderDiscount(discount, bonus)}</div>
                    )}
                    {showDeliveryCost && (
                      <div className="Basket-line">{this.renderDeliveryCost(deliveryCost)}</div>
                    )}
                    <div className="Basket-lineTotal">
                      <TotalCost number={productsCost} />
                      {!!bonus.earned && (
                        <div className="Basket-willGettedBonuses">
                          <span className="Basket-willGettedBonusesMain">
                            {`+${bonus.earned} бонусов `}
                          </span>
                          за покупку
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="Basket-section">
                    <div className="Basket-line">{this.renderGiftChoice()}</div>
                  </div>
                  <div className="Basket-totalRow">
                    <div className="Basket-specialOffers">
                      <PromoRow
                        coupon={coupon}
                        optionId={promoOptionId}
                        isPromoLoading={isPromoLoading}
                        onPromoCodeSubmit={onPromoCodeSubmit}
                        onPromoCodeReset={onPromoCodeReset}
                        isVerified={isPromoVerified}
                        disabled={bonus.isApplied}
                        openApplyAnotherGiftDialog={openApplyAnotherGiftDialog}
                      />
                      {bonus.available && isAuthorized && (
                        <Fragment>
                          <div className="Basket-bonusHeader">
                            <div className="Basket-line">
                              <BonusHeader
                                editing={bonus.isApplied}
                                value={bonus.applied}
                                available={bonus.available}
                                onUnApply={onRemoveBonus}
                                disabled={this.isBonusesDisabled(promo)}
                                isLoading={bonus.isLoading}
                              />
                            </div>
                          </div>
                          {!bonus.isApplied && (
                            <BonusInputRow
                              acceptedBonus={bonus.applied}
                              onApply={onApplyBonus}
                              onUnApply={onRemoveBonus}
                              isLoading={bonus.isLoading}
                              isConfirmationPending={bonus.isConfirmationPending}
                              available={bonus.available}
                              disabled={this.isBonusesDisabled(promo)}
                              // disabled={!!promo}
                              editing={bonus.isApplied}
                            />
                          )}
                        </Fragment>
                      )}
                    </div>
                    <div className="Basket-totalHolder">
                      {showProductCost && this.renderProductsCost()}
                      {/* {bonus &&
                        bonus.isApplied &&
                        this.renderBonusDiscount(bonus.applied)} */}
                      {showDiscount && this.renderDiscount(discount, bonus)}
                      {showDeliveryCost && this.renderDeliveryCost(deliveryCost)}
                      <TotalCost number={productsCost} />
                      {bonus.earned > 0 && (
                        <div className="Basket-willGettedBonuses">
                          <span className="Basket-willGettedBonusesMain">
                            {`+${bonus.earned} бонусов `}
                          </span>
                          за покупку
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              )}

              <div className="Basket-section">
                {isMobile ? (
                  <div className="Basket-actionList">
                    <div className="Basket-action" key="default">
                      {bonus.conformationOptions.required
                        ? this.confirmCodeButton({ isMobile })
                        : this.renderPlaceOrderButton({ isMobile })}
                    </div>
                    {/* <div className="Basket-action" key="quick">
                      {this.renderQuickOrderButton({ isMobile })}
                    </div> */}
                  </div>
                ) : (
                  <div className="Basket-actionList">
                    {/* <div className="Basket-action" key="quick">
                      {this.renderQuickOrderButton({ isMobile })}
                    </div> */}
                    <div className="Basket-action" key="default">
                      {bonus.conformationOptions.required
                        ? this.confirmCodeButton({ isMobile })
                        : this.renderPlaceOrderButton({ isMobile })}
                    </div>
                  </div>
                )}
              </div>

              {/* DIALOG SECTION */}
              <PackingDialogContainer slug="present-pack" />

              <Media query="(max-width: 1100px)">
                {isTablet => (
                  <Fragment>
                    {isTablet ? (
                      <GiftProductBottomSheet
                        show={isGiftChoiseShowed}
                        promoOptions={promoOptions}
                        onSelect={onPromoOptionChose}
                        onCancel={onPromoCodeReset}
                      />
                    ) : (
                      <GiftProductDialog
                        show={isGiftChoiseShowed}
                        promoOptions={promoOptions}
                        onSelect={onPromoOptionChose}
                        onCancel={onPromoCodeReset}
                        closeApplyAnotherGiftDialog={closeApplyAnotherGiftDialog}
                      />
                    )}
                  </Fragment>
                )}
              </Media>
            </div>
          )}
        </Media>
      </Fragment>
    );
  }

  isBonusesDisabled(promo) {
    if (!!promo && promo.isApplied) {
      return true;
    }

    if (!!promo && promo.code) {
      return true;
    }

    return false;
  }

  renderFreeDeliveryAlert({ isMobile }) {
    const { productsCost, freeDeliveryPrice, isDeliveryFree } = this.props;

    if (isDeliveryFree) {
      return null;
    }

    return (
      <div className="Basket-alert">
        <FreeDeliveryNotification currentPrice={productsCost} discountPrice={freeDeliveryPrice} />
      </div>
    );
  }

  renderProductTable({ isMobile }) {
    const {
      products,
      changeAmount,
      removeProduct,
      onToggleFavorite,
      isAuthorized,
      openAuthorizationDialog,
      isBasketFetching,
      onFavouriteClick
    } = this.props;

    const ProductComponent = isMobile ? ProductPreview : BasketProduct;

    return (
      <Fragment>
        {!isMobile && (
          <div className="Basket-section">
            <Row>
              <Cell type="mainInfo">
                <span className="Basket-title">Товар</span>
              </Cell>
              <Cell type="amount">
                <span className="Basket-title">Количество</span>
              </Cell>
              <Cell type="price">
                <span className="Basket-title">Сумма</span>
              </Cell>
              <Cell type="actions" />
            </Row>
          </div>
        )}
        <div className="Basket-section">
          {products.map(item => (
            <div
              key={item.uid}
              className={cn('Basket-product', { 'Basket-product--gift': item.gift })}
            >
              {item.willBeRemoved ? (
                <RemovedProduct
                  product={item.product}
                  onCancel={() => this.props.cancelProductRemoval(item)}
                />
              ) : (
                <ProductComponent
                  product={item.product}
                  price={item.price}
                  amount={item.amount}
                  isGift={item.gift}
                  onToggleFavorite={onToggleFavorite}
                  onFavouriteClick={onFavouriteClick}
                  onAmountChange={amount =>
                    changeAmount(item, amount, { previousAmount: item.amount })
                  }
                  onRemove={() => removeProduct(item)}
                  isAuthorized={isAuthorized}
                  onAuthorizationClick={openAuthorizationDialog}
                  withCounter={!item.isAmountLocked}
                  listId="basket"
                  isBasketFetching={isBasketFetching}
                />
              )}
            </div>
          ))}
        </div>
      </Fragment>
    );
  }

  renderDiscount(discount, bonus) {
    return (
      <div className="Basket-receiptEntry">
        <div className="Basket-receiptCaption">
          {bonus.isApplied && !bonus.isLoading ? 'Скидка с учетом бонусов:' : 'Скидка:'}
        </div>
        <div className="Basket-receiptValue">
          <Price number={-discount} variant="discount" />
        </div>
      </div>
    );
  }

  renderBonusDiscount(discount) {
    return (
      <div className="Basket-receiptEntry">
        <div className="Basket-receiptCaption">Скидка бонусами:</div>
        <div className="Basket-receiptValue">
          <Price number={-discount} variant="discount" />
        </div>
      </div>
    );
  }

  renderProductsCost() {
    const { productsDefaultCost } = this.props;

    return (
      <div className="Basket-receiptEntry">
        <div className="Basket-receiptCaption">Стоимость товаров:</div>
        <div className="Basket-receiptValue">
          <Price number={productsDefaultCost} />
        </div>
      </div>
    );
  }

  renderDeliveryCost(price) {
    return (
      <div className="Basket-receiptEntry">
        <div className="Basket-receiptCaption">Доставка:</div>
        <div className="Basket-receiptValue">{price}</div>
      </div>
    );
  }

  renderDiscountCost() {
    return null;
  }

  renderGiftChoice() {
    const { onGiftDialogOpen } = this.props;

    return (
      <div className="Basket-giftChoice">
        <Link
          component="button"
          type="button"
          appendedIcon={<IconGift />}
          onClick={onGiftDialogOpen}
        >
          Выбрать подарочную упаковку
        </Link>
      </div>
    );
  }

  renderQuickOrderButton({ isMobile }) {
    return <Button title="Быстрый заказ" size={isMobile && 'small'} expanded={isMobile} />;
  }

  renderPlaceOrderButton({ isMobile }) {
    const { isPlacing, isVerifying, bonus } = this.props;

    const isLoading =
      isPlacing || isVerifying || bonus.isLoading || bonus.conformationOptions.isPlacing;

    return (
      <Button
        title="Оформить заказ"
        variant="primary"
        type="submit"
        disabled={isLoading}
        iconAppend={isLoading && <IconFlatLoader />}
        size={isMobile && 'small'}
        expanded={isMobile}
        onClick={this.handleSubmitButtonClick}
      />
    );
  }

  confirmCodeButton({ isMobile }) {
    const { bonus, onConfirmBonuses } = this.props;

    const { confirmFetching, codeFetching } = bonus.conformationOptions;

    const isLoading =
      confirmFetching || codeFetching || bonus.isLoading || bonus.conformationOptions.isPlacing;

    return (
      <Button
        title="Оформить заказ"
        variant="primary"
        type="submit"
        disabled={isLoading}
        iconAppend={isLoading && <IconFlatLoader />}
        size={isMobile && 'small'}
        expanded={isMobile}
        onClick={onConfirmBonuses}
      />
    );
  }

  componentDidMount() {
    this.props.show();
  }

  getDeliveryCost() {
    const { deliveryCost } = this.props;

    if (deliveryCost === null) {
      return null;
    }

    if (deliveryCost === 0) {
      return 'бесплатно';
    }

    return <Price number={deliveryCost} />;
  }

  handleSubmitButtonClick = e => {
    e.preventDefault();
    this.props.onBasketSubmit();
  };
}

export default Basket;
