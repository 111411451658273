import { createReducer, handle as on } from 'modules/utils/dux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as fromDeliveryAddresses from './delivery-addresses';
import * as ACCOUNT from 'modules/core/ducks/types/account';
import * as SOCIAL from 'modules/core/ducks/types/social';
import * as ORDERS from 'modules/ordering/ducks/types/orders';
import * as PROFILE from '../types/profile';
import * as FAVORITES from '../types/favorites';
import * as fromOrders from 'modules/ordering/ducks/orders';
import * as fromCities from 'modules/geography/ducks/city-list';
import ORDER_STATUS from 'modules/ordering/constants/order-status';

const initialState = {
  firstName: null,
  lastName: null,
  phone: null,
  birthDate: null,
  photo: null,
  city: null,
  deliveryAddresses: [],
  favorites: [],
  orders: [],
  _isLoading: false,
  _isReady: false,
  UI: { isDropdownOpened: false }
};

const startLoading = state => ({ ...state, _isLoading: true });
const endLoading = state => ({ ...state, _isLoading: false });

/* REDUCERS */
const reducer = createReducer(
  on(PROFILE.REQUEST, startLoading),

  on(PROFILE.RESPONSE, (state, { message, profile }, error) => {
    if (error) {
      return {
        ...state,
        _isLoading: false,
        _isReady: true
      };
    }

    return {
      ...state,
      ...profile,
      _isLoading: false,
      _isReady: true
    };
  }),

  on(ACCOUNT.AUTHORIZATION_REQUEST, startLoading),

  on(ACCOUNT.AUTHORIZATION_ERROR, endLoading),

  on(ACCOUNT.AUTHORIZATION_SUCCESS, (state, { entities }) => {
    if (!entities.profile) {
      return state;
    }

    return {
      ...entities.profile,
      _isLoading: false,
      _isReady: true
    };
  }),

  on(ACCOUNT.REGISTRATION_REQUEST, startLoading),

  on(ACCOUNT.REGISTRATION_ERROR, endLoading),

  on(ACCOUNT.REGISTRATION_COMPLETE, (state, { entities }) => {
    if (!entities.profile) {
      return state;
    }

    return {
      ...entities.profile,
      _isLoading: false,
      _isReady: true
    };
  }),

  on(ACCOUNT.SIGN_OUT, state => {
    return initialState;
  }),

  on(SOCIAL.FACEBOOK_RECEIVE_AUTH, startLoading),
  on(SOCIAL.VK_RECEIVE_AUTH, startLoading),

  on(ORDERS.SUCCESS, (state, { id }) => {
    return {
      ...state,
      orders: [...state.orders, id]
    };
  }),

  on(FAVORITES.ADD_REQUEST, (state, { product }) => {
    const slug = product.slug;

    return {
      ...state,
      favorites: [...state.favorites, slug]
    };
  }),

  on(FAVORITES.ADD_RESPONSE, (state, { product }, error) => {
    const slug = product.slug;

    if (error) {
      return {
        ...state,
        favorites: state.favorites.filter(item => item !== slug)
      };
    }

    return state;
  }),

  on(FAVORITES.REMOVE_REQUEST, (state, { product }) => {
    const slug = product.slug;

    return {
      ...state,
      favorites: state.favorites.filter(item => item !== slug)
    };
  }),

  on(FAVORITES.REMOVE_RESPONSE, (state, { product }, error) => {
    const slug = product.slug;

    if (error) {
      return {
        ...state,
        favorites: [...state.favorites, slug]
      };
    }

    return state;
  }),

  on(PROFILE.FULL_NAME_CHANGE, (state, { firstName, lastName }) => {
    return {
      ...state,
      firstName,
      lastName
    };
  }),

  on(PROFILE.PHONE_CHANGE, (state, { phone }) => {
    return {
      ...state,
      phone
    };
  }),

  on(PROFILE.PHOTO_CHANGE_RESPONSE, (state, { photo }) => {
    return {
      ...state,
      photo
    };
  }),

  on(PROFILE.ADDRESS_CHANGE, (state, { address }) => {
    return {
      ...state,
      legacyAddress: address
    };
  }),

  on(PROFILE.BIRTH_DATE_CHANGE, (state, { birthDate }) => {
    return {
      ...state,
      birthDate
    };
  }),

  on(PROFILE.CITY_CHANGE, (state, { cityId }) => {
    return {
      ...state,
      city: cityId
    };
  }),

  on(PROFILE.CHANGE, (state, { address, ...profile }) => {
    return {
      ...state,
      legacyAddress: address,
      ...profile
    };
  }),

  on(PROFILE.DROPDOWN_OPEN, state => {
    return {
      ...state,
      UI: { isDropdownOpened: true }
    };
  }),

  on(PROFILE.DROPDOWN_CLOSE, state => {
    return {
      ...state,
      UI: { isDropdownOpened: false }
    };
  })
);

export default reducer(initialState);

/* SELECTORS */

export const getRoot = state => state.profile.profile;

export const getDeliveryAddressIds = createSelector(getRoot, root => root.deliveryAddresses);

export const getUi = createSelector(getRoot, root => root.UI || null);

export const getIsProfileDropdownOpened = createSelector(getUi, ui => {
  if (!ui) {
    return null;
  }
  return ui.isDropdownOpened;
});

export const getDeliveryAddresses = createSelector(
  getDeliveryAddressIds,
  fromDeliveryAddresses.getAll,
  (ids, all) => ids.map(id => all[id])
);

export const getCity = createSelector(getRoot, function _getCity(profile) {
  if (!profile) {
    return null;
  }

  const city = profile.city;

  if (!city) {
    return null;
  }

  return city;
});

export const getCityData = createSelector(getCity, fromCities.getFindItem, (cityId, findCity) => {
  return findCity(cityId);
});

export const getAccessDeliveryAddressById = createSelector(
  getDeliveryAddressIds,
  fromDeliveryAddresses.getAccessAddressById,
  (ids, accessAddressById) => id => {
    if (!ids) {
      return null;
    }

    if (!ids.includes(id)) {
      return null;
    }

    return accessAddressById(id);
  }
);

export const getFirstName = createSelector(getRoot, profile => profile.firstName);

export const getFirstNameLetter = createSelector(getFirstName, function _getFirstNameLetter(
  firstName
) {
  if (!firstName) {
    return null;
  }
  return firstName.substring(0, 1);
});

export const getLastName = createSelector(getRoot, profile => profile.lastName);

export const getFullName = createSelector(getFirstName, getLastName, (firstName, lastName) => {
  if (!lastName || !lastName.length) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
});

export const getPhoto = createSelector(getRoot, profile => profile.photo);

export const getFavoritesCount = createSelector(getRoot, profile => profile.favorites.length || 0);

export const getIsLoading = createSelector(getRoot, profile => profile._isLoading);

export const getReady = createSelector(getRoot, profile => profile._isReady);

export const getFavoriteList = createSelector(getRoot, profile => profile.favorites);

export const getCheckInFavorites = createSelector(getFavoriteList, function _getCheckInFavorites(
  favorites
) {
  return slug => {
    if (!slug) {
      return false;
    }

    return favorites.indexOf(slug) !== -1;
  };
});

export const getEmail = createSelector(getRoot, root => root.email);
export const getPhone = createSelector(getRoot, root => root.phone);
export const getBirthDate = createSelector(getRoot, root => {
  if (!root || !root.birthDate) {
    return null;
  }
  return root.birthDate;
});

export const getOrderList = createSelector(getRoot, fromOrders.getFindItem, (root, findOrder) => {
  return root.orders.map(findOrder).filter(item => item.status !== ORDER_STATUS.ABANDONED);
});
export const getOrderCount = createSelector(getOrderList, orderList => {
  if (!orderList) {
    return 0;
  }

  return orderList.length || 0;
});

export const getCompleteness = () => 0;

export const getAll = createStructuredSelector({
  fullName: getFullName,
  firstName: getFirstName,
  email: getEmail,
  phone: getPhone,
  birthDate: getBirthDate,
  photo: getPhoto,
  completeness: getCompleteness
});

export const getLegacyAddress = createSelector(getRoot, root => root.legacyAddress);

export const getLegacyStreetTitle = createSelector(getLegacyAddress, legacyAddress => {
  if (!legacyAddress) return null;
  return legacyAddress.street;
});

export const getLegacyBuilding = createSelector(getLegacyAddress, legacyAddress => {
  if (!legacyAddress) return null;
  return legacyAddress.building;
});

export const getLegacyApartment = createSelector(getLegacyAddress, legacyAddress => {
  if (!legacyAddress) return null;
  return legacyAddress.apartment;
});

export const getDelivery = createStructuredSelector({
  city: getCityData,
  address: getLegacyAddress
});
