import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromDialogs from '../ducks/dialogs';
import { actions as Dialogs } from '../ducks/dialogs';

import EmailConfirmDialog from '../components/email-confirm-dialog/email-confirm-dialog';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsEmailConfirmOpened
});

const mapDispatchToProps = {
  closeDialog: Dialogs.closeEmailConfirmDialog
};

class EmailConfirmDialogContainer extends Component {
  render() {
    const { isOpened, closeDialog } = this.props;

    console.log();

    return (
      <EmailConfirmDialog
        show={isOpened}
        onClose={() => {
          closeDialog();
        }}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmDialogContainer);
