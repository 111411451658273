import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import * as fromProducts from '../ducks/products';

import GiftProduct from '../components/gift-product/gift-product';

const mapStateToProps = createStructuredSelector({
  product: fromProducts.getItemBySlug
});

class ProductContainer extends Component {
  render() {
    const { product, ...rest } = this.props;

    if (!product) {
      return null;
    }

    return <GiftProduct product={product} {...rest} />;
  }
}

export default connect(mapStateToProps)(ProductContainer);
