import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Loader from 'modules/core/components/loader/loader';
import Button from 'modules/core/components/button/button';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import BonusCard from '../bonus-card/bonus-card';
import BonusCardStatus from '../bonus-card-status/bonus-card-status';
import BonusTransactions from '../bonus-transactions/bonus-transactions';

import chunkString from 'modules/utils/chunk-string';

import './bonus-scene.css';

const { number, string, bool, shape, oneOf, arrayOf } = PropTypes;

const bonusShape = shape({
  id: number.isRequired,
  type: oneOf(['BONUS_TYPE/DEFAULT', 'BONUS_TYPE/EXTRA']).isRequired,
  value: number.isRequired,
  expire: string
});

const bonusesShape = shape({
  summary: number.isRequired,
  bonusList: arrayOf(bonusShape).isRequired
});

const transactionShape = shape({
  id: number.isRequired,
  title: string.isRequired,
  date: string.isRequired,
  expense: arrayOf(bonusShape),
  income: arrayOf(bonusShape),
  orderId: number,
  orderTotal: number
});

class BonusScene extends Component {
  static propTypes = {
    accountNumber: number.isRequired,
    defaultBonuses: bonusesShape.isRequired,
    extraBonuses: bonusesShape.isRequired,
    transactions: arrayOf(transactionShape).isRequired,
    isBonusesLoaded: bool.isRequired,
    isBonusesExist: bool.isRequired,
    isActivated: bool.isRequired
  };

  render() {
    const {
      accountNumber,
      transactions,
      defaultBonuses,
      extraBonuses,
      isBonusesLoaded,
      isBonusesExist,
      isActivated
    } = this.props;

    if (!isBonusesLoaded) {
      return (
        <div className="BonusScene">
          <div className="BonusScene-loader">
            <Loader type="spin" />
          </div>
        </div>
      );
    }

    if (!isBonusesExist) {
      return (
        <div className="BonusScene">
          <div className="BonusScene-suggestion">
            <div className="BonusScene-suggestionText">
              У вас еще нет бонусной карты. Скорее получите бонусную карту и оцените все
              преимущества бонусов!
            </div>

            <div className="BonusScene-suggestionButton">
              <Media query="(min-width: 841px)">
                {isDesktop => (
                  <Button
                    title="Получить карту"
                    variant="primary"
                    size={isDesktop ? 'normal' : 'small'}
                    expanded={!isDesktop}
                    component="a"
                    href="https://mixit.ru/top/bonus-activate/"
                    target="_blank"
                    rel="noreferrer noopener"
                  />
                )}
              </Media>
            </div>
          </div>
        </div>
      );
    }

    const chunkedNumber = chunkString(accountNumber, 4);

    if (!isActivated) {
      return (
        <div className="BonusScene">
          <div className="BonusScene-metaInformation">
            <span className="BonusScene-accountNumber">№{chunkedNumber}</span>

            <span className="BonusScene-accountStatus">
              <BonusCardStatus is={isActivated} />
            </span>
          </div>

          <div className="BonusScene-suggestion">
            <div className="BonusScene-suggestionText">
              Активируйте бонусную карту и получите первые бонусы уже после следующей покупки!
            </div>

            <div className="BonusScene-suggestionButton">
              <Media query="(min-width: 841px)">
                {isDesktop => (
                  <Button
                    title="Активировать карту"
                    variant="primary"
                    size={isDesktop ? 'normal' : 'small'}
                    expanded={!isDesktop}
                    component="a"
                    href="https://mixit.ru/top/bonus-activate/"
                    target="_blank"
                    rel="noreferrer noopener"
                  />
                )}
              </Media>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="BonusScene">
        <div className="BonusScene-metaInformation">
          <span className="BonusScene-accountNumber">№{chunkedNumber}</span>

          <span className="BonusScene-accountStatus">
            <HideErrorBoundary>
              <BonusCardStatus is={isActivated} />
            </HideErrorBoundary>
          </span>
        </div>

        <div className="BonusScene-cardGridHolder">
          <div className="BonusScene-cardGrid">
            <HideErrorBoundary>
              <div className="BonusScene-card">
                <BonusCard
                  summary={defaultBonuses.summary}
                  bonusList={defaultBonuses.bonusList}
                  type="default"
                />
              </div>
            </HideErrorBoundary>

            <HideErrorBoundary>
              <div className="BonusScene-card">
                <BonusCard
                  summary={extraBonuses.summary}
                  bonusList={extraBonuses.bonusList}
                  type="extra"
                />
              </div>
            </HideErrorBoundary>
          </div>
        </div>

        <HideErrorBoundary>
          <div className="BonusScene-transactions">
            <BonusTransactions transactions={transactions} />
          </div>
        </HideErrorBoundary>
      </div>
    );
  }
}

export default BonusScene;
