import React, { Component } from 'react';
import PropTypes from 'prop-types';

import isFunction from 'modules/utils/is-function';

import ReplyForm from './../components/reply-form/reply-form';

const { func, object } = PropTypes;

class ReplyFormContainer extends Component {
  state = {
    text: this.getAppeal() || ''
  };

  static propTypes = {
    onClose: func,
    onSubmit: func,
    replyTo: object
  };

  render() {
    const { onClose } = this.props;

    const { text } = this.state;

    return (
      <ReplyForm
        comment={text}
        onCommentChange={this.handleCommentChange}
        onSubmit={this.handleSubmit}
        onClose={onClose}
      />
    );
  }

  getAppeal() {
    const { replyTo, authorsAlias } = this.props;

    if (!replyTo.author || !replyTo.author.fullName) {
      // return null;
      return `${authorsAlias}, `;
    }

    return `${replyTo.author.fullName}, `;
  }

  setComment(text) {
    this.setState({
      text
    });
  }

  createReplyData() {
    const { text } = this.state;

    return { text };
  }

  submitData(data) {
    const { onSubmit } = this.props;

    if (!isFunction(onSubmit)) {
      return;
    }
    onSubmit(data);
  }

  handleSubmit = e => {
    e.preventDefault();
    const data = this.createReplyData();
    this.submitData(data);
  };

  handleCommentChange = text => {
    this.setComment(text);
  };
}

export default ReplyFormContainer;
