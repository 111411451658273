import React, {useRef} from 'react';
// import Media from 'modules/core/components/media/media';
import Button from 'modules/core/components/button/button';
import MediaQuery from 'react-media';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import "./top-banner.css";

export default function ProblemSkin_TopBanner({data}) {
    const sectionRef = useRef(null)
    return (
        <MediaQuery query="(max-width:1100px)">
        {isTablet => (
        // <div className="ProblemSkin_TopBanner" styles={JSON.stringify(data.styles)}>
        <div ref={sectionRef} className="ProblemSkin_TopBanner" style={{...data.styles}}>
            <div className="ProblemSkin_TopBanner-main">
                <div className="ProblemSkin_TopBanner-title" dangerouslySetInnerHTML={{
          __html: data.title
        }}></div>
                <div className="ProblemSkin_TopBanner-description">{data.description}</div>
                <div className="ProblemSkin_TopBanner-toReasons">
                <Button 
                    variant="black" 
                    title="5 причин появления прыщей"
                    href='#ProblemSkin_Reasons'
                    offset='25'
                    // onClick={scrollToBottom}
                    component={AnchorLink}
                    size={isTablet ?   "small" : "medium"}  />
                </div>
            </div>
            <div className="ProblemSkin_TopBanner-visual">
                <img className="ProblemSkin_TopBanner-image" src={data.images[0].sources[0].main.normal} alt=""/>
                <img className="ProblemSkin_TopBanner-image ProblemSkin_TopBanner-image--mob" src={data.images[1].sources[0].main.normal} alt=""/>
                {/* <Media media={data.images[0]} stretch="horizontal" resizeWidth={80} nocaption /> */}


            </div>
        </div>
                        )}
                        </MediaQuery>
    )

    // function scrollToBottom() {
    //     // console.log("scrollToList -> listRef.current", listRef.current.offsetTop)
    //     // window.scrollTo({ behavior: 'smooth', top: listRef.current.offsetTop })
    //     // window.scrollTo(0, listRef.current.offsetTop)
    //     if(sectionRef){
    //         sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    //     }
    // }
} 