import React, { Component } from 'react';
import Container from '../container/container';
import Content from '../content/content';
import Button from 'modules/core/components/button/button';

import './deprecated-special-offer.css';

class DeprecatedSpecialOffer extends Component {
  render() {
    return (
      <div className="DeprecatedSpecialOffer">
        <Container small>
          <div className="DeprecatedSpecialOffer-content">
            <Content
              html={`
                    <img
                    src="https://images.mindbox.ru/images/Mixit/2027/f3741112-2293-469a-9ac0-d125aa10a12a.png"
                    alt="Летний Бонус"
                  />
                  <p style="text-align: center">
                    При покупке от 2000 руб. на карту начисляется 200 экcтрабонусов, которыми можно оплатить
                    до 30% стоимости покупки с 1 по 30 сентября.
                  </p>
                  <p style="text-align: center">
                    Срок действия акции: <b> с 19 по 25 августа</b>.
                  </p>
                  <p style="text-align: center">
                    Экстрабонусы будут начислены, если сумма покупки превысит 2000 руб. после применения
                    всех скидок и промокодов. Начисление экстрабонусов будет произведено </br> <b> 1 сентября</b>.
                  </p>
                  <p style="text-align: center">
                    Все подробности акции уточняйте у продавцов-консультантов.
                  </p>
                      `}
              expanded
            />
          </div>
          <div className="DeprecatedSpecialOffer-rule">
            Для участия в акции нужно: иметь бонусную карту (полученную на сайте виртуально или в
            магазине), подтвердить email и телефон, а также заполнить обязательные поля.
          </div>
          <div className="DeprecatedSpecialOffer-button">
            <Button
              title="Получить карту"
              variant="primary"
              component="a"
              href="https://mixit.ru/top/bonus-activate/"
              size="small"
              target="_blank"
            />
          </div>
        </Container>
      </div>
    );
  }
}

export default DeprecatedSpecialOffer;
