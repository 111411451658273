import React, { Component } from 'react';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import Header from './header/header';
import Transaction from './transaction/transaction';

import { combineReducers } from 'redux';
import { createReducer } from 'modules/utils/dux';

import './bonus-history-table.css';

const open = id => {
  return { type: 'OPEN', payload: { id } };
};

const close = id => {
  return { type: 'CLOSE', payload: { id } };
};

const openedListReducer = createReducer({
  OPEN: (state, { id }) => [...state, id],
  CLOSE: (state, { id }) => state.filter(openedId => openedId !== id)
});

const reducer = combineReducers({
  openedIdList: openedListReducer([])
});

class BonusHistoryTable extends Component {
  state = {
    openedIdList: this.getOpenedIdList()
  };

  render() {
    const { transactions, emptyMessage, openedOutsideId } = this.props;
    const { openedIdList } = this.state;

    const isBonusesExist = transactions.length > 0;

    if (!isBonusesExist) {
      return (
        <div className="BonusHistoryTable BonusHistoryTable--withoutBorder">
          <div className="BonusHistoryTable-emptyMessage">{emptyMessage}</div>
        </div>
      );
    }

    return (
      <div className="BonusHistoryTable">
        <div className="BonusHistoryTable-header">
          <Header />
        </div>

        <ul className="BonusHistoryTable-transactionList">
          {transactions.map(transaction => {
            const { id, orderId } = transaction;

            const isOrderExists = !!orderId;
            const isOpened = isOrderExists && openedIdList.indexOf(id) !== -1;
            const isActive = openedOutsideId === id;

            return (
              <HideErrorBoundary>
                <li key={id} className="BonusHistoryTable-transaction">
                  <Transaction
                    transaction={transaction}
                    isActive={isActive}
                    isOpened={isOpened}
                    isOrderExists={isOrderExists}
                    onClick={isOrderExists && this.handleClick(id, isOpened)}
                  />
                </li>
              </HideErrorBoundary>
            );
          })}
        </ul>
      </div>
    );
  }

  getOpenedIdList() {
    const { openedOutsideId } = this.props;

    const isOutsideIdExists = typeof openedOutsideId === 'number';

    if (!isOutsideIdExists) {
      return [];
    }

    return [openedOutsideId];
  }

  dispatch(action) {
    this.setState(state => reducer(state, action));
  }

  toggleTransaction(id, isOpened) {
    if (isOpened) {
      this.dispatch(close(id));
      return;
    }

    this.dispatch(open(id));
  }

  handleClick = (id, isOpened) => () => {
    this.toggleTransaction(id, isOpened);
  };
}

export default BonusHistoryTable;
