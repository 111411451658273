import React, { Component } from 'react';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import TemporaryLabelIcon from '../../../product/temporary-label-icon';

import './one-column-grid.css';
import Media from 'react-media';

class MainCard_OneColumnGrid extends Component {
  render() {
    const {
      title,
      description,
      properties,
      slider,
      labels,
      price,

      weightChoice,
      sizeChoice,

      code,
      availability,

      buyForm,

      delivery,
      buttonFavorite,
      // reviews,
      linkToReviews,
      IsBfkFeature,
      starsRating
    } = this.props;

    return (
      <div className="MainCard_OneColumnGrid">
        <div className="MainCard_OneColumnGrid-header">
          <div className="MainCard_OneColumnGrid-category">{title}</div>
          {React.cloneElement(description, {
            className: 'MainCard_OneColumnGrid-title'
          })}
          <div className="MainCard_OneColumnGrid-review">
            {Boolean(starsRating) &&(
              <div className="MainCard_OneColumnGrid-starsRating">{starsRating}</div>
            )}
            <div className="MainCard_OneColumnGrid-linkToReviews">{linkToReviews}</div>
          </div>
        </div>

        <div className="MainCard_OneColumnGrid-sliderHolder">
          <div className="MainCard_OneColumnGrid-temporaryLabelIcon">
            {IsBfkFeature ? (
              <Media query="(max-width:480px)">
                {isMobile => <TemporaryLabelIcon size={isMobile ? 'small' : 'medium'} />}
              </Media>
            ) : null}
          </div>
          <div className="MainCard_OneColumnGrid-metaHeader">
            <div className="MainCard_OneColumnGrid-labelHolder">
              <div className="MainCard_OneColumnGrid-label">{labels}</div>
            </div>
            <div className="MainCard_OneColumnGrid-quickActionHolder">
              <div className="MainCard_OneColumnGrid-quickAction">{buttonFavorite}</div>
            </div>
          </div>

          <HideErrorBoundary>
            <div className="MainCard_OneColumnGrid-slider">{slider}</div>
          </HideErrorBoundary>
        </div>

        {weightChoice && <div className="MainCard_OneColumnGrid-weight">{weightChoice}</div>}

        {sizeChoice && <div className="MainCard_OneColumnGrid-size">{sizeChoice}</div>}

        <div className="MainCard_OneColumnGrid-prices">{price}</div>

        <div className="MainCard_OneColumnGrid-buy">
          <div className="MainCard_OneColumnGrid-buyBtn">{buyForm}</div>
        </div>

        <div className="MainCard_OneColumnGrid-row">
          <div className="MainCard_OneColumnGrid-availability">{availability}</div>
          <div className="MainCard_OneColumnGrid-code">{code}</div>
        </div>

        <div className="MainCard_OneColumnGrid-delivery">{delivery}</div>

        {!!properties && <div className="MainCard_OneColumnGrid-description">{properties}</div>}
      </div>
    );
  }
}

export default MainCard_OneColumnGrid;
