import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';
import SubscribeContainer from 'modules/core/containers/subscribe';
import FullIngredient from '../../containers/full-ingredient';
import Page from 'modules/core/components/page/page';
import { getIndexLink } from 'routes/links';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

const getIdFromRouteParams = (_, props) => {
  return props.match.params.id;
};

const mapState = createStructuredSelector({
  id: getIdFromRouteParams
});

class IngredientPage extends Component {
  static path = '/ingredient/:id';
  static exact = true;

  render() {
    const { id, ingredient } = this.props;

    return (
      <Page
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: ingredient ? ingredient.title : ''
          }
        ]}
        banner={
          ingredient
            ? {
              title: ingredient.title
            }
            : false
        }
        withBackButton
      >
        <FullIngredient id={id} />

        <SubscribeContainer />

        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Page>
    );
  }
}

export default connect(mapState)(IngredientPage);
