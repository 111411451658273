import React, { Component } from 'react';
import cn from 'classnames';

import './product.css';

class ButtonTooltip extends Component {
  render() {
    const { active, children, ...rest } = this.props;
    return (
      <div
        className={cn('Product-buttonFavoriteTooltipInner', {
          'Product-buttonFavoriteTooltipInner--active': active
        })}
        {...rest}
      >
        {children}
      </div>
    );
  }
}

export default ButtonTooltip;
