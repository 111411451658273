import plural from 'modules/utils/plural';

const TITLE_PLURAL_OPTIONS = ['бонус', 'бонуса', 'бонусов'];
const VERB_PLURAL_OPTIONS = ['сгорит', 'сгорят', 'сгорят'];

function getAmountString(bonusesAmount) {
  const pluralBonus = plural(bonusesAmount, TITLE_PLURAL_OPTIONS);
  const pluralAction = plural(bonusesAmount, VERB_PLURAL_OPTIONS);

  return `${bonusesAmount} ${pluralBonus} ${pluralAction} `;
}

export default getAmountString;
