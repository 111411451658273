import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { createStructuredSelector } from 'reselect';
// import { connect } from 'react-redux';

import ConditionalRenderer from 'modules/core/components/conditional-renderer/conditional-renderer';
import getNormalizedRenderConditions from 'modules/core/components/conditional-renderer/helpers/get-normalized-render-conditions';

import { Page } from 'modules/core/components/page';
import { getError404Link } from 'routes/links';
import * as fromLandings from '../ducks/landings';
import Loader from 'modules/core/components/loader/loader';

import Landing from '../components/landing/landing';

import { msk, spb, new_shops, msk_1 } from './configs/landings-config';

const slugs = {
  msk,
  spb,
  new_shops,
  msk_1
};

function LandingPage({ slug, breadcrumbs }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  if (!slug || !slugs[slug]) {
    return <Redirect to={getError404Link()} />;
  }

  const pageProps = {
    ...(breadcrumbs && { breadcrumbs })
  };

  const data = slugs[slug];

  const conditionalConfig = {
    elseRender: <Redirect to={getError404Link()} />,
    ...getNormalizedRenderConditions(data.render_conditions)
  };

  return (
    <Page {...pageProps}>
      <Helmet>
        <title>{`MIXIT — ${slugs[slug].page.title}`}</title>
      </Helmet>
      <ConditionalRenderer config={conditionalConfig}>
        <Landing data={slugs[slug]} />
      </ConditionalRenderer>
    </Page>
  );
}

export default LandingPage;

const mapStateToProps = createStructuredSelector({
  landingData: fromLandings.getLandingBySlugFromRouteParams,
  isDataLoaded: fromLandings.getIsLandingDataLoaded
});

function _LandingSlugFromRoute(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = props.landingData;

  if (!props.landingData) {
    if (props.isDataLoaded) {
      return <Redirect to={getError404Link()} />;
    }

    return (
      <div style={{ position: 'relative', paddingTop: '120px' }}>
        <Loader />
      </div>
    );
  }

  const hasParentPage = Boolean(data.page.parent_page);
  const breadcrumbs = hasParentPage
    ? [
        {
          name: data.page.parent_page.title,
          link: data.page.parent_page.path
        },
        {
          name: data.page.title
        }
      ]
    : null;

  // const conditionalConfig = {
  //   elseRender: <Redirect to={getError404Link()} />,
  //   ...getNormalizedRenderConditions(data.render_conditions)
  // };

  return (
    // <ConditionalRenderer config={conditionalConfig}>
    <Page breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{`MIXIT — ${data.page.title}`}</title>
      </Helmet>
      <Landing data={data} />
    </Page>
    // </ConditionalRenderer>
  );
}

export const LandingSlugFromRoute = withRouter(connect(mapStateToProps)(_LandingSlugFromRoute));
