import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from 'modules/core/components/link/short-link';
import ListDescriptions from 'modules/core/components/list-description/list-description';
import isFunction from 'modules/utils/is-function';

import RateButton from '../rate-button/rate-button';
import ReviewRating from '../review-rating/review-rating';

import './review.css';
// import ReviewReply from '../../review-reply/review-reply';
import ReviewReply from '../review-reply/review-reply';
import ReplyFormContainer from '../../containers/reply-form-container';
import { RATE_TYPE, RATE_VARIANTS } from '../../constants/review';

const { object, bool, oneOf, arrayOf, func } = PropTypes;

const ASSESSMENT_TYPES = {
  pros: 'Достоинства',
  cons: 'Недостатки'
};

function notEmptyArrayElements(arr) {
  return arr.every(item => !!item);
}

function hasAssessment(content) {
  const { advantages, disadvantages } = content;

  return (
    (!!advantages && !!advantages.length && notEmptyArrayElements(advantages)) ||
    (!!disadvantages && !!disadvantages.length && notEmptyArrayElements(disadvantages))
  );
}

function setAssessmentProperty(property, value) {
  return {
    title: ASSESSMENT_TYPES[property],
    value: value.join(', ')
  };
}

class Review extends Component {
  static propTypes = {
    review: object,
    replyList: arrayOf(object),
    isRepliable: bool,
    isRateable: bool,
    userRate: oneOf(RATE_VARIANTS),
    showReplyForm: bool,
    onReplyFormClose: func,
    onReplyFormSubmit: func,
    onReply: func,
    onRate: func
  };

  static defaultProps = {
    isRepliable: false,
    isRateable: false
  };

  render() {
    const {
      author,
      rating,
      content,
      createDate,

      isRepliable,
      isRateable,
      userRate,
      replyList,
      showReplyForm,
      counters
    } = this.props;

    const { ...review } = this.props;

    let listAssessment = [];
    if (content.advantages && content.advantages.length) {
      listAssessment.push(setAssessmentProperty('pros', content.advantages));
    }
    if (content.disadvantages && content.disadvantages.length) {
      listAssessment.push(setAssessmentProperty('cons', content.disadvantages));
    }

    const hasReplies = replyList && replyList.length > 0;
    const hasCommentText = content.text && content.text.length > 0;

    const authorsAlias = 'Аноним';

    return (
      <div
        className="Review"
        data-testid="review"
        itemprop="review"
        itemscope=""
        itemtype="http://schema.org/Review"
      >
        <div className="Review-header">
          <meta itemprop="datePublished" content={createDate.formatted} />
          {/* <meta itemprop="worstRating" content="1" />
          <span itemprop="ratingValue" style={{ display: 'none' }}>
            5
          </span>
          <span itemprop="bestRating" style={{ display: 'none' }}>
            5
          </span> */}
          <div className="Review-name" itemprop="author">
            {author ? author.fullName : authorsAlias}
          </div>
          {rating > 0 && (
            <div className="Review-rating">
              <ReviewRating rating={rating} />
            </div>
          )}
          <time dateTime={createDate.datetime} className="Review-date" data-testid="time">
            {createDate.formatted}
          </time>
        </div>
        <div className="Review-content" data-testid="content">
          {hasCommentText && (
            <div className="Review-text" data-testid="content-text" itemprop="description">
              {content.text}
            </div>
          )}
          {hasAssessment(content) && (
            <div className="Review-assessment" data-testid="list-description">
              <ListDescriptions list={listAssessment} />
            </div>
          )}
        </div>

        <div className="Review-footer">
          {isRepliable && (
            <div className="Review-reply">
              <Link
                component="button"
                type="button"
                onClick={this.handleReply}
                data-testid="reply-button"
              >
                Ответить
              </Link>
            </div>
          )}

          <div className="Review-rate">
            {isRateable && (
              <span data-testid="rate-question" className="Review-question">
                Полезен ли отзыв?
              </span>
            )}

            <div className="Review-button">
              <RateButton
                data-testid="like-button"
                variant="LIKE"
                count={counters.likes}
                onClick={this.handleLikeClick}
                active={userRate === RATE_TYPE.LIKE}
              />
            </div>
            <div className="Review-button">
              <RateButton
                data-testid="dislike-button"
                variant="DISLIKE"
                count={counters.dislikes}
                onClick={this.handleDislikeClick}
                active={userRate === RATE_TYPE.DISLIKE}
              />
            </div>
          </div>
        </div>

        {showReplyForm && (
          <div className="Review-replyForm">
            <ReplyFormContainer
              onClose={this.handleReplyFormClose}
              onSubmit={this.handleReplyFormSubmit}
              replyTo={review}
              authorsAlias={authorsAlias}
            />
          </div>
        )}

        {hasReplies && (
          <div className="Review-replies">
            {replyList.map((reply, index) => (
              <div key={index} className="Review-replyItem">
                <ReviewReply reply={reply} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  like() {
    const { onRate, id, isAuthorized, openAuthDialog } = this.props;

    if (!isAuthorized) {
      openAuthDialog();
      return;
    }

    const action = RATE_TYPE.LIKE;

    if (!isFunction(onRate)) {
      return;
    }
    onRate({ id, action });
  }

  dislike() {
    const { onRate, id, isAuthorized, openAuthDialog } = this.props;

    if (!isAuthorized) {
      openAuthDialog();
      return;
    }

    const action = RATE_TYPE.DISLIKE;

    if (!isFunction(onRate)) {
      return;
    }
    onRate({ id, action });
  }

  reply() {
    const { onReply, id } = this.props;

    if (!isFunction(onReply)) {
      return;
    }
    onReply(id);
  }

  formClose() {
    const { onReplyFormClose, id } = this.props;

    if (!isFunction(onReplyFormClose)) {
      return;
    }
    onReplyFormClose(id);
  }

  handleLikeClick = () => {
    this.like();
  };

  handleDislikeClick = () => {
    this.dislike();
  };

  handleReply = () => {
    this.reply();
  };

  handleReplyFormClose = () => {
    this.formClose();
  };

  handleReplyFormSubmit = comment => {
    const { onReplyFormSubmit, id } = this.props;

    if (!isFunction(onReplyFormSubmit)) {
      return;
    }
    onReplyFormSubmit({ id, comment });
  };
}

export default Review;
