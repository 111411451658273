import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import { StickyContainer } from 'react-sticky';
import ScrollTrigger from 'react-scroll-trigger';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';
import Loader from 'modules/core/components/loader/loader';
import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';
import ProductSpoiler from 'modules/core/components/accordion/product-spoiler';
import AccordionNotification from 'modules/core/components/accordion/accordion-notification/accordion-notification';
import AccordionLabel from 'modules/core/components/accordion/accordion-label/accordion-label';
import SectionProductConsistency from 'modules/ingredient/components/section-product-consistency/section-product-consistency';
import ProductConsistency from 'modules/ingredient/components/product-consistency/product-consistency';
import ProductReviewContainer from 'modules/review/containers/product-review-container';
import InstagramReviewSlider from 'modules/social/components/instagram-review-slider/instagram-review-slider';
import InstagramPostContainer from 'modules/social/containers/instagram-post';

import SectionProductInfo from '../section-product-info/section-product-info';
import SectionProductUsage from '../section-product-usage/section-product-usage';
import SectionProductStorage from '../section-product-storage/section-product-storage';
import SectionProductNavigation from '../section-product-navigation/section-product-navigation';

import ProductCarouselSection from './product-carousel-section';
import SimilarProducts from './similar-products';

import plural from 'modules/utils/plural';

import './details.css';

function IncludedProducts({ products }) {
  return (
    <ProductCarouselSection
      title={`${products.length} ${plural(products.length, [
        'товар',
        'товара',
        'товаров'
      ])} в составе набора`}
      list={products}
    />
  );
}

class ProductDetails extends Component {
  render() {
    const { details, isLoaded, onEnter, onExit } = this.props;

    if (!details || !isLoaded) {
      return (
        <div className="Details">
          <div className="Details-placeholder">
            <Loader type="short" secondary />
          </div>
        </div>
      );
    }

    return (
      <ScrollTrigger onEnter={onEnter} onExit={onExit}>
        <div className="Details">
          <Media query="(max-width: 840px)">{this.renderMedia}</Media>
        </div>
      </ScrollTrigger>
    );
  }

  renderMedia = isMobile => {
    return isMobile ? this.renderMobile() : this.renderDefault();
  };

  renderDefault() {
    const { details, title, product } = this.props;

    const filteredInstagramReviews = this.filterInstagramReviews(details);

    return (
      <Fragment>
        {details.included && <IncludedProducts products={details.included} />}
        <StickyContainer>
          <SectionProductNavigation details={details} product={product} />

          {details.about && (
            <HideErrorBoundary>
              <SectionProductInfo about={details.about} />
            </HideErrorBoundary>
          )}

          {details.consistency && (
            <HideErrorBoundary>
              <SectionProductConsistency consistency={details.consistency} />
            </HideErrorBoundary>
          )}

          {details.usage && (
            <HideErrorBoundary>
              <SectionProductUsage usage={details.usage} />
            </HideErrorBoundary>
          )}

          {this.renderRelatedProducts()}

          {this.renderSimilarProducts()}

          {details.storage && (
            <HideErrorBoundary>
              <SectionProductStorage conditions={details.storage} />
            </HideErrorBoundary>
          )}

          <ProductReviewContainer
            slug={this.props.slug}
            idsList={details.reviews}
            rating={0}
            withHeader
          />
          {filteredInstagramReviews && (
            <SectionDefault title={`Отзывы о ${title} в Instagram`}>
              <InstagramReviewSlider
                instagramPosts={filteredInstagramReviews}
                onError={this.handleInstagramPostError}
                renderItem={({ ...rest }) => {
                  return <InstagramPostContainer {...rest} />
                }}
              />
            </SectionDefault>
          )}
        </StickyContainer>
      </Fragment>
    );
  }

  renderMobile() {
    const { details } = this.props;

    return (
      <Fragment>
        <Container>
          <HideErrorBoundary>
            <ProductSpoiler
              initialOpened
              anchor="about"
              title="Описание"
              panel={<SectionProductInfo about={details.about} isMobile />}
            />
          </HideErrorBoundary>

          {details.consistency && (
            <HideErrorBoundary>
              <ProductSpoiler
                anchor="consistency"
                title="Состав"
                panel={<ProductConsistency consistency={details.consistency} />}
              />
            </HideErrorBoundary>
          )}

          {details.included && (
            <ProductSpoiler
              title="Состав набора"
              anchor="included"
              panel={<SimilarProducts list={details.included} />}
              label={
                <AccordionLabel>
                  <AccordionNotification text={this.formIncludedProductsLabel()} />
                </AccordionLabel>
              }
            />
          )}

          {details.usage && (
            <HideErrorBoundary>
              {details && details.usage &&
                (<div itemscope="" itemtype="https://schema.org/FAQPage" style={{ display: 'none' }}>
                  <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h3 itemprop="name">Применение</h3>
                    {/* <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                      {details && details.usage && (
                        <div itemprop="text">{details.usage}</div>
                      )}
                    </div> */}
                  </div>
                </div>)
              }

              <ProductSpoiler
                anchor="usage"
                title="Применение"
                panel={<SectionProductUsage usage={details.usage} isMobile />}
              />
            </HideErrorBoundary>
          )}

          {details.storage && (
            <HideErrorBoundary>
              <ProductSpoiler
                anchor="productStorage"
                title="Условия хранения"
                panel={<SectionProductStorage conditions={details.storage} />}
              />
            </HideErrorBoundary>
          )}

          <ProductSpoiler
            initialOpened
            anchor="reviews"
            title={details.reviews.length > 0 ? 'Отзывы' : 'Оставить отзыв'}
            panel={this.renderMobileReviews()}
            label={
              <AccordionLabel>
                {/* <ReviewRating rating={0} /> */}
                {details.reviews.length > 0 && (
                  <AccordionNotification text={details.reviews.length} />
                )}
              </AccordionLabel>
            }
          />
        </Container>
        {this.renderRelatedProducts()}
      </Fragment>
    );
  }

  renderMobileReviews() {
    const { details, title } = this.props;

    const filteredInstagramReviews = this.filterInstagramReviews(details);

    return (
      <Fragment>
        <ProductReviewContainer
          slug={this.props.slug}
          idsList={details.reviews}
          rating={0}
          withoutContainer
        // withHeader
        // showTopReview
        // enableSorting
        />
        {filteredInstagramReviews && (
          <SectionDefault title={`Отзывы о ${title} в Instagram`}>
            <InstagramReviewSlider
              instagramPosts={filteredInstagramReviews}
              onError={this.handleInstagramPostError}
              renderItem={({ ...rest }) => {
                return <InstagramPostContainer {...rest} />
              }}
            />
          </SectionDefault>
        )}
      </Fragment>
    );
  }

  renderSimilarProducts() {
    const { details, category, subCategory, slug } = this.props;

    if (!details.similar) {
      return null;
    }

    const crossSellTitle = `Другие ${subCategory ? subCategory.toLowerCase() : 'товары'
      } ${category.toLowerCase()}`;

    return (
      <ProductCarouselSection
        title={crossSellTitle}
        list={details.similar.slice(0, 16)}
        listId={`similar-products-from-${slug}`}
      />
    );
  }

  renderRelatedProducts() {
    const { details, slug } = this.props;

    if (!details.related) {
      return null;
    }

    return (
      <ProductCarouselSection
        title="Для достижения максимального эффекта"
        list={details.related}
        listId={`max-effect-from-${slug}`}
      />
    );
  }

  formIncludedProductsLabel() {
    const { details } = this.props;

    const includedAmount = details.included.length;
    const plurals = ['продукт', 'продукта', 'продуктов'];

    return `${includedAmount} ${plural(includedAmount, plurals)}`;
  }

  filterInstagramReviews(details) {
    if (!details.instagram_reviews) {
      return null;
    }
    const instagramReviews = details.instagram_reviews;

    const filteredInstagramReviews = instagramReviews.filter(review => {
      return review.hasOwnProperty('link');
    });

    return filteredInstagramReviews.length > 0 ? filteredInstagramReviews : null;
  }
}

export default ProductDetails;
