import {
  fill,
  getRandomDate,
  getRandomFromCollection,
  getRandomNum,
  getRandomInt
} from 'modules/utils';

const names = [
  'Томашевская Наталья',
  'Поршнева Екатерина',
  'Сулейманова Айгур',
  'Ауєль Ержан',
  'Шалпан Аматова',
  'Алина Кобаева',
  'Милославская Валентина Петровна',
  'Пушкина Ирина'
];

const _id = Date.now();

const _fullName = 'Томашевская Наталья';
const _rating = 2.5;
const _createDate = {
  humanized: '12 минут назад',
  formatted: '17 июня, 12:34',
  datetime: '2018-06-17T12:34:04'
};
const _text =
  'Крем оочень хорош! Легонький такой, для лета самое то, для зимы слабоват, я думаю. Сделайте, пожалуйста, упаковку побольше! Неужели всем хватает этой малюсенькой баночки?!!';
const _advantages = ['Натуральность, хорошо наносится, и кожа одно божество!!! Приятный запах.'];
const _disadvantages = ['Маленький объем.'];
const _likes = 8;
const _dislikes = 10;
const _media = [
  {
    id: 5,
    type: 'IMAGE',
    title: 'Идеальная кожа в домашних условиях',
    caption: 'Идеальная кожа в домашних условиях',
    source: [
      {
        size: 1120,
        main: {
          normal: 'content/review/review_1.jpg',
          double: 'content/review/review_1.jpg'
        },
        fallback: {
          normal: 'content/review/review_1.jpg',
          double: 'content/review/review_1.jpg'
        }
      }
    ]
  },
  {
    id: 6,
    type: 'IMAGE',
    title: 'Идеальная кожа в домашних условиях',
    caption: 'Идеальная кожа в домашних условиях',
    source: [
      {
        size: 1120,
        main: {
          normal: 'content/review/review_2.jpg',
          double: 'content/review/review_2.jpg'
        },
        fallback: {
          normal: 'content/review/review_2.jpg',
          double: 'content/review/review_2.jpg'
        }
      }
    ]
  },
  {
    id: 7,
    type: 'IMAGE',
    title: 'Идеальная кожа в домашних условиях',
    caption: 'Идеальная кожа в домашних условиях',
    source: [
      {
        size: 1120,
        main: {
          normal: 'content/review/review_1.jpg',
          double: 'content/review/review_1.jpg'
        },
        fallback: {
          normal: 'content/review/review_1.jpg',
          double: 'content/review/review_1.jpg'
        }
      }
    ]
  },
  {
    id: 8,
    type: 'IMAGE',
    title: 'Идеальная кожа в домашних условиях',
    caption: 'Идеальная кожа в домашних условиях',
    source: [
      {
        size: 1120,
        main: {
          normal: 'content/review/review_2.jpg',
          double: 'content/review/review_2.jpg'
        },
        fallback: {
          normal: 'content/review/review_2.jpg',
          double: 'content/review/review_2.jpg'
        }
      }
    ]
  },
  {
    id: 1,
    type: 'IMAGE',
    title: 'Идеальная кожа в домашних условиях',
    caption: 'Идеальная кожа в домашних условиях',
    source: [
      {
        size: 1120,
        main: {
          normal: 'content/banners/blog-banner.jpg',
          double: 'content/banners/blog-banner.jpg'
        },
        fallback: {
          normal: 'content/banners/blog-banner.jpg',
          double: 'content/banners/blog-banner.jpg'
        }
      }
    ]
  },
  {
    id: 2,
    type: 'IMAGE',
    title: 'Идеальная кожа в домашних условиях',
    caption: 'Идеальная кожа в домашних условиях',
    source: [
      {
        size: 1120,
        main: {
          normal: 'content/banners/banner-1.jpg',
          double: 'content/banners/banner-1.jpg'
        },
        fallback: {
          normal: 'content/banners/banner-1.jpg',
          double: 'content/banners/banner-1.jpg'
        }
      }
    ]
  },
  {
    id: 3,
    type: 'IMAGE',
    title: 'Идеальная кожа в домашних условиях',
    caption: 'Идеальная кожа в домашних условиях',
    source: [
      {
        size: 1120,
        main: {
          normal: 'content/blog/article_3.jpg',
          double: 'content/blog/article_3.jpg'
        },
        fallback: {
          normal: 'content/blog/article_3.jpg',
          double: 'content/blog/article_3.jpg'
        }
      }
    ]
  },
  {
    id: 4,
    type: 'IMAGE',
    title: 'Идеальная кожа в домашних условиях',
    caption: 'Идеальная кожа в домашних условиях',
    source: [
      {
        size: 1120,
        main: {
          normal: 'content/blog/article_1.jpg',
          double: 'content/blog/article_1.jpg'
        },
        fallback: {
          normal: 'content/blog/article_1.jpg',
          double: 'content/blog/article_1.jpg'
        }
      }
    ]
  }
];

export function createReview({
  id = _id,
  fullName = _fullName,
  rating = _rating,
  createDate = _createDate,
  text = _text,
  advantages = _advantages,
  disadvantages = _disadvantages,
  likes = _likes,
  dislikes = _dislikes,
  media = _media,
  mediaSize,
  replyList
} = {}) {
  let review = {
    author: {},
    content: { media: [] },
    counters: {}
  };

  if (id) {
    review.id = id;
  }

  if (fullName) {
    review.author.fullName = fullName;
  }

  if (rating) {
    review.rating = rating;
  }

  if (createDate) {
    review.createDate = createDate;
  }

  if (text) {
    review.content.text = text;
  }

  if (advantages) {
    review.content.advantages = advantages;
  }

  if (disadvantages) {
    review.content.disadvantages = disadvantages;
  }

  if (media) {
    review.content.media = media;
  }

  if (mediaSize) {
    review.content.media = fill(review.content.media, mediaSize);
  }

  if (likes) {
    review.counters.likes = likes;
  }

  if (dislikes) {
    review.counters.dislikes = dislikes;
  }

  if (replyList) {
    review.replyList = replyList;
  }

  return review;
}

export function createReviewWithRandomParameters(id) {
  const randomDate = getRandomDate(new Date(2010, 0, 1), new Date());
  const randomDateInMilliseconds = randomDate.getTime();
  return createReview({
    id,
    fullName: getRandomFromCollection(names),
    createDate: randomDateInMilliseconds,
    rating: getRandomNum(0, 5),
    likes: getRandomInt(0, 25),
    dislikes: getRandomInt(0, 18),
    replyList: [
      createReview({
        createDate: randomDate.setDate(randomDate.getDate() + 1)
      }),
      createReview({
        createDate: randomDate.setDate(randomDate.getDate() + 5)
      })
    ]
  });
}

export function createReviewList(size) {
  let resultingArray = [];
  let id = 10;
  while (resultingArray.length < size) {
    resultingArray = resultingArray.concat(createReviewWithRandomParameters(id++));
  }
  return resultingArray.slice(0, size);
}
