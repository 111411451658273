import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import './input.css';
import isFunction from 'modules/utils/is-function';


const { string, bool, element, oneOf } = PropTypes;

const SPACE_KEYCODE = 32

class Input extends Component {
  static propTypes = {
    /**
     * Input placeholder
     */
    placeholder: string,

    /**
     * Flag of input disabled state
     */
    disabled: bool,

    /**
     * Ability of Input to be selected
     */
    selectable: bool,

    /**
     * Focus input flag
     */
    isFocused: bool,

    disableAutofill: bool,

    /**
     * Stores the state of the field. 'Error' - state when a validation error occurs
     */
    state: oneOf(['default', 'error']),

    /**
     * Adjust input size. The default state is normal
     */
    size: oneOf(['normal', 'small']),

    /**
     * Element, which is on the left of input`s content
     */
    prepended: element,

    /**
     * Element, which is on the right of input`s content
     */
    appended: element,

    mask: MaskedInput.propTypes.mask
  };

  static defaultProps = {
    placeholder: '',
    disabled: false,
    selectable: true,
    state: 'default',
    size: 'normal',
    prepended: null,
    appended: null,
    mask: null,
    disableAutofill: false
  };

  state = {
    autofilled: false,
    readOnlyRorced: this.props.disableAutofill
  }

  render() {
    const {
      selectable,
      size,
      state,
      prepended,
      appended,
      inputRef,
      isFocused,
      mask,
      nocontrols,
      withoutStroke,
      type,
      fake,
      isQueryField,
      name,
      ...rest
    } = this.props;

    const { autofilled, readOnlyRorced } = this.state

    const readOnly = this.props.readOnly || !selectable || readOnlyRorced


    return (
      <div
        className={classNames('Input', {
          'Input--focused': isFocused,
          'Input--small': size === 'small',
          'Input--error': state === 'error',
          'Input--selectable': selectable,
          'Input--withLeftGap': prepended,
          'Input--withRightGap': appended,
          'Input--nocontrols': nocontrols,
          'Input--withoutStroke': withoutStroke,
          'Input--password': type === 'password',
          'Input--autofilled': autofilled,
          'Input--fake': fake,
        })}
      >
        {prepended && <span className="Input-prepended">{prepended}</span>}

        {mask ? (
          <MaskedInput
            mask={mask}
            {...rest}
            render={(ref, props) => (
              <input
                className="Input-field"
                ref={this.handleBothRefs(ref)}
                readOnly={readOnly}
                type={type}
                onAnimationStart={this.handleAutoFill}
                onKeyDown={this.handleKeyDown}
                {...props}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                aria-hidden={fake}
                name={name}
              />
            )}
          />
        ) : (
            <input
              className="Input-field"
              ref={inputRef}
              readOnly={readOnly}
              type={type}
              onAnimationStart={this.handleAutoFill}
              onKeyDown={this.handleKeyDown}
              {...rest}
              aria-hidden={fake}
              itemprop={isQueryField ? "query-input" : ''}
              name={isQueryField ? "search_term_string" : name}
            />
          )}

        {appended && <span className="Input-appended">{appended}</span>}
      </div>
    );
  }

  handleFocus = (e) => {
    const { onFocus, disableAutofill } = this.props

    if (disableAutofill) {
      this.setState({
        readOnlyRorced: false
      })
    }


    if (isFunction(onFocus)) {
      onFocus(e)
    }
  }

  handleBlur = (e) => {
    const { onBlur, disableAutofill } = this.props

    if (disableAutofill) {
      this.setState({
        readOnlyRorced: true
      })
    }

    if (isFunction(onBlur)) {
      onBlur(e)
    }
  }

  handleKeyDown = (e) => {
    const { onKeyDown, type } = this.props;
    if (type === "email" && e.keyCode === SPACE_KEYCODE) {
      e.preventDefault();
    }

    if (isFunction(onKeyDown)) {
      onKeyDown(e)
    }
  }

  handleBothRefs = ref => element => {
    const { inputRef } = this.props;
    ref(element);
    if (isFunction(inputRef)) {
      inputRef(element);
    }
  };

  handleAutoFill = (e) => {
    const { animationName } = e
    const isAutifilled = animationName === "onAutoFillStart"

    this.setState({
      autofilled: isAutifilled
    })

  }
}

export default Input;
