import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { getHappyMasterPageLink } from 'routes/links';

import './section-contacts.css';

const { string } = PropTypes;

class SectionContacts extends Component {
  static propTypes = {
    /**
     * The array of questions and their description. They are located in the left column
     */
    questionList: PropTypes.arrayOf(
      PropTypes.shape({
        title: string.isRequired,
        description: string.isRequired
      })
    ).isRequired,

    /**
     * The array of numbers and their description. They are located in the right column
     */
    contactList: PropTypes.arrayOf(
      PropTypes.shape({
        number: string.isRequired,
        description: string.isRequired
      })
    ).isRequired
  };

  render() {
    const { questionList, contactList } = this.props;
    return (
      <div className="SectionContacts">
        <div className="SectionContacts-questionCol">
          {questionList.map(this.renderQuestionItem)}
        </div>
        <div className="SectionContacts-contactCol">{contactList.map(this.renderContactItem)}</div>
      </div>
    );
  }

  handleHappyMasterJivoOpen = () => {
    if (__BROWSER__ && window.jivo_api) {
      window.jivo_api.open();
    }
  };

  renderQuestionItem = (item, index) => {
    return (
      <div key={index} className="SectionContacts-questionItem">
        <h3 className="SectionContacts-questionTitle">{item.title}</h3>
        <p
          className="SectionContacts-questionDescription"
          dangerouslySetInnerHTML={{
            __html: item.description
          }}
        />
      </div>
    );
  };

  renderContactItem = (item, index) => {
    return (
      <div key={index} className="SectionContacts-contactItem">
        <h3 className="SectionContacts-contactTitle">
          <a href={`tel:${item.number}`} className="SectionContacts-contactLink">
            {item.formattedNumber}
          </a>
        </h3>
        <p
          className="SectionContacts-contactDescription"
          dangerouslySetInnerHTML={{
            __html: item.description
          }}
        />
      </div>
    );
  };
}

export default SectionContacts;
