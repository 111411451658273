export default function initProfileRequest(createRequest) {
  return function _getProfileRequest() {
    return {
      item: createRequest(initItemRequest()),
      update: createRequest(initUpdateRequest()),
      uploadPhoto: createRequest(initUploadPhotoRequest()),
      favorites: slug => ({
        add: createRequest(initAddToFavoritesRequest()),
        remove: createRequest(initRemoveFromFavoritesRequest(slug))
      }),
      physicalTraits: data => {
        return {
          request: createRequest(initPhysicalTraitsRequest()),
          add: createRequest(initAddPhysicalTraits(data))
        };
      },
      bonusAccount: () => ({
        all: createRequest(initBonusesAllRequest()),
        bonuses: createRequest(initBonusesRequest()),
        transactions: createRequest(initTransactionsRequest())
      })
    };
  };
}

function initItemRequest() {
  return function getItemRequest() {
    return {
      method: 'GET',
      path: `/profile`
    };
  };
}

function initUpdateRequest() {
  return function getItemRequest(data) {
    return {
      method: 'PUT',
      path: `/profile`,
      data
    };
  };
}

function initAddToFavoritesRequest() {
  return function getItemRequest(data) {
    return {
      method: 'POST',
      path: `/profile/favorites`,
      data
    };
  };
}

function initRemoveFromFavoritesRequest(slug) {
  return function getItemRequest() {
    return {
      method: 'DELETE',
      path: `/profile/favorites/${slug}`
    };
  };
}

function initUploadPhotoRequest() {
  return function uploadPhotoRequest(photo) {
    return {
      method: 'POST',
      path: `/profile/photo`,
      data: {
        file: photo
      },
      multipart: true
    };
  };
}

function initPhysicalTraitsRequest() {
  return function uploadPhotoRequest() {
    return {
      method: 'GET',
      path: `/profile/physical-traits`
    };
  };
}

function initAddPhysicalTraits(data) {
  return function getItemRequest() {
    return {
      method: 'PUT',
      path: `/profile/physical-traits/${data.slug}`,
      data: data.value
    };
  };
}

// function initBonusAccountRequest(createRequest) {
//   return function _getIngredientsRequest() {
//     return {
//       account: createRequest(initAccountRequest()),
//       bonuses: createRequest(initBonusesRequest()),
//       transactions: createRequest(initTransactionsRequest())
//     };
//   };
// }

function initBonusesAllRequest() {
  return function getItemRequest() {
    return {
      method: 'GET',
      path: `/bonus-account`
    };
  };
}

function initBonusesRequest() {
  return function getItemRequest() {
    return {
      method: 'GET',
      path: `/bonus-account/bonuses`
    };
  };
}

function initTransactionsRequest() {
  return function getItemRequest() {
    return {
      method: 'GET',
      path: `/bonus-account/transactions`
    };
  };
}
