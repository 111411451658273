import React, { Component } from 'react';

import Dialog from 'modules/core/components/dialog/dialog';
import { IconCheck } from 'modules/core/components/icons';

import './callback-conformation-dialog.css';

class CallbackConformationDialog extends Component {
  render() {
    const { onClose, name, action, ...rest } = this.props;
    const isFromMarketing = action === "send guide";
    return (
      <Dialog closeable {...rest} onClose={onClose}>
        <div className="CallbackConformationDialog">
          {isFromMarketing ? (
          <>          
            
            <div className="CallbackConformationDialog-info">
              <div className="CallbackConformationDialog-iconHolder">
                <div className="CallbackConformationDialog-icon">
                  <IconCheck />
                </div>
              </div>
              Спасибо! <br/> Проверь, пожалуйста, почтовый ящик.
            </div>
          </>
          ):(
          <>          
            <h1 className="CallbackConformationDialog-title">{name}, cпасибо за обращение!</h1>
            <p className="CallbackConformationDialog-info">
              В ближайшее время наш менеджер свяжемся с Вами
            </p>
          </>
          )}
        </div>
      </Dialog>
    );
  }
}

export default CallbackConformationDialog;
