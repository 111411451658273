import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './followers-counter.css';

const { string, element, oneOf } = PropTypes;

class FollowersCounter extends Component {
  static propTypes = {
    /** Компонент кнопки-иконки */
    button: element.isRequired,

    /** Количество подписчиков в социальной сети */
    amount: string.isRequired,

    size: oneOf(['normal', 'small'])
  };

  static defaultProps = {
    size: 'normal',
    lineDirection: false
  };

  render() {
    const { button, amount, size, lineDirection } = this.props;
    return (
      <div
        className={classNames('Social_FollowersCounter', `Social_FollowersCounter--size-${size}`, {
          'Social_FollowersCounter--lineDirection': lineDirection
        })}
      >
        <div className="Social_FollowersCounter-button">{button}</div>
        <div className="Social_FollowersCounter-amount">{amount}</div>
      </div>
    );
  }
}

export default FollowersCounter;
