import React, { Component } from 'react';
import { getIndexLink } from 'routes/links';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import * as DeprecatedSpecialOffers from '../ducks/actions/deprecated-special-offers.js';

import { Page, PageContent } from '../components/page/index';
import SectionSpecialOfferList from '../components/section-special-offer-list/section-special-offer-list';
import Container from '../components/container/container';
import FeedbackCall from '../components/feedback-call/feedback-call';
import SectionDefault from '../components/section-default/section-default';
import HideErrorBoundary from '../components/hide-error-boundary/hide-error-boundary';

import SpecialOfferTabsContainer from '../containers/special-offer-tabs';
// import DeprecatedSpecialOfferListContainer from '../containers/deprecated-special-offer';
import SubscribeContainer from '../containers/subscribe';

const mapDispatchToProps = {
  showDeprecatedSpecialOffers: DeprecatedSpecialOffers.showDeprecatedSpecialOffers
};

class SpecialOffersPage extends Component {
  render() {
    return (
      <Page
        title="Акции"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Акции'
          }
        ]}
      >
        <Helmet>
          <title>Акции и скидки в интернет-магазине современной косметики MIXIT</title>

          <meta
            name="description"
            content="Все акции и скидки в косметическом магазине MIXIT. Получай подарки к заказам, выигрывай поездки и поднимайте свое настроение вместе с нами."
          />
        </Helmet>
        <PageContent>
          {/* <DeprecatedSpecialOfferListContainer /> */}

          <HideErrorBoundary>
            <SectionSpecialOfferList>
              <SpecialOfferTabsContainer />
            </SectionSpecialOfferList>
          </HideErrorBoundary>

          <SubscribeContainer />

          <SectionDefault>
            <Container>
              <FeedbackCall />
            </Container>
          </SectionDefault>
        </PageContent>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    const { showDeprecatedSpecialOffers } = this.props;

    showDeprecatedSpecialOffers();
    this.scrollWindowToTop();
  }
}

export default withRouter(connect(() => { }, mapDispatchToProps)(SpecialOffersPage));
