import PropTypes from 'prop-types';

import mediaShape from './media-shape';
import priceShape from 'modules/ordering/prop-types/price-shape';
import dateShape from 'modules/core/prop-types/date-shape';
import variantShape from './product-variant-shape';

const { bool, number, string, arrayOf, shape } = PropTypes;

const seriesShape = shape({
  id: number.isRequired,
  title: string.isRequired
});

const categoryShape = shape({
  id: number.isRequired,
  title: string.isRequired
});

export default shape({
  id: number.isRequired,
  name: string.isRequired,
  title: string,
  sku: string,
  properties: arrayOf(string),
  description: string.isRequired,
  series: seriesShape,
  category: categoryShape,
  price: priceShape,
  media: arrayOf(mediaShape),
  quantity: number.isRequired,
  features: arrayOf(
    shape({
      type: string,
      title: string,
      description: string
    })
  ),
  details: shape({
    id: number.isRequired
  }),
  variants: arrayOf(variantShape),
  createDate: dateShape,
  inFavorite: bool,
  inBasket: bool
});
