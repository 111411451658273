import React, { Component } from 'react';
import Media from 'react-media';

import Loader from 'modules/core/components/loader/loader';
import Button from 'modules/core/components/button/button';

import BonusHistorySceneDesktop from './desktop/desktop';
import BonusHistorySceneMobile from './mobile/mobile';

import './bonus-history-scene.css';

class BonusHistoryScene extends Component {
  render() {
    const {
      isTransactionsLoaded,
      isTransactionsExist,
      isBonusesExist,
      isActivated,
      ...rest
    } = this.props;

    if (!isTransactionsLoaded) {
      return (
        <div className="BonusHistoryScene">
          <div className="BonusHistoryScene-loader">
            <Loader type="spin" />
          </div>
        </div>
      );
    }

    if (!isBonusesExist) {
      return (
        <div className="BonusHistoryScene">
          <div className="BonusHistoryScene-suggestion">
            <div className="BonusHistoryScene-suggestionText">
              У вас еще нет бонусной карты. Скорее получите бонусную карту и оцените все
              преимущества бонусов!
            </div>
            <div className="BonusHistoryScene-suggestionButton">
              <Media query="(min-width: 841px)">
                {isDesktop => (
                  <Button
                    title="Получить карту"
                    variant="primary"
                    size={isDesktop ? 'normal' : 'small'}
                    expanded={!isDesktop}
                    component="a"
                    href="https://mixit.ru/top/bonus-activate/"
                    target="_blank"
                    rel="noreferrer noopener"
                  />
                )}
              </Media>
            </div>
          </div>
        </div>
      );
    }

    if (!isActivated) {
      return (
        <div className="BonusHistoryScene">
          <div className="BonusHistoryScene-suggestion">
            <div className="BonusHistoryScene-suggestionText">
              Активируйте бонусную карту и получите первые бонусы уже после следующей покупки!
            </div>
            <div className="BonusHistoryScene-suggestionButton">
              <Media query="(min-width: 841px)">
                {isDesktop => (
                  <Button
                    title="Активировать карту"
                    variant="primary"
                    size={isDesktop ? 'normal' : 'small'}
                    expanded={!isDesktop}
                    component="a"
                    href="https://mixit.ru/top/bonus-activate/"
                    target="_blank"
                    rel="noreferrer noopener"
                  />
                )}
              </Media>
            </div>
          </div>
        </div>
      );
    }

    if (!isTransactionsExist) {
      return (
        <div className="BonusHistoryScene">
          <div className="BonusHistoryScene-emptyMessage">
            Пока нет начислений. Выполняйте задания и делайте покупки, чтобы заработать бонусы.
          </div>
        </div>
      );
    }

    return (
      <div className="BonusHistoryScene">
        <Media query="(min-width: 841px)">
          {isDesktop =>
            isDesktop ? (
              <BonusHistorySceneDesktop {...rest} />
            ) : (
              <BonusHistorySceneMobile {...rest} />
            )
          }
        </Media>
      </div>
    );
  }
}

export default BonusHistoryScene;
