import React from 'react';
// import Media from 'modules/core/components/media/media';
import Content from 'modules/core/components/content/content';

import "./about-company.css";

export default function ProblemSkin_AboutCompany({data}) {
    return (
        // <div className="ProblemSkin_TopBanner" styles={JSON.stringify(data.styles)}>
        <div className="ProblemSkin_AboutCompany" style={{...data.style}}>
            <div className="ProblemSkin_AboutCompany-title">{data.title}</div>

            <div className="ProblemSkin_AboutCompany-main">
                <div className="ProblemSkin_AboutCompany-imageHolder">
                    <img src={data.image.sources[0].main.normal} alt="" className="ProblemSkin_AboutCompany-image"/>
                </div>
                <div className="ProblemSkin_AboutCompany-descriptionHolder">
                    <div className="ProblemSkin_AboutCompany-description">
                        <Content html={data.description} expanded/>
                    </div>
                </div>
            </div>
        </div>
    )
} 