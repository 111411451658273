import React, { Component, Fragment } from 'react';

import Tooltip from 'modules/core/components/tooltip/tooltip';
import Link from 'modules/core/components/link/short-link';

import './add-to-favorite-tooltip.css';

class AddToFavoriteTooltip extends Component {
  static defaultProps = {
    alignment: 'center'
  };

  render() {
    const { isAuthorized, children, alignment } = this.props;

    if (isAuthorized) {
      return <Fragment>{children}</Fragment>;
    }

    return (
      <div className="AddToFavoriteTooltip">
        <Tooltip
          content={this.getTooltipContent()}
          showDelay={300}
          hideDelay={100}
          alignment={alignment}
        >
          <div className="AddToFavoriteTooltip-trigger">{children}</div>
        </Tooltip>
      </div>
    );
  }

  getTooltipContent() {
    const { onAuthorizationClick } = this.props;
    return (
      <span className="AddToFavoriteTooltip-content">
        Пожалуйста,{' '}
        <Link component="button" onClick={onAuthorizationClick}>
          авторизуйтесь
        </Link>
        , чтобы добавить в избранное
      </span>
    );
  }
}

export default AddToFavoriteTooltip;
