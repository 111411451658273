import { makeGetIncreasingId } from 'modules/utils';

import { createReview } from '../helpers';

const startId = 9999;

const getIncreasingId = makeGetIncreasingId(startId);

export default function createNewReview(review) {
  const { text, rating, advantage, disadvantage } = review;
  return createReview({
    id: getIncreasingId(),
    fullName: '',
    createDate: Date.now(),
    text,
    rating,
    advantages: [advantage],
    disadvantages: [disadvantage],
    media: [],
    likes: 0,
    dislikes: 0
  });
}
