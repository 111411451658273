import React, { Component, Fragment } from 'react';
import './section-product-usage.css';
import Media from 'modules/core/components/media/media';
import productDetailsShape from '../../prop-types/product-details-shape';
import YoutubeVideo from 'modules/core/components/youtube-video/youtube-video';

import Container from 'modules/core/components/container/container';

import getYoutubeVideoIdentifier from 'modules/utils/get-youtube-video-identifier';

class SectionProductUsage extends Component {
  static propTypes = {
    usage: productDetailsShape
  };

  render() {
    const { isMobile } = this.props;
    return (
      <section className="SectionProductUsage" id="usage" name="usage">
        {isMobile ? this.renderContent() : <Container small>{this.renderContent()}</Container>}
      </section>
    );
  }

  renderContent() {
    const { usage, isMobile } = this.props;

    let youtubeVideoUrl = false;

    if (usage.media && usage.media.sources[0].main.normal) {
      youtubeVideoUrl = getYoutubeVideoIdentifier(usage.media.sources[0].main.normal);
    }

    return (
      <>

        <div itemscope="" itemtype="https://schema.org/FAQPage" style={{ display: 'none' }}>
          <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
            <h3 itemprop="name">Применение</h3>
            <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
              {usage && usage.description && (
                <div itemprop="text">{usage.description}</div>
              )}
            </div>
          </div>
        </div>

        <Fragment>
          {!isMobile && <h3 className="SectionProductUsage-title">Применение</h3>}
          <p className="SectionProductUsage-description">{usage.description}</p>
          {usage.media && (
            <div className="SectionProductUsage-videoContainer">
              {!!youtubeVideoUrl ? (
                <YoutubeVideo id={youtubeVideoUrl} />
              ) : (
                  <Media media={usage.media} />
                )}
            </div>
          )}
        </Fragment>
      </>
    );
  }
}

export default SectionProductUsage;
