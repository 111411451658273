import React, { Component } from 'react';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import './three-column-grid.css';

class MainCard_ThreeColumnGrid extends Component {
  render() {
    const {
      breadcrumbs,
      description,
      properties,
      slider,
      labels,
      price,
      weightChoice,
      sizeChoice,
      colorChoice,
      code,
      availability,
      reviews,
      buyForm,
      buyOneClick,
      addToFavorite,
      delivery
    } = this.props;

    return (
      <div className="MainCard_ThreeColumnGrid">
        <div className="MainCard_ThreeColumnGrid-coll MainCard_ThreeColumnGrid-coll--properties">
          <div className="MainCard_ThreeColumnGrid-breadcrumbs">{breadcrumbs}</div>
          <div className="MainCard_ThreeColumnGrid-title">{description}</div>
          <div className="MainCard_ThreeColumnGrid-review">{reviews}</div>
          {properties && <div className="MainCard_ThreeColumnGrid-description">{properties}</div>}
        </div>

        <div className=" MainCard_ThreeColumnGrid-coll MainCard_ThreeColumnGrid-coll--slider">
          <HideErrorBoundary>
            <div className="MainCard_ThreeColumnGrid-slider">{slider}</div>
          </HideErrorBoundary>
        </div>

        <div className="MainCard_ThreeColumnGrid-coll MainCard_ThreeColumnGrid-coll--options">
          <div className="MainCard_ThreeColumnGrid-markers">{labels}</div>
          <div className="MainCard_ThreeColumnGrid-prices">{price}</div>

          {weightChoice && <div className="MainCard_ThreeColumnGrid-switch">{weightChoice}</div>}
          {colorChoice && <div className="MainCard_ThreeColumnGrid-switch">{colorChoice}</div>}
          {sizeChoice && <div className="MainCard_ThreeColumnGrid-switch">{sizeChoice}</div>}

          <div className="MainCard_ThreeColumnGrid-code">{code}</div>
          <div className="MainCard_ThreeColumnGrid-availability">{availability}</div>
          <div className="MainCard_ThreeColumnGrid-buy">{buyForm}</div>
          <div className="MainCard_ThreeColumnGrid-row">
            <div className="MainCard_ThreeColumnGrid-rowLink">
              <div className="MainCard-link">{addToFavorite}</div>
            </div>
            <div className="MainCard_ThreeColumnGrid-rowLink">
              <div className="MainCard-link">{buyOneClick}</div>
            </div>
          </div>
          <div className="MainCard_ThreeColumnGrid-delivery">{delivery}</div>
        </div>
      </div>
    );
  }
}

export default MainCard_ThreeColumnGrid;
