import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ProfileActions from '../actions/profile';
import * as fromFavoritesFilter from '../ducks/favorites-filter';
import * as fromProfile from '../ducks/profile';
import FavouriteScene from '../components/favourite-scene/favourite-scene';

// const productsArray = Object.keys(products).map(i => products[i]);

// const getAllCategories = productsArray.map(i => i.category);

// const getAllFilters = removeDuplicates(getAllCategories, 'id');

const mapStateToProps = createStructuredSelector({
  products: fromFavoritesFilter.getFilteredProductIds,
  filters: fromFavoritesFilter.getFavoriteCategories,
  activeFilter: fromFavoritesFilter.getActiveOption,
  isReady: fromProfile.getReady
});

const mapDispatchToProps = {
  filter: ProfileActions.filterFavorites
};

class FavoriteSceneContainer extends Component {
  render() {
    const { products, filters, activeFilter, isReady } = this.props;
    const isFavoritesExist = products && !!products.length;

    return (
      <FavouriteScene
        isReady={isReady}
        activeFilter={activeFilter}
        products={products}
        filters={filters}
        isFavoritesExist={isFavoritesExist}
        onFilterChange={this.handleFilter}
        onDeleteProduct={this.deleteProduct}
      />
    );
  }

  handleFilter = id => {
    this.props.filter(id);
  };

  deleteProduct = deletedProduct =>
    this.setState(state => ({
      products: state.products.filter(product => product.id !== deletedProduct.id)
    }));

  handleFilterChange = id => {
    this.props.filterChange(id);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavoriteSceneContainer);
