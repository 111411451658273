import * as REVIEWS from '../types/review';
import { createAction } from 'modules/utils/dux';

export default {
  reply(id, reply) {
    return createAction(REVIEWS.REPLY, { id, reply });
  },

  replyResponse() {
    return createAction(REVIEWS.REPLY_RESPONSE);
  },

  reviewResponse() {
    return createAction(REVIEWS.CREATE_RESPONSE);
  },

  create(productSlug, review) {
    return createAction(REVIEWS.CREATE, { productSlug, review });
  },

  rate(id, type) {
    return createAction(REVIEWS.RATE, { id, type });
  }
};
