import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SquareGrid from 'modules/product/components/square-grid/square-grid';
import ProductSetCardContainer from 'modules/product/containers/product-set-card';

import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';

import FilterOptionsMenu from './filter-options-menu/filter-options-menu';

import productShape from 'modules/product/prop-types/product-shape';
import { getCategoryLink } from 'routes/links';
import Media from 'react-media';

import HideErrorBoundary from '../../components/hide-error-boundary/hide-error-boundary';

import './gift-sets.css';

const { arrayOf } = PropTypes;

const filterOptionList = [
  {
    title: 'Все',
    link: getCategoryLink({ slug: 'gift-sets' })
  },
  {
    title: 'до 1000 руб.',
    link: getCategoryLink({ slug: 'gift-sets' }, null, {
      filter: ['price-under-500', 'price-from-500-to-1000']
    })
  },
  {
    title: 'до 3000 руб.',
    link: getCategoryLink({ slug: 'gift-sets' }, null, {
      filter: ['price-under-500', 'price-from-500-to-1000', 'price-1k-to-2k', 'price-2k-to-3k']
    })
  },
  {
    title: 'от 3000 руб.',
    link: getCategoryLink({ slug: 'gift-sets' }, null, {
      filter: ['price-upper-3k']
    })
  }
];

const MOBILE_GIFT_SETS_COUNT = 8;

class GiftSets extends Component {
  static propTypes = {
    setList: arrayOf(productShape).isRequired
  };

  render() {
    const { setList, isLoaded, sectionProps } = this.props;

    if (!isLoaded) {
      return null;
    }

    return (
      <SectionDefault {...sectionProps}>
        <Container>
          <div className="GiftSets">
            <div className="GiftSets-filterMenu">
              <FilterOptionsMenu filterOptionList={filterOptionList} />
            </div>
            <div className="GiftSets-content">
              <Media query="(max-width: 840px)">
                {isMobile =>
                  isMobile ? (
                    // <div className="GiftSets-carousel">
                    //   {setList.slice(0, MOBILE_GIFT_SETS_COUNT).map(slug => (
                    //     <div className="GiftSets-item">
                    //       <ProductSetCardContainer slug={slug} />
                    //     </div>
                    //   ))}
                    // </div>
                    <div className="GiftSets-grid">
                      {setList.slice(0, MOBILE_GIFT_SETS_COUNT).map(slug => (
                        <div className="GiftSets-gridItem">
                          <ProductSetCardContainer slug={slug} />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <SquareGrid>
                      <HideErrorBoundary>
                        <SquareGrid.ItemLarge>
                          <ProductSetCardContainer slug={setList[0]} col={2} listId="gift-sets" />
                        </SquareGrid.ItemLarge>
                      </HideErrorBoundary>

                      {setList
                        .filter((_, index) => index !== 0 && index < 6)
                        .map((item, index) => (
                          <HideErrorBoundary>
                            <SquareGrid.Item key={index}>
                              <ProductSetCardContainer slug={item} listId="gift-sets" />
                            </SquareGrid.Item>
                          </HideErrorBoundary>
                        ))}
                    </SquareGrid>
                  )
                }
              </Media>
            </div>
          </div>
        </Container>
      </SectionDefault>
    );
  }

  componentDidMount() {
    // Defer request to improve FCP
    setTimeout(() => this.props.onShow(), 1200); // REMOVE DELAY AFTER INTEGRATING SSR
  }
}

export default GiftSets;
