import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import cn from "classnames"

import IconContainer from '../icon-container/icon-container';
import NotificationCounter from '../notification-counter/notification-counter';

import './header-button.css';

const { element, string, number, func, oneOfType, bool } = PropTypes;

class HeaderButton extends Component {
  static propTypes = {
    /**
     * Button icon
     **/
    icon: element.isRequired,

    /**
     * Container tag
     **/
    component: oneOfType([string, func]),

    /**
     * Container tag
     **/
    count: number,

    loading: bool
  };

  static defaultProps = {
    component: 'button',
    count: null
  };

  render() {
    const { component: HeaderButtonComponent, icon, count, caption, loading,  ...rest } = this.props;

    return (
      <Media query="(max-width: 841px)">
        {isMobile => (
          <HeaderButtonComponent className={cn("HeaderButton", {
            "HeaderButton--loading": loading
          })} {...rest}>
            <span className="HeaderButton-icon">
              <IconContainer size={isMobile ? 'small' : 'medium'}>{icon}</IconContainer>
              {count > 0 && (
                <span className="HeaderButton-counter">
                  <NotificationCounter count={count} />
                </span>
              )}
            </span>

            {caption && <span className="HeaderButton-caption">{caption}</span>}
          </HeaderButtonComponent>
        )}
      </Media>
    );
  }
}

export default HeaderButton;
