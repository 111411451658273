import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromProducts from '../ducks/products';
import FavoritesAction from 'modules/profile/actions/favorites';
import ProductSetCard from '../components/product-set-card/product-set-card';
import { ToastConsumer } from 'modules/core/containers/toast-stack';

const mapStateToProps = createStructuredSelector({
  set: fromProducts.getItemBySlug
});

const mapDispatchToProps = {
  addProductToBasket: fromBasket.actions.addProduct,
  addToFavorites: FavoritesAction.add,
  removeFromFavorites: FavoritesAction.remove
};

class ProductSetCardContainer extends Component {
  static propTypes = {
    slug: PropTypes.string.isRequired
  };

  render() {
    return (
      <ToastConsumer>
        {({ create, close }) => (
          <ProductSetCard
            {...this.props}
            createToast={create}
            closeToast={close}
            onToggleFavorite={this.handleToggleFavorite}
            onAddToFavorites={this.handleAddToFavorites}
            onRemoveFromFavorites={this.handleRemoveFromFavorites}
          />
        )}
      </ToastConsumer>
    );
  }

  handleToggleFavorite = () => {
    const { product } = this.props;
    if (product.inFavorite) {
      this.props.removeFromFavorites(product);
    } else {
      this.props.addToFavorites(product);
    }
  };

  handleAddToFavorites = () => {
    const { product } = this.props;
    this.props.addToFavorites(product);
  };

  handleRemoveFromFavorites = () => {
    const { product } = this.props;
    this.props.removeFromFavorites(product);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSetCardContainer);
