import { LOCATION_CHANGE } from 'connected-react-router';
import { put, takeEvery } from 'redux-saga/effects';

import { queryStringToJSON } from 'modules/utils/get-utm';
import fromDraw from '../ducks/actions/draw-roll-up';
import { DRAW_ROLL_UP_OPEN, DRAW_ROLL_UP_CLOSE } from '../ducks/types/draw-roll-up';

export function* drawRollUpWatcher() {
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
  yield takeEvery(DRAW_ROLL_UP_OPEN, handleHideJivo);
  yield takeEvery(DRAW_ROLL_UP_CLOSE, handleVisibleJivo);
}

function* handleLocationChange(action) {
  const { search } = action.payload.location;

  const parameters = queryStringToJSON(search);

  const isSubscriptionSuggestionOpened = parameters.utm_term === 'prizes';

  if (isSubscriptionSuggestionOpened) {
    yield put(fromDraw.open());
  }
}

function handleHideJivo() {
  if (__BROWSER__) {
    document.body.classList.add('BODY_drawRollUpOpen');
  }
}

function handleVisibleJivo() {
  if (__BROWSER__) {
    document.body.classList.remove('BODY_drawRollUpOpen');
  }
}
