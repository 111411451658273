import { createAction } from 'modules/utils/dux';
import * as BONUSES from '../types/bonuses';

export default {
  requestAll(withFollowingTransactionsLoaded = false) {
    return createAction(BONUSES.ALL_REQUEST, {
      withFollowingTransactionsLoaded
    });
  },

  responseAll(all) {
    return createAction(BONUSES.ALL_RESPONSE, all);
  },

  errorAll() {
    return createAction(BONUSES.ALL_ERROR);
  },

  showTransactions() {
    return createAction(BONUSES.TRANSACTIONS_SHOW);
  },

  requestTransactions() {
    return createAction(BONUSES.TRANSACTIONS_REQUEST);
  },

  responseTransactions(transactions) {
    return createAction(BONUSES.TRANSACTIONS_RESPONSE, transactions);
  },

  errorTransactions() {
    return createAction(BONUSES.TRANSACTIONS_ERROR);
  }
};
