import React, { Component } from 'react';

import InstagramGalleryTile from './instagram-gallery-tile';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import './instagram-gallery-list.css';

class InstagramGalleryList extends Component {
  render() {
    const { instagramPosts } = this.props;

    return (
      <div className="InstagramGalleryList">
        {instagramPosts.map(post => (
          <HideErrorBoundary>
            <div key={post.link} className="InstagramGalleryList-item">
              <InstagramGalleryTile post={post} />
            </div>
          </HideErrorBoundary>
        ))}
      </div>
    );
  }
}

export default InstagramGalleryList;
