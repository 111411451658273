import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import isFunction from 'modules/utils/is-function';

import Item from './filter-item';

import './filter-menu.css';

import { Select } from 'modules/form/index';

const { func, bool, arrayOf, shape, number, string, oneOfType, object } = PropTypes;

export default class FilterMenu extends Component {
  static Item = Item;

  static propTypes = {
    /** active filter */
    activeFilter: oneOfType([object, number]),
    /** колбек смены фильтра */
    onChange: func,
    /** option of filter without filtering */
    all: bool,
    /** array of filters */
    filters: arrayOf(
      shape({
        id: number,
        value: string,
        title: string
      })
    )
  };

  render() {
    return (
      <Media query="(max-width: 840px)">
        {matches => (matches ? this.renderSelectFilter() : this.renderButtonsFilter())}
      </Media>
    );
  }

  renderButtonsFilter() {
    const { filters, activeFilter } = this.props;
    return (
      <div className="FilterMenu">
        {filters.map(filter => (
          <Item
            key={filter.id}
            active={activeFilter === filter.id}
            id={filter.id}
            title={filter.title}
            onClick={this.handleFilterClick(filter.id)}
          />
        ))}
      </div>
    );
  }

  renderSelectFilter() {
    const { activeFilter, filters } = this.props;

    const options = filters.map(item => ({
      ...item,
      value: item.id
    }));

    return (
      <Select options={options} value={activeFilter} onChange={this.handleSelectFilterChange} />
    );
  }

  handleFilterClick = (id = -1) => () => {
    const { onChange } = this.props;

    if (!isFunction(onChange)) {
      return;
    }

    onChange(id);
  };

  handleSelectFilterChange = e => {
    const { onChange } = this.props;
    const targetValue = parseInt(e.target.value, 10);

    if (!isFunction(onChange)) {
      return;
    }

    onChange(targetValue);
  };
}
