import { takeEvery, select, put } from 'redux-saga/effects';
// import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'connected-react-router';
// import { replace } from 'connected-react-router';
// import { matchPath } from 'react-router';

// import { getError404Link } from 'routes/links';
import { newData } from 'modules/landing/pages/configs/new-shops';

// import * as LANDINGS_TYPES from '../ducks/types';
import * as fromLandings from '../ducks/landings';
import * as LANDING_TYPES from '../ducks/types';

import ProductActions from "modules/product/ducks/products/actions.js"


export function* watchLandings() {
  // yield takeEvery(LOCATION_CHANGE, handleLocationChange);
  yield takeEvery(LOCATION_CHANGE, handleLocationChange2);
  yield takeEvery(LANDING_TYPES.REQUEST_LANDING_PRODUCTS, handleRequestProducts);
}

function* handleRequestProducts ({payload}) {
const {slug} = payload
yield put(ProductActions.showItem(slug));
}

function* handleLocationChange2() {
  const isLandingDataLoaded = yield select(fromLandings.getIsLandingDataLoaded);
  if (isLandingDataLoaded) {
    return;
  }
  let data;
  // yield delay(2000);
  try {
    // data = yield getData();
    data = newData;
  } catch (e) {
    // yield put(replace(getError404Link()));
    yield put(fromLandings.actions.setLoaded());
    return;
  }

  if (!data) {
    // yield put(replace(getError404Link()));

    return;
  }
  yield put(fromLandings.actions.addList(data));
  yield put(fromLandings.actions.setLoaded());
}

// function* getData() {
//   const data = yield fetch('/upload/landings/data.json?556', {
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     }
//   }).then(response => response.json());

//   return data;
// }

// function* handleLocationChange({
//   payload: {
//     location: { pathname }
//   }
// }) {
//   const slug = getSlugFromPath(pathname);

//   const isLandingExist = yield select(fromLandings.getLandingBySlugFromProps, { slug });

//   let data;

//   if (!slug || Boolean(isLandingExist)) {
//     return;
//   }

//   try {
//     // data = yield getData();
//     data = [seeds[slug]];
//   } catch (e) {
//     yield put(replace(getError404Link()));
//     return;
//   }

//   if (!data) {
//     yield put(replace(getError404Link()));
//   }
//   // yield put(fromLandings.actions.add(data));
//   yield put(fromLandings.actions.addList(data));
// }

// function getSlugFromPath(pathname) {
//   const match = matchPath(pathname, {
//     path: getLandingLink(),
//     exact: true
//   });

//   if (!match || !match.params || !match.params.slug) {
//     return null;
//   }

//   return match && match.params && match.params.slug;
// }
