import { createSelector } from 'reselect';
import { createReducer, handle, createAction } from 'modules/utils/dux';
import * as types from './types/dialogs';
import * as accountTypes from './types/account';
import * as CALLBACK from './types/callback';
import * as SOCIALS from './types/social';
import * as SUBSCRIBE from './types/subscribe';
import * as REVIEW from '../../review/types/review';
import * as FEEDBACK from '../ducks/types/feedback';
import * as PROFILE from '../../profile/types/profile';
import * as BONUSES_CONFIRMATION from '../../ordering/ducks/types/bonuses-conformation';
import * as fromProducts from 'modules/product/ducks/types/products';
import constants from './dialogs/constants';

const reducer = createReducer(
  handle(accountTypes.AUTHORIZATION_ERROR, state => ({
    ...state,
    current: constants.DIALOG_AUTH
  })),

  // handle(accountTypes.RECOVERY_PASSWORD_RESPONSE, state => ({
  //   ...state,
  //   current: null
  // })),

  // handle(accountTypes.RECOVERY_PASSWORD_REQUEST, state => ({
  //   ...state,
  //   current: null
  // })),

  handle(fromProducts.ADD_TO_WAIT_LIST_RESPONSE, state => ({
    ...state,
    current: constants.DIALOG_ADD_TO_WAITING_LIST_CONFORMATION
  })),

  handle(types.AUTH_OPEN, state => ({
    ...state,
    current: constants.DIALOG_AUTH
  })),

  handle(types.REGISTRATION_OPEN, state => ({
    ...state,
    current: constants.DIALOG_REGISTRATION
  })),

  handle(types.CITY_OPEN, state => ({
    ...state,
    current: constants.DIALOG_CITY
  })),

  handle(types.CALLBACK_OPEN, state => ({
    ...state,
    current: constants.DIALOG_CALLBACK
  })),

  handle(types.CALLBACK_CONFORMATION_OPEN, state => ({
    ...state,
    current: constants.DIALOG_CALLBACK_CONFORMATION
  })),

  handle(types.FEEDBACK_CONFIRMATION_OPEN, state => ({
    ...state,
    current: constants.DIALOG_FEEDBACK_CONFIRM
  })),

  // handle(FEEDBACK.RESPONSE, state => ({
  //   ...state,
  //   current: constants.DIALOG_FEEDBACK_CONFIRM
  // })),

  /**DELETE!!!!! */
  handle(FEEDBACK.ERROR, state => ({
    ...state,
    current: constants.DIALOG_FEEDBACK_CONFIRM
  })),

  handle(types.SUBSCRIBE_CONFORMATION_OPEN, state => ({
    ...state,
    current: constants.DIALOG_SUBSCRIBE_CONFORMATION
  })),

  // handle(types.SUBSCRIBE_SUGGESTION_OPEN, state => ({
  //   ...state,
  //   current: constants.DIALOG_SUBSCRIBE_SUGGESTION
  // })),

  handle(types.ADD_TO_WAIT_LIST_CONFORMATION_OPEN, state => ({
    ...state,
    current: constants.DIALOG_ADD_TO_WAITING_LIST_CONFORMATION
  })),

  handle(types.REPLY_CONFORMATION_OPEN, state => ({
    ...state,
    current: constants.DIALOG_REPLY_CONFORMATION
  })),

  handle(types.REVIEW_CONFORMATION_OPEN, state => ({
    ...state,
    current: constants.DIALOG_REVIEW_CONFORMATION
  })),

  handle(REVIEW.REPLY_RESPONSE, state => ({
    ...state,
    current: constants.DIALOG_REPLY_CONFORMATION
  })),

  handle(REVIEW.CREATE_RESPONSE, state => ({
    ...state,
    current: constants.DIALOG_REVIEW_CONFORMATION
  })),

  handle(CALLBACK.RESPONSE, (state, {name, action}) => {
  return {    
    ...state,
    current: constants.DIALOG_CALLBACK_CONFORMATION,
    callbackName: name,
    action}
  }),

  handle(SUBSCRIBE.RESPONSE, state => ({
    ...state,
    current: constants.DIALOG_SUBSCRIBE_CONFORMATION
  })),

  handle(types.DIALOG_CHANGE_PROFILE_NAME_OPEN, state => ({
    ...state,
    current: constants.DIALOG_CHANGE_PROFILE_NAME
  })),
  handle(types.DIALOG_CHANGE_PROFILE_NAME_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.GIFT_OPEN, state => ({
    ...state,
    current: constants.DIALOG_GIFT
  })),

  handle(types.AUTH_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.ADD_TO_WAIT_LIST_CONFORMATION_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.FEEDBACK_CONFIRMATION_CLOSE, state => ({
    ...state,
    current: null
  })),

  // handle(types.SUBSCRIBE_SUGGESTION_CLOSE, state => ({
  //   ...state,
  //   current: null
  // })),

  handle(types.SUBSCRIBE_CONFORMATION_OPEN, state => ({
    ...state,
    current: null
  })),

  handle(types.REGISTRATION_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.CITY_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.CALLBACK_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.CALLBACK_CONFORMATION_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.GIFT_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.REPLY_CONFORMATION_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.REVIEW_CONFORMATION_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.OPEN, (state, { name }) => ({
    ...state,
    current: name
  })),

  handle(types.CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(accountTypes.AUTHORIZATION_REQUEST, state => {
    if (state.current === constants.DIALOG_AUTH) {
      return {
        ...state,
        current: null
      };
    }
    return state;
  }),

  handle(SOCIALS.FACEBOOK_AUTH_CALL, state => {
    if (state.current === constants.DIALOG_AUTH) {
      return {
        ...state,
        current: null
      };
    }
    return state;
  }),

  handle(SOCIALS.VK_AUTH_CALL, state => {
    if (state.current === constants.DIALOG_AUTH) {
      return {
        ...state,
        current: null
      };
    }
    return state;
  }),

  handle(accountTypes.REGISTRATION_COMPLETE, state => {
    return {
      ...state,
      current: constants.DIALOG_ACQUAINTANCE
    };
  }),

  // recovery password start
  handle(types.PASSWORD_RECOVERY_REQUEST_OPEN, state => ({
    ...state,
    current: constants.DIALOG_PASSWORD_RECOVERY_REQUEST
  })),

  handle(types.PASSWORD_RECOVERY_REQUEST_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.PASSWORD_RECOVERY_REQUEST_SUCCESS_OPEN, state => ({
    ...state,
    current: constants.DIALOG_PASSWORD_RECOVERY_REQUEST_SUCCESS
  })),

  handle(types.PASSWORD_RECOVERY_REQUEST_SUCCESS_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.CONFIRM_PASSWORD_RECOVERY_OPEN, state => ({
    ...state,
    current: constants.DIALOG_CONFIRM_PASSWORD_RECOVERY
  })),

  handle(types.CONFIRM_PASSWORD_RECOVERY_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.CONFIRM_PASSWORD_RECOVERY_SUCCESS_OPEN, state => ({
    ...state,
    current: constants.DIALOG_CONFIRM_PASSWORD_RECOVERY_SUCCESS
  })),

  handle(types.CONFIRM_PASSWORD_RECOVERY_SUCCESS_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.CONFIRM_PASSWORD_RECOVERY_ERROR_OPEN, state => ({
    ...state,
    current: constants.DIALOG_CONFIRM_PASSWORD_RECOVERY_ERROR
  })),

  handle(types.CONFIRM_PASSWORD_RECOVERY_ERROR_CLOSE, state => ({
    ...state,
    current: null
  })),
  // recovery password end

  // PHYSIACL TRAITS START
  handle(types.CONFIRM_PHYSICAL_TRAITS_OPEN, state => ({
    ...state,
    current: constants.DIALOG_CONFIRM_PHYSICAL_TRAITS
  })),

  handle(PROFILE.PHYSICAL_TRAITS_RESPONSE, state => ({
    ...state,
    current: constants.DIALOG_CONFIRM_PHYSICAL_TRAITS
  })),

  handle(types.CONFIRM_PHYSICAL_TRAITS_CLOSE, state => ({
    ...state,
    current: null
  })),
  // PHYSIACL TRAITS END

  // BONUSES_CONFIRMATION start
  handle(types.BONUSES_CONFIRMATION_CODE_OPEN, state => ({
    ...state,
    current: constants.DIALOG_BONUSES_CONFIRM_CODE
  })),

  handle(types.BONUSES_CONFIRMATION_CODE_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(BONUSES_CONFIRMATION.ERROR_CONFIRM, function(state) {
    return {
      ...state,
      current: constants.DIALOG_BONUSES_CONFIRM_CODE
    };
  }),

  // BONUSES_CONFIRMATION end

  // UTM start
  handle(types.UTM_OPEN, state => ({
    ...state,
    current: constants.DIALOG_UTM
  })),

  handle(types.UTM_CLOSE, state => ({
    ...state,
    current: null
  })),

  // UTM end
  // UTM start
  handle(types.INGREDIENT_DIALOG_OPEN, (state, { id }) => ({
    ...state,
    current: constants.INGREDIENT_DIALOG,
    ingredientId: id
  })),

  handle(types.INGREDIENT_DIALOG_CLOSE, state => ({
    ...state,
    current: null
    // ingredientId: null
  })),

  // UTM end
  // FAQ start
  handle(types.DIALOG_FAQ_QUESTION_OPEN, state => ({
    ...state,
    current: constants.DIALOG_FAQ_QUESTION
  })),

  handle(types.DIALOG_FAQ_QUESTION_CLOSE, state => ({
    ...state,
    current: null
  })),

  handle(types.DIALOG_EMAIL_CONFIRM_OPEN, state => ({
    ...state,
    current: constants.DIALOG_EMAIL_CONFIRM
  })),

  handle(types.DIALOG_EMAIL_CONFIRM_CLOSE, state => ({
    ...state,
    current: null
  }))

  // FAQTM end
);

export default reducer({
  current: null,
  callbackName: null,
  ingredientId: null,
  action: null
});

export const getDialogs = state => state.core.dialogs;

export const getName = createSelector([getDialogs], dialogs => dialogs.callbackName);
export const getAction = createSelector([getDialogs], dialogs => dialogs.action);

export const getIngredientId = createSelector(getDialogs, dialogs => dialogs.ingredientId);

export const getCurrent = createSelector([getDialogs], dialogs => dialogs.current);

export const getIsAuthOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_AUTH
);

// recovery password start
export const getIsPasswordRecoveryRequestOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_PASSWORD_RECOVERY_REQUEST
);

export const getIsFAQQuestionOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_FAQ_QUESTION
);

export const getIsPasswordRecoveryRequestSuccessOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_PASSWORD_RECOVERY_REQUEST_SUCCESS
);

export const getIsConfirmPasswordRecoveryOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_CONFIRM_PASSWORD_RECOVERY
);

export const getIsConfirmPasswordRecoverySuccessOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_CONFIRM_PASSWORD_RECOVERY_SUCCESS
);

export const getIsConfirmPasswordRecoveryErrorOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_CONFIRM_PASSWORD_RECOVERY_ERROR
);
// recovery password end

export const getIsAcquaintanceOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_ACQUAINTANCE
);

export const getIsPhysicalTraitsConfirmOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_CONFIRM_PHYSICAL_TRAITS
);

export const getIsRegistrationOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_REGISTRATION
);

export const getIsCityOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_CITY
);

export const getIsCallbackOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_CALLBACK
);

export const getIsReplyConformationDialogOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_REPLY_CONFORMATION
);

export const getIsReviewConformationDialogOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_REVIEW_CONFORMATION
);

export const getIsConformationOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_CALLBACK_CONFORMATION
);

export const getIsSubscribeConformationOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_SUBSCRIBE_CONFORMATION
);

export const getIsSubscribeSuggestionOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_SUBSCRIBE_SUGGESTION
);

export const getIsGiftOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_GIFT
);

export const getIsAddToWaitListConformationOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_ADD_TO_WAITING_LIST_CONFORMATION
);

export const getIsBonusesConformationCodeOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_BONUSES_CONFIRM_CODE
);

export const getIsFeedbackConfirmOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_FEEDBACK_CONFIRM
);

export const getIsUTMOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_UTM
);
export const getIsChangeProfileNameOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_CHANGE_PROFILE_NAME
);
export const getIsIngredientOpened = createSelector(
  [getCurrent],
  current => current === constants.INGREDIENT_DIALOG
);

export const getIsEmailConfirmOpened = createSelector(
  [getCurrent],
  current => current === constants.DIALOG_EMAIL_CONFIRM
);

export const makeGetIsOpened = name => createSelector(getCurrent, current => current === name);

export const actions = {
  openAuthDialog() {
    return createAction(types.AUTH_OPEN);
  },

  openAddToWaitListConformationDialog() {
    return createAction(types.ADD_TO_WAIT_LIST_CONFORMATION_OPEN);
  },

  closeAddToWaitListConformationDialog() {
    return createAction(types.ADD_TO_WAIT_LIST_CONFORMATION_CLOSE);
  },

  closeAuthDialog() {
    return createAction(types.AUTH_CLOSE);
  },

  openRegistrationDialog() {
    return createAction(types.REGISTRATION_OPEN);
  },

  closeRegistrationDialog() {
    return createAction(types.REGISTRATION_CLOSE);
  },

  openCityDialog() {
    return createAction(types.CITY_OPEN);
  },

  closeCityDialog() {
    return createAction(types.CITY_CLOSE);
  },

  openCallbackDialog() {
    return createAction(types.CALLBACK_OPEN);
  },

  closeCallbackDialog() {
    return createAction(types.CALLBACK_CLOSE);
  },

  openCallbackConformationDialog() {
    return createAction(types.CALLBACK_CONFORMATION_OPEN);
  },

  closeCallbackConformationDialog() {
    return createAction(types.CALLBACK_CONFORMATION_CLOSE);
  },

  openSubscribeConformationDialog() {
    return createAction(types.SUBSCRIBE_CONFORMATION_OPEN);
  },

  closeSubscribeConformationDialog() {
    return createAction(types.SUBSCRIBE_CONFORMATION_CLOSE);
  },

  openSubscribeSuggestionDialog() {
    return createAction(types.SUBSCRIBE_SUGGESTION_OPEN);
  },

  closeSubscribeSuggestionDialog() {
    return createAction(types.SUBSCRIBE_SUGGESTION_CLOSE);
  },

  openGiftDialog() {
    return createAction(types.GIFT_OPEN);
  },

  closeGiftDialog() {
    return createAction(types.GIFT_CLOSE);
  },

  close() {
    return createAction(types.CLOSE);
  },

  // recovery password start
  openPasswordRecoveryRequestDialog() {
    return createAction(types.PASSWORD_RECOVERY_REQUEST_OPEN);
  },

  closePasswordRecoveryRequestDialog() {
    return createAction(types.PASSWORD_RECOVERY_REQUEST_CLOSE);
  },

  openPasswordRecoveryRequestSuccessDialog() {
    return createAction(types.PASSWORD_RECOVERY_REQUEST_SUCCESS_OPEN);
  },

  closePasswordRecoveryRequestSuccessDialog() {
    return createAction(types.PASSWORD_RECOVERY_REQUEST_SUCCESS_CLOSE);
  },

  openConfirmPasswordRecovery() {
    return createAction(types.CONFIRM_PASSWORD_RECOVERY_OPEN);
  },

  closeConfirmPasswordRecovery() {
    return createAction(types.CONFIRM_PASSWORD_RECOVERY_CLOSE);
  },

  openConfirmPasswordSuccessRecoveryDialog() {
    return createAction(types.CONFIRM_PASSWORD_RECOVERY_SUCCESS_OPEN);
  },

  closeConfirmPasswordSuccessRecoveryDialog() {
    return createAction(types.CONFIRM_PASSWORD_RECOVERY_SUCCESS_CLOSE);
  },

  openConfirmPasswordErrorRecoveryDialog() {
    return createAction(types.CONFIRM_PASSWORD_RECOVERY_ERROR_OPEN);
  },

  closeConfirmPasswordErrorRecoveryDialog() {
    return createAction(types.CONFIRM_PASSWORD_RECOVERY_ERROR_CLOSE);
  },

  // recovery password end

  // physical traits start
  openConfirmPhysicalTraitsDialog() {
    return createAction(types.CONFIRM_PHYSICAL_TRAITS_OPEN);
  },

  closeConfirmPhysicalTraitsDialog() {
    return createAction(types.CONFIRM_PHYSICAL_TRAITS_CLOSE);
  },
  // physical traits end

  openBonusesConfirmationCodeDialog() {
    return createAction(types.BONUSES_CONFIRMATION_CODE_OPEN);
  },

  closeBonusesConfirmationCodeDialog() {
    return createAction(types.BONUSES_CONFIRMATION_CODE_CLOSE);
  },

  // feedback
  openFeedbackConfirmDialog() {
    return createAction(types.FEEDBACK_CONFIRMATION_OPEN);
  },

  closeFeedbackConfirmDialog() {
    return createAction(types.FEEDBACK_CONFIRMATION_CLOSE);
  },

  // UTM
  openUTMDialog() {
    return createAction(types.UTM_OPEN);
  },

  closeUTMDialog() {
    return createAction(types.UTM_CLOSE);
  },

  // CHANGE_PROFILE_NAME
  openChangeProfileName() {
    return createAction(types.DIALOG_CHANGE_PROFILE_NAME_OPEN);
  },

  closeChangeProfileName() {
    return createAction(types.DIALOG_CHANGE_PROFILE_NAME_CLOSE);
  },
  // CHANGE_PROFILE_NAME
  openIngredient(id) {
    return createAction(types.INGREDIENT_DIALOG_OPEN, { id });
  },

  closeIngredient() {
    return createAction(types.INGREDIENT_DIALOG_CLOSE);
  },
  openFAQQuestion() {
    return createAction(types.DIALOG_FAQ_QUESTION_OPEN);
  },

  closeFAQQuestion() {
    return createAction(types.DIALOG_FAQ_QUESTION_CLOSE);
  },

  openEmailConfirmDialog() {
    return createAction(types.DIALOG_EMAIL_CONFIRM_OPEN);
  },

  closeEmailConfirmDialog() {
    return createAction(types.DIALOG_EMAIL_CONFIRM_CLOSE);
  }
};
