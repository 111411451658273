import * as category from './category-saga';
import * as product from './product-saga';
import * as group from './group';
import * as search from './search-saga';
import * as suggest from './suggest-saga';
import * as reviewRateSaga from './review-rate-saga';
import * as recentProductsLocalStorage from './recent-products-saga';

const sagas = {
  ...category,
  ...product,
  ...group,
  ...search,
  ...suggest,
  ...reviewRateSaga,
  ...recentProductsLocalStorage
};

export default function register(middleware) {
  return () => Object.values(sagas).map(middleware.run);
}
