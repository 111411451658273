import React, { Component } from 'react';
import { getProfileOrderLink } from 'routes/links';

import RouteLink from 'modules/core/components/link/route-link';
import Loader from 'modules/core/components/loader/loader';
import Price from 'modules/ordering/components/price-formatted/price-formatted';

import TransactionOrderDetailsProductContainer from '../../../containers/transaction-order-details-product';

import './order-details.css';

class OrderDetails extends Component {
  render() {
    const { id, total, goodsList, isLoading, isError } = this.props;

    if (isLoading) {
      return (
        <div className="BonusHistoryData_OrderDetails">
          <div className="BonusHistoryData_OrderDetails-loader">
            <Loader type="spin" size="small" />
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="BonusHistoryData_OrderDetails">
          <div className="BonusHistoryData_OrderDetails-errorMessage">
            К сожалению, детали заказа сейчас недоступны
          </div>
        </div>
      );
    }

    return (
      <div className="BonusHistoryData_OrderDetails">
        <div className="BonusHistoryData_OrderDetails-number">
          <RouteLink to={getProfileOrderLink({ id })}>№{id}</RouteLink>
        </div>

        <div className="BonusHistoryData_OrderDetails-title">Приобретенные товары</div>

        <ul className="BonusHistoryData_OrderDetails-list">
          {goodsList.map(goods => {
            if (goods.gift) {
              return null;
            }

            return (
              <li key={goods.product} className="BonusHistoryData_OrderDetails-item">
                <TransactionOrderDetailsProductContainer
                  slug={goods.product}
                  amount={goods.amount}
                />
              </li>
            );
          })}
        </ul>

        <div className="BonusHistoryData_OrderDetails-price">
          Сумма заказа: <Price number={total} />
        </div>
      </div>
    );
  }
}

export default OrderDetails;
