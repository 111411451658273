import React, { Component } from 'react';

import InputRating from '../components/input-rating/input-star-rating';

class InputRatingContainer extends Component {
  state = {
    hoveredIndex: -1
  };
  render() {
    const { hoveredIndex } = this.state;

    const { starsAmount, rating } = this.props;
    const checkedIndex = rating - 1;

    const activeIndex = hoveredIndex !== -1 ? hoveredIndex : checkedIndex;

    return (
      <InputRating
        activeIndex={activeIndex}
        starsAmount={starsAmount}
        onClick={this.handleClick}
        onHover={this.handleHover}
        onMouseOut={this.handleMouseOut}
      />
    );
  }

  resetHoveredIndex() {
    this.setState({
      hoveredIndex: -1
    });
  }

  setHoveredIndex(index) {
    this.setState({
      hoveredIndex: index
    });
  }

  handleClick = index => {
    const { onChange } = this.props;

    const newRating = index + 1;

    onChange(newRating);
  };

  handleHover = index => {
    this.setHoveredIndex(index);
  };

  handleMouseOut = () => {
    this.resetHoveredIndex();
  };
}

export default InputRatingContainer;
