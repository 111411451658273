import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PropTypes from 'prop-types';

import { actions as Dialog } from 'modules/core/ducks/dialogs';
import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromAccount from 'modules/core/ducks/account';
import * as fromProducts from '../ducks/products';

import * as fromWaitingList from 'modules/core/ducks/waiting-list';

import FavoritesAction from 'modules/profile/actions/favorites';
import Product from '../components/product/product';
import { ToastConsumer } from 'modules/core/containers/toast-stack';

const mapStateToProps = createStructuredSelector({
  product: fromProducts.getItemBySlug,
  isAuthorized: fromAccount.getIsAuthorized,
  authorizedEmail: fromAccount.getEmail,
  isWaitingListRequestLoading: fromWaitingList.getIsLoading,
  isBasketPlacing: fromBasket.getIsPlacing,
  isBasketVerifying: fromBasket.getIsVerifying,
  isBasketFetching: fromBasket.getIsBasketFetching
});

const mapDispatchToProps = {
  addProductToBasket: fromBasket.actions.addProduct,
  addToFavorites: FavoritesAction.add,
  removeFromFavorites: FavoritesAction.remove,
  onFavouriteClick: FavoritesAction.click,
  openAuthorizationDialog: Dialog.openAuthDialog,
  addToWaitListRequest: fromProducts.actions.addToWaitListRequest
};

class ProductContainer extends Component {
  static propTypes = {
    slug: PropTypes.string.isRequired
  };

  state = {
    isAddToWaitListDialogOpened: false,
    isAddToWaitListConfirmationDialogOpened: false
  };

  render() {
    const { isAuthorized, isLanding } = this.props;
    const { isAddToWaitListDialogOpened } = this.state;
    return (
      <ToastConsumer>
        {({ create, close }) => (
          <Product
            {...this.props}
            isLanding={isLanding}
            isAuthorized={isAuthorized}
            onButtonFavoriteClick={this.handleButtonFavoriteClick}
            onRemoveFromFavorites={this.handleRemoveFromFavorites}
            onAuthorizationClick={this.handleAuthorizationClick}
            createToast={create}
            closeToast={close}
            isAddToWaitListDialogOpened={isAddToWaitListDialogOpened}
            onDialogFormSubmit={this.handleDialogFormSubmit}
            onButtonAddToWaitingListClick={this.handleButtonAddToWaitingListClick}
            onQuickViewClick={this.handleClick}
            onAddToWaitListDialogClose={this.handleAddToWaitListDialogClose}
            isCosmopolitanIconExist={this.getIsCosmopolitanIconExist()}
          />
        )}
      </ToastConsumer>
    );
  }

  getIsCosmopolitanIconExist() {
    const { product } = this.props;
    if (!product) {
      return null;
    }
    const _mappingProd = product.features.map(item => item.type);
    return _mappingProd.includes('FEATURE/COSMOPOLITAN');
  }

  toggleFavorite() {
    const { product, removeFromFavorites, addToFavorites } = this.props;

    if (product.inFavorite) {
      removeFromFavorites(product);
    } else {
      addToFavorites(product);
    }
  }

  openAddToWaitListDialog() {
    this.setState(state => ({ ...state, isAddToWaitListDialogOpened: true }));
  }

  openAddToWaitListConfirmationDialog() {
    this.setState(state => ({
      ...state,
      isAddToWaitListConfirmationDialogOpened: true
    }));
  }

  closeAddToWaitListDialog() {
    this.setState(state => ({ ...state, isAddToWaitListDialogOpened: false }));
  }

  closeAddToWaitListConfirmationDialog() {
    this.setState(state => ({
      ...state,
      isAddToWaitListConfirmationDialogOpened: false
    }));
  }

  handleDialogFormSubmit = email => {
    const { slug, addToWaitListRequest } = this.props;

    this.closeAddToWaitListDialog();

    addToWaitListRequest(slug, email);
  };

  handleRequest(email) {
    this.openAddToWaitListConfirmationDialog();
  }

  handleButtonFavoriteClick = () => {
    const { isAuthorized, openAuthorizationDialog, onFavouriteClick } = this.props;
    const { product } = this.props;

    if (!isAuthorized) {
      openAuthorizationDialog();
      onFavouriteClick(product);
      return;
    }

    this.toggleFavorite();
  };

  handleRemoveFromFavorites = () => {
    const { product } = this.props;
    this.props.removeFromFavorites(product);
  };

  handleAuthorizationClick = () => {
    const { openAuthorizationDialog } = this.props;

    openAuthorizationDialog();
  };

  handleButtonAddToWaitingListClick = () => {
    const { slug, isAuthorized, addToWaitListRequest, authorizedEmail } = this.props;

    if (isAuthorized) {
      addToWaitListRequest(slug, authorizedEmail);
    } else {
      this.openAddToWaitListDialog();
    }
  };

  handleAddToWaitListDialogClose = () => {
    this.closeAddToWaitListDialog();
  };

  handleAddToWaitListConfirmationDialogClose = () => {
    this.closeAddToWaitListConfirmationDialog();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
