import React, { Component } from 'react';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import FilterMenu from '../../filter-menu/filter-menu';
import BonusHistoryTable from '../../bonus-history-table/bonus-history-table';

import './desktop.css';

const NO_FILTER = 'all';

const filters = {
  all: { id: 'all', title: 'Все' },
  income: { id: 'income', title: 'Начисленные' },
  expense: { id: 'expense', title: 'Списанные' }
};

function getEmptyMessage(activeFilterId, filters) {
  const bonusType =
    activeFilterId === NO_FILTER ? 'Бонусы' : `${filters[activeFilterId].title} бонусы`;

  return `${bonusType} отсутствуют`;
}

class BonusHistorySceneDesktop extends Component {
  state = {
    activeFilterId: 'all'
  };

  render() {
    const { transactions, openedOutsideId, onTransactionClick } = this.props;
    const { activeFilterId } = this.state;

    const transactionList =
      activeFilterId === NO_FILTER
        ? transactions
        : transactions.filter(item => item[activeFilterId] && item[activeFilterId].length > 0);

    const emptyMessage = getEmptyMessage(activeFilterId, filters);

    const filterList = Object.values(filters);

    return (
      <div className="BonusHistoryScene_Desktop">
        <div className="BonusHistoryScene_Desktop-filter">
          <FilterMenu
            filters={filterList}
            activeFilter={activeFilterId}
            onChange={this.handleFilterChange}
          />
        </div>

        <HideErrorBoundary>
          <div className="BonusHistoryScene_Desktop-table">
            <BonusHistoryTable
              key={activeFilterId}
              transactions={transactionList}
              emptyMessage={emptyMessage}
              openedOutsideId={openedOutsideId}
              onTransactionClick={onTransactionClick}
            />
          </div>
        </HideErrorBoundary>
      </div>
    );
  }

  setActiveFilterId(activeFilterId) {
    this.setState({ activeFilterId });
  }

  handleFilterChange = id => {
    const { onFilterChange } = this.props;

    this.setActiveFilterId(id);
    onFilterChange();
  };
}

export default BonusHistorySceneDesktop;
