export const PRODUCT_LIST_SHOW = 'ECOMMERCE/PRODUCT_LIST_SHOW';

export const PRODUCT_CLICK = 'ECOMMERCE/PRODUCT_CLICK';
export const PRODUCT_VIEW = 'ECOMMERCE/PRODUCT_VIEW';
export const PRODUCT_ADD_TO_BASKET = 'ECOMMERCE/PRODUCT_ADD_TO_BASKET';
export const PRODUCT_REMOVE_FROM_BASKET = 'ECOMMERCE/PRODUCT_REMOVE_FROM_BASKET';

export const CATEGORY_VIEW = 'ECOMMERCE/CATEGORY_VIEW';

export const BASKET_SHOW = 'ECOMMERCE/BASKET_SHOW';
export const BASKET_DATA_SHOW = 'ECOMMERCE/BASKET_DATA_SHOW';

export const CHECKOUT = 'ECOMMERCE/CHECKOUT';
export const TRANSACTION = 'ECOMMERCE/TRANSACTION';
export const USER_SET = 'ECOMMERCE/USER_SET';
export const USER_RESET = 'ECOMMERCE/USER_RESET';
export const PAGE_VIEW = 'ECOMMERCE/PAGE_VIEW';

export const ADD_TO_FAVOURITE = 'ECOMMERCE/ADD_TO_FAVOURITE';
export const CLICK_FAVOURITE = 'ECOMMERCE/CLICK_FAVOURITE';
