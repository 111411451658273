import React, { Component } from 'react';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import Transaction from './transaction/transaction';

import { getTopElementPosition, getMaxScroll } from 'modules/utils';

import './bonus-history-table-mobile.css';

class BonusHistoryTableMobile extends Component {
  state = {
    openedIdList: this.getOpenedIdList()
  };

  _transactionListElement = null;
  _transactionElements = null;

  render() {
    const { transactions, bonusType, openedOutsideId } = this.props;
    const { openedIdList } = this.state;

    return (
      <div className="BonusHistoryTableMobile">
        <ul
          className="BonusHistoryTableMobile-transactionList"
          ref={this.setTransactionListElementRef}
        >
          {transactions.map(transaction => {
            const { id, orderId } = transaction;

            const isOrderExists = !!orderId;
            const isOpened = isOrderExists && openedIdList.indexOf(id) !== -1;
            const isActive = openedOutsideId === id;

            return (
              <HideErrorBoundary>
                <li
                  key={id}
                  className="BonusHistoryTableMobile-transaction"
                  ref={this.setTransactionElementRef(id)}
                >
                  <Transaction
                    transaction={transaction}
                    bonusType={bonusType}
                    isActive={isActive}
                    isOpened={isOpened}
                    isOrderExists={isOrderExists}
                    onClick={isOrderExists && this.handleClick(id, isOpened)}
                  />
                </li>
              </HideErrorBoundary>
            );
          })}
        </ul>
      </div>
    );
  }

  componentDidMount() {
    const { openedOutsideId } = this.props;

    if (typeof openedOutsideId === 'number') {
      this.scrollToShopElementTop(openedOutsideId);
    }
  }

  setTransactionListElementRef = element => {
    if (!element) return;

    this._transactionListElement = element;
  };

  setTransactionElementRef = id => element => {
    if (!element) return;

    this._transactionElements = { ...this._transactionElements, [id]: element };
  };

  scrollToShopElementTop(id) {
    if (!this._transactionElements[id]) {
      return;
    }

    const topShopElementPosition = getTopElementPosition(this._transactionElements[id]);

    const maxScroll = getMaxScroll(this._transactionListElement);

    const pageY = topShopElementPosition > maxScroll ? maxScroll : topShopElementPosition;

    window.scrollTo({
      top: pageY
    });
  }

  getOpenedIdList() {
    const { openedOutsideId } = this.props;

    const isOutsideIdExists = typeof openedOutsideId === 'number';

    if (!isOutsideIdExists) {
      return [];
    }

    return [openedOutsideId];
  }

  open(id) {
    this.setState(state => ({
      ...state,
      openedIdList: [...state.openedIdList, id]
    }));
  }

  close(id) {
    this.setState(state => {
      const filteredList = state.openedIdList.filter(openedId => openedId !== id);

      return { ...state, openedIdList: filteredList };
    });
  }

  handleClick = (id, isOpened) => () => {
    if (isOpened) {
      this.close(id);
      return;
    }

    this.open(id);
  };
}

export default BonusHistoryTableMobile;
