import React from 'react';
// import Media from 'modules/core/components/media/media';
import YoutubeVideo from 'modules/core/components/youtube-video/youtube-video';

import "./video-section.css";

export default function ProblemSkin_VideoSection({data}) {
    return (
        <div className="ProblemSkin_VideoSection" style={{...data.style}}>
            <div className="ProblemSkin_VideoSection-title">{data.title}</div>
            <div className="ProblemSkin_VideoSection-video">
                <YoutubeVideo id={data.youtube_video_id} />
            </div>
        </div>
    )
} 