import React, { Component } from 'react';
import ReactMedia from 'react-media';
import cn from 'classnames';

import PromoCodeGroup from '../promo-code-with-button/promo-code-with-button';
import Media from '../media/media';
import Link from '../link/link';
import LinkText from '../link/link-text/link-text';

import './special-offer.css';

const APOLOGIES_TOAST_ID = 'apply_special_order';

class SpecialOffer extends Component {
  render() {
    const {
      title,
      description,
      promocode,
      metaCaption,
      link,
      media,
      timeCaption,
      isPromocodeExists,
      isTimeLeftAligned,
      isTimeColorGrey
    } = this.props;

    return (
      <div
        className={cn('SpecialOffer', {
          'SpecialOffer--timeLeftAligned': isTimeLeftAligned,
          'SpecialOffer--timeColorGrey': isTimeColorGrey
        })}
      >
        <h2 className="SpecialOffer-title">{title}</h2>

        <div className="SpecialOffer-metaInfo">{metaCaption}</div>

        <div className="SpecialOffer-media">
          <Media media={media} stretch="horizontal" nocaption />
        </div>

        <div className="SpecialOffer-description">{description}</div>

        {link && (
          <ReactMedia query="(max-width:380px)">
            {isMobile =>
              isMobile ? (
                <div className="SpecialOffer-link">
                  <a className="SpecialOffer-linkText" href={link}>
                    Подробнее об акции
                  </a>
                </div>
              ) : (
                <div className="SpecialOffer-link">
                  <Link href={link}>
                    <LinkText title="Подробнее об акции" />
                  </Link>
                </div>
              )
            }
          </ReactMedia>
        )}

        {timeCaption && (
          <div className="SpecialOffer-time">
            {timeCaption}
          </div>
        )}

        {isPromocodeExists && (
          <div className="SpecialOfferPage-promoCodeGroup">
            <ReactMedia query="(max-width: 640px)">
              {isLowMobile => (
                <PromoCodeGroup
                  code={promocode}
                  size={isLowMobile ? 'small' : 'normal'}
                  variant="apply"
                  applyPromocode={this.createToast}
                />
              )}
            </ReactMedia>
          </div>
        )}
      </div>
    );
  }

  createToast = () => {
    const { apologiesToastCreate } = this.props;

    let toastOptions = {
      id: APOLOGIES_TOAST_ID,
      state: 'loading',
      delay: false,
      content:
        'Промокод применен! Теперь когда ты перейдешь в корзину, стоимость покупки будет пересчитана с учетом примененного промокода',
      actionList: [
        {
          title: 'ок',
          type: 'CLOSE_TOAST',
          variant: 'primary'
        }
      ]
    };

    apologiesToastCreate({
      toast: toastOptions,
      onActionTrigger: this.handleToastAction
    });
  };

  handleToastAction = action => {
    const { apologiesToastClose } = this.props;
    if (action.type === 'CLOSE_TOAST') {
      apologiesToastClose(APOLOGIES_TOAST_ID);
    }
  };

  componentWillUnmount() {
    const { apologiesToastClose } = this.props;

    if (Boolean(apologiesToastClose)) {
      apologiesToastClose(APOLOGIES_TOAST_ID);
    }
  }
}

export default SpecialOffer;
