import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IconAngleArrowRight } from '../icons/index';

import './breadcrumbs.css';

class Breadcrumbs extends Component {
  static propTypes = {
    /**
     *
     */
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string
      })
    )
  };
  render() {
    const { pages } = this.props;

    const microdataObj = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": pages.map((item, index) => {
        return {
          "@type": "ListItem",
          "position": index + 1,
          "name": item.name,
          "item": item.link
        }
      })
    }

    return (
      <>
        <Helmet script={[{
          type: 'application/ld+json',
          innerHTML: JSON.stringify(microdataObj)
        }]}
        />
        <div className="Breadcrumbs" itemscope="" itemtype="http://schema.org/BreadcrumbList">
          {pages.map(this.renderPage)}
        </div>
      </>
    );
  }

  renderPage = (item, index, pages) => {
    const lastIndex = pages.length - 1;
    if (index === lastIndex) {
      return this.renderLastItem(item, index);
    }

    return this.renderItem(item, index);
  };

  renderItem(item, index) {
    return (
      <span
        key={index}
        itemprop="itemListElement"
        itemscope=""
        itemtype="http://schema.org/ListItem"
      >
        <meta itemprop="position" content={index + 1}></meta>
        {!!item.absoluteLink ? (
          <a
            href={item.absoluteLink}
            rel="noreferrer noopener"
            className="Breadcrumbs-linkPrevious"
            itemprop="item"
          >
            <span itemprop="name">{item.name}</span>
          </a>
        ) : (
            <Link
              to={item.link}
              className="Breadcrumbs-linkPrevious"
              itemprop="item"
              href={item.link}
            >
              <span itemprop="name">{item.name}</span>
            </Link>
          )}
        <div className="Breadcrumbs-arrow">
          <IconAngleArrowRight />
        </div>
      </span>
    );
  }

  renderLastItem(item, index) {
    return (
      <span
        key={index}
        className="Breadcrumbs-linkCurrent"
        itemprop="itemListElement"
        itemscope=""
        itemtype="http://schema.org/ListItem"
      >
        <meta itemprop="position" content={index + 1}></meta>
        <span itemprop="name">{item.name}</span>
      </span>
    );
  }
}

export default Breadcrumbs;
