export default function getIsMatchedString(list, string) {
  if(!Boolean(string)) {
    return false;
  }
  
  let state = false;
  const _string = String(string);
  const _list = list.map(item => String(item));

  state = _list.find(element => {
    return _string.includes(element)
  });
  
  return state;
}
