import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import { withRouter } from 'react-router';

import { getBasketLink } from 'routes/links';

import SmallGapButton from 'modules/core/components/small-gap-button/small-gap-button';
import { IconSimpleBasketAdd } from 'modules/core/components/icons';
// import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';

import ButtonQuickViewIcon from './button-quick-view-icon';
import ButtonAddToBasketText from './button-add-to-basket-text';
import ButtonAddToBasket from './button-add-to-basket';
import ButtonQuickView from './button-quick-view';
import ButtonAddToWaitingList from './button-add-to-waiting-list';

import './product.css';

const DesktopActionGroup = ({
  product,
  isUpsellVariant,
  isFavoriteVariant,
  isWaitingListRequestLoading,
  onButtonAddToBasketClick,
  onButtonViewClick,
  onButtonAddToWaitingListClick,
  // isBasketPlacing,
  // isBasketVerifying,
  isBasketFetching
}) => {
  const { isAvailable, isGift, isInBasket } = product;

  if (!isAvailable) {
    return (
      <div className="Product-actionGroup">
        <div className="Product-action Product-action--expanded">
          <ButtonAddToWaitingList
            disabled={isWaitingListRequestLoading}
            onClick={onButtonAddToWaitingListClick}
          />
        </div>
      </div>
    );
  }

  const isNotDefaultVariant = isFavoriteVariant || isUpsellVariant;

  if (isNotDefaultVariant) {
    return (
      <div className="Product-actionGroup">
        <div className="Product-action">
          <ButtonQuickViewIcon onClick={onButtonViewClick} />
        </div>

        <div className="Product-action Product-action--expanded">
          <ButtonAddToBasketText
            isInBasket={isInBasket}
            disabled={isGift || isBasketFetching}
            onClick={onButtonAddToBasketClick}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="Product-actionGroup">
      <div className="Product-action">
        <ButtonAddToBasket
          isInBasket={isInBasket}
          disabled={isGift || isBasketFetching}
          onClick={onButtonAddToBasketClick}
          isFetching={isBasketFetching}
        />
      </div>

      <div className="Product-action">
        <ButtonQuickView onClick={onButtonViewClick} />
      </div>
    </div>
  );
};

const MobileActionGroup = ({
  product,
  onButtonAddToBasketClick,
  // isBasketPlacing,
  // isBasketVerifying,
  isBasketFetching
}) => {
  const { isAvailable, isGift } = product;

  if (!isAvailable) {
    return null;
  }

  const variant = product.isInBasket ? 'primary' : 'default';
  const title = product.isInBasket ? product.amountInBasket : '';

  return (
    <div className="Product-actionGroup">
      <div className="Product-action">
        <SmallGapButton
          size="small"
          variant={variant}
          title={title}
          disabled={isGift || isBasketFetching}
          iconPrepend={<IconSimpleBasketAdd />}
          // iconAppend={isBasketFetching && <IconFlatLoader />}
          onClick={onButtonAddToBasketClick}
          reducedGap
        />
      </div>
    </div>
  );
};

class ActionGroup extends Component {
  render() {
    const {
      product,
      isUpsellVariant,
      isFavoriteVariant,
      isWaitingListRequestLoading,
      onButtonViewClick,
      onButtonAddToWaitingListClick,
      isBasketPlacing,
      isBasketVerifying,
      isBasketFetching
    } = this.props;

    return (
      <Fragment>
        <Media
          query="(min-width: 1101px)"
          defaultMatches={false}
          render={() => (
            <DesktopActionGroup
              product={product}
              isUpsellVariant={isUpsellVariant}
              isFavoriteVariant={isFavoriteVariant}
              isWaitingListRequestLoading={isWaitingListRequestLoading}
              onButtonViewClick={onButtonViewClick}
              onButtonAddToWaitingListClick={onButtonAddToWaitingListClick}
              onButtonAddToBasketClick={this.handleButtonAddToBasketClick(true)}
              isBasketPlacing={isBasketPlacing}
              isBasketVerifying={isBasketVerifying}
              isBasketFetching={isBasketFetching}
            />
          )}
        />
        <Media
          query="(max-width: 1100px)"
          defaultMatches
          render={() => (
            <MobileActionGroup
              product={product}
              onButtonAddToBasketClick={this.handleButtonAddToBasketClick(false)}
              isBasketPlacing={isBasketPlacing}
              isBasketVerifying={isBasketVerifying}
              isBasketFetching={isBasketFetching}
            />
          )}
        />
      </Fragment>
    );
  }

  createToast(isDesktop) {
    const { product, createToast } = this.props;

    const delay = isDesktop ? 3000 : 1500;

    let toast = {
      id: `${product.slug}-ordering-added-${Math.random()}`,
      title: `${product.title} добавлен в корзину!`,
      state: 'success',
      delay
    };

    const desktopToastParam = {
      content: product.description,
      actionList: [
        {
          title: 'Перейти в корзину',
          type: 'ACTION_GO_BASKET',
          variant: 'primary'
        }
      ]
    };

    if (isDesktop) {
      toast = { ...toast, ...desktopToastParam };
    }

    createToast({
      toast: toast,
      onActionTrigger: this.handleToastAction
    });
  }

  handleToastAction = action => {
    if (action.type === 'ACTION_GO_BASKET') {
      this.props.history.push(getBasketLink());
    }
  };

  handleButtonAddToBasketClick = isDesktop => e => {
    const { product, addProductToBasket } = this.props;

    e.preventDefault();

    this.createToast(isDesktop);
    addProductToBasket(product);
  };
}

export default withRouter(ActionGroup);
