import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Dadata from 'services/dadata';

import DeliveryForm from '../components/delivery-form/delivery-form';

import * as fromDeliveryForm from '../ducks/delivery-form';
import * as fromLocation from 'modules/geography/ducks/location';
import * as fromProfile from 'modules/profile/ducks/profile';

import * as fromOrders from '../ducks/orders';
import * as fromBasket from '../ducks/basket/selectors';
import * as fromPickPointQuery from '../ducks/pick-point-query';
import * as fromBasketProducts from '../ducks/basket-products';
import { actions as CityDelivery } from '../ducks/city-delivery';

const dadata = new Dadata(process.env.REACT_APP_DADATA_API_KEY);

const mapStateToProps = createStructuredSelector({
  deliveryLoading: fromDeliveryForm.getLoadingByCityId,
  isDeliveryFree: fromBasketProducts.getIsDeliveryFree,
  isCityChoosen: fromDeliveryForm.getHasCity,
  cityId: fromDeliveryForm.getCityId, // object with possible city ids
  cityData: fromDeliveryForm.getCity,
  deliveryMethodList: fromDeliveryForm.getMethodList,
  selectedDeliveryMethod: fromDeliveryForm.getDeliveryMethod,
  selectedPickPoint: fromDeliveryForm.getPickPoint,
  savedDeliveryAddresses: fromDeliveryForm.getSavedAddresses,
  selectedAddress: fromDeliveryForm.getAddress,
  streetRaw: fromDeliveryForm.getStreetString,
  streetFiasId: fromDeliveryForm.getStreetFiasId,
  apartment: fromDeliveryForm.getApartment,
  geography: fromDeliveryForm.getGeography,
  recipientName: fromDeliveryForm.getRecipientName,
  recipientFirstName: fromDeliveryForm.getRecipientFirstName,
  recipientLastName: fromDeliveryForm.getRecipientLastName,
  recipientPhone: fromDeliveryForm.getRecipientPhone,
  isValid: fromDeliveryForm.getIsValid,
  isValidExceptFullName: fromDeliveryForm.getIsValidExceptFullName, //depricated
  freeDeliveryFromByDeliveryCity: fromDeliveryForm.getDeliveryFrom,
  isDeliveryFreeByDeliveryCity: fromBasketProducts.getIsDeliveryFreeByDeliveryCity,
  defaultCity: fromLocation.getCurrentCity,
  defaultFirstName: fromOrders.getFirstNameById,
  defaultFullName: fromProfile.getFullName,
  defaultPhone: fromOrders.getPhoneById,
  defaultStreet: fromProfile.getLegacyStreetTitle,
  defaultBuilding: fromProfile.getLegacyBuilding,
  defaultApartment: fromProfile.getLegacyApartment,

  // defaultFirstName: fromProfile.getFirstName,
  defaultLastName: fromOrders.getLastNameById,
  profileLastName: fromProfile.getLastName,


  orderDelivery: fromOrders.getDeliveryById,
  pickPointFilterMask: fromPickPointQuery.getQuery,

  cityErrors: fromDeliveryForm.getCityValidation,
  deliveryMethodErrors: fromDeliveryForm.getDeliveryMethodValidation,
  pickPointErrors: fromDeliveryForm.getPickPointValidation,
  phoneErrors: fromDeliveryForm.getPhoneValidation,

  fullNameErrors: fromDeliveryForm.getFullNameValidation,
  firstNameErrors: fromDeliveryForm.getFirstNameValidation,
  lastNameErrors: fromDeliveryForm.getLastNameValidation,

  addressErrors: fromDeliveryForm.getAddressValidation,
  apartmentErrors: fromDeliveryForm.getApartmentValidation,

  productsCost: fromOrders.getProductsCostWithDiscount,
  maxDeliveryPrice: fromBasket.getMaxDeliveryPrice,
  freeDeliveryPrice: fromLocation.getFreeDeliveryFrom
});

const mapDispatchToProps = {
  ...fromDeliveryForm.actions,
  submitDeliveryInfo: fromOrders.actions.submitDeliveryInfo,
  pickPointRequest: CityDelivery.pickPointRequest,
  pickPointQueryReset: fromPickPointQuery.actions.reset
};

class DeliveryFormContainer extends Component {
  state = {
    isPickPointDialogShowed: false,
    showErrors: false,
    addRecipient: false,
    initialCity: false
  };

  render() {
    const { isValid, ...rest } = this.props;
    const { isPickPointDialogShowed, showErrors, addRecipient, initialCity } = this.state;

    // const disableSubmit = showErrors && !isValid;
    const disableSubmit = this.getIsSubmitDisable();

    return (
      <DeliveryForm
        {...rest}
        isPickPointDialogShowed={isPickPointDialogShowed}
        onDeliveryMethodChange={this.handleDeliveryMethodChange}
        onPickPointChange={this.handlePickPointChange}
        onRecipientNameChange={this.handleRecipientNameChange}
        onRecipientFirstNameChange={this.handleRecipientFirstNameChange}
        onRecipientLastNameChange={this.handleRecipientLastNameChange}
        onRecipientPhoneChange={this.handleRecipientPhoneChange}
        onAddressChange={this.handleAddressChange}
        onBuildingChange={this.handleBuildingChange}
        onApartmentChange={this.handleApartmentChange}
        onPickPointDialogShow={this.handlePickpointDialogShow}
        onPickPointDialogClose={this.handlePickpointDialogClose}
        onCityChange={this.handleCityChange}
        showErrors={showErrors}
        disableSubmit={disableSubmit}
        onSubmit={this.handleSubmit}
        handleAddRecepientChange={this.handleAddRecepientChange}
        addRecipient={addRecipient}
        initialCity={initialCity}
      // isValid={isValid}
      />
    );
  }

  handleAddRecepientChange = () => {
    this.setState(
      prevState => {
        return {
          ...prevState,
          addRecipient: !prevState.addRecipient
        };
      },
      () => {
        const { addRecipient } = this.state;

        if (addRecipient) {
          this.props.resetInitialRecipientData();
        } else {
          const { defaultFullName, defaultLastName, profileLastName, defaultPhone, orderDelivery, defaultFirstName } = this.props;
          const fullName =
            (orderDelivery || {}).recipientName || defaultFullName || defaultFirstName;
          const phone = (orderDelivery || {}).recipientPhone || defaultPhone;

          const lastName = defaultLastName || profileLastName || "";

          this.props.setInitialRecipientData(fullName, phone, lastName);
        }
      }
    );
  };

  getIsSubmitDisable() {
    const { isValid } = this.props;
    // const hasLastNameError = this.getHasLastnameError()

    // if(hasLastNameError) {
    //   return false
    // }

    if (isValid) {
      return false;
    }

    return true;
  }

  getHasLastnameError() {
    const { fullNameErrors } = this.props;
    if (
      fullNameErrors &&
      fullNameErrors[0] &&
      fullNameErrors[0].message &&
      fullNameErrors[0].message === 'Введите, пожалуйста, фамилию'
    ) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    const {
      defaultCity,
      defaultFullName,
      defaultPhone,
      defaultStreet,
      defaultBuilding,
      defaultApartment,
      orderDelivery,
      defaultFirstName,
      defaultLastName,
      profileLastName
    } = this.props;


    const city = ((orderDelivery || {}).geography || {}).city || defaultCity;
    const fullName = (orderDelivery || {}).recipientName || defaultFullName || defaultFirstName;
    const phone = (orderDelivery || {}).recipientPhone || defaultPhone;

    const orderAddress = ((orderDelivery || {}).geography || {}).address || {};
    const address = {
      street: (orderAddress.street || {}).title || defaultStreet,
      building: orderAddress.building || defaultBuilding,
      apartment: orderAddress.apartment || defaultApartment
    };
    const lastName = defaultLastName || profileLastName || "";



    this.setInitialData(city, fullName, phone, address, lastName);
    // this.props.setInitial(city, fullName, phone, address);
    // this.setSelectedDelivery();
  }
  componentDidUpdate() {
    // this.setSelectedDelivery();
  }

  // setSelectedDelivery = () => {
  //   const {selectedDeliveryMethod} = this.props;
  //   const { addRecipient } = this.state;
  //   if(!selectedDeliveryMethod || !selectedDeliveryMethod.name) {
  //     return
  //   }
  //   if(selectedDeliveryMethod.name === 'DELIVERY_METHOD/DELIVERY_TO_BELARUS' ||
  //   selectedDeliveryMethod.name === 'DELIVERY_METHOD/DELIVERY_TO_KAZAKHSTAN') {
  //     if(!addRecipient) {
  //       this.handleAddRecepientChange();
  //     }
  //   }
  // }

  setInitialData(city, fullName, phone, address, lastName) {
    if (city && !city.fiasId) {
      dadata.city(city.title).then(data => {
        if (!data.suggestions.length) {
          this.props.setInitial(city, fullName, phone, address, lastName);
          return;
        }
        const cityItem = data.suggestions[0];
        const cityObj = {
          ...city,
          fiasId: cityItem.data.city_fias_id || cityItem.data.settlement_fias_id
        };

        this.setState({ initialCity: cityObj.title });

        this.props.setInitial(cityObj, fullName, phone, address, lastName);
      });
      return;
    }

    this.props.setInitial(city, fullName, phone, address, lastName);
  }

  showErrors() {
    this.setState({
      showErrors: true
    });
  }

  openPickPointDialog() {
    this.setState({ isPickPointDialogShowed: true });
  }

  closePickPointDialog() {
    this.setState({ isPickPointDialogShowed: false });
  }

  requestPickPoint() {
    const { cityId, selectedDeliveryMethod } = this.props;

    if (!cityId || !cityId.fiasId) {
      return null; // FIXME: pickpoints only for Russia
    }
    this.props.pickPointRequest(cityId.fiasId, selectedDeliveryMethod);
  }

  handleCityChange = city => {
    const { changeCityFiasId, changeCity } = this.props;
    const cityObj = {
      ...city,
      id: null,
      title: city.name || city.title
    };

    if (!city.fiasId) {
      changeCity(city.geonameId, cityObj);
      return;
    }

    changeCityFiasId(city.fiasId, cityObj);
  };

  handleDeliveryMethodChange = deliveryMethod => {
    const { changeDeliveryMethodId } = this.props;

    if (deliveryMethod && deliveryMethod.id) {
      changeDeliveryMethodId(deliveryMethod.id);
    }
  };

  handlePickPointChange = pickPoint => {
    const { changePickPoint } = this.props;
    changePickPoint(pickPoint);
  };

  handleRecipientPhoneChange = value => {
    const { changeRecipientPhone } = this.props;
    // const phone = e.target.value;
    changeRecipientPhone(value);
  };

  handleRecipientNameChange = e => {
    const { changeRecipientName } = this.props;
    // const recipientName = e.target.value;
    const recipientName = e;
    changeRecipientName(recipientName);
  };
  handleRecipientFirstNameChange = ({ target: { value } }) => {
    const { changeRecipientFirstName } = this.props;
    // const recipientName = e.target.value;

    changeRecipientFirstName(value);
  };
  handleRecipientLastNameChange = ({ target: { value } }) => {
    const { changeRecipientLastName } = this.props;
    // const recipientName = e.target.value;
    changeRecipientLastName(value);
  };

  handleAddressChange = address => {
    const { changeAddressId } = this.props;
    changeAddressId(address.id);
  };

  handleApartmentChange = event => {
    const { changeApartment } = this.props;
    const apartment = event.target.value;
    changeApartment(apartment);
  };

  handleBuildingChange = data => {
    if (!data.option) {
      const { changeStreetString } = this.props;
      changeStreetString(data.value);
      return;
    }

    // const { changeStreetFiasId, changeBuilding } = this.props;

    const {
      streetFiasId,
      street,
      streetType,
      house,
      houseType,
      block,
      blockType,
      title
    } = data.option;

    // changeStreetFiasId(streetFiasId);
    // changeBuilding();
    const houseString = house ? `${houseType} ${house}` : '';
    const blockString = block ? `, ${blockType} ${block}` : '';
    this.props.changeStreetAndBuilding({
      streetFiasId,
      street,
      streetType,
      building: `${houseString}${blockString}`,
      title
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      isValid,
      id,
      geography,
      selectedDeliveryMethod,
      selectedPickPoint,
      recipientPhone,
      // recipientName,
      recipientFirstName,
      recipientLastName
    } = this.props;

    const _recipientName = recipientFirstName + ' ' + recipientLastName;

    if (!isValid) {
      this.showErrors();
      return;
    }

    this.props.submitDeliveryInfo(
      id,
      geography.city,
      selectedDeliveryMethod,
      selectedDeliveryMethod.addressRequired ? geography.address : null,
      selectedDeliveryMethod.pickPointRequired ? selectedPickPoint : null,
      selectedDeliveryMethod.phoneRequired ? recipientPhone : null,
      selectedDeliveryMethod.fullNameRequired ? _recipientName : null
    );

    this.props.onComplete();
  };

  handlePickpointDialogShow = () => {
    this.requestPickPoint();
    this.openPickPointDialog();
  };

  handlePickpointDialogClose = () => {
    this.closePickPointDialog();
    this.props.pickPointQueryReset();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFormContainer);


