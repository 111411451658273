import { makeGetIncreasingId } from 'modules/utils';

const startId = 1;

const getIncreasingId = makeGetIncreasingId(startId);

export default function createReply(text) {
  return {
    author: {
      fullName: ''
    },
    content: { text: text },
    createDate: Date.now(),
    id: getIncreasingId()
  };
}
