import { takeEvery, put } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import { queryStringToJSON } from 'modules/utils/get-utm';

import { actions as dialogsActions } from '../ducks/dialogs';

export function* emailConfirmDialogWatcher() {
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}

function* handleLocationChange(action) {
  const { search } = action.payload.location;

  const parameters = queryStringToJSON(search);

  const isSubscriptionSuggestionOpened =
    !!parameters.utm_content && parameters.utm_content === 'doi';

  if (isSubscriptionSuggestionOpened) {
    yield put(dialogsActions.openEmailConfirmDialog());
  }
}
