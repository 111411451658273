import React, { Component } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromProducts from '../ducks/products';
import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromUTMBanner from 'modules/core/ducks/utm-banner';
import * as fromABTestMarkers from 'modules/core/ducks/a-b-test-markers';
import ProductToolbar from '../components/product-toolbar/product-toolbar';
import { VARIANT_OPTION } from '../ducks/products/constants';
import * as fromAccount from 'modules/core/ducks/account';
import { actions as Dialog } from 'modules/core/ducks/dialogs';
import FavoritesAction from 'modules/profile/actions/favorites';

const getDefaultWeight = fromProducts.makeDefaultOptionGetter(VARIANT_OPTION.WEIGHT)(
  fromProducts.getSlugFromProps
);

const mapStateToProps = createStructuredSelector({
  product: fromProducts.getItemBySlug,
  defaultWeight: getDefaultWeight,
  productsAmount: fromBasket.getProductsAmount,
  UTMBannerExist: fromUTMBanner.getIsOpen,
  isProductPageTestVersion: fromABTestMarkers.getIsProductPageTestVersion,
  isAuthorized: fromAccount.getIsAuthorized,
  
});

const mapDispatchToProps = {
  addProductToBasket: fromBasket.actions.addProduct,
  onAuthorizationClick: Dialog.openAuthDialog,
  removeFromFavorites: FavoritesAction.remove,
  addToFavorites: FavoritesAction.add,
};

class ProductToolbarContainer extends Component {
  render() {
    const { product, opened, defaultWeight, productsAmount, UTMBannerExist, isProductPageTestVersion, ...rest } = this.props;

    if (!product || !product.isAvailable) {
      return null;
    }

    return (
      <ProductToolbar
        opened={!UTMBannerExist && opened}
        title={product.title}
        description={product.description}
        media={product.announcementMedia}
        price={product.price}
        defaultWeight={defaultWeight}
        productsAmount={productsAmount}
        amountInBasket={product.amountInBasket}
        onAddToBasket={this.handleAddProductToBasket}
        product={product}
        isABTEst={isProductPageTestVersion}
        {...rest}
      />
    );
  }

  handleAddProductToBasket = () => {
    this.props.addProductToBasket(this.props.product);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductToolbarContainer);
