import React, { Component, Fragment } from 'react';
import Media from 'react-media';

class ResizeMedia extends Component {
  render() {
    const { children } = this.props;

    return (
      <Fragment>
        <Media query="(min-width: 1101px)">{children('desktop')}</Media>

        <Media query="(min-width:841px) and (max-width:1100px)">{children('tablet')}</Media>

        <Media query="(min-width:641px) and (max-width:840px)">{children('mobile')}</Media>

        <Media query="(max-width: 640px)">{children('smallMobile')}</Media>
      </Fragment>
    );
  }
}

export default ResizeMedia;
