import React, { Component, Fragment, useState } from 'react';
// import ReactMedia from 'react-media';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactMedia from 'react-media';

import CountDown from 'modules/core/components/count-down/count-down';
import CountdownTimer from 'modules/core/components/count-down-timer/count-down-timer';

import Media from 'modules/core/components/media/media';
import RouterLink from 'modules/core/components/link/router-link';

import Button from '../button/button';
import PromoCode from '../promo-code/promo-code';

import extractHostname from './helpers/extract-host-name.js';
import getIsCurrentHostName from './helpers/get-is-current-host-name';
import getRelativeLink from './helpers/get-relative-link';

import ConditionalRenderer from 'modules/core/components/conditional-renderer/conditional-renderer';

import './featured-banner.css';

const { shape, string, oneOf, bool } = PropTypes;

// const DESKTOP_BANNER_WIDTH = 830;
// const MOBILE_BANNER_WIDTH = 540;

function isRouterLink(link) {
  return !extractHostname(link);
}

class FeaturedBanner extends Component {
  state = {
    isVideoLoaded: false,
    wasShoved: false
  };

  static propTypes = {
    banner: shape({
      image: string,
      label: string,
      title: string,
      description: string
    }),
    align: oneOf(['left', 'right', 'center']),
    theme: oneOf(['dark', 'light']),
    promo: string,
    withOverlay: bool,
    themeColor: string,
    isInteractive: bool
  };

  static defaultProps = {
    align: 'center',
    theme: 'light',
    withOverlay: false,
    themeColor: '#262931',
    link: '/'
  };

  render() {
    const { banner, promo, withOverlay, themeColor, isCurrent, link, onExpire, index } = this.props;

    const BIRTHDAY_BANNER_ID = 1792832;
    const isBirthdayBanner = BIRTHDAY_BANNER_ID === +banner.id;

    const { isVideoLoaded, wasShoved } = this.state;

    const isCurrentHostName = getIsCurrentHostName(process.env.REACT_APP_HOST, link);

    const finalLink = isCurrentHostName ? getRelativeLink(link) : link;
    const isLinkRelative = isRouterLink(finalLink);

    const buttonText =
      banner.buttonText || isBirthdayBanner
        ? 'Подробнее'
        : banner.id === 1872454
        ? 'К условиям акции'
        : 'купить';

    const bannerStyle = {
      color: themeColor
    };

    if (banner.themeColors && banner.themeColors.background) {
      bannerStyle.backgroundColor = banner.themeColors.background;
    }

    if (!withOverlay) {
      delete bannerStyle.color;
    }

    const textStyle = {};

    if (banner.themeColors && banner.themeColors.text) {
      textStyle.color = banner.themeColors.text;
    }

    return (
      <div
        className={cn('FeaturedBanner', {
          'FeaturedBanner--isVideoLoaded': isVideoLoaded
        })}
        style={bannerStyle}
      >
        <div className="FeaturedBanner-mediaHolder">
          <div className="FeaturedBanner-imageHolder">
            {/* <ReactMedia query={`(max-width: ${MOBILE_BANNER_WIDTH}px`}>
              {isMobile => ( */}
            <Media
              className="FeaturedBanner-media"
              media={banner.image}
              stretch="horizontal"
              // resizeWidth={isMobile ? MOBILE_BANNER_WIDTH : DESKTOP_BANNER_WIDTH}
              background="#fff"
              nocaption
            />
            {/* )}
            </ReactMedia> */}
          </div>
          {wasShoved && banner.video && (
            <div className="FeaturedBanner-videoHolder">
              <Media
                className="FeaturedBanner-media"
                media={banner.video}
                stretch="horizontal"
                nocaption
                noControls
                noButtonPlay
                muted
                active={isCurrent}
                onCanPlay={this.handleCanPlay}
                onLoadedMetadata={this.handleCanPlay}
              />
            </div>
          )}
          {isLinkRelative ? (
            <RouterLink className="FeaturedBanner-mediaLink" href={finalLink}>
              <span className="FeaturedBanner-mediaLinkContent">{buttonText}</span>
            </RouterLink>
          ) : (
            <a
              className="FeaturedBanner-mediaLink"
              href={finalLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="FeaturedBanner-mediaLinkContent">{buttonText}</span>
            </a>
          )}
        </div>
        <div className="FeaturedBanner-contentHolder">
          <div className="FeaturedBanner-content">
            {!!banner.label && (
              <div className="FeaturedBanner-label" style={textStyle}>
                {banner.label}
              </div>
            )}
            {!!banner.title && (
              <h2 className="FeaturedBanner-title" style={textStyle}>
                {banner.title}
              </h2>
            )}
            {!!banner.description && (
              <p className="FeaturedBanner-description" style={textStyle}>
                {banner.description}
              </p>
            )}
            {
              <RenderCountDown
                startDate={banner.startDate}
                endDate={banner.endDate}
                onExpire={onExpire}
                index={index}
              />
            }
            <ReactMedia query="(min-width:840px)">
              <div className="FeaturedBanner-actions" style={textStyle}>
                <Fragment>
                  {promo && (
                    <div className="FeaturedBanner-button">
                      <PromoCode code={promo} expanded />
                    </div>
                  )}

                  <div className="FeaturedBanner-button">
                    <Button
                      title={buttonText}
                      variant="primary"
                      component={isLinkRelative ? RouterLink : 'a'}
                      expanded
                      href={finalLink}
                      target={!isLinkRelative && '_blank'}
                      rel="noreferrer noopener"
                    />
                  </div>
                </Fragment>
              </div>
            </ReactMedia>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { isCurrent } = this.props;
    if (isCurrent) {
      this.setWasShoved();
    }
  }

  componentDidUpdate(prevProps) {
    const { isCurrent } = this.props;
    if (!prevProps.isCurrent && isCurrent) {
      this.setWasShoved();
    }
  }

  setWasShoved() {
    this.setState({
      wasShoved: true
    });
  }

  setIsVideoLoaded() {
    this.setState({
      isVideoLoaded: true
    });
  }

  handleCanPlay = () => {
    this.setIsVideoLoaded();
  };
}

function getMoscowTimeZonedDate(data) {
  const MOSCOW_TIMEZONE = '+0300';
  const isTimeZoned = data.includes('+');
  if (isTimeZoned) {
    return data;
  }
  return data + MOSCOW_TIMEZONE;
}

function RenderCountDown({ index, onExpire, startDate, endDate }) {
  // { startDate, endDate }
  // const startDate = '2020-02-06T00:01:00';
  // const endDate = '2020-03-18T18:10:00';

  const [expiredTime, setExpiredTime] = useState({});
  const [isExpired, setIsExpired] = useState(false);

  const show = Boolean(endDate) && Boolean(startDate);
  // const show = Boolean(endDate);

  if (!show || isExpired) {
    return null;
  }

  const conditionalRendererConfig = {
    show,
    ...(startDate && { startTime: getMoscowTimeZonedDate(startDate) })
  };
  const elseConditionalRendererConfig = {
    show,
    ...(endDate && { startTime: getMoscowTimeZonedDate(endDate) })
  };

  return (
    <div className="FeaturedBanner-countDownHolder">
      {isExpired && show ? (
        <ConditionalRenderer config={elseConditionalRendererConfig}>
          {/* <div className="FeaturedBanner-countDownExpired">Акция закончилась</div> */}
        </ConditionalRenderer>
      ) : (
        <ConditionalRenderer config={conditionalRendererConfig}>
          <div className="FeaturedBanner-countDown">
            <CountDown
              expireTime={expiredTime}
              detalized
              fontSizeInherited
              theme="inBanner"
              fullUnit
            />
            <CountdownTimer expireDate={endDate} onTick={handleTick} onExpire={handleExpire} />
          </div>
        </ConditionalRenderer>
      )}
    </div>
  );
  // {isExpired && show ? (
  //   <ConditionalRenderer config={elseConditionalRendererConfig}>
  //     {/* <div className="FeaturedBanner-countDownExpired">Акция закончилась</div> */}
  //   </ConditionalRenderer>
  // ) : (
  //   <ConditionalRenderer config={conditionalRendererConfig}>
  //     <div className="FeaturedBanner-countDown">
  //       <CountDown
  //         expireTime={expiredTime}
  //         detalized
  //         fontSizeInherited
  //         theme="inBanner"
  //         fullUnit
  //       />
  //       {/* <CountdownTimer expireDate={endDate} onTick={handleTick} onExpire={handleExpire} /> */}
  //     </div>
  //   </ConditionalRenderer>
  // )}

  function handleTick(expiredTime) {
    setExpiredTime(expiredTime);
  }
  function handleExpire() {
    setIsExpired(true);
    onExpire(index);
  }
}

export default withRouter(FeaturedBanner);
