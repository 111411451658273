import { takeEvery, select, put } from 'redux-saga/effects';
// import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'connected-react-router';
// import { replace } from 'connected-react-router';
// import { matchPath } from 'react-router';

// import { getError404Link } from 'routes/links';
// import { dataList2 } from 'modules/landing/pages/configs/new-shops';

// import * as LANDINGS_TYPES from '../ducks/types';
import * as fromNavigations from '../ducks/navigations';

import normalizeNavigation from './helpers/navigation-normalizr';

import mockedData from './helpers/mocked-navigation-data'; // , {navigationConfig2}

export function* watchLandings() {
  // yield takeEvery(LOCATION_CHANGE, handleLocationChange);
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}

function* handleLocationChange() {
  const isLandingDataLoaded = yield select(fromNavigations.getNavigationDataLoaded);
  if (isLandingDataLoaded) {
    return;
  }
  let normalizedFetchedData;
  let rawFetchedData;
  let normalizedMockedData = normalizeNavigation(mockedData);

  // yield delay(2000);

  try {
    rawFetchedData = yield getData();
    // const rawFetchedData = navigationConfig2;
    // const rawData = mockedData;
    normalizedFetchedData = normalizeNavigation(rawFetchedData);
  } catch (e) {
    // yield put(replace(getError404Link()));

    // DON'T DELETE LOG
    console.info('mocked navigation config', { normalizedMockedData, rowData: mockedData });

    yield put(fromNavigations.actions.add(normalizedMockedData));
    yield put(fromNavigations.actions.setLoaded());
    return;
  }
  if (!normalizedFetchedData) {
    // yield put(replace(getError404Link()));

    return;
  }

  const { fresherData, rowData } = getFresherData(
    normalizedFetchedData,
    normalizedMockedData,
    rawFetchedData,
    mockedData
  );

  // DON'T DELETE LOG
  console.info('navigation config', { fresherData, rowData });

  yield put(fromNavigations.actions.add(fresherData));
  yield put(fromNavigations.actions.setLoaded());
}

function getFresherData(fetchedData, mockedData, rawFetchedData, rawMockedData) {
  if (!fetchedData || !fetchedData.options || !fetchedData.options.version) {
    return { fresherData: mockedData, rowData: rawMockedData };
  }

  const isMockedDataFresher = +fetchedData.options.version < +mockedData.options.version;

  if (isMockedDataFresher) {
    return { fresherData: mockedData, rowData: rawMockedData };
  }

  return { fresherData: fetchedData, rowData: rawFetchedData };
}

function* getData() {
  const data = yield fetch('/upload/client-content/navigation_config.json', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(response => response.json());

  return data;
}
