import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromProducts from '../ducks/products';

import PromoProduct from '../components/promo-product/promo-product';

const mapState = createStructuredSelector({
  product: fromProducts.getItemBySlug
});

class PromoProductContainer extends Component {
  render() {
    const { product, ...rest } = this.props;

    if (!product) {
      return null;
    }

    return <PromoProduct product={product} {...rest} />;
  }
}

export default connect(mapState)(PromoProductContainer);
