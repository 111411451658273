import React from 'react';
import { Redirect } from 'react-router-dom';
import { getError404Link } from 'routes/links';

// import { prefix } from 'inline-style-prefixer';

// import Container from 'modules/core/components/container/container';
import OpeningActions from './opening-actions/opening-actions';
import ActionsGift from './actions-gifts/actions-gifts';
// import LandingSectionTitle from './landing-section-title/landing-section-title';
import LandingProducts from './landing-products/landing-products';
import LandingBanner from './landing-banner/landing-banner';
// import LandingBG from './landing-bg/landing-bg';
import Activities from './activities/activities';
import BottomBanner from './bottom-banner/bottom-banner';
import OpeningTimetable from './opening-timetable/opening-timetable';
import OpeningShopsBanner from './opening-shops-banner/opening-shops-banner';
import DesctiptionSection from './desctiption_section/desctiption_section';
import TitleSection from './title-section/title-section';
import PlainText from './plain-text/plain-text';
import Html from './html/html';
import ConditionalRenderer from 'modules/core/components/conditional-renderer/conditional-renderer';
import getNormalizedRenderConditions from 'modules/core/components/conditional-renderer/helpers/get-normalized-render-conditions';

import getContainer from './get-container';

import ProblemSkin_TopBanner from '../problem-skin/top-banner/top-banner';
import ProblemSkin_Reasons from '../problem-skin/reasons/reasons';
import ProblemSkin_AboutCompany from '../problem-skin/about-company/about-company';
import ProblemSkinProductPlusGift from '../problem-skin/product-plus-gift/product-plus-gift';
import ProblemSkin_Form from '../problem-skin/form/form';
import ProblemSkin_VideoSection from '../problem-skin/video-section/video-section';
import ProblemSkinProducts from '../problem-skin/products/products';
import ProblemSkin_Solutions from '../problem-skin/solutions/solutions';
import ProblemSkin_Slider from '../problem-skin/slider/slider';

import './landing.css';

const components = {
  top_banner: LandingBanner,
  opening_shops_banner: OpeningShopsBanner,
  bottom_banner: BottomBanner,
  gifts: ActionsGift,
  rules: OpeningActions,
  group: LandingProducts,
  opening_timetable: OpeningTimetable,
  activity: Activities,
  bottom_info: ButtomInfo,
  desctiption_section: DesctiptionSection,
  title_section: TitleSection,
  plain_text: PlainText,
  html: Html,
  
  // problem-skin
  'problem-skin_top-banner': ProblemSkin_TopBanner,
  'problem-skin_reasons': ProblemSkin_Reasons,
  "problem-skin_about-company": ProblemSkin_AboutCompany,
  "problem-skin_product-plus-gift": (props) => <ProblemSkinProductPlusGift {...props} />,
  "problem-skin_form": ProblemSkin_Form,
  "problem-skin_video": ProblemSkin_VideoSection,
  "problem-skin_products": ProblemSkinProducts,
  "problem-skin_solutions": ProblemSkin_Solutions,
  "problem-skin_slider": ProblemSkin_Slider,
};

function Landing({ data }) {
  if (!data) {
    return null;
  }

  const redirectTo = data.redirect_to || getError404Link()

  const landingRenderConditions = {...getNormalizedRenderConditions(data.render_conditions),
    elseRender: <Redirect to={redirectTo} />
  }

  return (
    <ConditionalRenderer  config={landingRenderConditions} >
      <div className="Landing">
        {/* {renderBG()} */}
        {renderContent()}
      </div>
    </ConditionalRenderer>
  );

  // function renderBG() {
  //   const landingStyle = { background: data.background && data.background.gradient && data.background.gradient };
  //   const bgsCounter = new Array(15).fill('');

  //   return (
  //     <div className="Landing-BG" style={landingStyle}>
  //       {bgsCounter.map((_, index) => (
  //         <LandingBG key={index} style={data.background.style} />
  //       ))}
  //     </div>
  //   );
  // }

  function renderContent() {
    return <div className="Landing-content">{renderComponentsByType(data)}</div>;
  }

  function normalizeComponentData(data) {
    const { type = null, show = true, hasContainer = false } = data;
    return { type, show, hasContainer, ...data };
  }

  function renderComponentsByType(data) {
    return data.components.map((componentProps, index) => {
      const { colors, light } = data;

      const _componentProps = normalizeComponentData(componentProps);

      if (!_componentProps.type) {
        return null;
      }
      const componentType = _componentProps.type.toLowerCase().trim();
      const Component = components[componentType];
      if (typeof Component === 'undefined') {
        return null;
      }

      if (!_componentProps.show) {
        return null;
      }

      const [Container, containerProps] = getContainer(_componentProps);

      const conditionalConfig = {
        elseRender: null,
        ...getNormalizedRenderConditions(_componentProps.render_conditions)
      };
      const initialWraperStyle = {
        backgroundPosition:"center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }

      return (
        <ConditionalRenderer key={index} config={conditionalConfig}>
          <div style={{...initialWraperStyle, ..._componentProps.wraper_style}}>
            <Container {...containerProps}>
              <Component data={_componentProps} totalData={data} colors={colors} light={light} />
            </Container>
          </div>
        </ConditionalRenderer>
      );
    });
  }
}

function ButtomInfo({ data }) {
  return <div className="Landing-bottomInfo">{data.text}</div>;
}

export default Landing;
