import * as TYPES from '../types';
import { createAction } from 'modules/utils/dux';

export default {
  add(data) {
    return createAction(TYPES.ADD, {
      data
    });
  },
  addList(list) {
    return createAction(TYPES.ADD_LIST, {
      list
    });
  },
  setLoaded() {
    return createAction(TYPES.SET_LOADED);
  },
  requestLendingProducts(slug) {
    return createAction(TYPES.REQUEST_LANDING_PRODUCTS, {slug});
  },
  responseLendingProducts() {
    return createAction(TYPES.RESPONSE_LANDING_PRODUCTS);
  },
};
