import React, { Component } from 'react';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconFlash } from 'modules/core/components/icons/index';

import dateString from 'modules/utils/date-string';
import getAmountString from './helpers/get-amount-string';

import './bonus-deadline.css';

class Deadline extends Component {
  render() {
    const { deadline } = this.props;

    const expireDate = new Date(deadline.expire);
    const formatedDate = dateString(deadline.expire, true);

    const amountString = getAmountString(deadline.value);

    return (
      <div className="BonusDeadline">
        <div className="BonusDeadline-icon">
          <IconContainer inline>
            <IconFlash />
          </IconContainer>
        </div>

        <div className="BonusDeadline-info">
          <span className="BonusDeadline-amount">{amountString}</span>

          <time className="BonusDeadline-time" dateTime={expireDate}>
            {formatedDate}
          </time>
        </div>
      </div>
    );
  }
}

export default Deadline;
