import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './user-bonuses.css';

const { number } = PropTypes;

class UserBonuses extends Component {
  static propTypes = {
    defaultAmount: number.isRequired,
    extraAmount: number.isReuquired
  };

  render() {
    const { defaultAmount, extraAmount } = this.props;

    return (
      <div className="UserBonuses">
        <div className="UserBonuses-item UserBonuses-item--type-default">
          <span className="UserBonuses-itemTitle">Бонусов на карте: </span>
          <span className="UserBonuses-itemAmount">{defaultAmount}</span>
        </div>
        <div className="UserBonuses-item UserBonuses-item--type-extra">
          <span className="UserBonuses-itemTitle">Экстрабонусов: </span>
          <span className="UserBonuses-itemAmount">{extraAmount}</span>
        </div>
      </div>
    );
  }
}

export default UserBonuses;
