export function normalizeAll(apiAllBonuses) {
  if (!apiAllBonuses) {
    return {};
  }

  const { id, is_activated, summary, bonuses, transactions } = apiAllBonuses;

  const normalizedTransactions = _normalizeTransactions(transactions);
  const normalizedBonuses = _normalizeBonuses(bonuses);

  return {
    id,
    isActivated: is_activated,
    summary,
    bonuses: normalizedBonuses,
    transactions: normalizedTransactions
  };
}

export function normalizeTransactions(apiTransactions) {
  if (!apiTransactions) {
    return {};
  }

  const { transactions, total_amount } = apiTransactions;

  const normalizedTransactions = _normalizeTransactions(transactions);

  return {
    transactions: normalizedTransactions,
    totalTransactionAmount: total_amount
  };
}

function _normalizeTransactions(transactions) {
  return transactions.map(({ id, date, title, income, expense, order_total, order_id }) => {
    return {
      id,
      date,
      title,
      income,
      expense: expense || null,
      orderTotal: order_total,
      orderId: order_id
    };
  });
}

function _normalizeBonuses(bonuses) {
  return bonuses.map(bonus => {
    if (!bonus.expire) {
      return { ...bonus, expire: null };
    }

    return bonus;
  });
}
