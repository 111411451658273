import React, { Component } from 'react';
import PropTypes from 'prop-types';

import isFunction from 'modules/utils/is-function';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import IconStar from 'modules/core/components/icons/icon-star/icon-star';

import './input-star-rating.css';

const { number, func } = PropTypes;

class InputStarRating extends Component {
  static propTypes = {
    activeIndex: number,
    starsAmount: number,
    onClick: func,
    onHover: func,
    onMouseOut: func
  };

  static defaultProps = {
    activeIndex: -1,
    starsAmount: 5
  };
  render() {
    const { activeIndex, starsAmount, onMouseOut } = this.props;
    const starList = [...Array(starsAmount)];

    return (
      <div className="InputStarRating">
        <ul className="InputStarRating-holder">
          {starList.map((_, index) => (
            <li
              key={index}
              className="InputStarRating-item"
              onMouseEnter={this.handleMouseOver(index)}
              onMouseLeave={onMouseOut}
            >
              <button
                type="button"
                className="InputStarRating-button"
                onClick={this.handleClick(index)}
                data-testid="star-button"
              >
                <IconContainer size="medium">
                  {activeIndex >= index ? (
                    <IconStar fill="FULL" key="full" />
                  ) : (
                    <IconStar key="stroke" />
                  )}
                </IconContainer>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  handleClick = index => () => {
    const { onClick } = this.props;

    if (!isFunction(onClick)) {
      return;
    }

    onClick(index);
  };

  handleMouseOver = index => () => {
    const { onHover, activeIndex } = this.props;

    if (!isFunction(onHover)) {
      return;
    }

    if (activeIndex === index) {
      return;
    }
    onHover(index);
  };
}

export default InputStarRating;
