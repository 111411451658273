import React, { Component } from 'react';
import ReactMedia from 'react-media';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { getSpecialOfferLink } from 'routes/links';

import PromoCodeGroup from '../promo-code-with-button/promo-code-with-button';
import { IconClock } from '../icons';
import IconContainer from '../icon-container/icon-container';
import Media from '../media/media';

import ResizeMedia from './media';

import './special-offer-preview.css';

const PROOMO_CODE_QUERY = {
  desktop: 'normal',
  tablet: 'small',
  mobile: 'normal',
  smallMobile: 'small'
};

const APOLOGIES_TOAST_ID = 'apply_special_order';

class SpecialOfferPreview extends Component {
  render() {
    const {
      expireCaption,
      status,
      title,
      description,
      media,
      promocode,
      isPromocodeExists,
      id
    } = this.props;

    return (
      <div className={cn('SpecialOfferPreview', `SpecialOfferPreview--status-${status}`)}>
        <div className="SpecialOfferPreview-media">
          <RouterLink to={getSpecialOfferLink(id)}>
            <Media media={media} stretch="horizontal" nocaption />
          </RouterLink>
        </div>

        <div className="SpecialOfferPreview-content">
          <h3 className="SpecialOfferPreview-title">
            <RouterLink to={getSpecialOfferLink(id)} className="SpecialOfferPreview-link">
              {title}
            </RouterLink>
          </h3>

          <RouterLink to={getSpecialOfferLink(id)} className="SpecialOfferPreview-link">
            <div className="SpecialOfferPreview-description">{description}</div>
          </RouterLink>

          {isPromocodeExists && (
            <div className="SpecialOfferPreview-promoCodeGroup">
              <ResizeMedia>
                {query => (
                  <PromoCodeGroup
                    code={promocode}
                    size={PROOMO_CODE_QUERY[query]}
                    variant={'apply'}
                    applyPromocode={this.createToast}
                  />
                )}
              </ResizeMedia>
            </div>
          )}

          {expireCaption && (
            <div className="SpecialOfferPreview-expireGroup">
              <div className="SpecialOfferPreview-expireIcon">
                <ReactMedia query="(max-width: 640px)">
                  {isLowMobile => (
                    <IconContainer size={isLowMobile ? 'small' : 'medium'}>
                      <IconClock />
                    </IconContainer>
                  )}
                </ReactMedia>
              </div>
                <div className="SpecialOfferPreview-expireCaption">{expireCaption}</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  createToast = () => {
    const { apologiesToastCreate } = this.props;

    let toastOptions = {
      id: APOLOGIES_TOAST_ID,
      state: 'loading',
      delay: false,
      content:
        'Промокод применен! Теперь когда ты перейдешь в корзину, стоимость покупки будет пересчитана с учетом примененного промокода',
      actionList: [
        {
          title: 'ок',
          type: 'CLOSE_TOAST',
          variant: 'primary'
        }
      ]
    };

    apologiesToastCreate({
      toast: toastOptions,
      onActionTrigger: this.handleToastAction
    });
  };

  handleToastAction = action => {
    const { apologiesToastClose } = this.props;
    if (action.type === 'CLOSE_TOAST') {
      apologiesToastClose(APOLOGIES_TOAST_ID);
    }
  };

  componentWillUnmount() {
    const { apologiesToastClose } = this.props;

    if (Boolean(apologiesToastClose)) {
      apologiesToastClose(APOLOGIES_TOAST_ID);
    }
  }
}

export default SpecialOfferPreview;
