export default [
  {
    title: 'ОБЩИЕ ПОЛОЖЕНИЯ',
    list: [
      `В соответствии с п. 2 ст. 437 Гражданского Кодекса Российской Федерации (ГК РФ) содержащее
      все существенные условия договора предложение, из которого усматривается воля лица, делающего
      предложение, заключить договор на указанных в предложении условиях с любым, кто отзовется,
      признается офертой (публичной офертой), а совершение лицом, получившим оферту, действий по
      выполнению указанных в ней условий договора считается заключением договора лицом,
      совершившим указанное действие, с лицом, сделавшим соответствующее предложение.`,

      `Настоящий документ является публичной офертой, т.е. предложением Продавца о заключении с
      любым физическим лицом договора розничной купли-продажи товаров на основании ознакомления
      сописанием товара, представленного на Сайте или в Приложении.`,

      `Обязательства продавца по передаче товара и иные обязательства, связанные с передачей
      товара, возникают с момента получения продавцом соответствующего сообщения покупателя о
      намерении заключить договор.`,

      `Лицо, согласное с изложенными ниже условиями настоящей Оферты и всехеё приложений, имеет
      возможность заключить с Продавцом договор розничной купли-продажи Товара, при этом в
      соответствии с пунктом 3 статьи 438 ГК РФ и п. 20 Правил дистанционной продажи, моментом
      заключения указанным лицом договора купли-продажи с Продавцом на условиях, изложенных в
      настоящей Оферте, будет являться момент получения Продавцом сообщения о намерении покупателя
      приобрести товар.
      <br />
      <b>Учитывая важность вышеизложенного, лицу, заинтересованному в покупке товара на условиях,
      зафиксированных в настоящей Оферте, настоятельно рекомендуется внимательно ознакомиться с
      ее текстом, и в случае несогласия с каким-либо её пунктом, предлагается отказаться от покупки
      товаров или использования услуг, предоставляемых Продавцом.</b>`,
      {
        title:
          `В настоящей оферте, если контекст не требует иного, нижеприведенные термины имеют
          следующие значения:`,
        list: [
          `«Продавец» — 000 «Миксит», осуществляющее свою деятельность под товарным знаком
          «Mixit» и реализующее товар, описание которого представлено на Сайте/в Приложении.`,

          `«Оферта» - настоящий документ, являющийся публичным предложением Продавца,
          адресованное любому физическому лицу, о заключении договора розничной купли-продажи
          товара (далее - "Договор") на условиях, содержащихся в Оферте, включая все её приложения`,

          `«Покупатель» - физическое лицо, заключившее с Продавцом Договор на условиях,
          содержащихся в Оферте`,

          `«Потребитель»  дееспособное физическое лицо, которому адресована настоящая Оферта.`,

          `«Товар» - любая продукция под товарным знаком «МИХ», ассортиментный перечень которой
          представлен на Сайте/в Приложении.`,

          `«Акцепт» - полное и безоговорочное принятие Покупателем условий Оферты,
          подтверждающееся передачей покупателем Продавцу сообщения о намерении приобрести
          Товар и подтверждение Продавцом факта получения сообщения Покупателя способом,
          зафиксированным в Оферте.`,

          `«Торговая сеть»/ «Магазин» — магазины, работающие под товарным знаком «МТ» и
          перечисленные на Сайте.`,

          `«Заказ» — действия Покупателя, совершаемые им способом, предусмотренным настоящей
          Офертой, с целью приобретения отдельных позиций Товара, заключающиеся в передаче
          Продавцу сообщения о намерении приобрести Товар.`,

          `«Доставка» - услуга по доставке Заказа Покупателю силами Продавца или Курьерской службой.`,

          `«Курьерская служба» - третье лицо, определяемое Продавцом и предоставляющее
          Покупателю услуги по доставке Товара.`,

          `«Пункт выдачи» - автоматизированный терминал выдачи Товаров, заказанных в рамках
          Оферты, либо отделение Почты России, либо иной пункт, в котором возможно получение
          Заказа, в том числе Магазин.`,

          `«Сайт» - https://mixit.ru`,

          `«Приложение» — программа для ЭВМ, работающая на операционной системе 10$, под
          названием «МИЙ», основной функцией которой является ознакомление Потребителей с
          каталогом Товаров, возможность приобретения которых предоставлена с помощью
          Приложения, а также оформление заказов на приобретение доступных Товаров в соответствии
          с настоящей Офертой и ознакомление с информацией о новинках, акциях и специальных
          предложениях.`,

          `«Подарочный сертификат» - неименной документ, выпускаемый в электронной форме -
          «Виртуальный Подарочный сертификат», «ВПС», содержащий уникальные реквизиты (номер),
          удостоверяющий факт внесения денежных средств в размере Номинала.`,

          `«Карта» — банковская карта, эмитированная кредитной организацией, позволяющая
          Покупателю совершать с ее помощью операций с денежными средствами, находящимися у
          эмитировавшей Карту кредитной организации, в соответствии с законодательством
          Российской Федерации и договором с кредитной организацией, в том числе операции по
          оплате Товаров, приобретаемых у Продавца на основании Договора.
          `,

          `«Правила дистанционной продажи» - Правил продажи товаров дистанционным способом,
          утвержденные постановлением Правительства Российской Федерации от 27 сентября 2007
          года № 612.`,

          `«Код» - набор символов, высылаемый Продавцом Покупателю на указанный при оформлении
          Заказа номер мобильного телефона. Код является простой электронной подписью Покупателя
          в соответствии с п.2 ст, 5 Федерального закона от 06.04.2011г. № 63-ФЗ «Об электронной
          подписи», которая используется при подписании согласия Покупателя на обработку его
          персональных данных.`,

          `«Колл-центр» — центр поддержки, через который Продавец осуществляет круглосуточное
          информационно-справочное обслуживание Потребителей/Покупателей.`,

          `«Мессенджер» - программа (приложение) для смартфона или персонального компьютера,
          позволяющая мгновенно обмениваться текстовыми сообщениями.`
        ],
        unordered: true
      },
      `Срок действия Оферты в отношении конкретных, отдельных позиций Товара из ассортиментного
      перечня Товара, представленного на Сайте или в Приложении, может быть ограничен сроком наличия
      соответствующих позиций на складе Продавца или условиями настоящей Оферты.`
    ]
  },
  {
    title: 'ПРЕДМЕТ ДОГОВОРА',
    list: [
      `Продавец обязуется продать, а Покупатель обязуется принять и оплатить Товар по ценам,
      указанным в описании Товара на соответствующей странице Сайта /в Приложении на условиях
      настоящей Оферты, действующая редакция которой размещена на Сайте.`
    ]
  },
  {
    title: 'ОФОРМЛЕНИЕ ЗАКАЗА',
    list: [
      {
        title: 'Оформление Заказа Товара осуществляется Покупателем следующими способами:',
        list: [
          'с использованием Сайта;',
          'путем совершения Покупателем исходящего вызова по телефонному номеру Колл-центра: 8(800) 550-98-70;',
          'с помощью Приложения;',
          'с помощью Мессенджеров WhatsApp, Instagram, Viber.'
        ]
      },
      {
        title: 'При оформлении Заказа Покупатель обязуется указать следующие данные:',
        list: [
          'имя, фамилия;',
          'контактный телефон и адрес электронной почты;',
          'способ доставки Заказа;',
          'адрес доставки Заказа (если доставка Заказа производится курьером) или Пункт выдачи;',
          'способ оплаты Заказа.'
        ]
      },
      {
        title:
          `Оформленный Покупателем Заказ является сообщением Покупателя о намерении приобрести
          Товар. Факт оформления Покупателем Заказа и передачи Продавцу сообщения о намерении
          приобрести Товар подтверждается одним из следующих способов:`,
        list: [
          `совершение Продавцом через Колл-центр исходящего вызова по контактному телефону,
          указанному при оформлении Заказа, и последующего подтверждения Покупателем сведений,
          сообщенных Покупателем при оформлении Заказа (п. 3.2. настоящей оферты), а также уточнения
          условий Доставки;`,

          `направлением Продавцом смс-сообщения Покупателю с информацией об оформлении Заказа
          и последующего подтверждения Покупателем сведений, сообщенных Покупателем при оформлении
          Заказа (п. 3.2. настоящей оферты), а также уточнения условий Доставки.`
        ]
      },

      `В случае выбора Покупателем предварительной оплаты Товара, Покупатель в сроки и порядке,
      установленные разделом 4 настоящей Оферты, производит предоплату Продавцу суммы стоимости
      Товаров, входящих в Заказ.`,

      {
        title:
          `В случае не осуществления Покупателем предоплаты суммы Заказа в установленный пунктом 3.4.
          Оферты срок, данная неоплата будет признаваться Продавцом как односторонний отказ Покупателя
          от исполнения настоящей Оферты, что будет означать расторжение договора купли-продажи Товаров,
          заключенного между Покупателем и Продавцом, в одностороннем порядке (по инициативе
          Покупателя) и прекращать:`,
        list: [
          `обязанность Продавца перед Покупателем продать последнему Товара на условиях
          предоплаты;`,

          `право требования Покупателя к Продавцу о продаже Покупателю Товара на условиях
          предоплаты.`
        ],
        unordered: true
      },

      `Покупатель самостоятельно несёт ответственность за содержание и достоверность
      предоставленных при оформлении Заказа данньх.`,

      `Все информационные материалы, представленные на Сайте Продавца, носят справочный
      характер и не могут в полной мере передавать достоверную информацию об определенных свойствах
      и характеристиках Товара, таких как: цвет, форма, размер и упаковка. В случае возникновения у
      Покупателя вопросов, касающихся свойств и характеристик Товара, перед оформлением Заказа
      Покупателю необходимо обратиться за консультацией по телефону Колл-центра.`,

      `Покупатель дает согласие Продавцу на обработку Продавцом персональных данных Покупателя
      в соответствии с Федеральным законом № 152-Ф3 «О персональных данных». Согласие дается путем
      совершения Покупателем конклюдентных действий в момент нажатия Покупателем «кнопки С
      условиями Оферты на покупку товара ознакомлен и согласен» при оформлении Заказа на Сайте /в
      Приложении в целях исполнения Продавцом Договора, заключенного с Покупателем. Данное условие
      распространяется на информацию, передаваемую Продавцу Покупателем при оформлении Заказа (п.
      3.2 Оферты), а также банковские реквизиты при оплате Заказа на Сайте (далее — «Персональные
      данные»).
      <br/>
      Согласие предоставляется на осуществление Продавцом в целях, предусмотренных настоящей
      Офертой, действий в отношении Персональных данных Покупателя включая: сбор, систематизацию,
      накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том
      числе передача), обезличивание, блокирование, уничтожение Персональных данных, а также для
      осуществления Продавцом контактов с Покупателем, в том числе, в целях продвижения товаров,
      работ, услуг на рынке (с использованием, включая, но не ограничиваясь: зтз-сервисов, электронной
      почты, почтовой и телефонной связи) с учетом требований действующего законодательства
      Российской Федерации.
      <br/>
      Предоставленное Покупателем согласие на обработку персональных данных действует:
      <br/>
      1) до даты исполнения Продавцом Договора (вручения Товара Покупателю),
      <br/>
      2) или до отзыва Покупателем такого согласия в простой письменной форме в адрес Продавца.
      <br />
      Продавец обязуется прекратить обработку персональных данных Покупателя и уничтожить
      Персональные данные в срок, не превышающий 30 (тридцати) дней с даты:
      <br />
      1) исполнения Продавцом Договора (вручения Покупателю Товара),
      <br />
      2) получения от Покупателя отзыва согласия.
      `,
    ]
  },
  {
    title: 'ОПЛАТА ЗАКАЗА',
    list: [
      {
        title:
          `Исполнение Покупателем обязанности по оплате Товаров, входящих в Заказ, возможно
          следующими способами:`,
        list: [
          `Оплата наличными денежными средствами или с помощью Карты Курьерской службе в случае
          осуществления Доставки Товара по адресу, указанному Покупателю.`,

          `Путем осуществления перевода денежных средств в соответствии с требованиями
          действующего законодательства Российской Федерации на основании реквизитов, сообщенных
          Продавцом Покупателю путем электронной почты или на Сайте Продавца.`,

          `Путем оплаты стоимости Заказа на Сайте Продавца (Банковской картой, с использованием
            карты программы лояльности «Mixit».)`,

          `Путем оплаты стоимости Заказа полностью либо в части с использованием Подарочного
          сертификата`
        ]
      },

      `При выборе Покупателем в качестве способа Доставки получение Заказа в Пункте выдачи оплата
        возможно только в порядке предоплаты на Сайте Продавца.`,

      `В случае выбора Покупателем предварительной оплаты Товара, обязанность по оплате Товара
      должна быть исполнена в срок не более чем 24 (двадцать четыре) часа с момента получения
      Покупателем сообщения от Продавца для проведения предоплаты.`,

      `Цена на каждую единицу Товара/стоимость Доставки указывается Продавцом при оформлении
      Заказа и дополнительно сообщается Покупателю одним из способов, указанных в п. 3.3. настоящей
      Оферты.`,

      `Продавец вправе в любой момент изменить цену Товара, указанную на Сайте/ в Приложении, без
      уведомления Покупателя, при этом сумма денежных средств, подлежащая уплате Продавцу за
      Товары, входящие в Заказ, определяется на основании цены товара, указанной на Сайте/ в
      Приложении в момент заключения договора розничной купли-продажи (п. 1.3. настоящей Оферты).`,

      `Обязанность Продавца по передаче Покупателю Товаров, входящих в Заказ, возникает только
      после заключения между Покупателем и Продавцом договора купли-продажи Товаров, момент
      заключения которого определяется в соответствии с пунктом 1.3 настоящей Оферты`
    ]
  },
  {
    title: 'ДОСТАВКА ТОВАРА ПОКУПАТЕЛЮ. ВОЗВРАТ ИЛИ ОТКАЗ ОТ ЗАКАЗАНОГО(ДОСТАВЛЕНОГО) ТОВАРА',
    list: [
      `Условия осуществления Доставки Товара, а именно срок осуществления Доставки и ее стоимость,
      устанавливаются Продавцом и доводятся до сведения Покупателя одним из способов, указанных в п.
      3.3. настоящей Оферты.`,

      `Покупатель вправе выбрать в качестве способа получения Заказа путем самовывоза его из
      выбранного Пункта выдачи согласно списка, представленного на Сайте, При выборе в качестве Пункта
      выдачи Магазин Заказ можно получить в Магазинах только на территории г.Москва в соответствии с
      информацией, представленной на Сайте.`,

      `Покупатель вправе отказаться от Товара в любое время до передачи Товара Покупателю, а также
      при получении Товара, но до момента вскрытия упаковки Товара Покупателем.
      <br />
      В таких случаях, Продавец должен возвратить Покупателю сумму, уплаченную Покупателем за Заказ
      (для предоплаченных Заказов), за исключением расходов Продавца на доставку Покупателю и от
      Покупателя возвращенного товара, не позднее чем через 10 дней с даты предъявления Покупателем.
      соответствующего требования.`,

      `В соответствии с Постановлением Правительства Российской Федерации от 19 января 1998 г. №55
      парфюмерно-косметическая продукция надлежащего качества не подлежат возврату или замене.`,

      `В случае отказа Покупателя от Товара по основаниям, предусмотренным действующим
      законодательством Российской Федерации, в связи с выявлением недостатков Товара, Продавец
      осуществляет возврат Покупателю стоимости возвращаемого Товара (с учетом всех предоставленных
      скидок, в том числе, скидки, полученной Покупателем при оплате с использованием карты программы
      лояльности «Mixit»), а Покупатель обязан вернуть Продавцу Товар, в отношении которого заявлен
      отказ в соответствии с настоящим пунктом.
      <br />
      При наличии у Продавца сомнений относительно причины возникновения заявленных Покупателем
      недостатков Товара, Продавец вправе запросить у Покупателя Товар, в отношении которого заявлен
      отказ, и провести экспертизу такого Товара с привлечением специализированной организации. Если
      Покупатель отказывает Продавцу предоставить Товар для проведения экспертизы, Продавец вправе
      не удовлетворять требования Покупателя при наличии сомнений относительно причин возникновения
      недостатков.<br />
      Если по результатам экспертизы будет подтверждено отсутствие вины Продавца в возникновении
      заявленных недостатков, Продавец вправе по своему усмотрению:
      <ul>
        <li>отказать Покупателю в удовлетворении заявленного отказа от Товара и возврата уплаченных
        денежных средств, а также потребовать компенсации стоимости проведенной экспертизы;</li>

        <li>удовлетворить требования Покупателя в части возврата денежных средств.</li>
      <ul>
      Если по результатам экспертизы будет подтверждена вина Продавца в возникновении заявленных
      недостатков, Продавец обязан удовлетворить заявленные требования Покупателя о возврате
      стоимости некачественного Товара.`,

      `При возврате предоплаты, уплаченной Покупателем в безналичном порядке, банковские
      комиссии, уплаченные Покупателем в этой связи, Продавцом не компенсируются.`,

      `После оформления Заказа и внесения Покупателем информации указанной в п. 3.2 настоящей
      оферты, изменение адреса, времени и иные изменения касающиеся Доставки Товара осуществляются
      Покупателем по согласованию с Курьерской службой самостоятельно и за счет Покупателя в полном
      объеме.
      <br />
      В том случае если Товар был доставлен Курьерской службой по времени и адресу, указанному
      Покупателем, но не был передан Покупателю по вине Покупателя, последующая/ие Доставка/и будут
      осуществлены за счет Покупателя в полном объёме.`
    ]
  },

  {
    title: 'АВТОРСКИЕ ПРАВА',
    list: [
      `Вся текстовая информация и графические изображения, размещенные на Сайте Продавца,
      являются собственностью Продавца и/или его поставщиков и производителей Товара.`
    ]
  },

  {
    title: 'ОТВЕТСТВЕННОСТЬ ПРОДАВЦА',
    list: [
      `Продавец не несет ответственности за ненадлежащее использование Покупателем товаров,
      купленных по Договору, а также за индивидуальную непереносимость того или иного компонента в
      составе Товара и/или аллергические реакции в результате использования Товара.`,

      `Продавец вправе передавать свои права и обязанности по исполнению Заказа Покупателя
      третьим лицам, оставаясь ответственным перед Покупателем за предоставление заказанного
      Покупателем Товара.`,

      `Продавец имеет право на осуществление записи телефонных переговоров с Покупателем. В
      соответствии с п.4 ст.16 Федерального закона "Об информации, информационных технологиях и о
      защите информации" Продавец обязуется предотвращать попытки несанкционированного доступа к
      информации о Покупателе и/или передачу ее лицам, не имеющим непосредственного отношения к
      исполнению Заказов, а также своевременно обнаруживать и пресекать такие попытки.`,

      `Право собственности на указанный в Заказе Товар, а также риск его случайной гибели или
      повреждения переходят к Покупателю с момента подписания Покупателем акта приема-
      передачи/накладной на передачу ему указанного в Заказе Товара.`,

      `Продавец освобождается от ответственности за нарушение условий Доставки в случае сообщения
      Покупателем при оформлении Заказа недостоверных данных о себе (п. 3.2. настоящей Оферты)
      `
    ]
  },
  {
    title: 'ПРЕТЕНЗИИ ПОКУПАТЕЛЯ',
    list: [
      'Претензии по ненадлежащему исполнению Продавцом Договора Покупатель вправе сообщить по телефону Колл-центра или по электронной почте hello@mixit.ru',
      'Претензии Покупателей рассматриваются Продавцом в порядке и сроки, установленные действующим законодательством Российской Федерации.'
    ]
  }
];
