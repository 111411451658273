import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Draw from '../ducks/actions/draw-roll-up';
import * as fromDraw from '../ducks/draw-roll-up';

import DrawRollUp from '../components/draw-roll-up/draw-roll-up';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDraw.getIsOpened
});

const mapDispatchToProps = {
  close: Draw.close
};

const DELAY = 1000;

class DrawRollUpContainer extends Component {
  state = {
    isDelayOpened: false
  };
  render() {
    const { isDelayOpened } = this.state;

    return <DrawRollUp onClose={this.handleClose} opened={isDelayOpened} />;
  }

  componentDidMount() {
    setTimeout(() => this.open(), DELAY);
  }

  open = () => {
    const { isOpened } = this.props;

    if (!isOpened) {
      return;
    }

    this.setState({ isDelayOpened: true });
  };

  handleClose = () => {
    const { close } = this.props;

    this.setState({ isDelayOpened: false });
    close();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawRollUpContainer);
