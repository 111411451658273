import { combineReducers } from 'redux';

import deliveryAddresses from './delivery-addresses';
import profile from './profile';
import subscriptions from './subscriptions';
import favoritesFilter from './favorites-filter';
import physicalTraits from './physical-traits';
import bonuses from './bonuses';
import passwordChange from './password-change';

export default combineReducers({
  deliveryAddresses,
  profile,
  subscriptions,
  favoritesFilter,
  physicalTraits,
  bonuses,
  passwordChange
});
