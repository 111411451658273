import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

import * as fromOrders from 'modules/ordering/ducks/orders';
import { actions as Orders } from 'modules/ordering/ducks/orders';

import TransactionOrderDetails from '../components/bonus-history-table/order-details/order-details';

const mapStateToProps = createStructuredSelector({
  goodsList: fromOrders.getGoodsListById,
  isLoading: fromOrders.getIsLoadingById,
  isError: fromOrders.getIsErrorById
});

const mapDispatchToProps = {
  show: Orders.show
};

const { number, bool, array } = PropTypes;

class TransactionOrderDetailsContainer extends Component {
  static propTypes = {
    id: number, // Order id
    total: number, // Order total price
    goodsList: array,
    isLoading: bool,
    isError: bool
  };

  render() {
    const { ...rest } = this.props;

    return <TransactionOrderDetails {...rest} />;
  }

  componentDidMount() {
    const { id, show } = this.props;
    show(id);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionOrderDetailsContainer);
