import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Container from 'modules/core/components/container/container';

import FeedbackFormContainer from '../../containers/feedback-form-container';

import './section-feedback-form.css';

const { func, bool } = PropTypes;

class SectionFeedbackForm extends Component {
  static propTypes = {
    onChange: func,
    withoutContainer: bool
  };

  render() {
    const { withoutContainer, withTitle, ...rest } = this.props;
    return (
      <section className="SectionFeedbackForm">
        {withTitle && <h2 className="SectionFeedbackForm-title">Оставить отзыв о товаре</h2>}
        {withoutContainer ? (
          <FeedbackFormContainer {...rest} />
        ) : (
          <Container narrow>
            <FeedbackFormContainer {...rest} />
          </Container>
        )}
      </section>
    );
  }
}

export default SectionFeedbackForm;
