import React from 'react';
import { withRouter } from 'react-router';

import ButtonAddToBasket from 'modules/product/components/product/button-add-to-basket.jsx';
import { ToastConsumer } from 'modules/core/containers/toast-stack';
import Media from 'react-media';
import { getBasketLink } from 'routes/links';

function AddToBasketWithToastConsumer ({product, history, addProductToBasket, ...rest}){
    return (
        <Media query="(max-width: 840px)">
            {isMobile => (
            <ToastConsumer>
                {({ create, close }) => (
                    <ButtonAddToBasket 
                        expanded={false} 
                        size="medium" 
                        variant="black" 
                        title="Добавить в корзину"  
                        onClick={handleClick(create, close, isMobile)} 
                        {...rest}
                        />
                )}
            </ToastConsumer>
            )}
        </Media>
    )

    function   handleToastAction (action) {
        if (action.type === 'ACTION_GO_BASKET') {
          history.push(getBasketLink());
        }
      };

    function   createToast(isDesktop, create) {
        const delay = isDesktop ? 3000 : 1500;
    
        let toast = {
          id: `${product.slug}-ordering-added-${Math.random()}`,
          title: `${product.title} добавлен в корзину!`,
          state: 'success',
          delay
        };
    
        const desktopToastParam = {
          content: product.description,
          actionList: [
            {
              title: 'Перейти в корзину',
              type: 'ACTION_GO_BASKET',
              variant: 'primary'
            }
          ]
        };
    
        if (isDesktop) {
          toast = { ...toast, ...desktopToastParam };
        }
    
        create({
          toast: toast,
          onActionTrigger: handleToastAction
        });
      }
    
    function handleClick(create, close, isMobile) {
        return (e) => {
            e.preventDefault();

            createToast(!isMobile, create);
            addProductToBasket(product);
        }
    }
}

export default withRouter(AddToBasketWithToastConsumer)