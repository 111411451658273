import React, { Component, Fragment } from 'react';
import Page from 'modules/core/components/page/page';
import SectionCategoryProducts from '../section-category-products/section-category-products';
import Placeholder from './placeholder';
import CategoryProductsContainer from '../../containers/category-products';
import Helmet from 'react-helmet';
import { getIndexLink, getCategoryLink } from 'routes/links';

// import SectionReview from './section-review';
// import reviewList from 'modules/review/ducks/reviews/seeds/reviews.json';

function getBannerImage(category) {
  return category.banner.sources[0].main.normal;
}

const SHOW_ALL_SUBCATEGORIES = true;

class Category extends Component {
  render() {
    const { category, nested, isLoaded, isNestedLoaded } = this.props;

    // if (!isLoaded || (nested && !isNestedLoaded)) {
    //   return <Placeholder />;
    // }
    const isLoading = !isLoaded || (nested && nested.slug && !isNestedLoaded);

    const banner = this.getBanner();
    const currentCategory = nested && nested.slug ? nested : category;

    const breadcrumbs = this.getBreadcrumbs(category, currentCategory);

    const hasMeta = currentCategory && currentCategory.meta;

    return (
      <div itemscope="" itemtype="http://schema.org/Product">
        <Page
          breadcrumbs={breadcrumbs}
          title={isLoading ? '' : currentCategory.title}
          banner={banner}
          isLoading={isLoading}
          description={(category && category.description) ? category.description : null }
        >
          {isLoading ? (
            <Placeholder />
          ) : (
            <Fragment>
              <Helmet>
                {hasMeta && currentCategory.meta.title && (
                  <title>{currentCategory.meta.title}</title>
                )}
                {hasMeta && currentCategory.meta.description && (
                  <meta name="description" content={currentCategory.meta.description} />
                )}
              </Helmet>
              <div itemprop="name" style={{ display: 'none' }}>
                {category.title}
              </div>
              <div itemprop="name" style={{ display: 'none' }}>
                {category.title}
              </div>
              <SectionCategoryProducts>
                <CategoryProductsContainer slug={category.slug} nested={nested.slug} />
              </SectionCategoryProducts>

              {/* {category.reviews && <SectionReview reviews={category.reviews} />} */}
            </Fragment>
          )}
        </Page>
      </div>
    );
  }

  getBanner() {
    const { category, nested, isLoaded, isMobile, isNestedLoaded } = this.props;

    if (!isLoaded || (nested && nested.slug && !isNestedLoaded)) {
      return null;
    }

    if (nested.slug && !nested.banner) {
      return null;
    }

    if (isMobile && SHOW_ALL_SUBCATEGORIES) {
      return null;
    }

    if (nested.slug && nested.banner) {
      return getBannerImage(nested);
    }

    if (!category.banner) {
      return null;
    }

    if (isMobile && !nested.slug) {
      return null;
    }

    return getBannerImage(category);
  }

  getBreadcrumbs(category, currentCategory) {
    const breadcrumbs = [];

    if (!category) {
      return null;
    }

    if (!currentCategory) {
      return null;
    }

    if (!category.isLoaded) {
      return null;
    }

    if (!currentCategory.isLoaded) {
      return null;
    }

    breadcrumbs.push({
      name: 'Главная',
      link: getIndexLink()
    });

    breadcrumbs.push({
      name: category.title,
      link: getCategoryLink(category)
    });

    if (category.nested.find(item => item === currentCategory.slug)) {
      breadcrumbs.push({
        name: currentCategory.title
      });
    }

    return breadcrumbs;
  }
}

export default Category;
