import { createAction, createActionError } from 'modules/utils/dux';
import * as FAVORITES from '../types/favorites';

export default {
  add(product) {
    return createAction(FAVORITES.ADD_REQUEST, { product });
  },

  remove(product) {
    return createAction(FAVORITES.REMOVE_REQUEST, { product });
  },

  click(product) {
    return createAction(FAVORITES.CLICK, { product });
  },

  addResponse(product) {
    return createAction(FAVORITES.ADD_RESPONSE, { product });
  },

  addResponseError(product, message) {
    return createActionError(FAVORITES.ADD_RESPONSE, { product, message });
  },

  removeResponse(product) {
    return createAction(FAVORITES.REMOVE_RESPONSE, { product });
  },

  removeResponseError(product, message) {
    return createActionError(FAVORITES.REMOVE_RESPONSE, { product, message });
  }
};
