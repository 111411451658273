import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Helmet } from 'react-helmet';

import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';
import SubscribeContainer from 'modules/core/containers/subscribe';
import Page from 'modules/core/components/page/page';
import { getIndexLink, getCategoryLink } from 'routes/links';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

import * as fromProducts from '../ducks/products';
import CardContainer from '../containers/card';
import DetailsContainer from '../containers/details';
import SectionProductCard from '../components/product-scene/section-product-card/section-product-card';
import ProductToolbarContainer from '../containers/product-toolbar';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

function getOptionsString(product) {
  if (!product.variantOptionsAll) {
    return '';
  }

  return product.variantOptionsAll
    .map(
      item =>
        `${item.variantTitle}: ${item.value}${item.payload && item.payload.unit ? ` ${item.payload.unit}` : ''
        }`
    )
    .join(', ');
}

class ProductPage extends Component {
  static propTypes = {
    slug: PropTypes.string.isRequired
  };

  state = {
    inBuyForm: false,
    inDetails: false
  };

  render() {
    const { slug, product, inFooter, consistencyString } = this.props;
    const { inBuyForm, inDetails } = this.state;

    if (!product) {
      return null;
    }
    const showToolbar =
      inDetails && !inBuyForm && !inFooter && product.isAvailable && !product.isGift;
    const breadcrumbs = this.getBreadcrumbs();
    const hasMeta = product && product.meta;

    const description = `${product.description} по цене ${product.price.default}
    руб. в интернет-магазине MIXIT. ${getOptionsString(product)}.${product.details &&
    product.details.consistency &&
    product.details.consistency.ingredients
    ? ` В составе: ${consistencyString}`
    : ''
  }`

    return (
      <Page breadcrumbs={breadcrumbs} withBackButton>
        <Helmet>
          {product.meta.title && <title>{product.meta.title}</title>}
          {hasMeta && product.meta.description ? (
            <meta name="description" content={product.meta.description} />
          ) : (
            <meta
              name="description"
              content={description}
            />
            )}
        </Helmet>

        <div itemscope="" itemtype="http://schema.org/Product">
          <HideErrorBoundary>
            <SectionProductCard>
              <CardContainer
                key={slug}
                slug={slug}
                onBuyEnter={this.handleBuyEnter}
                onBuyExit={this.handleBuyExit}
              />
            </SectionProductCard>
          </HideErrorBoundary>

          <DetailsContainer
            slug={slug}
            title={product.title}
            onAccordionVisibleItemChange={this.handleAccordionVisibleItemChange}
            onEnter={this.handleDetailsEnter}
            onExit={this.handleDetailsExit}
          />

          <ProductToolbarContainer slug={slug} opened={showToolbar} />

          <SubscribeContainer />

          <SectionDefault>
            <Container>
              <FeedbackCall />
            </Container>
          </SectionDefault>
        </div>
      </Page>
    );
  }

  init(props) {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }

    props.showProduct(props.slug);
  }

  componentDidMount() {
    this.init(this.props);
  }

  componentWillMount() {
    if (__SERVER__) {
      this.init(this.props);
    }
  }

  UNSAFE_componentWillUpdate(props) {
    if (props.slug === this.props.slug) {
      return;
    }

    this.init(props);
  }

  getBreadcrumbs() {
    const { product } = this.props;

    const DECORATE_SLUG = 'decorate';
    const OUTER_DECORATE_CATEGORY_LINK = 'https://art.mixit.ru/';

    const breadcrumbs = [];

    breadcrumbs.push({
      name: 'Главная',
      link: getIndexLink()
    });

    const hasNestedCategory = !!product.category && !!product.category.parent;

    const isCategoryDecorate = !!product.category && product.category.slug === DECORATE_SLUG;

    if (hasNestedCategory) {
      const isNestedCategoryDecorate =
        !!product.category &&
        !!product.category.parent &&
        product.category.parent.slug === DECORATE_SLUG;

      breadcrumbs.push({
        name: product.category.parent.title,
        ...(isNestedCategoryDecorate
          ? { absoluteLink: OUTER_DECORATE_CATEGORY_LINK }
          : { link: getCategoryLink(product.category.parent) })
      });

      breadcrumbs.push({
        name: product.category.title,
        link: getCategoryLink(product.category.parent, product.category)
      });
    } else if (product.category) {
      breadcrumbs.push({
        name: product.category.title,
        ...(isCategoryDecorate
          ? { absoluteLink: OUTER_DECORATE_CATEGORY_LINK }
          : { link: getCategoryLink(product.category) })
      });
    }

    breadcrumbs.push({
      name: product.title
    });

    return breadcrumbs;
  }

  openReviewAccordionItem() {
    this.setState({
      initiallyOpenedAccordionItem: 2
    });
  }

  handleAccordionVisibleItemChange = () => {
    this.setState({
      initiallyOpenedAccordionItem: 0
    });
  };

  handleReviewLinkClick = () => {
    this.openReviewAccordionItem();
  };

  handleBuyEnter = () => {
    this.setState({
      inBuyForm: true
    });
  };

  handleBuyExit = () => {
    this.setState({
      inBuyForm: false
    });
  };

  handleDetailsEnter = () => {
    this.setState({
      inDetails: true
    });
  };

  handleDetailsExit = () => {
    this.setState({
      inDetails: false
    });
  };
}

export default connect(
  createStructuredSelector({
    slug: fromProducts.getSlugFromRouteParams,
    product: fromProducts.getItemByRouteParams,
    consistencyString: fromProducts.getConsistencyStringByRouteParams
  }),
  {
    showProduct: fromProducts.actions.showItem
  }
)(ProductPage);
