import { createReducer, handle } from 'modules/utils/dux';
import * as TYPES from './types/deprecated-special-offers';
import { createSelector } from '../../utils/debug-reselect';

/* REDUCERS */

const reducer = createReducer(
  handle(TYPES.ADD_LIST, (state, { list }) => {
    return {
      ...state,
      list,
      isLoaded: true
    };
  })
);

export default reducer({ list: [], isLoaded: false });

/* SELECTORS */

const getAll = state => state.core.deprecatedSpecialOffers;

export const getList = createSelector(getAll, function _getSpecialOffers(all) {
  if (all.list.length === 0) {
    return null;
  }

  return all.list;
});

export const getIsListLoaded = createSelector(getAll, function _getSpecialOffers(all) {
  return all.isLoaded;
});

const getIdFromProps = (_, { id }) => id;

export const getSlugFromRouteParams = (_, { match }) => {
  return match.params.slug;
};

export const getSpecialOfferById = createSelector(
  getList,
  getIdFromProps,
  function _getSpecialOfferById(all, id) {
    if (!all) {
      return null;
    }
    if (!id) {
      return null;
    }
    const _currentPreviewArr = all.filter(item => item.id === +id);
    return _currentPreviewArr[0];
  }
);

export const getSpecialOfferBySlug = createSelector(
  getList,
  getSlugFromRouteParams,
  function _getSpecialOfferBySlug(all, slug) {
    if (!all) {
      return null;
    }
    if (!slug) {
      return null;
    }
    const _currentPreviewArr = all.filter(item => item.id === +slug);
    return _currentPreviewArr[0];
  }
);
