export const root = {
  path: '/profile',
  title: 'Профиль'
};

export const dashboard = {
  path: '/profile/dashboard',
  title: 'Дашборд'
};

export const general = {
  path: '/profile/general',
  title: 'Мой профиль'
};

export const favorites = {
  path: '/profile/favorites',
  title: 'Список желаний'
};

export const orderings = {
  path: '/profile/orderings',
  title: 'История покупок'
};

export const offers = {
  path: '/profile/offers',
  title: 'Акции и предложения'
};

export const shops = {
  path: '/profile/shops',
  title: 'Магазины'
};

export const subscriptions = {
  path: '/profile/subscriptions',
  title: 'Уведомления и рассылки'
};

export const bonuses = {
  path: '/profile/bonuses',
  title: 'Мои бонусы'
};

export const bonusHistory = {
  path: '/profile/bonuses/history',
  title: 'История бонусов'
};

export const changePassword = {
  path: '/profile/change-password',
  title: 'Сменить пароль'
};
