import compareUsefules from './compare-usefules';
// utils
const makeSelect = comparator => (a, b) => (comparator(a, b) ? a : b);
const minByValue = makeSelect((a, b) => a.rating <= b.rating);
const maxByValue = makeSelect((a, b) => a.rating >= b.rating);

export default list => {
  return list.reduce(maxByValue, list[0]).id;
};

export const getIdOfFirstLessUsefulReview = list => list.reduce(minByValue, list[0]).id;

export const getId = list => {
  return list.sort(compareUsefules)[0].id;
};
