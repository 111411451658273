// import { createSelector } from 'reselect';
import { createReducer, /*handle,*/ createAction } from 'modules/utils/dux';
import * as types from './types/callback';

const reducer = createReducer();

export default reducer({});

export const actions = {
  callbackRequest({ name, phone, email, action = null }) {
    return createAction(types.REQUEST, { name, phone, email, action });
  },
  callbackResponse(name, action) {
    return createAction(types.RESPONSE, {name, action});
  }
};
