import { PRODUCT_TYPE } from './constants';

export function selectIdentifier(product) {
  return product.slug;
}

export function createVariantSet(variantOptions) {
  if (!variantOptions) {
    return null;
  }

  return variantOptions.reduce((set, option) => {
    return {
      ...set,
      [option.name]: set[option.name] ? [...set[option.name], option] : [option]
    };
  }, {});
}

export function calculateDiscount(price) {
  const { discount } = price;

  if (!discount) {
    return 0;
  }

  if (discount.numerical) {
    return discount.numerical;
  }

  if (discount.percentage) {
    return price.default * discount.percentage;
  }

  return 0;
}

export function transformProduct(product, inlineProps) {
  const isClothes = product.type === PRODUCT_TYPE.CLOTHES;
  const isCosmetics = product.type === PRODUCT_TYPE.COSMETICS;

  const price = product.price;
  const discount = calculateDiscount(price);
  const currentPrice = price.default - discount;
  const relativeDiscount = 1 - currentPrice / price.default;
  const hasDiscount = discount > 0;

  const features = [
    ...product.features.filter(
      feature =>
        feature.type === 'FEATURE/NEW' ||
        feature.type === 'FEATURE/CONSUMERS_CHOICE' ||
        feature.type === 'FEATURE/MIXIT_ART' ||
        feature.type === 'FEATURE/BFKH' ||
        feature.type === 'FEATURE/COSMOPOLITAN' ||
        feature.type === 'FEATURE/RETAIL' ||
        feature.type === 'FEATURE/COLLABORATION'
    )
  ];

  const discountString = `${Math.round(relativeDiscount * 100)}%`;

  if (hasDiscount) {
    features.push({
      type: 'FEATURE/SALE',
      title: `−${discountString}`,
      description: `Данный товар предоставляется со скидкой ${discountString}`
    });
  }

  return {
    ...product,
    features,
    price: {
      ...price,
      discount,
      current: currentPrice
    },
    isClothes,
    isCosmetics,
    hasDiscount,
    ...inlineProps
  };
}

export function reduceListBySlug(list) {
  return list.reduce(
    (all, item) => ({
      ...all,
      [selectIdentifier(item)]: item
    }),
    {}
  );
}

export function productsFromDetails(details) {
  if (!details) {
    return null;
  }

  let products = [];

  if (details.related_products && details.related_products.length > 0) {
    products = products.concat(details.related_products);
  }

  if (details.similar_products && details.similar_products.length > 0) {
    products = products.concat(details.similar_products);
  }

  if (details.included_products && details.included_products.length > 0) {
    products = products.concat(details.included_products);
  }

  return products;
}

export function ingredientsFromDetails(details) {
  if (
    !details ||
    !details.consistency ||
    !details.consistency.ingredients ||
    !details.consistency.ingredients.length
  ) {
    return [];
  }

  return details.consistency.ingredients;
}

export function resolveCategories(products, categories) {
  Object.keys(products).forEach(slug => {
    const _parent = products[slug].category.parent;

    products[slug].category = categories[products[slug].category];

    products[slug].category.parent = _parent;
  });

  return products;
}
