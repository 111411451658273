import React from 'react';
// import Media from 'modules/core/components/media/media';
import Container from 'modules/core/components/container/container';
import Content from 'modules/core/components/content/content';

import "./solutions.css";

export default function ProblemSkin_Solutions({data}) {
    return (
        <div className="ProblemSkin_Solutions" style={{...data.style}}>
            <Container style={{maxWidth: "1285px"}}>
                <div className="ProblemSkin_Solutions-title">{data.title}</div>
                <div className="ProblemSkin_Solutions-inner">
                    <div className="ProblemSkin_Solutions-photoSection">
                        <div className="ProblemSkin_Solutions-outerCircle">
                            <div className="ProblemSkin_Solutions-innerCircle">
                                <div className="ProblemSkin_Solutions-imageHolder">
                                    <img src={data.image.sources[0].main.normal} alt="" className="ProblemSkin_Solutions-image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ProblemSkin_Solutions-listSection">
                        <div className="ProblemSkin_Solutions-list">
                            {data.solutions.map((solution, index)=>(                                
                                <div className="ProblemSkin_Solutions-item">
                                    <div className="ProblemSkin_Solutions-itemPointer" style={{backgroundImage: `url(${solution.pointer_src})`}}></div>
                                    <div key={index} className="ProblemSkin_Solutions-item_Visual">
                                        <div className="ProblemSkin_Solutions-item_ImageHolder">
                                            <img className="ProblemSkin_Solutions-item_Image" src={solution.image.sources[0].main.normal} alt="" />
                                        </div>
                                    </div>
                                    <div className="ProblemSkin_Solutions-item_Info">
                                        <span className="ProblemSkin_Solutions-item_InfoNumber">{index + 1}. </span> 
                                        <Content html={solution.html} expanded tag="span"/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
} 