import React, { Component, Fragment } from 'react';
import Media from 'react-media';

import LOADING_STATE from 'modules/core/constants/loading-state';
import Loader from 'modules/core/components/loader/loader';

import Form from 'modules/form/components/form/form';
import Input from 'modules/form/components/input/input';
import FromGroup from 'modules/form/components/form-group/form-group';
import LeftLabeledField from 'modules/form/components/left-labeled-field/left-labeled-field';
import Button from 'modules/core/components/button/button';
import DadataCitySuggest from 'modules/geography/containers/dadata-city-suggest';
import DadataBuildingSuggest from 'modules/geography/containers/dadata-building-suggest';
import Checkbox from 'modules/form/components/checkbox/checkbox';

import MethodChoice from './method-choice';
import AddressChoice from 'modules/form/components/address-choice/address-choice';
import Field from 'modules/form/components/field/field';

import InputApartment from './input-apartment';
import InputPhoneWithAreaCode from 'modules/core/components/input-phone-with-area-code/input-phone-with-area-code_new';
import areaCodes from 'modules/core/components/input-phone-with-area-code/area-codes';

// import RecipientForm from "./recipient-form"
// import Notification from 'modules/core/components/notification/notification';
// import Price from '../price-formatted/price-formatted';

import './delivery-form.css';

// const FREE_DELIVERY_COST = 3000;

class DeliveryForm extends Component {
  state = {
    addRecipient: false
  };

  render() {
    return <Media query="(max-width: 840px)">{this.renderForDevice}</Media>;
  }

  renderForDevice = isMobile => {
    const {
      isCityChoosen,
      cityId,
      cityData,
      deliveryLoading,
      deliveryMethodList,
      selectedDeliveryMethod,
      selectedPickPoint,

      cityErrors,
      deliveryMethodErrors,
      pickPointErrors,

      isBeingEdited,
      disableSubmit,
      showErrors,
      productsCost,

      isPickPointDialogShowed,

      onCityChange,
      onDeliveryMethodChange,
      onPickPointChange,
      onPickPointDialogShow,
      onPickPointDialogClose,
      onSubmit,
      maxDeliveryPrice,
      // isDeliveryFree,
      // freeDeliveryPrice,
      // freeDeliveryFromByDeliveryCity,
      initialCity,
      isDeliveryFreeByDeliveryCity
    } = this.props;

    return (
      <div className="DeliveryForm">
        <Form>
          <Form.LeftLabeledRow>
            <LeftLabeledField
              label="Город"
              size="small"
              required
              error={showErrors && cityErrors && cityErrors[0].message}
            >
              <DadataCitySuggest // work as uncontrolled component
                withSettlements
                onlyRussia={false}
                placeholder="Начните вводить название"
                selected={(cityId && (cityId.fiasId || cityId.geonameId)) || null}
                onChangeFull={onCityChange}
                size="small"
                state={showErrors && cityErrors ? 'error' : 'default'}
                initialCity={initialCity}
              />
            </LeftLabeledField>
          </Form.LeftLabeledRow>

          {this.renderAddressFields()}

          {isCityChoosen && (
            <Fragment>
              {deliveryLoading !== LOADING_STATE.LOADED ? (
                <Form.LeftLabeledRow>
                  <LeftLabeledField label="Доставка" size="choice" required>
                    <div className="DeliveryForm-loader">
                      <Loader type="goo" size="small" />
                    </div>
                  </LeftLabeledField>
                </Form.LeftLabeledRow>
              ) : (
                  (deliveryMethodList && deliveryMethodList.length > 0) ? (
                    <Fragment>
                      {/* {!isDeliveryFreeByDeliveryCity && maxDeliveryPrice !== 0 && (
                      <Form.Row>
                        <Notification type="info">
                          <span>
                            C 27.03.2020 c 18-00 (мск) заказы оформляются только{' '}
                            <nobr>с онлайн-оплатой.</nobr> При покупке товаров от{' '}
                            <b>
                              <Price number={freeDeliveryFromByDeliveryCity} />
                            </b>{' '}
                            доставка бесплатно!
                          </span>
                        </Notification>
                      </Form.Row>
                    )} */}
                      <Form.LeftLabeledRow>
                        <LeftLabeledField
                          label="Доставка"
                          size="choice"
                          required
                          error={
                            showErrors &&
                            ((deliveryMethodErrors && deliveryMethodErrors[0].message) ||
                              (pickPointErrors && pickPointErrors[0].message))
                          }
                        >
                          <MethodChoice
                            city={cityData}
                            list={deliveryMethodList}
                            selected={selectedDeliveryMethod}
                            selectedPickPoint={selectedPickPoint}
                            onChange={onDeliveryMethodChange}
                            onPickPointChange={onPickPointChange}
                            isDialogShowed={isPickPointDialogShowed}
                            onPickPointDialogShow={onPickPointDialogShow}
                            onPickPointDialogClose={onPickPointDialogClose}
                            productsCost={productsCost}
                            maxDeliveryPrice={maxDeliveryPrice}
                            isDeliveryFree={isDeliveryFreeByDeliveryCity}
                          />
                        </LeftLabeledField>
                      </Form.LeftLabeledRow>
                    </Fragment>
                  ) :
                    (
                      <Form.LeftLabeledRow>
                        <LeftLabeledField label="Доставка" size="choice" required>
                          <div className="DeliveryForm-deliveryMethodsListOpen">К сожалению для вашего города не доступно ни одной доставки</div>
                        </LeftLabeledField>
                      </Form.LeftLabeledRow>
                    )
                )}

              {/* {this.renderAdditionalFields()} */}
              {this.renderRecipientFields()}


              <Form.ActionRow>
                <FromGroup right>
                  {isMobile ? (
                    <FromGroup.ItemWide>
                      <Button
                        title={isBeingEdited ? 'Подтвердить' : 'Далее'}
                        variant="primary"
                        type="submit"
                        onClick={onSubmit}
                        // disabled={disableSubmit || !selectedDeliveryMethod}
                        visualyDisabled={disableSubmit || !selectedDeliveryMethod}
                        caption={disableSubmit && 'Закончите заполнение формы'}
                        size="small"
                        expanded={true}
                      />
                    </FromGroup.ItemWide>
                  ) : (
                      <FromGroup.Item>
                        <Button
                          title={isBeingEdited ? 'Подтвердить' : 'Далее'}
                          variant="primary"
                          type="submit"
                          onClick={onSubmit}
                          visualyDisabled={disableSubmit || !selectedDeliveryMethod}
                          // disabled={disableSubmit || !selectedDeliveryMethod}
                          caption={disableSubmit && 'Закончите заполнение формы'}
                        />
                      </FromGroup.Item>
                    )}
                </FromGroup>
              </Form.ActionRow>
            </Fragment>
          )}
        </Form>
      </div>
    );
  };

  renderAddressFields = () => {
    const {
      cityId,
      selectedDeliveryMethod,
      savedDeliveryAddresses,
      streetRaw,
      apartment,
      selectedAddress,
      showErrors,
      addressErrors,
      apartmentErrors,
      onAddressChange,
      onBuildingChange,
      onApartmentChange,
    } = this.props;

    if (!selectedDeliveryMethod) {
      return null;
    }

    return (
      <Fragment>
        {selectedDeliveryMethod.addressRequired &&
          (this.isSavedAddresses() ? (
            <Form.LeftLabeledRow>
              <LeftLabeledField label="Адрес" size="choice">
                <Form.Row>
                  <AddressChoice
                    list={savedDeliveryAddresses}
                    selected={selectedAddress}
                    onChange={onAddressChange}
                  />
                </Form.Row>

                {this.isNewAddressOption(selectedAddress) && (
                  <Fragment>
                    <Form.Row>
                      <Field label="Улица, дом" size="small">
                        <Input
                          placeholder="Введите адрес"
                          value={streetRaw}
                          type="text"
                          size="small"
                          name="street"
                          onChange={onBuildingChange}
                          autoComplete="off"
                          autoFill="off"
                        />
                      </Field>
                    </Form.Row>

                    <Form.Row>
                      <Field label="Квартира" size="small">
                        <Input
                          placeholder="Введите квартиру"
                          name="apartment"
                          value={apartment}
                          type="text"
                          size="small"
                          onChange={onApartmentChange}
                          autoComplete="off"
                          autoFill="off"
                        />
                      </Field>
                    </Form.Row>
                  </Fragment>
                )}
              </LeftLabeledField>
            </Form.LeftLabeledRow>
          ) : (
              <Fragment>
                <Form.LeftLabeledRow>
                  <LeftLabeledField
                    label="Улица, дом"
                    size="small"
                    required
                    error={showErrors && addressErrors && addressErrors[0].message}
                  >
                    <DadataBuildingSuggest
                      defaultQuery={streetRaw}
                      placeholder="Начните вводить название улицы"
                      size="small"
                      onChange={onBuildingChange}
                      city={cityId && cityId.fiasId} // FIXME: street suggestions only for Russia
                      state={showErrors && addressErrors ? 'error' : 'default'}
                      name="streetaddress"
                    />
                  </LeftLabeledField>
                </Form.LeftLabeledRow>

                <Form.LeftLabeledRow>
                  <LeftLabeledField
                    label="Квартира/офис"
                    size="small"
                    required
                    error={showErrors && apartmentErrors && apartmentErrors[0].message}
                  >
                    <InputApartment
                      value={apartment}
                      placeholder="Номер"
                      name="appartment"
                      size="small"
                      onChange={onApartmentChange}
                      state={showErrors && apartmentErrors ? 'error' : 'default'}
                      autoComplete="off"
                      autoFill="off"
                    />
                  </LeftLabeledField>
                </Form.LeftLabeledRow>
              </Fragment>
            ))}
      </Fragment>
    );
  }

  renderRecipientFields = () => {
    const {
      selectedDeliveryMethod,
      recipientPhone,
      recipientFirstName,
      recipientLastName,
      showErrors,
      phoneErrors,
      firstNameErrors,
      lastNameErrors,
      onRecipientPhoneChange,
      onRecipientFirstNameChange,
      onRecipientLastNameChange,
      addRecipient,
      handleAddRecepientChange
    } = this.props;

    if (!selectedDeliveryMethod) {
      return null;
    }

    const recepientRequired =
      selectedDeliveryMethod.phoneRequired || selectedDeliveryMethod.fullNameRequired;

    return (
      <Fragment>
        {recepientRequired && (
          <>
            <Form.Row>
              <div className="DeliveryForm-recipientToogler">
                <div className="DeliveryForm-recipientTooglerCheckbox">
                  <Checkbox
                    label="Добавить получателя"
                    checked={addRecipient}
                    onChange={handleAddRecepientChange}
                  />
                </div>
                {!addRecipient && (
                  <div className="DeliveryForm-recipientTooglerInfo">
                    Добавьте получателя, если вы покупаете не для себя, или хотите, чтобы товар
                    забрал другой человек
                  </div>
                )}
              </div>
            </Form.Row>
            {addRecipient && selectedDeliveryMethod.phoneRequired && (
              <Form.LeftLabeledRow>
                <LeftLabeledField
                  label="Телефон"
                  size="small"
                  required
                  error={showErrors && phoneErrors && phoneErrors[0].message}
                >
                  <InputPhoneWithAreaCode
                    codeList={areaCodes}
                    value={recipientPhone}
                    size="small"
                    onChange={onRecipientPhoneChange}
                    name="phone"
                  />
                </LeftLabeledField>
              </Form.LeftLabeledRow>
            )}
            {addRecipient && selectedDeliveryMethod.fullNameRequired && (
              <>
                <Form.LeftLabeledRow>
                  <LeftLabeledField
                    label="Имя"
                    error={showErrors && firstNameErrors && firstNameErrors[0].message}
                    size="small"
                  >
                    <Input
                      name="firstname"
                      value={recipientFirstName}
                      size="small"
                      onChange={onRecipientFirstNameChange}
                      state={showErrors && firstNameErrors ? 'error' : 'default'}
                    />
                  </LeftLabeledField>
                </Form.LeftLabeledRow>
                <Form.LeftLabeledRow>
                  <LeftLabeledField
                    label="Фамилия"
                    error={showErrors && lastNameErrors && lastNameErrors[0].message}
                    size="small"
                  >
                    <Input
                      name="lastname"
                      value={recipientLastName}
                      size="small"
                      onChange={onRecipientLastNameChange}
                      state={showErrors && lastNameErrors ? 'error' : 'default'}
                    />
                  </LeftLabeledField>
                </Form.LeftLabeledRow>
              </>
            )}
          </>
        )}
      </Fragment>
    );
  }

  renderAdditionalFields() {  // depricated
    const {
      cityId,
      selectedDeliveryMethod,

      recipientPhone,

      // recipientName,
      recipientFirstName,
      recipientLastName,

      savedDeliveryAddresses,
      streetRaw,
      apartment,
      selectedAddress,

      showErrors,

      phoneErrors,

      // fullNameErrors,
      firstNameErrors,
      lastNameErrors,

      addressErrors,
      apartmentErrors,

      onRecipientPhoneChange,

      // onRecipientNameChange,
      onRecipientFirstNameChange,
      onRecipientLastNameChange,

      onAddressChange,
      onBuildingChange,
      onApartmentChange,

      addRecipient,
      handleAddRecepientChange
    } = this.props;

    if (!selectedDeliveryMethod) {
      return null;
    }

    const recepientRequired =
      selectedDeliveryMethod.phoneRequired || selectedDeliveryMethod.fullNameRequired;

    // const isDeliveryNoRussia =  this.getIsDeliveryNoRussia();
    return (
      <Fragment>
        {recepientRequired && (
          <>
            {/*           
          {!isDeliveryNoRussia ? ( */}
            <Form.Row>
              <div className="DeliveryForm-recipientToogler">
                <div className="DeliveryForm-recipientTooglerCheckbox">
                  <Checkbox
                    label="Добавить получателя"
                    checked={addRecipient}
                    onChange={handleAddRecepientChange}
                  />
                </div>
                {!addRecipient && (
                  <div className="DeliveryForm-recipientTooglerInfo">
                    Добавьте получателя, если вы покупаете не для себя, или хотите, чтобы товар
                    забрал другой человек
                  </div>
                )}
              </div>
            </Form.Row>

            {/*  ): (
             <Form.Row>
              <b>Получатель:</b>
             </Form.Row>
           )} */}


            {addRecipient && selectedDeliveryMethod.phoneRequired && (
              <Form.LeftLabeledRow>
                <LeftLabeledField
                  label="Телефон"
                  size="small"
                  required
                  error={showErrors && phoneErrors && phoneErrors[0].message}
                >
                  <InputPhoneWithAreaCode
                    codeList={areaCodes}
                    value={recipientPhone}
                    size="small"
                    onChange={onRecipientPhoneChange}
                    name="phone"
                  />
                </LeftLabeledField>
              </Form.LeftLabeledRow>
            )}
            {/* {addRecipient && selectedDeliveryMethod.fullNameRequired && (
          <Form.LeftLabeledRow>
            <LeftLabeledField
              label="Получатель"
              size="small"
              required
              error={(showErrors ) && fullNameErrors && fullNameErrors[0].message}
              // error={fullNameErrors[0] === "Введите, пожалуйста, фамилию" && fullNameErrors && fullNameErrors[0].message}
            >
              <Input
                placeholder="Имя и фамилия"
                value={recipientName}
                type="text"
                size="small"
                state={(showErrors ) &&  fullNameErrors ? 'error' : 'default'}
                // state={fullNameErrors ? 'error' : 'default'}
                onChange={onRecipientNameChange}
              />
            </LeftLabeledField>
          </Form.LeftLabeledRow>
          )} */}
            {addRecipient && selectedDeliveryMethod.fullNameRequired && (
              <>
                <Form.LeftLabeledRow>
                  <LeftLabeledField
                    label="Имя"
                    error={showErrors && firstNameErrors && firstNameErrors[0].message}
                    size="small"
                  >
                    <Input
                      name="firstname"
                      value={recipientFirstName}
                      size="small"
                      onChange={onRecipientFirstNameChange}
                      state={showErrors && firstNameErrors ? 'error' : 'default'}
                    />
                  </LeftLabeledField>
                </Form.LeftLabeledRow>
                <Form.LeftLabeledRow>
                  <LeftLabeledField
                    label="Фамилия"
                    error={showErrors && lastNameErrors && lastNameErrors[0].message}
                    size="small"
                  >
                    <Input
                      name="lastname"
                      value={recipientLastName}
                      size="small"
                      onChange={onRecipientLastNameChange}
                      state={showErrors && lastNameErrors ? 'error' : 'default'}
                    />
                  </LeftLabeledField>
                </Form.LeftLabeledRow>
              </>
              // <Form.LeftLabeledRow>
              //   <Field
              //     // label="Получатель"
              //     size="small"
              //     required
              //     error={(showErrors ) && fullNameErrors && fullNameErrors[0].message}
              //     // error={fullNameErrors[0] === "Введите, пожалуйста, фамилию" && fullNameErrors && fullNameErrors[0].message}
              //   >
              //     <RecipientForm
              //       value={recipientName}
              //       showErrors={showErrors}
              //       fullNameErrors={fullNameErrors}
              //       error={(showErrors ) && fullNameErrors && fullNameErrors[0].message}
              //       onChange={onRecipientNameChange}
              //     />
              //   </Field>
              // </Form.LeftLabeledRow>
            )}
          </>
        )}

        {/* {selectedDeliveryMethod.phoneRequired && (
          <Form.LeftLabeledRow>
            <LeftLabeledField
              label="Телефон"
              size="small"
              required
              error={showErrors && phoneErrors && phoneErrors[0].message}
            >
              <InputPhoneWithAreaCode
                codeList={areaCodes}
                value={recipientPhone}
                size="small"
                onChange={onRecipientPhoneChange}
              />
            </LeftLabeledField>
          </Form.LeftLabeledRow>
        )} */}

        {/* {selectedDeliveryMethod.fullNameRequired && (
          <Form.LeftLabeledRow>
            <LeftLabeledField
              label="Получатель"
              size="small"
              required
              error={(showErrors ) && fullNameErrors && fullNameErrors[0].message}
              // error={fullNameErrors[0] === "Введите, пожалуйста, фамилию" && fullNameErrors && fullNameErrors[0].message}
            >
              <Input
                placeholder="Имя и фамилия"
                value={recipientName}
                type="text"
                size="small"
                state={(showErrors ) &&  fullNameErrors ? 'error' : 'default'}
                // state={fullNameErrors ? 'error' : 'default'}
                onChange={onRecipientNameChange}
              />
            </LeftLabeledField>
          </Form.LeftLabeledRow>
        )} */}

        {selectedDeliveryMethod.addressRequired &&
          (this.isSavedAddresses() ? (
            <Form.LeftLabeledRow>
              <LeftLabeledField label="Адрес" size="choice">
                <Form.Row>
                  <AddressChoice
                    list={savedDeliveryAddresses}
                    selected={selectedAddress}
                    onChange={onAddressChange}
                  />
                </Form.Row>

                {this.isNewAddressOption(selectedAddress) && (
                  <Fragment>
                    <Form.Row>
                      <Field label="Улица, дом" size="small">
                        <Input
                          placeholder="Введите адрес"
                          value={streetRaw}
                          type="text"
                          size="small"
                          name="street"
                          onChange={onBuildingChange}
                          autoComplete="off"
                          autoFill="off"
                        />
                      </Field>
                    </Form.Row>

                    <Form.Row>
                      <Field label="Квартира" size="small">
                        <Input
                          placeholder="Введите квартиру"
                          name="apartment"
                          value={apartment}
                          type="text"
                          size="small"
                          onChange={onApartmentChange}
                          autoComplete="off"
                          autoFill="off"
                        />
                      </Field>
                    </Form.Row>
                  </Fragment>
                )}
              </LeftLabeledField>
            </Form.LeftLabeledRow>
          ) : (
              <Fragment>
                <Form.LeftLabeledRow>
                  <LeftLabeledField
                    label="Улица, дом"
                    size="small"
                    required
                    error={showErrors && addressErrors && addressErrors[0].message}
                  >
                    <DadataBuildingSuggest
                      defaultQuery={streetRaw}
                      placeholder="Начните вводить название улицы"
                      size="small"
                      onChange={onBuildingChange}
                      city={cityId && cityId.fiasId} // FIXME: street suggestions only for Russia
                      state={showErrors && addressErrors ? 'error' : 'default'}
                      name="streetaddress"
                    />
                  </LeftLabeledField>
                </Form.LeftLabeledRow>

                <Form.LeftLabeledRow>
                  <LeftLabeledField
                    label="Квартира/офис"
                    size="small"
                    required
                    error={showErrors && apartmentErrors && apartmentErrors[0].message}
                  >
                    <InputApartment
                      value={apartment}
                      placeholder="Номер"
                      name="appartment"
                      size="small"
                      onChange={onApartmentChange}
                      state={showErrors && apartmentErrors ? 'error' : 'default'}
                      autoComplete="off"
                      autoFill="off"
                    />
                  </LeftLabeledField>
                </Form.LeftLabeledRow>
              </Fragment>
            ))}
      </Fragment>
    );
  }

  // getIsDeliveryNoRussia = () => {
  //   const {selectedDeliveryMethod} = this.props
  //   if(!selectedDeliveryMethod || !selectedDeliveryMethod.name) {
  //     return false
  //   }
  //   if(selectedDeliveryMethod.name === 'DELIVERY_METHOD/DELIVERY_TO_BELARUS' ||
  //   selectedDeliveryMethod.name === 'DELIVERY_METHOD/DELIVERY_TO_KAZAKHSTAN') {
  //     return true;
  //   }
  //   return false;
  // }

  isSavedAddresses() {
    const { isAuthorized, savedDeliveryAddresses } = this.props;

    return isAuthorized && savedDeliveryAddresses.length;
  }

  isNewAddressOption(addressOption) {
    if (!addressOption) {
      return false;
    }
    return addressOption.isNew;
  }
}

export default DeliveryForm;
