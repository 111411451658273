import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isFunction from 'modules/utils/is-function';

import './filter-item.css';

const { string, bool, func } = PropTypes;

export default class FilterItem extends Component {
  static propTypes = {
    /** название фильтрующей категори */
    title: string,
    /** активность категории */
    active: bool,
    /** колбек применения текущей категории фильтра */
    onClick: func
  };

  render() {
    const { title, active } = this.props;

    return (
      <button
        type="button"
        className={cn('FilterItem', {
          'FilterItem--isActive': active
        })}
        onClick={this.handleClick}
      >
        {title}
      </button>
    );
  }

  handleClick = () => {
    const { onClick } = this.props;

    if (!isFunction(onClick)) {
      return;
    }
    onClick();
  };
}
