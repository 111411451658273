import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'modules/core/components/button/button';
import isFunction from 'modules/utils/is-function';

import './sort-options.css';

const { string, arrayOf, shape } = PropTypes;

class SortOptions extends Component {
  static propTypes = {
    sortOptionList: arrayOf(
      shape({
        type: string,
        title: string
      })
    ),
    activeSortOption: string
  };

  render() {
    const { sortOptionList, activeSortOption } = this.props;

    return (
      <div className="SortOptions">
        <div className="SortOptions-title">Сортировать</div>
        <div className="SortOptions-holder">
          {sortOptionList.map(option => {
            return (
              <span key={option.type} className="SortOptions-item">
                <Button
                  data-testid={option.type}
                  title={option.title}
                  size="small"
                  variant={option.type === activeSortOption ? 'primary' : 'default'}
                  onClick={this.handleClick(option.type)}
                />
              </span>
            );
          })}
        </div>
      </div>
    );
  }

  changeSortType(sortType) {
    const { onSortChange } = this.props;

    if (!isFunction(onSortChange)) {
      return;
    }
    onSortChange(sortType);
  }

  handleClick = sortType => () => {
    const { activeSortOption } = this.props;

    if (sortType === activeSortOption) {
      return;
    }

    this.changeSortType(sortType);
  };
}

export default SortOptions;
