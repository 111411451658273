import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import * as fromProducts from '../ducks/products';
import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromAccount from 'modules/core/ducks/account';
import * as fromWaitingList from 'modules/core/ducks/waiting-list';
import * as fromLocation from 'modules/geography/ducks/location';
import { actions as Dialog } from 'modules/core/ducks/dialogs';
import isFunction from 'modules/utils/is-function';
import Card from '../components/card/card';
import FavoritesAction from 'modules/profile/actions/favorites';
import { monthToString } from 'modules/utils/date-string';

const mapStateToProps = createStructuredSelector({
  product: fromProducts.getItemByRouteParams,
  variantSet: fromProducts.getVariantSet,
  defaultVariant: fromProducts.getDefaultVariant,
  volumeList: fromProducts.getVolumeList,
  weightList: fromProducts.getWeightList,
  // currentWeight: fromProducts.getCurrentWeight,
  defaultVolume: fromProducts.getDefaultVolume,
  defaultWeight: fromProducts.getDefaultWeight,
  colorList: fromProducts.getColorList,
  // currentColor: fromProducts.getCurrentColor,
  defaultColor: fromProducts.getDefaultColor,
  sizeList: fromProducts.getSizeList,
  // currentSize: fromProducts.getCurrentSize,
  defaultSize: fromProducts.getDefaultSize,
  currentVariant: fromProducts.getCurrentVariant,
  isAuthorized: fromAccount.getIsAuthorized,
  authorizedEmail: fromAccount.getEmail,
  isWaitingListRequestLoading: fromWaitingList.getIsLoading,
  isBasketFetching: fromBasket.getIsBasketFetching,
  currentCity: fromLocation.getCurrentCity
});

const mapDispatchToProps = {
  addToFavorites: FavoritesAction.add,
  removeFromFavorites: FavoritesAction.remove,
  onFavouriteClick: FavoritesAction.click,
  addProductToBasket: fromBasket.actions.addProduct,
  addToWaitListRequest: fromProducts.actions.addToWaitListRequest,
  openAuthorizationDialog: Dialog.openAuthDialog
};

const DEFAULT_AMOUNT = 1;

class CardContainer extends Component {
  static propTypes = {
    /** идентификатор продукта */
    slug: PropTypes.string.isRequired
  };

  state = {
    amount: 1,
    currentVariant: this.props.defaultVariant,
    currentWeight: this.props.defaultWeight,
    currentSize: this.props.defaultSize,
    currentColor: this.props.defaultColor,
    isAddToWaitListDialogOpened: false,
    isVideoDialogOpened: false,
    videoId: null
  };

  render() {
    const {
      amount,
      currentVariant,
      currentColor,
      currentSize,
      currentWeight,
      isAddToWaitListDialogOpened,
      isVideoDialogOpened,
      videoId
    } = this.state;

    const { ...rest } = this.props;

    delete rest.toggleFavorite;
    delete rest.addProductToBasket;
    delete rest.defaultVariant;
    delete rest.defaultWeight;
    delete rest.defaultSize;
    delete rest.defaultColor;

    return (
      <Card
        {...rest}
        amount={amount}
        currentVariant={currentVariant}
        currentColor={currentColor}
        currentSize={currentSize}
        currentWeight={currentWeight}
        videoId={videoId}
        isAddToWaitListDialogOpened={isAddToWaitListDialogOpened}
        isVideoDialogOpened={isVideoDialogOpened}
        onFavoriteToggle={this.handleToggleFavorite}
        onAddToBasket={this.handlerAddToBasket}
        onAmountChange={this.handleAmountChange}
        onColorChange={this.handleColorChange}
        onWeightChange={this.handleWeightChange}
        onSizeChange={this.handleSizeChange}
        onDialogFormSubmit={this.handleDialogFormSubmit}
        onAddToWaitListDialogClose={this.handleAddToWaitListDialogClose}
        onVideoDialogClose={this.handleVideoDialogClose}
        onButtonAddToWaitingListClick={this.handleButtonAddToWaitingListClick}
        onAuthorizationClick={this.handleAuthorizationClick}
        onVideoTriggerClick={this.handleVideoTriggerClick}
        isCosmopolitanIconExist={this.getIsCosmopolitanIconExist()}
        deliveryDateForMoscov={this.getDeliveryDateForMoscov()}
      />
    );
  }

  getIsCosmopolitanIconExist() {
    const { product } = this.props;
    if (!product) {
      return null;
    }
    const _mappingProd = product.features.map(item => item.type);
    return _mappingProd.includes('FEATURE/COSMOPOLITAN');
  }

  changeAmount(newAmount) {
    this.setState({
      amount: newAmount
    });
  }

  resetAmount() {
    this.changeAmount(DEFAULT_AMOUNT);
  }

  changeColor(newColor) {
    this.setState({
      currentColor: newColor
    });
  }

  changeWeight(newWeight) {
    this.setState({
      currentSize: newWeight
    });
  }

  changeSize(newSize) {
    this.setState({
      currentSize: newSize
    });
  }

  openAddToWaitListDialog() {
    this.setState(state => ({ ...state, isAddToWaitListDialogOpened: true }));
  }

  closeAddToWaitListDialog() {
    this.setState(state => ({ ...state, isAddToWaitListDialogOpened: false }));
  }

  closeVideoDialog() {
    this.setState(state => ({ ...state, isVideoDialogOpened: false }));
  }

  openVideoDialog(id) {
    this.setState(state => ({
      ...state,
      isVideoDialogOpened: true,
      videoId: id
    }));
  }

  handleDialogFormSubmit = email => {
    const { slug, addToWaitListRequest } = this.props;

    this.closeAddToWaitListDialog();

    addToWaitListRequest(slug, email);
  };

  handleButtonAddToWaitingListClick = () => {
    const { slug, isAuthorized, addToWaitListRequest, authorizedEmail } = this.props;

    if (isAuthorized) {
      addToWaitListRequest(slug, authorizedEmail);
    } else {
      this.openAddToWaitListDialog();
    }
  };

  handleVideoTriggerClick = id => {
    this.openVideoDialog(id);
  };

  handleAddToWaitListDialogClose = () => {
    this.closeAddToWaitListDialog();
  };

  handleVideoDialogClose = () => {
    this.closeVideoDialog();
  };

  handleAddToWaitListConfirmationDialogClose = () => {
    this.closeAddToWaitListConfirmationDialog();
  };

  handlerAddToBasket = () => {
    const { addProductToBasket, product } = this.props;
    const { amount } = this.state;

    if (isFunction(addProductToBasket)) {
      addProductToBasket(product, amount);
    }

    this.resetAmount();
  };

  handleToggleFavorite = () => {
    const {
      product,
      removeFromFavorites,
      addToFavorites,
      isAuthorized,
      openAuthorizationDialog,
      onFavouriteClick
    } = this.props;

    if (!isAuthorized) {
      openAuthorizationDialog();
      onFavouriteClick(product);
      return;
    }

    if (product.inFavorite) {
      removeFromFavorites(product);
    } else {
      addToFavorites(product);
    }
  };

  handleAuthorizationClick = () => {
    const { openAuthorizationDialog } = this.props;

    openAuthorizationDialog();
  };

  handleAmountChange = newAmount => {
    this.changeAmount(newAmount);
  };

  handleColorChange = newColor => {
    this.changeColor(newColor);
  };

  handleSizeChange = newSize => {
    this.changeSize(newSize);
  };

  handleWeightChange = newWeight => {
    this.changeWeight(newWeight);
  };
  handleAddToWaitingList = () => {
    console.warn('Not implemented');
  };

  getDeliveryDateForMoscov = () => {
    const { currentCity } = this.props;

    if (!currentCity) {
      return false;
    }

    if (+currentCity.id === 617) {
      const now = new Date();
      now.setDate(now.getDate() + 2);

      return `${now.getDate()}  ${monthToString(now.getMonth())}`;
    }

    return false;
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardContainer));
