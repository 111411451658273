export default function chunkString(string, size = string.length) {
  if (!string) {
    return;
  }

  const stringWithoutSpaces = string.replace(/\s/g, '');
  const pattern = `.{1,${size}}`;
  const regex = new RegExp(pattern, 'g');

  return stringWithoutSpaces.match(regex).join(' ');
}
