import uniq from 'lodash/uniq';
import shuffle from 'lodash/shuffle';

function isLoadedInstagramUrl(post, list = []) {
  return list.indexOf(post) === -1;
}

function getCleanedUrl(url) {
  const transformedUrl = cleanURL(getCheckedURL(url));
  return `${transformedUrl}`;
}

function getCheckedURL(url) {
  const lastCharacter = url.slice(-1);
  if (lastCharacter !== '/') {
    return url + '/';
  }
  return url;
}

function cleanURL(dirtyUrl) {
  return dirtyUrl.split('?')[0];
}

function getEaqualLinkView(link) {
  const linkArray = link.split('/');
  const linkIdentifier = linkArray[linkArray.length - 2];
  return `https://www.instagram.com/p/${linkIdentifier}/`;
}

const getPreparedInstagramPosts = initialListOfLinks => {
  let preparedList = [...initialListOfLinks];

  const actions = {
    getList(log) {
      if (log) {
        console.log(`%c getList(log) `, 'color: red; font-size: 20px; background: #ccc');
        console.log(preparedList);
      }
      return preparedList;
    },
    getLog(message = 'getPreparedInstagramPosts log') {
      console.log(`%c${message} `, 'color: blue; font-size: 20px; background: #ccc');
      console.log(preparedList);
      return this;
    },
    filterAvailable(notAvailableLinks) {
      preparedList = preparedList.filter(instagramPost =>
        isLoadedInstagramUrl(instagramPost, notAvailableLinks)
      );
      return this;
    },
    cleanLinks() {
      preparedList = preparedList.map(url => getCleanedUrl(url));
      return this;
    },
    makeEaqualTypeOfLink() {
      preparedList = preparedList.map(link => getEaqualLinkView(link));
      return this;
    },
    uniqLinks() {
      preparedList = uniq(preparedList);
      return this;
    },
    shuffleLinks() {
      preparedList = shuffle([...preparedList]);
      return this;
    },
    spliceLinks(amount) {
      preparedList = [...preparedList].splice(0, amount);
      return this;
    }
  };
  return actions;
};

export default getPreparedInstagramPosts;
