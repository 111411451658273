import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import GiftSets from '../components/gift-sets/gift-sets';

import * as fromCategory from 'modules/product/ducks/categories';
import { actions as Category } from 'modules/product/ducks/categories';

const SLUG = 'gift-sets';

const getSlug = () => SLUG;

const SORT = {
  sortType: 'popularity',
  sortOrder: 'desc'
};

const mapState = createStructuredSelector({
  setList: fromCategory.makeGetProductSlugList(getSlug),
  isLoaded: fromCategory.makeGetIsProductsLoaded(getSlug)
});

const mapActions = {
  onShow: () => Category.loadProducts(SLUG, 0, [], null, SORT)
};

export default connect(
  mapState,
  mapActions
)(GiftSets);
