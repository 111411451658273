import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from './short-link';

class RouteLink extends Component {
  render() {
    const { href, ...rest } = this.props;
    return <Link component={RouterLink} to={href} {...rest} />;
  }
}

export default RouteLink;
