import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Container from 'modules/core/components/container/container';

import ListReview from '../list-review/list-review';

const { arrayOf, shape, string, number, func, object, bool } = PropTypes;
class SectionReviewList extends Component {
  static propTypes = {
    list: arrayOf(object),
    sortOptionList: arrayOf(
      shape({
        type: string,
        title: string
      })
    ),
    pageAmount: number,
    currentPage: number,
    onSortChange: func,
    onPageChange: func,
    withoutContainer: bool
  };

  render() {
    const { withoutContainer, ...rest } = this.props;

    return (
      <section className="SectionListReview">
        {withoutContainer ? (
          <ListReview {...rest} />
        ) : (
          <Container narrow>
            <ListReview {...rest} />
          </Container>
        )}
      </section>
    );
  }
}

export default SectionReviewList;
