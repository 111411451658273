import React, { Component } from 'react';
import Container from '../container/container';
// import SideNavigation from '../side-navigation/side-navigation';
import Content from '../content/content';

import Tile from './tile/tile';
import PaymentInfo from './payment-info/payment-info';
import PaymentInfoShort from './payment-info-short/payment-info-short';

import { IconMaster, IconVisa, IconCardWorld, IconGooglePay, IconApplePay } from '../icons';

import './payment-and-delivery-section.css';

const timeList = [
  {
    title: 'Рабочие дни',
    value: 'с 9:00 до 18:00'
  }
];

const paymentList = [
  {
    title: 'Курьером',
    // importantTitle: 'от 3 000руб.',
    value: '490 руб.'
  },

  {
    title: 'В постаматы и пункты выдачи Pick point',
    value: '290 руб.'
  },

  {
    title: 'В пункты выдачи IML',
    value: '220 руб.'
  },

  {
    title: 'Доставкой TK DPD',
    value: '490 руб.'
  },

  {
    title: 'Почтой России',
    value: '220 руб.'
  }
];

const timeListMain = [
  {
    title: 'Рабочие дни',
    value: 'с 10:00 до 21:00'
  }
];

const paymentListMain = [
  {
    title: 'Курьером (в пределах МКАД и КАД)',
    // importantTitle: 'от 3 000руб.',
    value: '290 руб.'
  },

  {
    title: 'В постаматы и пункты выдачи Pick point',
    value: '290 руб.'
  },

  {
    title: 'В пункты выдачи IML',
    value: '220 руб.'
  },

  {
    title: 'Почтой России',
    value: '220 руб.'
  }
];

const freeCost = {
  title: 'При заказе',
  importantTitle: 'от 3 000 руб.',
  importantInfo: 'БЕСПЛАТНО'
};

const mainDescription =
  'Доставка в постаматы и пункты выдачи Pick point осуществляется только после 100 % предоплаты.';

const description = 'Доставка осуществляется только после 100% предоплаты.';

const listKazahstan = [
  {
    title: 'Стоимость доставки курьером',
    value: '800 руб.'
  },
  {
    title: 'Срок',
    value: '3-5 дней'
  }
];

const listBelarus = [
  {
    title: 'Стоимость доставки курьером',
    value: '600 руб.'
  },
  {
    title: 'Срок',
    value: '3-5 дней'
  }
];

// const linkList = [
//   {
//     title: 'Доставка и оплата',
//     path: '/help/payment-and-delivery'
//   },
//   {
//     title: 'Обмен и возврат',
//     path: '/help/exchange-and-return'
//   },
//   {
//     title: 'FAQ',
//     path: '/help/faq'
//   }
// ];

// const content = `
//                    <h3>Доставка</h3>
//                    <p><b>Наш курьер доставит ваш заказ по Москве, Московской области и в регионы Российской Федерации</b></p>
//                    <p>Парфюмерно-косметические товары входят в перечень непродовольственных товаров надлежащего качества, не подлежащих возврату или обмену на аналогичный товар других размера, формы, габарита, фасона, расцветки или комплектации (в ред. Постановлений Правительства РФ от 20.10.1998 N 1222, от 06.02.2002 N 81)</p>
//                    <h3>Доставка в постаматы Pick point</h3>
//                    <p>Постамат – автоматизированный посылочный терминал, куда доставляется ваш заказ. Далее вы самостоятельно забираете заказ в удобное вам время, следуя инструкциям в меню терминала.</p>
//                    <p>Постаматы установлены в торговых центрах, крупных розничных сетях и супермаркетах по всей территории России! Узнайте, где находится постамат в вашем городе.</p>
//                    <iframe width="100%" src="https://www.youtube.com/embed/DCPG4IQRS3k?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
//                    <h3>Способ оплаты</h3>
//                    <p><b>Доставка осуществляется только по 100% предоплате.</b> Оплата происходит через авторизационный сервер Процессингового центра Банка с использованием Банковских кредитных карт следующих платежных систем:</p>
//                    <ul>
//                       <li>VISA International</li>
//                       <li>MasterCard World Wide</li>
//                    </ul>
//                    <h4>Описание процесса передачи данных</h4>
//                    <p>Для оплаты покупки Вы будете перенаправлены на платежный шлюз ПАО "Сбербанк России" для ввода реквизитов Вашей карты. Пожалуйста, приготовьте Вашу пластиковую карту заранее. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL.</p>
//                    <p>В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или MasterCard Secure Code для проведения платежа также может потребоваться ввод специального пароля. Способы и возможность получения паролей для совершения интернет-платежей Вы можете уточнить в банке, выпустившем карту. Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО "Сбербанк России". Введенная информация   не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ.</p>
//                    <p>Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем Visa Int. и MasterCard Europe Sprl.</p>
//                    <h4>Описание процесса передачи данных</h4>
//                    <p>При выборе формы оплаты с помощью пластиковой карты проведение платежа по заказу производится непосредственно после его оформления. После завершения оформления заказа в нашем магазине, Вы должны будете нажать на кнопку «Оплата банковской картой», при этом система переключит Вас на страницу авторизационного сервера, где Вам будет предложено ввести данные пластиковой карты, инициировать ее авторизацию, после чего вернуться в наш магазин кнопкой "Вернуться в магазин".</p>
//                    <p>После того, как Вы возвращаетесь в наш магазин, система уведомит Вас о результатах авторизации. В случае подтверждения авторизации Ваш заказ будет автоматически выполняться в соответствии с заданными Вами условиями. В случае отказа в авторизации карты Вы сможете повторить процедуру оплаты.</p>
// `;

class PaymentAndDeliverySection extends Component {
  render() {
    return (
      <div className="PaymentAndDeliverySection">
        <Container>
          <div className="PaymentAndDeliverySection-content">
            {/* <div className="PaymentAndDeliverySection-sidebarCol">
              <SideNavigation list={linkList} />
            </div>
            <div className="PaymentAndDeliverySection-contentCol">
              <Content html={content} />
            </div> */}
            <div className="PaymentAndDeliverySection-exchangeAndReturn">
              <Content
                html={`
                      <p>Наш курьер доставит ваш заказ по Москве, Московской области и в регионы Российской Федерации.
                      В случае не получения заказа клиентом, повторная отправка посылки осуществляется за счет средств клиента. 
                      Если клиент отказывается от доставки после передачи заказа в Транспортную компанию или не забирает свой заказ в месте вручения, то стоимость доставки и стоимость возврата отправления на склад ООО "Миксит" будет удержана с клиента при возврате средств.</p>
                      <h1>Правила обмена и возврата:</h1>
                      <p>
                        Парфюмерно-косметические товары входят в перечень непродовольственных товаров надлежащего качества,
                        не подлежащих возврату или обмену на аналогичный товар других размера, формы, габарита, фасона,
                        расцветки или комплектации (в ред. Постановлений Правительства РФ от 20.10.1998 N 1222,
                          от 06.02.2002 N 81)
                      </p>`}
                expanded
              />
            </div>

            <div className="PaymentAndDeliverySection-deliveryMethods">
              <Content html={`<h1>Способы доставки заказов:</h1>`} expanded />

              <div className="PaymentAndDeliverySection-deliveryMethodsGrid">
                <div className="PaymentAndDeliverySection-deliveryMethod">
                  <Tile
                    title="По Москве и Санкт-Петербургу"
                    renderContent={() => (
                      <PaymentInfo
                        timeList={timeListMain}
                        paymentList={paymentListMain}
                        freeCost={freeCost}
                        description={mainDescription}
                      />
                    )}
                  />
                </div>

                <div className="PaymentAndDeliverySection-deliveryMethod">
                  <Tile
                    title="В регионы России"
                    renderContent={() => (
                      <PaymentInfo
                        timeList={timeList}
                        paymentList={paymentList}
                        freeCost={freeCost}
                        description={mainDescription}
                      />
                    )}
                  />
                </div>

                <div className="PaymentAndDeliverySection-deliveryMethod">
                  <Tile
                    title="В Казахстан"
                    renderContent={() => (
                      <PaymentInfoShort list={listKazahstan} description={description} />
                    )}
                  />
                </div>

                <div className="PaymentAndDeliverySection-deliveryMethod">
                  <Tile
                    title="В Беларусь"
                    renderContent={() => (
                      <PaymentInfoShort list={listBelarus} description={description} />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="PaymentAndDeliverySection-Pick pointsHolder">
            <div className="PaymentAndDeliverySection-Pick points">
              <div className="PaymentAndDeliverySection-Pick pointTextCol">
                <Content
                  html={`
                        <h1>Порядок получения посылки у курьера, в пунке выдачи заказов, в отделении Почты России:</h1>
                        <p>
                        Прежде чем расписаться в получении посылки, убедитесь в ее целостности. Внимательно осмотрите посылку на наличие дефектов, повреждений и пр. Привлечь Ваше внимание могут смятые углы, поврежденные швы и неровно наклеенный скотч. Если Вас настораживает внешний вид упаковки или есть подозрение, что посылка пришла пустой:
                        </p>
                        <p>
                          <ul>
                            <li>Требуйте вскрытия посылки</li>
                            <li>Проводится сверка содержимого с накладной.</li>
                            <li>Рекомендуется сопровождать процесс фото/видеофиксацией.</li>
                            <li>Процедура вскрытия в обязательном порядке сопровождается составлением Акта.</li>
                          </ul>
                        </p>
                        <p>
                        Составьте совместно с курьером либо оператором Акт. Подробно опишите внешнее состояние: Опишите то, что было обнаружено внутри при вскрытии; Отметьте все обнаруженные дефекты.<br/> 
                        </p>
                        <p>
                          <b>Важно:</b> На основании данного документа проводится расследование, по результатам которого будет принято решение о выплате компенсации.
                        </p>
                        `}
                  expanded
                />
              </div>

              <div className="PaymentAndDeliverySection-Pick pointTextCol">
                <Content
                  html={`
                        <h1>Доставка в постаматы Pick point:</h1>
                        <p>
                          Постамат – автоматизированный посылочный терминал, куда доставляется ваш заказ.
                          Далее вы самостоятельно забираете заказ в удобное вам время, следуя инструкциям в
                          меню терминала.
                        </p>
                        <p>
                          Постаматы установлены в торговых центрах, крупных розничных сетях и супермаркетах
                          по всей территории России!
                        </p>`}
                  expanded
                />
              </div>

              <div className="PaymentAndDeliverySection-Pick pointVideoCol">
                <div className="PaymentAndDeliverySection-video">
                  <Content
                    html={`<iframe width="100%" src="https://www.youtube.com/embed/_zPEx5cTmOI?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen ></iframe>`}
                    expanded
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="PaymentAndDeliverySection-paymentMethods">
            <div className="PaymentAndDeliverySection-paymentMethodsText">
              <Content
                html={`
                      <h1>Способ оплаты заказов:</h1>
                      <p>
                        Доставка осуществляется только по 100% предоплате. Оплата происходит через авторизационный сервер
                        Процессингового центра Банка с использованием Банковских кредитных карт следующих платежных систем:
                      </p>`}
                expanded
              />
            </div>

            <div className="PaymentAndDeliverySection-paymentMethodsMedia">
              <div className="PaymentAndDeliverySection-paymentMethodsMediaItem">
                <IconMaster />
              </div>

              <div className="PaymentAndDeliverySection-paymentMethodsMediaItem">
                <IconVisa />
              </div>

              <div className="PaymentAndDeliverySection-paymentMethodsMediaItem">
                <IconGooglePay />
              </div>

              <div className="PaymentAndDeliverySection-paymentMethodsMediaItem">
                <IconApplePay />
              </div>

              <div className="PaymentAndDeliverySection-paymentMethodsMediaItem">
                <IconCardWorld />
              </div>
            </div>
          </div>

          <div className="PaymentAndDeliverySection-dataTransfer">
            <Content
              html={`
                      <h1>Описание процесса передачи данных:</h1>
                      <p>
                        Для оплаты покупки Вы будете перенаправлены на платежный шлюз ПАО "Сбербанк России" для ввода
                        реквизитов Вашей карты. Пожалуйста, приготовьте Вашу пластиковую карту заранее. Соединение с
                        платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием
                        протокола шифрования SSL.
                      </p>
                      <p>
                        В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей
                        Verified By Visa или MasterCard Secure Code для проведения платежа также может потребоваться
                        ввод специального пароля. Способы и возможность получения паролей для совершения интернет-платежей
                        Вы можете уточнить в банке, выпустившем карту. Настоящий сайт поддерживает 256-битное шифрование.
                        Конфиденциальность сообщаемой персональной информации обеспечивается ПАО "Сбербанк России".
                        Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных
                        законодательством РФ.
                      </p>
                      <p>
                        Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных
                        систем Visa Int. и MasterCard Europe Sprl.
                      </p>`}
              expanded
            />
          </div>

          <div className="PaymentAndDeliverySection-payment">
            <Content
              html={`
                      <h1>Описание процесса оплаты:</h1>
                      <p>
                        При выборе формы оплаты с помощью пластиковой карты проведение платежа по заказу производится
                        непосредственно после его оформления. После завершения оформления заказа в нашем магазине,
                        Вы должны будете нажать на кнопку «Оплата банковской картой», при этом система переключит
                        Вас на страницу авторизационного сервера, где Вам будет предложено ввести данные пластиковой
                        карты, инициировать ее авторизацию, после чего вернуться в наш магазин кнопкой "Вернуться в магазин".
                      </p>
                      <p>
                        После того, как Вы возвращаетесь в наш магазин, система уведомит Вас о результатах авторизации.
                        В случае подтверждения авторизации Ваш заказ будет автоматически выполняться в соответствии с заданными
                        Вами условиями. В случае отказа в авторизации карты Вы сможете повторить процедуру оплаты.
                      </p>`}
              expanded
            />
          </div>
        </Container>
      </div>
    );
  }
}

export default PaymentAndDeliverySection;
