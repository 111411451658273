import qs from 'qs';
import * as Sentry from '@sentry/browser';

export const getIndexLink = () => `/`;
export const getCategoryLink = ({ slug }, nested = null, parameters = null) => {
  let url = `/category/${slug}`;

  if (nested && nested.slug) {
    url += `/${nested.slug}`;
  }

  if (parameters) {
    const queryString = qs.stringify(parameters, {
      indices: false,
      encodeValuesOnly: true
    });

    url += `?${queryString}`;
  }

  return url;
};

export const getSearchLink = (query, category = null, parameters = null) => {
  let url = `/search/${query}`;

  if (category) {
    url += `/${category}`;
  }

  if (parameters) {
    const queryString = qs.stringify(parameters, {
      indices: false,
      encodeValuesOnly: true
    });

    url += `?${queryString}`;
  }

  return url;
};
export const getProductLink = (product = {}) => {
  const { slug } = product;
  if (typeof slug === 'undefined') {
    Sentry.captureException(
      new Error(
        `Cannot create product link with undefined \`slug\`. Product: ${JSON.stringify(product)}`
      )
    );
  }
  return `/product/${slug}`;
};
export const getSpecialOfferLink = slug => {
  return `/special-offers/${slug}`;
};

export const getIngredientLink = ({ id }) => `/ingredient/${id}`;

export const getGroupLink = ({ slug }) => `/group/${slug}`;
export const getGroupBaseLink = () => `/group`;

export const getSpecialOffersLink = () => `/special-offers`;

export const getBasketLink = () => `/basket`;
export const getOrderLink = ({ id }) => `/ordering/${id}`;
export const getOrderingSuccessLink = ({ id }) => `/ordering/${id}/success`;

export const getBlogLink = () => `/magazine`;
export const getArticleLink = ({ slug }) => `/magazine/${slug}`;

export const getFeedbackLink = () => `/feedback`;
export const getAskQuestion = () => `/ask-question`;

export const getProfileLink = () => `/profile`;
export const getProfileGeneralLink = () => `/profile/general`;
export const getProfileTraitsLink = anchor => `/profile/general/#${anchor}`;
export const getFavoritesLink = () => `/profile/favorites`;
export const getProfileOrderLink = ({ id }) => `/profile/orderings/${id}`;
export const getProfileOrderListLink = () => `/profile/orderings`;
export const getProfileBonusHistory = () => `/profile/bonuses/history`;
export const getProfileBonusHistoryById = () => `/profile/bonuses/history/`;
export const getFeedbackThanksLink = () => `/feedback/thanks/`;
export const getEmailFeedbackLink = ({ variant }) => `/email-feedback/${variant}`;
export const getEmailFeedbackThanksLink = () => `/email-feedback-thanks`;

/* STATIC */
export const getShopsLink = () => `/shops`;
export const getContactsLink = () => `/contacts`;
export const getAboutLink = () => `/about`;
export const getPaymentAndDeliveryLink = () => `/payment-and-delivery`;
export const getRewardLink = () => `/reward`;
export const getDeclarationsLink = () => `/declarations`;
export const getError404Link = () => `/error-404`;
export const getAgreementsLink = () => `/agreements`;
export const getGiftCertificateRules = () => `/gift-sertifikat-rules`;
export const getGiftCookie = () => `/cookie`;

export const getMoscovCastomersDayNotificationLink = () => '/msk';
export const getSPCastomersDayNotificationLink = () => '/spb';
export const getLandingLink = () => '/landing/:slug';

export const getRedirectPaymentPage = () => '/redirect-payment-page';
export const getHappyMasterPageLink = () => '/happy-master';
