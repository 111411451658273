import { takeEvery, put, call } from 'redux-saga/effects';
import { mixitApi } from 'services/mixit';

import { deserializeMedia } from 'modules/product/deserializers/media';

import * as DEPRECATED_SPECIAL_OFFERS from '../ducks/types/deprecated-special-offers';
import * as DeprecatedSpecialOffers from '../ducks/actions/deprecated-special-offers';

function normalizeOffer(apiOffer) {
  const {
    id,
    title,
    description,
    begin_date,
    end_date,
    link,
    media,
    is_primary,
    promocode
  } = apiOffer;

  return {
    id,
    title,
    description,
    beginDate: begin_date,
    endDate: end_date,
    link,
    media,
    isPrimary: is_primary,
    promocode
  };
}

export function* deprecatedSpecialOffersWatcher() {
  yield takeEvery(DEPRECATED_SPECIAL_OFFERS.SHOW_LIST, handleRequest);
}

function* handleRequest() {
  try {
    const response = yield call(mixitApi().deprecatedSpecialOffers);

    const specialOffersList = response.data;

    const normalizedSpecialOffersList = specialOffersList.map(offer => normalizeOffer(offer));

    const deserializedSpecialOffersList = normalizedSpecialOffersList.map(offer => {
      const media = deserializeMedia(offer.media);

      return { ...offer, media };
    });

    yield put(DeprecatedSpecialOffers.addDeprecatedSpecialOffers(deserializedSpecialOffersList));
  } catch (e) {
    console.error(e);
  }
}
