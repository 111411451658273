export const RATE_TYPE = {
  LIKE: 'RATE/LIKE',
  DISLIKE: 'RATE/DISLIKE'
};

const RATE_OPTIONS = [RATE_TYPE.LIKE, RATE_TYPE.DISLIKE];

export const RATE_VARIANTS = [...RATE_OPTIONS, null];

export const RATE_ACTION_TO_PROP = {
  [RATE_TYPE.LIKE]: 'likes',
  [RATE_TYPE.DISLIKE]: 'dislikes'
};
