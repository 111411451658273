import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromAccount from 'modules/core/ducks/account';
import * as fromSubscribe from 'modules/core/ducks/subscribe';
import { getIsSabscribeVersionA } from '../ducks/a-b-test-markers';

import actions from 'modules/core/ducks/actions/subscribe';
import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';
import Subscribe from 'modules/core/components/subscribe/subscribe';
import NewSubscribe from 'modules/core/components/new-subscribe/new-subscribe';

const mapStateToProps = createStructuredSelector({
  isLoading: fromSubscribe.getIsLoading,
  accountEmail: fromAccount.getEmail,
  isAuthorized: fromAccount.getIsAuthorized,
  isSubmitted: fromSubscribe.getIsSubmitted,
  requestedEmail: fromSubscribe.getRequestedEmail,
  isSabscribeVersionA: getIsSabscribeVersionA
});

const mapDispatchToProps = {
  request: actions.request
};

class SubscribeContainer extends Component {

  render() {
    const { isLoading, isSabscribeVersionA } = this.props;

    console.log('isSabscribeVersionA', isSabscribeVersionA);

    const _props = {
      key: isLoading ? 'isLoading' : this.getAccountEmail(),
      onSubmit: this.handleSubmit,
      isLoading: isLoading,
      accountEmail: this.getAccountEmail()
    }

    return (
      isSabscribeVersionA ? (
        <SectionDefault>
          <NewSubscribe {..._props} />
        </SectionDefault >
      ) :
        (
          <SectionDefault highlightBackground >
            <Container>
              <Subscribe {..._props} />
            </Container>
          </SectionDefault >
        )
    )

  }

  getAccountEmail() {
    const { accountEmail, isAuthorized, isSubmitted, isLoading, requestedEmail } = this.props;

    if (isLoading) {
      return requestedEmail;
    }

    return isAuthorized && !isSubmitted ? accountEmail : null;
  }

  handleSubmit = data => {
    const { request } = this.props;

    request(data.email);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribeContainer);
