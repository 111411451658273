import React, { Component } from 'react';
import cn from 'classnames';

import Loader from 'modules/core/components/loader/loader';

import InstagramGalleryList from '../instagram-group/instagram-gallery-list/instagram-gallery-list';
import InstagramSocialNetwork from './instagram-social-network/instagram-social-network';

import './instagram-group.css';

class InstagramGroup extends Component {
  render() {
    const { instagramPosts, isLoading } = this.props;

    return (
      <div
        className={cn('InstagramGroup', {
          'InstagramGroup--withoutInstagramLinks': !isLoading && instagramPosts.length === 0
        })}
      >
        {(isLoading || instagramPosts.length !== 0) && (
          <div className="InstagramGroup-item">
            {!isLoading ? (
              <InstagramGalleryList instagramPosts={instagramPosts} />
            ) : (
              <Loader type="spin" size="small" secondary />
            )}
          </div>
        )}

        <div className="InstagramGroup-item">
          <InstagramSocialNetwork />
        </div>
      </div>
    );
  }
}

export default InstagramGroup;
