import { createSelector } from 'reselect';
import { createReducer, handle } from 'modules/utils/dux';
import * as PROFILE from '../types/profile';
import * as fromProduct from 'modules/product/ducks/products';
import * as fromProfile from './profile';
import uniqBy from 'lodash/uniqBy';

const ALL_ID = -1;
const ALL_OPTION = {
  title: 'Все',
  id: ALL_ID
};

const favoritesFilter = createReducer(
  handle(PROFILE.FAVORITES_FILTER, (state, { option }) => option)
);

export default favoritesFilter(ALL_ID);

const getProfileModule = state => state.profile;

export const getActiveOption = createSelector(
  getProfileModule,
  profileModule => profileModule.favoritesFilter
);

export const getProductList = createSelector(
  fromProduct.getFindProduct,
  fromProfile.getFavoriteList,
  (findProduct, favorites) => {
    return favorites.map(findProduct);
  }
);

export const getFavoriteCategories = createSelector(
  getProductList,
  productList => {
    const categories = uniqBy(productList.map(item => item.category.parent || item.category), 'id');
    return [ALL_OPTION, ...categories];
  }
);

export const getFilteredProducts = createSelector(
  getProductList,
  getActiveOption,
  (productList, selectedCategory) => {
    if (!productList) {
      return null;
    }

    if (selectedCategory === ALL_ID) {
      return productList;
    }

    return productList.filter(item => {
      const category = item.category.parent || item.category;
      return category.id === selectedCategory;
    });
  }
);

export const getFilteredProductIds = createSelector(
  getFilteredProducts,
  filteredProducts => {
    if (!filteredProducts) {
      return null;
    }

    return filteredProducts.map(item => item.slug);
  }
);
