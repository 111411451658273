import React, { Component } from 'react';
import PropTypes from 'prop-types';

import isFunction from 'modules/utils/is-function';

import FeedbackForm from '../components/feedback-form/feedback-form';
const { func } = PropTypes;

class FeedbackFormContainer extends Component {
  state = {
    text: '',
    advantage: '',
    disadvantage: '',
    name: '',
    email: '',
    rating: 0
  };

  static propTypes = { onSubmit: func };

  render() {
    const { rating, text, advantage, disadvantage, name, email } = this.state;

    const { ...rest } = this.props;

    return (
      <FeedbackForm
        text={text}
        rating={rating}
        advantage={advantage}
        disadvantage={disadvantage}
        name={name}
        email={email}
        onTextChange={this.handleTextChange}
        onRatingChange={this.handleRatingChange}
        onAdvantageChange={this.handleAdvantageChange}
        onDisadvantageChange={this.handleDisadvantageChange}
        onNameChange={this.handleNameChange}
        onEmailChange={this.handleEmailChange}
        {...rest}
        onSubmit={this.handleSubmit}
      />
    );
  }

  setName(name) {
    this.setState({
      name
    });
  }

  setEmail(email) {
    this.setState({
      email
    });
  }

  setText(text) {
    this.setState({
      text
    });
  }

  setAdvantage(advantage) {
    this.setState({
      advantage
    });
  }

  setDisadvantage(disadvantage) {
    this.setState({
      disadvantage
    });
  }

  resetReviewForm() {
    this.setState({
      text: '',
      advantage: '',
      disadvantage: '',
      name: '',
      email: '',
      rating: 0
    });
  }

  setRating(rating) {
    this.setState({
      rating
    });
  }

  createReviewData() {
    const { text, advantage, disadvantage, rating, name, email } = this.state;

    const { isAuthorized } = this.props;

    if (isAuthorized) {
      return { text, advantage, disadvantage, rating };
    }

    return { text, advantage, disadvantage, rating, name, email };
  }

  submitData(data) {
    const { onSubmit } = this.props;

    if (!isFunction(onSubmit)) {
      return;
    }
    onSubmit(data);
  }

  handleTextChange = text => {
    this.setText(text);
  };

  handleAdvantageChange = advantage => {
    this.setAdvantage(advantage);
  };

  handleDisadvantageChange = disadvantage => {
    this.setDisadvantage(disadvantage);
  };

  handleRatingChange = rating => {
    this.setRating(rating);
  };

  handleNameChange = name => {
    this.setName(name);
  };

  handleEmailChange = email => {
    this.setEmail(email);
  };

  handleSubmit = e => {
    e.preventDefault();
    const data = this.createReviewData();

    this.submitData(data);
    this.resetReviewForm();
  };
}

export default FeedbackFormContainer;
