import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import { Formik, Field as FormikField } from 'formik';
import * as Yup from 'yup';

import Dialog from 'modules/core/components/dialog/dialog';
import Field from 'modules/form/components/field/field';
import Form from 'modules/form/components/form/form';
// import ReactPhoneInput from 'modules/form/components/react-phone-input/react-phone-input';
import Input from 'modules/form/components/input/input';
import getSeparatedPhone from 'modules/core/components/input-phone-with-area-code/helpers/get-separated-phone';
import Link from 'modules/core/components/link/short-link';
import Checkbox from 'modules/form/components/checkbox/checkbox';
import plural from 'modules/utils/plural';

import InputPhoneWithAreaCode from './../input-phone-with-area-code/input-phone-with-area-code_new';
import areaCodes from './../input-phone-with-area-code/area-codes';
import ConditionalWrapper from 'modules/core/components/conditional-wrapper/conditional-wrapper';

import Button from '../button/button';

import './callback-dialog.css';

// const phoneTemplateLength = ' (xxx) xxx xxxx'.length;

class CallbackDialog extends Component {
  state = {
    phone: this.props.profilePhone || ''
  };

  render() {
    const { profileFirstName, profilePhone, isCallbackDialog, marketingLandingData, profileEmail, ...rest } = this.props;
    const { phone } = this.state;
    const isMarketingLanding = Boolean(marketingLandingData);

    this.initialValues = {
      firstName: profileFirstName || '',
      ...(isMarketingLanding ? {email: profileEmail || ''}: {phone: profilePhone || ''}),
      confirm: false
    };

    const phoneLength =
      phone && phone.length && getSeparatedPhone(phone, areaCodes).code
        ? getSeparatedPhone(phone, areaCodes).code.phoneLength
        : areaCodes[0].phoneLength;

    const EMAIL_REQUIRED_ERROR = 'Введите свой email';
    const EMAIL_FORMAT_ERROR = 'Неверный формат введенной почты';
    const NAME_REQUIRED_ERROR = 'Укажите Ваше имя';
    const PHONE_REQUIRED_ERROR = 'Укажите Ваш номер телефона';
    const PHONE_SHORT_ERROR = `Номер должен содержать ${phoneLength} цифр, не хватает ${phoneLength -
      this.state.phone.length +
      1}${plural(phoneLength - this.state.phone.length + 1, ['-й', '-x', '-ти'])}`;
    const CONFIRM_ERROR = 'Поле дожно быть выбранно';

    const credentialsSchema = Yup.object().shape({
        ...(isMarketingLanding ? 
          { email: Yup.string()
            .required(EMAIL_REQUIRED_ERROR)
            .email(EMAIL_FORMAT_ERROR),}:
          { phone: Yup.string()
            .required(PHONE_REQUIRED_ERROR)
            .min(phoneLength, PHONE_SHORT_ERROR),}),
      firstName: Yup.string()
        .trim()
        .required(NAME_REQUIRED_ERROR),
      confirm: Yup.bool().oneOf([true], CONFIRM_ERROR)
    });

    return (
      <ConditionalWrapper
        condition={isCallbackDialog}
        wrappElement={<Dialog closeable overflowVisible {...rest}/>}
      >
        <Formik
          validationSchema={credentialsSchema}
          initialValues={this.initialValues}
          onSubmit={this.handleSubmit}
          render={props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid
            } = props;
            return (
              <div className="CallbackDialog">
                {isCallbackDialog && (
                  <>                
                    <h1 className="CallbackDialog-title">Обратный звонок</h1>
                    <p className="CallbackDialog-info">
                      Оставьте заявку, и мы с Вами обязательно свяжемся в ближайшее время!
                    </p>
                    <p className="CallbackDialog-warningInfo">
                      Внимание!!! В связи с повышенной нагрузкой время обработки заказов составляет 24
                      часа. Приносим свои извинения.
                    </p>
                  </>
                )}

                {isMarketingLanding && this.renderHeader()}

                <div className="CallbackDialog-form">
                  <Form onSubmit={handleSubmit}>
                    <Media query="(max-width: 840px)">
                      {isMobile => (
                        <Fragment>
                          <Form.LabeledRow>
                            <Field
                              label="Имя"
                              error={touched.firstName && errors.firstName}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <Input
                                name="firstName"
                                value={values.firstName}
                                state={touched.firstName && errors.firstName && 'error'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={isMobile ? 'small' : 'normal'}
                                // required
                              />
                            </Field>
                          </Form.LabeledRow>

                          <Form.LabeledRow>
                            {isMarketingLanding ? (
                              <Field error={touched.email && errors.email}>
                                <Input
                                  type="email"
                                  placeholder="Введите e-mail"
                                  name="email"
                                  size={isMobile ? 'small' : 'normal'}
                                  state={touched.email && errors.email && 'error'}
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                            </Field>
                            ) : (
                            <Field
                              label="Телефон"
                              error={touched.phone && errors.phone}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <FormikField
                                name="phone"
                                render={({ field, form }) => {
                                  return (
                                    <>
                                      <InputPhoneWithAreaCode
                                        name={field.name}
                                        codeList={areaCodes}
                                        value={field.value}
                                        size={isMobile ? 'small' : 'normal'}
                                        // value="+380506848618"
                                        onChange={this.handlePhoneChange(form)(field.name)}
                                      />
                                    </>
                                  );
                                }}
                              />
                            </Field>
                            )}
                          </Form.LabeledRow>
                          <Form.Row>
                            <Field
                              // label="Submit"
                              error={touched.confirm && errors.confirm}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <div className="CallbackDialog-submitField">
                                <div className="CallbackDialog-checkSubmit">
                                  <FormikField
                                    name="confirm"
                                    render={({ field }) => (
                                      <Checkbox
                                        {...field}
                                        variant={isMarketingLanding && "purple"}
                                        label={this.renderSubmitLable()}
                                        value={field.value}
                                        checked={field.value}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </Field>
                          </Form.Row>
                          <Form.Row>
                            <Field>
                              <div className="CallbackDialog-submit">
                                <Button
                                  title={isMarketingLanding ? 
                                          marketingLandingData.button_text : 
                                          "Перезвонить мне"}
                                  variant={isMarketingLanding ? "black" : "primary"}
                                  type="submit"
                                  size={isMobile ? 'small' : 'normal'}
                                  visualyDisabled={!isValid}
                                  expanded
                                />
                              </div>
                            </Field>
                          </Form.Row>
                        </Fragment>
                      )}
                    </Media>
                  </Form>
                </div>
              </div>
            );
          }}
        />
      </ConditionalWrapper>
    );
  }

  renderHeader() {
    const {marketingLandingData} = this.props;
    
    if(!marketingLandingData) {
      return null
    }
    const Header = marketingLandingData.header;

    return <Header data={marketingLandingData}></Header>
  }

  renderSubmitLable() {
    return (
      <>
        <span>Я согласен(-на) на обработку</span>{' '}
        <Link href="https://mixit.ru/upload/personal_data.pdf" target="_blank">
          персональных данных
        </Link>
      </>
    );
  }

  setPhone(phone) {
    this.setState({ phone: phone });
  }

  handleSubmit = (data, {resetForm }) => {
    const { onClose, request, marketingLandingData } = this.props;

    const { phone } = this.state;

    const requestData = {
      name: data.firstName,
      ...(marketingLandingData ? {email: data.email} : {phone}),
      ...(Boolean(marketingLandingData) && {action: "send guide"})
    };

    request(requestData);
    onClose();
    resetForm()
  };

  handlePhoneChange = form => name => value => {
    form.setFieldValue(name, value);
    this.setPhone(value);
  };
}

export default CallbackDialog;
