import * as TYPES from '../types/instagram-posts';
import { createAction } from 'modules/utils/dux';

export default {
  request() {
    return createAction(TYPES.REQUEST);
  },

  response(postList) {
    return createAction(TYPES.RESPONSE, { postList });
  },

  error(message) {
    return createAction(TYPES.ERROR, { message });
  }
};
