import { createSelector } from 'reselect';
import { getProductsModule } from '../../meta';
import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromVariantOptions from '../variant-options';
import * as fromProfile from 'modules/profile/ducks/profile';
import * as fromIngredients from 'modules/ingredient/ducks/ingredients';
import { createVariantSet, transformProduct } from './helpers';
import { VARIANT_OPTION } from './constants';

/* SCOPED SELECTORS */

/*
const fromProduct = {
  getId(product) {
    return product.slug;
  }
};

const fromProductMap = {
  getItem(productsMap, slug) {
    return productsMap[slug];
  }
};
*/

/* GLOBAL SELECTORS */
export const getIdFromProps = (_, { id }) => id;
export const getSlugFromProps = (_, { slug }) => slug;

export const getSlugFromRouteParams = (_, props) => {
  if (!props.match || !props.match.params) {
    return null;
  }

  return props.match.params.slug;
};

export const getProducts = createSelector(
  getProductsModule,
  (productsModule = { products: [] }) => productsModule.products
);

export const getFindInBasket = createSelector(
  fromBasket.getAccessIsProductInBasketBySlug,
  function _makeGetIsInBasket(accessIsProductInBasketBySlug) {
    return slug => accessIsProductInBasketBySlug(slug);
  }
);

export const getAmountInBasket = createSelector(
  fromBasket.getAccessAmountProductInBasketBySlug,
  function _makeGetIsInBasket(accessAmountProductInBasketBySlug) {
    return slug => accessAmountProductInBasketBySlug(slug);
  }
);

export const getFindProduct = createSelector(getProducts, function _getFindProduct(products) {
  return function _findProduct(slug) {
    if (!products || !slug) {
      return null;
    }

    const product = products[slug];

    if (!product) {
      return null;
    }

    return product;
  };
});

export const getFindProductBySlug = createSelector(
  getFindProduct,
  getFindInBasket,
  getAmountInBasket,
  fromProfile.getCheckInFavorites,
  function _makeGetProductsBySlug(findProduct, findInBasket, amountInBasket, checkInFavorites) {
    return function _getProductsBySlug(slug) {
      const product = findProduct(slug);

      if (!product) {
        return null;
      }

      return transformProduct(product, {
        isInBasket: findInBasket(slug),
        amountInBasket: amountInBasket(slug),
        inFavorite: checkInFavorites(slug)
      });
    };
  }
);

export const makeGetItem = getSlug =>
  createSelector(getFindProduct, getSlug, function _getItem(findProduct, slug) {
    return findProduct(slug);
  });

export const getBySlugArgument = (slug) => createSelector(getFindProductBySlug, findProductBySlug => findProductBySlug(slug)) 

export const makeGetDerivedItem = getSlug =>
  createSelector(getFindProductBySlug, getSlug, function _getItem(findProductBySlug, slug) {
    return findProductBySlug(slug);
  });

export const makeGetDetails = getSlug =>
  createSelector(makeGetItem(getSlug), product => {
    if (!product) {
      return null;
    }
    return product.details;
  });

export const makeGetIsDetailsLoaded = getSlug =>
  createSelector(makeGetItem(getSlug), function _getIsDetailsLoaded(product) {
    if (!product) {
      return false;
    }
    return product.isDetailsLoaded;
  });
export const makeGetIsInBasket = getSlug =>
  createSelector(getFindInBasket, getSlug, (findInBasket, slug) => findInBasket(slug));
export const makeGetIsInFavorite = getSlug =>
  createSelector(makeGetItem(getSlug), product => product.isInFavorite);

export const getItemBySlug = makeGetDerivedItem(getSlugFromProps);
export const getItemByRouteParams = makeGetDerivedItem(getSlugFromRouteParams);
export const getItemByPropsParams = makeGetDerivedItem(getSlugFromProps);
export const getDetailsBySlug = makeGetDetails(getSlugFromProps);
export const getDetailsByRouteParams = makeGetDetails(getSlugFromRouteParams);
export const getIsDetailsLoaded = makeGetIsDetailsLoaded(getSlugFromProps);

export const makeGetConsistyncyString = getSlug =>
  createSelector(
    makeGetDetails(getSlug),
    fromIngredients.makeGetIngredientsByIds,
    (details, getIngredientsByIds) => {
      if (!details || !details.consistency || !details.consistency.ingredients) {
        return '';
      }
      return getIngredientsByIds(details.consistency.ingredients)
        .map(({ title }) => title)
        .join(', ');
    }
  );
export const getConsistencyStringByRouteParams = makeGetConsistyncyString(getSlugFromRouteParams);

export const makeGetVariantOptions = getSlug =>
  createSelector(makeGetItem(getSlug), fromVariantOptions.getAll, (product, variantOptions) => {
    if (!product) {
      return null;
    }
    // return product.variantOptions
    //   .map(id => variantOptions[id])
    //   .filter(Boolean);
    return product.variantOptionsAll;
  });

export const makeGetDefaultVariant = getSlug =>
  createSelector(
    makeGetItem(getSlug),
    makeGetVariantOptions(getSlug),
    (product, variantOptions) => {
      // TODO: Filter by availability
      // const availableVariants = product.variants.filter(item => item.available);
      // const defaultVariant = availableVariants[0];

      // if (!defaultVariant) {
      //   return null;
      // }

      const options = variantOptions;

      return {
        // ...defaultVariant,
        options
      };
    }
  );

export const getVariantOptions = makeGetVariantOptions(getSlugFromRouteParams);
export const getDefaultVariant = makeGetDefaultVariant(getSlugFromRouteParams);

export const getCurrentVariant = createSelector(() => ({
  id: 123,
  options: [123, 125]
}));

export const makeGetVariantSet = getSlug =>
  createSelector(makeGetVariantOptions(getSlug), variantOptions =>
    createVariantSet(variantOptions)
  );

export const makeDefaultOptionGetter = optionName => getSlug =>
  createSelector(makeGetDefaultVariant(getSlug), defaultVariant => {
    if (!defaultVariant || !defaultVariant.options) {
      return null;
    }

    const options = defaultVariant.options.filter(item => {
      // console.log('OPTION_GETTER_1', item.name, optionName);
      return item.name === optionName;
    });

    // console.log('OPTION_GETTER_2', options);

    if (!options.length) {
      return null;
    }

    return options[0];
  });

export const makeOptionListGetter = optionName => getSlug =>
  createSelector(makeGetVariantSet(getSlug), variantSet => {
    if (!variantSet) {
      return null;
    }

    return variantSet[optionName];
  });

export const getVariantSet = makeGetVariantSet(getSlugFromRouteParams);

export const getVolumeList = makeOptionListGetter(VARIANT_OPTION.VOLUME)(getSlugFromRouteParams);
export const getDefaultVolume = makeDefaultOptionGetter(VARIANT_OPTION.VOLUME)(
  getSlugFromRouteParams
);

export const getWeightList = makeOptionListGetter(VARIANT_OPTION.WEIGHT)(getSlugFromRouteParams);
export const getDefaultWeight = makeDefaultOptionGetter(VARIANT_OPTION.WEIGHT)(
  getSlugFromRouteParams
);

export const getColorList = makeOptionListGetter(VARIANT_OPTION.COLOR)(getSlugFromRouteParams);
export const getDefaultColor = makeDefaultOptionGetter(VARIANT_OPTION.COLOR)(
  getSlugFromRouteParams
);

export const getSizeList = makeOptionListGetter(VARIANT_OPTION.SIZE)(getSlugFromRouteParams);
export const getDefaultSize = makeDefaultOptionGetter(VARIANT_OPTION.SIZE)(getSlugFromRouteParams);

export const getCategory = createSelector(getItemBySlug, product =>
  product.category ? product.category.title : ''
);

export const getSubcategory = createSelector(getItemBySlug, () => 'средства');
