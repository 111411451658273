import { createReducer } from 'modules/utils/dux';
import { createSelector } from 'reselect';

const reducer = createReducer();

export default reducer({
  1: {
    id: 1,
    full: 'Москва, ул. Тверская, д.45б кв.20',
    city: 'Москва',
    address: 'ул. Тверская, д.45б кв.20',
    street: 'Тверская',
    building: '55',
    apartment: '20'
  },
  2: {
    id: 2,
    full: 'Тверь, ул. Московская, д.20 кв.55',
    city: 'Тверь',
    address: 'ул. Московская, д.20 кв.55',
    street: 'Московская',
    building: '20',
    apartment: '55'
  },
  3: {
    id: 3,
    full: 'Омск, ул. Киевская, д.202 кв.551',
    city: 'Омск',
    address: 'ул. Киевская, д.202 кв.551',
    street: 'Киевская',
    building: '202',
    apartment: '551'
  }
});

const getRoot = state => state.profile.deliveryAddresses;
export const getAll = createSelector(
  getRoot,
  root => root
);

export const getAccessAddressById = createSelector(
  getAll,
  all => id => {
    if (!all || !id) {
      return null;
    }
    return all[id];
  }
);
