import SPECIAL_OFFERS from '../types/special-offers';
import { createAction } from 'modules/utils/dux';

function requestSpecialOffers() {
  return createAction(SPECIAL_OFFERS.REQUEST);
}

function responseSpecialOffers(specialOffers) {
  return createAction(SPECIAL_OFFERS.RESPONSE, { specialOffers });
}

function errorSpecialOffers(error) {
  return createAction(SPECIAL_OFFERS.ERROR, { error });
}

function showSpecialOffer(slug) {
  return createAction(SPECIAL_OFFERS.SHOW_ITEM, { slug });
}

export default {
  requestSpecialOffers,
  responseSpecialOffers,
  errorSpecialOffers,
  showSpecialOffer
};
