import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './review-reply.css';

const { shape, string } = PropTypes;

class ReviewReply extends Component {
  static propTypes = {
    reply: shape({
      fullName: string,
      formatedCreateDate: shape({
        humanized: string,
        formatted: string,
        datetime: string
      }),
      text: string
    })
  };

  render() {
    const { reply } = this.props;
    const {
      author,
      // formatedCreateDate,
      content
    } = reply;

    const hasCommentText = content.text && content.text.length > 0;

    return (
      <div className="ReviewReply">
        <div className="ReviewReply-header">
          <div className="ReviewReply-name">{author.fullName || 'Аноним'}</div>
          {/* <time dateTime={formatedCreateDate.datetime} className="ReviewReply-date">
            {formatedCreateDate.formatted}
          </time> */}
        </div>
        <div className="ReviewReply-content">
          {hasCommentText && (
            <div className="ReviewReply-text" data-testid="content-text">
              {content.text}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ReviewReply;
