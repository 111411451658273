import React, { Component } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Bonuses from '../actions/bonuses';
import * as fromBonuses from '../ducks/bonuses';

import UserBonuses from '../components/user-bonuses/user-bonuses';

const mapStateToProps = createStructuredSelector({
  defaultBonuses: fromBonuses.getDefaultBonuses,
  extraBonuses: fromBonuses.getExtraBonuses,
  isBonusesLoaded: fromBonuses.getIsAllLoaded,
  isBonusesExist: fromBonuses.getIsBonusesExist,
  isActivated: fromBonuses.getIsActivated
});

const mapDispatchToProps = {
  loadBonuses: Bonuses.requestAll
};

class UserBonusesContainer extends Component {
  render() {
    const {
      defaultBonuses,
      extraBonuses,
      isBonusesLoaded,
      isBonusesExist,
      isActivated
    } = this.props;

    const isDisplayConditionFollowed = isBonusesLoaded && isBonusesExist && isActivated;

    if (!isDisplayConditionFollowed) {
      return null;
    }

    return (
      <UserBonuses defaultAmount={defaultBonuses.summary} extraAmount={extraBonuses.summary} />
    );
  }

  componentDidMount() {
    const { isBonusesLoaded, loadBonuses } = this.props;

    if (!isBonusesLoaded) {
      loadBonuses();
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBonusesContainer);
