import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroupItem from 'modules/form/components/form-group/form-group-item';
import Form from 'modules/form/components/form/form';
import FormRow from 'modules/form/components/form/form-row';
import FormGroup from 'modules/form/components/form-group/form-group';
import Textarea from 'modules/form/components/textarea/textarea';
import Button from 'modules/core/components/button/button';
import InlineField from 'modules/form/components/inline-field/inline-field';
import Field from 'modules/form/components/field/field';
import isFunction from 'modules/utils/is-function';

import InputStarRatingContainer from '../../containers/input-rating-container';

import Input from 'modules/form/components/input/input';
// import Link from 'modules/core/components/link/short-link';
// import IconContainer from 'modules/core/components/icon-container/icon-container';
// import LabelGroup from 'modules/form/components//label-group/label-group';
// import LabelGroupItem from 'modules/form/components/label-group-item/label-group-item';
// import { IconPhoto, IconVideo } from 'modules/core/components/icons';

import './feedback-form.css';

const { number, func, string, bool } = PropTypes;

class FeedbackForm extends Component {
  static propTypes = {
    onSubmit: func,
    text: string,
    advantage: string,
    disadvantage: string,

    onTextChange: func,
    onAdvantageChange: func,
    onDisadvantageChange: func,

    activeStarIndex: number,
    onStarClick: func,
    onStarHover: func,
    onStarMouseOut: func,

    allowToSubmit: bool
  };

  render() {
    const {
      text,
      // advantage,
      // disadvantage,
      name,
      email,

      rating,
      onSubmit,
      onRatingChange,
      formRef,
      isAuthorized
    } = this.props;

    return (
      <Form onSubmit={onSubmit} formRef={formRef}>
        {/* <FormTitle>Оставить отзыв о товаре</FormTitle> */}
        <Form.Row>
          <InlineField label="Оцените товар">
            <InputStarRatingContainer rating={rating} onChange={onRatingChange} />
          </InlineField>
        </Form.Row>

        {/* <Form.LabeledRow>
          <Field label="Достоинства">
            <Textarea
              autosize
              name="advantages"
              value={advantage}
              placeholder="Чем Вам понравился товар?"
              onChange={this.handleAdvantageChange}
            />
          </Field>
        </Form.LabeledRow>

        <Form.LabeledRow>
          <Field label="Недостатки">
            <Textarea
              autosize
              value={disadvantage}
              placeholder="Чем Вам не понравился товар?"
              onChange={this.handleDisadvantageChange}
            />
          </Field>
        </Form.LabeledRow> */}

        <Form.LabeledRow>
          <Field label="Комментарий">
            <Textarea
              opened
              required
              value={text}
              name="feedback"
              placeholder="Общее впечатление от использования товара"
              onChange={this.handleTextChange}
            />
          </Field>
        </Form.LabeledRow>

        {/* TODO: Attach photos and videos to review */}
        {/* <FormRow>
            <LabelGroup>
              <LabelGroupItem accept="image/*" id="addPhoto">
                <Link
                  prependedIcon={
                    <IconContainer>
                      <IconPhoto />
                    </IconContainer>
                  }
                >
                  Прикрепить изображение
                </Link>
              </LabelGroupItem>
              <LabelGroupItem accept="video/*" id="addVideo">
                <Link
                  prependedIcon={
                    <IconContainer>
                      <IconVideo />
                    </IconContainer>
                  }
                >
                  Добавить видео
                </Link>
              </LabelGroupItem>
            </LabelGroup>
          </FormRow> */}

        {/* TODO: Comments for unregistered  users */}
        {!isAuthorized && (
          <FormRow>
            <FormGroup cols={2}>
              <FormGroupItem>
                <Field>
                  <Input
                    value={name}
                    placeholder="Имя"
                    type="text"
                    required
                    onChange={this.handleNameChange}
                  />
                </Field>
              </FormGroupItem>
              <FormGroupItem>
                <Field>
                  <Input
                    value={email}
                    type="email"
                    placeholder="E-mail"
                    required
                    onChange={this.handleEmailChange}
                  />
                </Field>
              </FormGroupItem>
            </FormGroup>
          </FormRow>
        )}

        <Form.ActionRow>
          <FormGroup center>
            <FormGroupItem>
              <Button
                variant="primary"
                title="Отправить"
                type="submit"
                caption="Отправить отзыв на модерацию"
              />
            </FormGroupItem>
          </FormGroup>
        </Form.ActionRow>
      </Form>
    );
  }

  handleNameChange = e => {
    const { value } = e.target;
    const { onNameChange } = this.props;

    if (!isFunction(onNameChange)) {
      return;
    }
    onNameChange(value);
  };

  handleEmailChange = e => {
    const { value } = e.target;
    const { onEmailChange } = this.props;

    if (!isFunction(onEmailChange)) {
      return;
    }
    onEmailChange(value);
  };

  handleTextChange = e => {
    const { value } = e.target;
    const { onTextChange } = this.props;

    if (!isFunction(onTextChange)) {
      return;
    }
    onTextChange(value);
  };

  handleAdvantageChange = e => {
    const { value } = e.target;
    const { onAdvantageChange } = this.props;

    if (!isFunction(onAdvantageChange)) {
      return;
    }
    onAdvantageChange(value);
  };

  handleDisadvantageChange = e => {
    const { value } = e.target;
    const { onDisadvantageChange } = this.props;

    if (!isFunction(onDisadvantageChange)) {
      return;
    }
    onDisadvantageChange(value);
  };
}

export default FeedbackForm;
