import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AuthDialog from '../components/auth-dialog/auth-dialog';
import AccountActions from '../ducks/actions/account';
import * as SocialActions from '../ducks/actions/social';
import * as fromDialogs from '../ducks/dialogs';
import { actions as Dialogs } from '../ducks/dialogs';
import * as fromAccount from '../ducks/account';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsAuthOpened,
  authError: fromAccount.getAuthorizationError
});

const mapDispatchToProps = {
  signIn: AccountActions.signIn,
  close: Dialogs.closeAuthDialog,
  openRegistrationDialog: Dialogs.openRegistrationDialog,
  openRecoveryPasswordDialog: Dialogs.openPasswordRecoveryRequestDialog,
  callVkAuth: SocialActions.callVkAuth,
  callFacebookAuth: SocialActions.callFacebookAuth
};

class AuthDialogContainer extends Component {
  state = {
    show: true
  };

  render() {
    const { isOpened, authError, callVkAuth, callFacebookAuth } = this.props;

    return (
      <AuthDialog
        show={isOpened}
        authError={authError}
        onClose={this.handleClose}
        onRegistrationOpen={this.handleRegister}
        onRecoveryPasswordOpen={this.handleRecoveryPasswordOpen}
        onSubmit={this.handleSubmit}
        onVkAuthCall={callVkAuth}
        onFacebookAuthCall={callFacebookAuth}
      />
    );
  }

  handleRecoveryPasswordOpen = () => {
    this.props.openRecoveryPasswordDialog();
  };

  handleRegister = () => {
    this.props.openRegistrationDialog();
  };

  handleClose = () => {
    this.props.close();
  };

  handleSubmit = data => {
    const { signIn } = this.props;
    signIn(data.email, data.password);
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthDialogContainer);
