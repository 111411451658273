import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AnimatedButtonLink from 'modules/core/components/animated-link/animated-button-link';

import * as fromDialogs from 'modules/core/ducks/dialogs';

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
  openCallbackDialog: fromDialogs.actions.openCallbackDialog
};

class CallbackButtonContainer extends Component {
  render() {
    const { openCallbackDialog, ...rest } = this.props;

    return <AnimatedButtonLink title="Перезвонить мне" onClick={openCallbackDialog} {...rest} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackButtonContainer);
