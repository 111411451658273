import React, { PureComponent } from 'react';
import Media from 'react-media';
import { HashLink } from 'react-router-hash-link';
import ScrollTrigger from 'react-scroll-trigger';

// import { getProductLink } from 'routes/links';

import plural from 'modules/utils/plural';
import { IconClick } from 'modules/core/components/icons';
import CardReviews from 'modules/core/components/card-reviews/card-reviews';
import BuyForm from 'modules/ordering/components/buy-form/buy-form';
import ButtonFavorite from 'modules/core/components/button-favorite/button-favorite';
import Link from 'modules/core/components/link/short-link';
import Availability from 'modules/core/components/availability/availability';
import AddToFavoriteTooltip from '../../add-to-favorite-tooltip/add-to-favorite-tooltip';

import FavoriteIcon from '../../favourite-icon/favourite-icon';
import ListDescriptions from '../../list-description/list-description';
import LocalBreadcrumbs from '../../local-breadcrumbs/local-breadcrumbs';
import ProductCode from '../../product-code/product-code';
import ProductFeatures from '../../product-features/product-features';
import VolumeChoice from '../../volume-choice/volume-choice';
import ColorChoice from '../../color-choice/color-choice';
import CardPrice from '../../card-price/card-price';

import ThreeColumnGrid from './three-column-grid/three-column-grid';
import TwoColumnGrid from './two-column-grid/two-column-grid';
import OneColumnGrid from './one-column-grid/one-column-grid';
import CardSlider from '../../card-slider/card-slider';
import CardDelivery from '../../card-delivery/card-delivery';
import ReviewRating from 'modules/review/components/review-rating/review-rating';

import './main-card.css';
import SizeChoice from '../../size-choice/size-choice';
import choseFeaturesList from '../../../data/chose';

const COLUMNS = {
  ONE: 'COLUMNS/ONE',
  TWO: 'COLUMNS/TWO',
  THREE: 'COLUMNS/THREE'
};
const reviewsMeasureStrings = ['отзыв', 'отзыва', 'отзывов'];

class MainCard extends PureComponent {
  render() {
    const { productRef } = this.props;

    return (
      <form ref={productRef} className="MainCard" id="ProductCard">
        <Media query="(max-width: 840px)" render={this.renderOneColumn} defaultMatches />
        <Media
          query="(min-width: 841px) and (max-width: 1100px)"
          render={this.renderTwoColumns}
          defaultMatches={false}
        />
        <Media query="(min-width: 1101px)" render={this.renderTwoColumns} defaultMatches={false} />
      </form>
    );
  }

  renderOneColumn = () => {
    return this.renderGrid(COLUMNS.ONE);
  };

  renderTwoColumns = () => {
    return this.renderGrid(COLUMNS.TWO);
  };

  renderGrid = columns => {
    const Grid = this.getGridByColumns(columns);

    return (
      <Grid
        breadcrumbs={this.renderBreadcrumbs()}
        title={this.renderTitle()}
        description={this.renderDescription()}
        properties={this.renderProperties()}
        slider={this.renderSlider()}
        labels={this.renderLabels()}
        weightChoice={this.renderVolumeChoice()}
        colorChoice={this.renderColorChoice()}
        sizeChoice={this.renderSizeChoice()}
        price={this.renderPrice()}
        availability={this.renderAvailability()}
        code={this.renderCode()}
        delivery={this.renderDelivery()}
        reviews={this.renderReviews()}
        linkToReviews={this.renderLinkToReviews()}
        buyForm={this.renderBuyForm()}
        buyOneClick={this.renderBuyOneClick()}
        addToFavorite={this.renderAddToFavorite()}
        buttonFavorite={this.renderButtonFavorite()}
        starsRating={this.renderStarsRating()}
      />
    );
  };

  renderStarsRating() {
    const { product } = this.props;
    const value = product.rating.value || 0;

    return (product.rating.reviews_count > 0 ?
      (
        <div itemprop="aggregateRating" itemscope="" itemtype="http://schema.org/AggregateRating">
          {/* <meta itemprop="worstRating" content="1" style={{display: "none"}} ></meta> */}
          {/* <span itemprop="bestRating" style={{display: "none"}} >5</span> */}
          <span itemprop="ratingValue" style={{ display: 'none' }}>
            {value}
          </span>
          <span itemprop="reviewCount" style={{ display: 'none' }}>
            {product.rating.reviews_count}
          </span>

          <ReviewRating rating={value} showRate reverse />
        </div>
      ) : (
        <ReviewRating rating={value} showRate reverse />
      )
    );
  }

  getGridByColumns(columns) {
    switch (columns) {
      case COLUMNS.ONE:
        return OneColumnGrid;
      case COLUMNS.TWO:
        return TwoColumnGrid;
      case COLUMNS.THREE:
      default:
        return ThreeColumnGrid;
    }
  }

  renderBreadcrumbs = () => {
    const { product } = this.props;

    if (!product.series) {
      return product.title;
    }

    return (
      <LocalBreadcrumbs
        list={[
          // {
          //   name: product.series.title,
          //   link: getProductLink(product)
          // },
          {
            name: product.title
          }
        ]}
      />
    );
  };

  renderTitle() {
    const { product } = this.props;

    return product.title;
  }

  renderDescription() {
    const { product } = this.props;
    return (
      <>
        <div itemprop="name">
          <h1>{product.description}</h1>
        </div>
        <span style={{ display: 'none' }} itemprop="brand">
          MIXIT
        </span>
      </>
    );
  }

  renderProperties() {
    const { product } = this.props;

    if (!product.details) {
      return null;
    }

    const { properties } = product.details;

    if (!properties.length) {
      return null;
    }

    return <ListDescriptions list={properties} />;
  }

  renderSlider() {
    const { product, onVideoTriggerClick, ...rest } = this.props;

    return (
      <>
        <a
          style={{ display: 'none' }}
          itemprop="image"
          href={product.media[0].source[0].main.normal}
        >
          <img
            src={product.media[0].source[0].main.normal}
            title={product.announcementMedia.title}
            alt=""
          ></img>
        </a>
        <CardSlider media={product.media} onVideoTriggerClick={onVideoTriggerClick} {...rest} />
      </>
    );
  }

  renderLabels() {
    const { product } = this.props;
    // console.log('product.features', product.features);
    return <ProductFeatures features={this.getFeatures(product)} isGift={product.isGift} />;
  }

  renderPrice() {
    const { product } = this.props;
    return (
      <div itemprop="offers" itemscope="" itemtype="http://schema.org/Offer">
        {__BROWSER__ && (
          <a itemprop="url" style={{ display: 'none' }} href={window.location.pathname}>
            {window.location.pathname}
          </a>
        )}

        <meta itemprop="price" content={product.price.current}></meta>
        <meta itemprop="priceCurrency" content="RUB"></meta>
        <link itemprop="availability" href="http://schema.org/InStock"></link>
        <meta itemprop="priceValidUntil" content="2029-12-31"></meta>
        <CardPrice
          price={product.price}
          hasDiscount={product.hasDiscount}
          isAvailable={product.isAvailable}
        />
      </div>
    );
  }

  renderVolumeChoice() {
    const { weightList, currentWeight, volumeList, currentVolume, onWeightChange } = this.props;

    if ((!weightList || !weightList.length) && (!volumeList || !volumeList.length)) return null;

    return (
      <VolumeChoice
        options={weightList || volumeList}
        selected={currentWeight || currentVolume}
        onChange={onWeightChange}
      />
    );
  }

  renderColorChoice() {
    const { colorList, onColorChange, currentColor } = this.props;

    if (!colorList || !colorList.length) return null;

    return <ColorChoice options={colorList} selected={currentColor} onChange={onColorChange} />;
  }

  renderSizeChoice() {
    const { sizeList, onSizeChange, currentSize } = this.props;

    if (!sizeList || !sizeList.length) return null;

    return (
      <SizeChoice options={sizeList} selected={currentSize} onChange={onSizeChange} noSizeTable />
    );
  }

  renderCode() {
    const { product } = this.props;

    if (!product.sku) {
      return null;
    }

    return <ProductCode sku={product.sku} />;
  }

  renderAvailability() {
    const { product } = this.props;

    return <Availability is={product.isAvailable} />;
  }

  renderReviews() {
    const reviewsCount = this.props.product.rating.reviews_count;

    return <CardReviews reviews={reviewsCount} rating={0} />;
  }

  renderLinkToReviews() {
    const reviewsCount = this.props.product.rating.reviews_count;
    return (
      <Link
        // href="#reviews"
        to="#reviews"
        component={HashLink}
        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
      >{` ${reviewsCount} ${plural(reviewsCount, reviewsMeasureStrings)}`}</Link>
    );
  }

  renderBuyForm() {
    const {
      product,
      amount,
      onAddToBasket,
      onAmountChange,
      onBuyEnter,
      onBuyExit,
      onButtonAddToWaitingListClick,
      isWaitingListRequestLoading,
      isBasketFetching
    } = this.props;

    return (
      <ScrollTrigger onEnter={onBuyEnter} onExit={onBuyExit}>
        <BuyForm
          isGift={product.isGift}
          isAvailable={product.isAvailable}
          isInBasket={product.isInBasket}
          amountInBasket={product.amountInBasket}
          amount={amount}
          onAddToBasket={onAddToBasket}
          onAmountChange={onAmountChange}
          onButtonAddToWaitingListClick={onButtonAddToWaitingListClick}
          isWaitingListRequestLoading={isWaitingListRequestLoading}
          isBasketFetching={isBasketFetching}
        />
      </ScrollTrigger>
    );
  }

  renderBuyOneClick() {
    return <Link prependedIcon={<IconClick />}>Купить в 1 клик</Link>;
  }

  renderAddToFavorite() {
    const { product, onFavoriteToggle, isAuthorized, onAuthorizationClick } = this.props;
    return (
      <AddToFavoriteTooltip isAuthorized={isAuthorized} onAuthorizationClick={onAuthorizationClick}>
        <Link
          component="button"
          type="button"
          onClick={onFavoriteToggle}
          prependedIcon={<FavoriteIcon active={product.inFavorite} />}
        >
          {product.inFavorite ? 'В избранном' : 'В избранное'}
        </Link>
      </AddToFavoriteTooltip>
    );
  }

  renderButtonFavorite() {
    const { product, onFavoriteToggle } = this.props;
    return <ButtonFavorite onClick={onFavoriteToggle} active={product.inFavorite} size="medium" />;
  }

  renderDelivery() {
    const { deliveryDateForMoscov } = this.props;

    return <CardDelivery deliveryDateForMoscov={deliveryDateForMoscov} />;
  }

  getFeatures = product => {
    if (choseFeaturesList.includes(product.slug)) {
      return [...product.features, {
        description: `Данный товар с пометкой "Выбор покупателя"`,
        title: "Выбор 2020",
        type: "FEATURE/CONSUMERS_CHOICE"
      }]
    }

    return product.features;
  }
}

export default MainCard;
