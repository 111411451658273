import React, { Component, Fragment } from 'react';

import Container from 'modules/core/components/container/container';
import InstagramGroupContainer from 'modules/social/containers/instagram-group';

import './section-instagram.css';

const instagramLinks = [
  'http://instagr.am/p/BlGHGrIBJW0/',
  'http://instagr.am/p/Bk0Ga6QBp89/?asasas',
  'https://www.instagram.com/p/Bk0Ga6QBp89/',
  'http://instagr.am/p/Bk0Ga6QBp89_fake/?asasas',
  'http://instagr.am/p/BgWpf4_jeNr',
  'http://instagr.am/p/Bni3dyGFJpi',
  'https://www.instagram.com/p/Bq9VVsZhGVd/?utm_source=ig_embed',
  'https://www.instagram.com/p/Bq-AFgtndKK/?utm_source=ig_embed',
  'https://www.instagram.com/p/Bq-Zju5B8Kl/?utm_source=ig_embed',
  'https://www.instagram.com/p/Bq9TA2jFcwc/?utm_source=ig_embed',
  'http://instagr.am/p/BgWpf4_jeNr',
  'http://instagr.am/p/BgWpf4_jeNr',
  'http://instagr.am/p/BgWpf4_jeNr_fake',
  'http://instagr.am/p/BgWpf4_jeNr_fake',
  'http://instagr.am/p/BgWpf4_jeNr_fake'
];

class SectionInstagram extends Component {
  render() {
    return (
      <Fragment>
        <Container>
          <InstagramGroupContainer instagramLinks={instagramLinks} />
        </Container>
      </Fragment>
    );
  }
}

export default SectionInstagram;
