export const newData = [
  {
    slug: 'problem_skin',
    render_conditions: {
      start_time: '2020-07-23T19:58:00+03:00',
      expire_time: '2020-10-01T19:58:00+03:00'
      // query_match_strings:{
      // query_ignore_strings:{
      //   list:[ "gtm_compaign"]
      // }
      // by_unauthorized: true,
      // show: false
    },
    // redirect_to:"/",
    page: {
      title: 'Надоели прыщи и черные точки?',
      parent_page: {
        path: '/',
        title: 'Главная'
      }
    },
    components: [
      {
        type: 'problem-skin_top-banner',
        render_conditions: {
          show: true
        },
        hasContainer: true,
        wraper_style: {
          background: 'linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1'
        },
        style: {
          // background: "linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1;"
          // background: "yellow"
        },
        images: [
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x_1.png',
                  double: '/content/problem-skin/top-banner@1x_1.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x_1.png',
                  double: '/content/problem-skin/top-banner@1x_1.png'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x_mob_1.png',
                  double: '/content/problem-skin/top-banner@1x_mob_1.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x_mob_1.png',
                  double: '/content/problem-skin/top-banner@1x_mob_1.png'
                }
              }
            ]
          }
        ],
        title: 'Надоели прыщи и черные точки?',
        description: 'Мы знаем, как тебе помочь!',
        button: {
          title: '5 причин появления прыщей'
        }
      },
      // {
      //   type: 'problem-skin_top-banner',
      //   show: true,
      //   hasContainer: true,
      //   wraper_style:{
      //     background: "linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1"
      //   },
      //   style:{
      //     // background: "linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1;"
      //     // background: "yellow"
      //   },
      //   images: [
      //     {
      //       id: 432490,
      //       type: 'MEDIA_TYPE/IMAGE',
      //       title: '',
      //       sources: [
      //         {
      //           main: {
      //             normal: '/content/problem-skin/top-banner@1x.png',
      //             double: '/content/problem-skin/top-banner@2x.png'
      //           },
      //           fallback: {
      //             normal: '/content/problem-skin/top-banner@1x.png',
      //             double: '/content/problem-skin/top-banner@2x.png'
      //         }
      //         }
      //       ]
      //     },
      //     {
      //       id: 432490,
      //       type: 'MEDIA_TYPE/IMAGE',
      //       title: '',
      //       sources: [
      //         {
      //           main: {
      //             normal: '/content/problem-skin/top-banner@1x_mob.png',
      //             double: '/content/problem-skin/top-banner@1x_mob.png'
      //           },
      //           fallback: {
      //             normal: '/content/problem-skin/top-banner@1x_mob.png',
      //             double: '/content/problem-skin/top-banner@1x_mob.png'
      //           }
      //         }
      //       ]
      //     },
      //   ],
      //   title: "Надоели прыщи и черные точки?</br> Замазываешь воспаления?</br> Кожа постоянно блестит?",
      //   description: "Мы знаем, как тебе помочь!",
      //   button: {
      //     title: "5 причин появления прыщей"
      //   }
      // },
      {
        type: 'problem-skin_reasons',
        show: true,
        // hasContainer: true,
        title: 'Причины появления прыщей',
        list: [
          {
            title: 'Гормоны или гормональная активность',
            description:
              'Чаще всего этому подвержены подростки, у которых гормональный фон только формируется. У взрослых гормоны влияют на появление акне. Например, у женщин, которые раз в месяц испытывают гормональные скачки.',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_1.jpg',
                      double: '/content/problem-skin/reason_1.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_1.jpg',
                      double: '/content/problem-skin/reason_1.jpg'
                    }
                  }
                ]
              }
            ]
          },
          {
            title: 'Наследственность',
            description:
              'Если у вашей мамы, папы, дедушки, бабушки была тяжелая форма угревой болезни, то вы в группе риска.',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_2.jpg',
                      double: '/content/problem-skin/reason_2.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_2.jpg',
                      double: '/content/problem-skin/reason_2.jpg'
                    }
                  }
                ]
              }
            ]
          },
          {
            title: 'Проблема сбоев работы ЖКТ и неправильное питание',
            description:
              'Некоторые продукты могут провоцировать появление прыщей. Любимые нами дрожжевые булочки со сладким вареньем, запитые молоком выйдут боком.',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_3.jpg',
                      double: '/content/problem-skin/reason_3.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_3.jpg',
                      double: '/content/problem-skin/reason_3.jpg'
                    }
                  }
                ]
              }
            ]
          },
          {
            title: 'Некоторая косметика, которая обладает комедогенным свойством',
            description:
              'Бывают ингредиенты, которые вызывают нарушение процесса обновления ороговевших клеток, а это приводит к  гиперкератозу — утолщению рогового слоя кожи.',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_4.jpg',
                      double: '/content/problem-skin/reason_4.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_4.jpg',
                      double: '/content/problem-skin/reason_4.jpg'
                    }
                  }
                ]
              }
            ]
          },
          {
            title: 'Стресс',
            description: 'Он провоцирует все плохое в нашем организме, в том числе и акне',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_5.jpg',
                      double: '/content/problem-skin/reason_5.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_5.jpg',
                      double: '/content/problem-skin/reason_5.jpg'
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'problem-skin_solutions',
        title: 'Как избавиться от прыщей и черных точек?',
        image: {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: '',
          sources: [
            {
              main: {
                normal: '/content/problem-skin/solution_main.png',
                double: '/content/problem-skin/solution_main.png'
              },
              fallback: {
                normal: '/content/problem-skin/solution_main.png',
                double: '/content/problem-skin/solution_main.png'
              }
            }
          ]
        },
        solutions: [
          {
            image: {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/solution_1.png',
                    double: '/content/problem-skin/solution_1.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/solution_1.png',
                    double: '/content/problem-skin/solution_1.png'
                  }
                }
              ]
            },
            pointer_src: '/content/problem-skin/pointer_top-left.svg',
            html:
              'Для <b>снижения выработки кожного сала</b> используют косметику с ретинолом и его эфирами в составе. К эфирам относятся ретинолацетат или ретинолпальмитат.'
          },
          {
            image: {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/solution_2.png',
                    double: '/content/problem-skin/solution_2.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/solution_2.png',
                    double: '/content/problem-skin/solution_2.png'
                  }
                }
              ]
            },
            pointer_src: '/content/problem-skin/pointer_bottom-left.svg',
            html:
              'Для <b>удаления очагов воспаления</b> временно вводят противовоспалительные и антибактериальные средства. Их используют не на все лицо, а точечно. К таким компонентам может относится цинк, эфирное масло чайного дерева, азелаиновая кислота, ниацинамид, ромашка, календула, чистотел.'
          },
          {
            image: {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/solution_3.png',
                    double: '/content/problem-skin/solution_3.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/solution_3.png',
                    double: '/content/problem-skin/solution_3.png'
                  }
                }
              ]
            },
            pointer_src: '/content/problem-skin/pointer_top-right.svg',
            html:
              'Чтобы <b>избежать в будущем открытых и закрытых комедонов</b>, нужно пользоваться косметикой с кислотами в составе. Самые популярные: AHA-кислоты (комплекс фруктовых кислот) и салициловая кислота. Кислоты отшелушивают кожу, себум без препятствий сможет выходить наружу.'
          },
          {
            image: {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/solution_4.png',
                    double: '/content/problem-skin/solution_4.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/solution_4.png',
                    double: '/content/problem-skin/solution_4.png'
                  }
                }
              ]
            },
            pointer_src: '/content/problem-skin/pointer_bottom-right.svg',
            html:
              'Также можно использовать абсорбенты, в частности маски из глин и косметических грязей. Они впитывают <b>излишки кожного себума</b> и подготавливают кожу к дальнейшему уходу.'
          }
        ]
      },
      {
        type: 'problem-skin_products',
        show: true,
        hasContainer: false,
        header: {
          title: 'AHA! Revolution',
          description: 'Специально для проблемной кожи мы разработали линейку',
          button_title: 'Посмотреть средства',
          images: [
            {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/main_product_1.png',
                    double: '/content/problem-skin/main_product_1.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/main_product_1.png',
                    double: '/content/problem-skin/main_product_1.png'
                  }
                }
              ]
            },
            {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/main_product_2.png',
                    double: '/content/problem-skin/main_product_2.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/main_product_2.png',
                    double: '/content/problem-skin/main_product_2.png'
                  }
                }
              ]
            }
          ],
          style: {
            background: 'linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1;'
          }
        },
        products: [
          {
            slug: 'aha-revolution-gel-cleanser',
            product: null,
            title: 'Гель для умывания с гликолевой кислотой',
            properties: [
              'Комплекс ELFAMOIST обеспечивает мгновенное увлажнение, смягчает раздраженную кожу.',
              'Гель алоэ вера стимулирует кровообращение.',
              'Экстракт готу колы улучшает внутренний метаболизм, имеет лимфодренажное действие, укрепляет стенки сосудов.',
              'Экстракт лакричника тонизирует, обладает заживляющими, бактерицидными, себорегулирующими свойствами.'
            ],
            consistency:
              'Гель алоэ вера, зеленый чай, ромашка, коко-сульфат натрия, кокамидопропилбетаин, глицерин, гликолевая кислота, кокоамфоацетат натрия, гидроксид натрия, гликольдистеарат, увлажняющий комплекс ELFAMOIST, растительные экстракты готу колы, лакричника, розмарина, дегидроацетовая кислота, бензиловый спирт, ПЭГ-7 глицерил кокоат, аллантоин, парфюмерная композиция.'
          },
          {
            slug: 'aha-revolution-soft-peeling',
            product: null,
            title: 'Пилинг-эксфолиант для лица с гликолевой кислотой',
            properties: [
              'Масло виноградных косточек повышает эластичность кожи.',
              'Гель алоэ вера стимулирует кровообращение.',
              'Комплекс ELFAMOIST обеспечивает мгновенное увлажнение, смягчает раздраженную кожу.',
              'Д-Ппантенол оказывает увлажняющее и смягчающее действие, успокаивает, способствует восстановлению эпидермального барьера.'
            ],
            consistency:
              'Гель алоэ вера, зеленый чай, ромашка, коко-сульфат натрия, кокамидопропилбетаин, глицерин, гликолевая кислота, кокоамфоацетат натрия, гидроксид натрия, гликольдистеарат, увлажняющий комплекс ELFAMOIST, растительные экстракты готу колы, лакричника, розмарина, дегидроацетовая кислота, бензиловый спирт, ПЭГ-7 глицерил кокоат, аллантоин, парфюмерная композиция.'
          },
          {
            slug: 'aha-revolution-day-cream',
            product: null,
            title: 'Дневной крем для лица с гликолевой кислотой (SPF 15)',
            properties: [
              'Масло виноградных косточек повышает эластичность кожи.',
              'Гель алоэ вера стимулирует кровообращение.',
              'Экстракт горца японского обладает успокаивающим действием.',
              'Экстракт готу колы имеет лимфодренажное действие, укрепляет стенки сосудов.'
            ],
            consistency:
              'Гель алоэ вера, зеленый чай, ромашка, коко-сульфат натрия, кокамидопропилбетаин, глицерин, гликолевая кислота, кокоамфоацетат натрия, гидроксид натрия, гликольдистеарат, увлажняющий комплекс ELFAMOIST, растительные экстракты готу колы, лакричника, розмарина, дегидроацетовая кислота, бензиловый спирт, ПЭГ-7 глицерил кокоат, аллантоин, парфюмерная композиция.'
          },
          {
            slug: 'aha-revolution-face-lotion',
            product: null,
            title: 'Лосьон для лица с гелем алоэ и гликолевой кислотой',
            properties: [
              'Гель алоэ вера стимулирует кровообращение.',
              'Комплекс ELFAMOIST обеспечивает мгновенное и пролонгированное увлажнение до 30 часов',
              'Экстракт горца японского обладает успокаивающим действием.',
              'Экстракт шлемника байкальского препятствует преждевременному старению.'
            ],
            consistency:
              'Гель алоэ вера, зеленый чай, ромашка, коко-сульфат натрия, кокамидопропилбетаин, глицерин, гликолевая кислота, кокоамфоацетат натрия, гидроксид натрия, гликольдистеарат, увлажняющий комплекс ELFAMOIST, растительные экстракты готу колы, лакричника, розмарина, дегидроацетовая кислота, бензиловый спирт, ПЭГ-7 глицерил кокоат, аллантоин, парфюмерная композиция.'
          },
          {
            slug: 'aha-revolution-night-cream',
            product: null,
            title: 'Ночной крем для лица с гликолевой кислотой',
            properties: [
              'Масло карите (ши) защищает кожу от обезвоживания и высыхания.',
              'Гель алоэ вера стимулирует кровообращение.',
              'Экстракт горца японского обладает успокаивающим действием.',
              'Экстракт готу колы имеет лимфодренажное действие, укрепляет стенки сосудов.'
            ],
            consistency:
              'Гель алоэ вера, зеленый чай, ромашка, коко-сульфат натрия, кокамидопропилбетаин, глицерин, гликолевая кислота, кокоамфоацетат натрия, гидроксид натрия, гликольдистеарат, увлажняющий комплекс ELFAMOIST, растительные экстракты готу колы, лакричника, розмарина, дегидроацетовая кислота, бензиловый спирт, ПЭГ-7 глицерил кокоат, аллантоин, парфюмерная композиция.'
          }
        ],
        set_product: {
          slug: 'aha-revolution-set',
          image_src: '/content/problem-skin/set.png',
          pruduct: null,
          style: {
            background: 'linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1;'
          },
          images: [
            {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/top-banner@1x.png',
                    double: '/content/problem-skin/top-banner@2x.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/top-banner@1x.png',
                    double: '/content/problem-skin/top-banner@2x.png'
                  }
                }
              ]
            }
          ]
        }
      },
      {
        type: 'problem-skin_slider',
        title: 'Не уверена, что тебе нужны эти средства? Посмотри на результаты',
        images: [
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/after-before_1.jpg',
                  double: '/content/problem-skin/after-before_1.jpg'
                },
                fallback: {
                  normal: '/content/problem-skin/after-before_1.jpg',
                  double: '/content/problem-skin/after-before_1.jpg'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/after-before_2.jpg',
                  double: '/content/problem-skin/after-before_2.jpg'
                },
                fallback: {
                  normal: '/content/problem-skin/after-before_2.jpg',
                  double: '/content/problem-skin/after-before_2.jpg'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/after-before_3.jpg',
                  double: '/content/problem-skin/after-before_3.jpg'
                },
                fallback: {
                  normal: '/content/problem-skin/after-before_3.jpg',
                  double: '/content/problem-skin/after-before_3.jpg'
                }
              }
            ]
          },
          // {
          //   id: 432490,
          //   type: 'MEDIA_TYPE/IMAGE',
          //   title: '',
          //   sources: [
          //     {
          //       main: {
          //         normal: '/content/problem-skin/after-before_4.jpg',
          //         double: '/content/problem-skin/after-before_4.jpg'
          //       },
          //       fallback: {
          //         normal: '/content/problem-skin/after-before_4.jpg',
          //         double: '/content/problem-skin/after-before_4.jpg'
          //       }
          //     }
          //   ]
          // },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/after-before_5.jpg',
                  double: '/content/problem-skin/after-before_5.jpg'
                },
                fallback: {
                  normal: '/content/problem-skin/after-before_5.jpg',
                  double: '/content/problem-skin/after-before_5.jpg'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/after-before_6.jpg',
                  double: '/content/problem-skin/after-before_6.jpg'
                },
                fallback: {
                  normal: '/content/problem-skin/after-before_6.jpg',
                  double: '/content/problem-skin/after-before_6.jpg'
                }
              }
            ]
          }
        ]
      },
      // {
      //   type: 'problem-skin_form',
      //   show: true,
      //   wraper_style: {
      //     backgroundImage: 'url(/content/problem-skin/form_bg.jpg)'
      //   },
      //   top_text: `Устала от дурацких советов типа <b>«не ешь сладкое»</b>?</br>
      //             Понимаем! Поэтому приготовили для тебя еще один </br>
      //             <b>полезный подарок</b>.`,
      //   bottom_text: `Заполни форму, и мы пришлем тебе полный гайд по уходу за жирной и проблемной кожей.`,
      //   button_text: 'Получить рекомендации'
      // },
      {
        type: 'problem-skin_product-plus-gift',
        show: true,
        hasContainer: true,
        wraper_style: {
          background: 'linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1'
        },
        set_product: {
          slug: 'aha-revolution-set'
        },
        gift_product: {
          slug: 'mermaid-body-highlighter',
          title: 'Хайлайтер для тела',
          title_2: 'Mermaid Body Highlighter, <span style="white-space: nowrap;">150 ml</span>'
        },
        slug: 'mermaid-body-highlighter',
        set_title: 'AHA! Revolution',
        image_description: 'Чтобы получить подарок, введи в корзине промокод',
        promocode: 'problemsk',
        image: {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: '',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/problem-skin/647A4214.png',
                double: '/content/problem-skin/647A4214.png'
              },
              fallback: {
                normal: '/content/problem-skin/647A4214.png',
                double: '/content/problem-skin/647A4214.png'
              }
            }
          ]
        }
      },
      {
        type: 'problem-skin_about-company',
        hasContainer: true,
        wraper_style: { background: 'rgba(142, 51, 135, 0.05)' },
        image: {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: '',
          sources: [
            {
              main: {
                normal: '/content/problem-skin/about_company.jpg',
                double: '/content/problem-skin/about_company.jpg'
              },
              fallback: {
                normal: '/content/problem-skin/about_company.jpg',
                double: '/content/problem-skin/about_company.jpg'
              }
            }
          ]
        },
        title: 'О компании MIXIT',
        description: `<p>Мы — <b>MIXIT</b>, российский амбициозный косметический бренд. Наша миссия — <b>помогать девушкам решить их проблемы</b>, а значит стать более уверенными в себе!</p>
      <p>Каждый день наши сотрудники взаимодействуют с тысячами девушек. И у многих <b>схожие проблемы</b>.</p>
      <p>В течение <b>6 лет мы наращивали свой опыт</b>, разрабатывая уникальные формулы. Теперь <b>мы точно знаем, как помочь твоей коже</b>.</p>`
      },
      {
        type: 'problem-skin_video',
        youtube_video_id: 'J6LUCUCysq8',
        hasContainer: true,
        title: 'Посмотри фильм, чтобы познакомиться с нами поближе!',
        image: {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: '',
          sources: [
            {
              main: {
                normal: '/content/problem-skin/top-banner@1x.png',
                double: '/content/problem-skin/top-banner@2x.png'
              },
              fallback: {
                normal: '/content/problem-skin/top-banner@1x.png',
                double: '/content/problem-skin/top-banner@2x.png'
              }
            }
          ]
        }
      }
    ]
  }
];

const all = {
  slug: 'all',
  render_conditions: {
    start_time: '2020-01-23T19:58:00+03:00',
    expire_time: '2020-09-23T19:58:00+03:00'
  },
  // related_banner: {
  //   title: 'подробнее о Акциях в Йошкар-Оле',
  //   render_conditions: {
  //     show: true,
  //     start_time: '2020-01-23T19:58:00+03:00',
  //     expire_time: '2020-09-23T19:58:00+03:00',
  //     city_ids: { list: [1166] },
  //     render_pathes: { list: [{ path: '/', exact: false }] },
  //     ignored_pathes: {
  //       list: [{ path: '/basket' }, { path: '/ordering', exact: false }]
  //     }
  //   }
  // },
  page: {
    title: 'Клиентские дни',
    parent_page: {
      path: '/new_shops',
      title: 'Новые магазины'
    }
  },
  colors: ['#DC296A', '#3D62D7'],
  light: true,
  background: {
    style: { opacity: 0.15 },
    gradient:
      'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
  },
  components: [
    {
      type: 'top_banner',
      show: true,
      hasContainer: {
        props: {
          // narrow: true,
          // small: true,
          // min: true
          // fullHeight: true
          // noWidth: true
        }
      },
      images: [
        {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'IWjas4ShAb8.jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              }
            }
          ]
        },
        {
          id: 414131,
          type: 'MEDIA_TYPE/IMAGE',
          title: '8447 (1).jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              }
            }
          ]
        }
      ],
      label: '21-22 февраля',
      title: ['Клиентские', 'дни'],
      address: {
        title: 'москва',
        date_list: [
          { date: '21/02', location: 'ТЦ Охотный ряд, Манежная площадь д. 1, стр. 2' },
          {
            date: '22/02',
            location: 'ТЦ Мега Белая дача, г. Котельники, 1 Покровский проезд, д.1  '
          }
        ]
      },
      action: {
        show: true,
        description: 'Принеси этот купон на открытие и получи классный подарок!',
        title: 'Расспечатать купон',
        link: {
          href: 'https://mixit.ru/upload/personal_data.pdf',
          target: '_blank',
          download: true
        }
      }
    },
    {
      type: 'gifts',
      show: true,
      hasContainer: true,
      list: [
        {
          title: 'Скидки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                },
                fallback: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'подарки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'двойные бонусы на карту',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'rules',
      title: ['Акции', 'на открытии'],
      hasContainer: true,
      show: true,
      list: [
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'group',
      show: false,
      hasContainer: true,
      title: ['товары', 'со скидкой'],
      slug: 'new'
    }
  ]
};

const all_2 = {
  slug: 'all_2',
  render_conditions: {
    start_time: '2020-01-23T19:58:00+03:00',
    expire_time: '2020-09-23T19:58:00+03:00'
  },
  // related_banner: {
  //   title: 'подробнее о Акциях в Йошкар-Оле',
  //   render_conditions: {
  //     show: true,
  //     start_time: '2020-01-23T19:58:00+03:00',
  //     expire_time: '2020-09-23T19:58:00+03:00',
  //     city_ids: { list: [1166] },
  //     render_pathes: { list: [{ path: '/', exact: false }] },
  //     ignored_pathes: {
  //       list: [{ path: '/basket' }, { path: '/ordering', exact: false }]
  //     }
  //   }
  // },
  page: {
    title: 'Клиентские дни',
    parent_page: {
      path: '/new_shops',
      title: 'Новые магазины'
    }
  },
  colors: ['#DC296A', '#3D62D7'],
  light: true,
  background: {
    style: { opacity: 0.15 },
    gradient:
      'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
  },
  components: [
    {
      type: 'top_banner',
      show: true,
      hasContainer: {
        props: {
          // narrow: true,
          // small: true,
          // min: true
          // fullHeight: true
          // noWidth: true
        }
      },
      images: [
        {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'IWjas4ShAb8.jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              }
            }
          ]
        },
        {
          id: 414131,
          type: 'MEDIA_TYPE/IMAGE',
          title: '8447 (1).jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              }
            }
          ]
        }
      ],
      label: '21-22 февраля',
      title: ['Клиентские', 'дни'],
      address: {
        title: 'москва',
        date_list: [
          { date: '21/02', location: 'ТЦ Охотный ряд, Манежная площадь д. 1, стр. 2' },
          {
            date: '22/02',
            location: 'ТЦ Мега Белая дача, г. Котельники, 1 Покровский проезд, д.1  '
          }
        ]
      },
      action: {
        show: true,
        description: 'Принеси этот купон на открытие и получи классный подарок!',
        title: 'Расспечатать купон',
        link: {
          href: 'https://mixit.ru/upload/personal_data.pdf',
          target: '_blank',
          download: true
        }
      }
    },
    {
      type: 'gifts',
      show: true,
      hasContainer: true,
      list: [
        {
          title: 'Скидки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                },
                fallback: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'подарки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'двойные бонусы на карту',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'rules',
      title: ['Акции', 'на открытии'],
      hasContainer: true,
      show: true,
      list: [
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'group',
      show: false,
      hasContainer: true,
      title: ['товары', 'со скидкой'],
      slug: 'new'
    }
  ]
};

const all_3 = {
  slug: 'all_3',
  render_conditions: {
    start_time: '2020-01-23T19:58:00+03:00',
    expire_time: '2020-09-23T19:58:00+03:00'
  },
  page: {
    title: 'Клиентские дни',
    parent_page: {
      path: '/new_shops/',
      title: 'Новые магазины'
    }
  },
  colors: ['#DC296A', '#3D62D7'],
  light: true,
  background: {
    style: { opacity: 0.15 },
    gradient:
      'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
  },
  components: [
    {
      type: 'top_banner',
      show: true,
      hasContainer: true,
      images: [
        {
          id: 432490,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'IWjas4ShAb8.jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_1_@1x.jpg',
                double: '/content/landing/girl_1_@2x.jpg'
              }
            }
          ]
        },
        {
          id: 414131,
          type: 'MEDIA_TYPE/IMAGE',
          title: '8447 (1).jpg',
          sources: [
            {
              dimensions: {
                width: 639,
                height: 960
              },
              main: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_2_@1x.jpg',
                double: '/content/landing/girl_2_@2x.jpg'
              }
            }
          ]
        }
      ],
      label: '21-22 февраля',
      title: ['Клиентские', 'дни'],
      address: {
        title: 'москва',
        date_list: [
          { date: '21/02', location: 'ТЦ Охотный ряд, Манежная площадь д. 1, стр. 2' },
          {
            date: '22/02',
            location: 'ТЦ Мега Белая дача, г. Котельники, 1 Покровский проезд, д.1  '
          }
        ]
      },
      action: {
        show: true,
        description: 'Принеси этот купон на открытие и получи классный подарок!',
        title: 'Расспечатать купон'
      }
    },
    {
      type: 'gifts',
      show: true,
      hasContainer: true,
      list: [
        {
          title: 'Скидки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                },
                fallback: {
                  normal: '/content/landing/ddd_1x.png',
                  double: '/content/landing/ddd_2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'подарки',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/qqq_@1x.png',
                  double: '/content/landing/qqq_@2x.png'
                }
              }
            ]
          }
        },
        {
          title: 'двойные бонусы на карту',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                },
                fallback: {
                  normal: '/content/landing/bbb_@1x.png',
                  double: '/content/landing/bbb_@2x.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'rules',
      title: ['Акции', 'на открытии'],
      hasContainer: true,
      show: true,
      list: [
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        },
        {
          title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
          media: {
            id: 749762,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'Молочко для тела',
            sources: [
              {
                dimensions: {
                  width: 3200,
                  height: 3200
                },
                main: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                },
                fallback: {
                  normal: 'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                }
              }
            ]
          }
        }
      ]
    },
    {
      type: 'group',
      show: true,
      hasContainer: true,
      title: ['товары', 'со скидкой'],
      slug: 'new'
    }
  ]
};

export default { all };

export const dataList2 = [
  {
    slug: 'msk',
    render_conditions: {
      start_time: '2020-01-23T19:58:00+03:00',
      expire_time: '2020-09-23T19:58:00+03:00'
    },
    related_banner: {
      title: 'подробнее о Акциях в Москве',
      render_conditions: {
        show: false,
        start_time: '2020-01-23T19:58:00+03:00',
        expire_time: '2020-09-23T19:58:00+03:00',
        ignored_city_ids: {
          list: ['566']
        }
      }
    },
    page: {
      title: 'Клиентские дни',
      parent_page: {
        path: '/new_shops/',
        title: 'Новые магазины'
      }
    },
    colors: ['#DC296A', '#3D62D7'],
    light: true,
    background: {
      style: {
        opacity: 0.15
      },
      gradient:
        'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
    },
    components: [
      {
        type: 'top_banner',
        show: true,
        hasContainer: true,
        images: [
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'IWjas4ShAb8.jpg',
            sources: [
              {
                dimensions: {
                  width: 639,
                  height: 960
                },
                main: {
                  normal: '/content/landing/girl_1_@1x.jpg',
                  double: '/content/landing/girl_1_@2x.jpg'
                },
                fallback: {
                  normal: '/content/landing/girl_1_@1x.jpg',
                  double: '/content/landing/girl_1_@2x.jpg'
                }
              }
            ]
          },
          {
            id: 414131,
            type: 'MEDIA_TYPE/IMAGE',
            title: '8447 (1).jpg',
            sources: [
              {
                dimensions: {
                  width: 639,
                  height: 960
                },
                main: {
                  normal: '/content/landing/girl_2_@1x.jpg',
                  double: '/content/landing/girl_2_@2x.jpg'
                },
                fallback: {
                  normal: '/content/landing/girl_2_@1x.jpg',
                  double: '/content/landing/girl_2_@2x.jpg'
                }
              }
            ]
          }
        ],
        label: '13-14 марта',
        title: ['Клиентские', 'дни'],
        address: {
          title: 'москва',
          date_list: [
            {
              date: '13/03',
              location: 'Авиапарк Ходынский б-р, дом № 4'
            },
            {
              date: '14/03',
              location: 'ТРЦ Европолис  Мира пр-кт, дом № 211, корпус 2'
            }
          ]
        },
        action: {
          show: true,
          description: 'Принеси этот купон на открытие и получи классный подарок!',
          title: 'Расспечатать купон'
        }
      },
      {
        type: 'gifts',
        show: true,
        hasContainer: true,
        list: [
          {
            title: 'Скидки',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/ddd_1x.png',
                    double: '/content/landing/ddd_2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/ddd_1x.png',
                    double: '/content/landing/ddd_2x.png'
                  }
                }
              ]
            }
          },
          {
            title: 'подарки',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/qqq_@1x.png',
                    double: '/content/landing/qqq_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/qqq_@1x.png',
                    double: '/content/landing/qqq_@2x.png'
                  }
                }
              ]
            }
          },
          {
            title: 'двойные бонусы на карту',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/bbb_@1x.png',
                    double: '/content/landing/bbb_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/bbb_@1x.png',
                    double: '/content/landing/bbb_@2x.png'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        type: 'activity',
        show: true,
        hasContainer: true,
        list: [
          {
            date: '13 марта',
            specialist: 'визажист',
            schedule: '16:00-20:00',
            location: 'ТЦ "Авиапарк"',
            list: [
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Иконка',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/lipstickIcon_@1x.png',
                        double: '/content/landing/lipstickIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/lipstickIcon_@1x.png',
                        double: '/content/landing/lipstickIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'роскошный мейк-ап от визажиста Mixit'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/saleIcon_@1x.png',
                        double: '/content/landing/saleIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/saleIcon_@1x.png',
                        double: '/content/landing/saleIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'скидка 20% на коллекцию Mixit ART'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/sertificatesIcon_@1x.png',
                        double: '/content/landing/sertificatesIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/sertificatesIcon_@1x.png',
                        double: '/content/landing/sertificatesIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'двойные бонусы на карту лояльности'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/giftIcon_@1x.png',
                        double: '/content/landing/giftIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/giftIcon_@1x.png',
                        double: '/content/landing/giftIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'подарки каждому'
              }
            ]
          },
          {
            date: '14 марта',
            specialist: 'косметолог',
            schedule: '14:00 – 19:00',
            location: 'ТЦ "Европолис"',
            list: [
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/faceIcon_@1x.png',
                        double: '/content/landing/faceIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/faceIcon_@1x.png',
                        double: '/content/landing/faceIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'диагностика состояния кожи с помощью дерматоскопа'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/goodsIcon_@1x.png',
                        double: '/content/landing/goodsIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/goodsIcon_@1x.png',
                        double: '/content/landing/goodsIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'подбор средств по типу кожи от нашего косметолога'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/saleIcon_@1x.png',
                        double: '/content/landing/saleIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/saleIcon_@1x.png',
                        double: '/content/landing/saleIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'скидка 15% на уходовые продукты'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/vvv_@1x.png',
                        double: '/content/landing/vvv_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/vvv_@1x.png',
                        double: '/content/landing/vvv_@2x.png'
                      }
                    }
                  ]
                },
                title: 'двойные бонусы на карту лояльности и подарки каждому'
              }
            ]
          }
        ]
      },
      {
        type: 'rules',
        title: ['Акции', 'на открытии'],
        hasContainer: true,
        show: false,
        list: [
          {
            title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  },
                  fallback: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  }
                }
              ]
            }
          },
          {
            title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  },
                  fallback: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  }
                }
              ]
            }
          },
          {
            title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  },
                  fallback: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  }
                }
              ]
            }
          },
          {
            title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  },
                  fallback: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        type: 'bottom_banner',
        hasContainer: false,
        show: true,
        image: {
          id: 749762,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'Молочко для тела',
          sources: [
            {
              dimensions: {
                width: 3200,
                height: 3200
              },
              main: {
                normal: '/content/landing/girl_3_@1x.jpg',
                double: '/content/landing/girl_3_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_3_@1x.jpg',
                double: '/content/landing/girl_3_@2x.jpg'
              }
            }
          ]
        },
        title: ['Приходите за', 'яркими эмоциями'],
        main_title: 'в Mixit!'
      },
      {
        type: 'bottom_info',
        hasContainer: false,
        show: true,
        text: '*все предложения действуют в указанное время в указанных магазинах'
      },
      {
        type: 'group',
        show: false,
        hasContainer: true,
        title: ['товары', 'со скидкой'],
        slug: 'new'
      }
    ]
  },
  {
    slug: 'bad_skin',
    render_conditions: {
      start_time: '2020-01-23T19:58:00+03:00',
      expire_time: '2020-08-01T19:58:00+03:00'
    },
    related_banner: {
      title: 'подробнее о Акциях в Москве',
      render_conditions: {
        show: false,
        start_time: '2020-01-23T19:58:00+03:00',
        expire_time: '2020-09-23T19:58:00+03:00',
        ignored_city_ids: {
          list: ['566']
        }
      }
    },
    page: {
      title: 'Клиентские дни',
      parent_page: {
        path: '/new_shops/',
        title: 'Новые магазины'
      }
    },
    colors: ['#DC296A', '#3D62D7'],
    light: false,
    background: {
      style: {
        opacity: 0
      }
    },
    components: [
      {
        type: 'problem-skin_top-banner',
        show: true,
        hasContainer: true,
        wraper_style: {
          background: 'linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1'
        },
        style: {
          // background: "linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1;"
          // background: "yellow"
        },
        images: [
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          }
        ],
        title: 'Надоели прыщи и черные точки? Замазываешь воспаления? Кожа постоянно блестит?',
        description: 'Мы знаем, как тебе помочь!',
        button: {
          title: '5 причин появления прыщей'
        }
      },
      {
        type: 'problem-skin_reasons',
        show: true,
        // hasContainer: true,
        title: 'Причины появления прыщей',
        list: [
          {
            title: 'Гормоны или гормональная активность',
            description:
              'Чаще всего этому подвержены подростки, у которых гормональный фон только формируется. У взрослых гормоны влияют на появление акне. Например, у женщин, которые раз в месяц испытывают гормональные скачки.',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_1.jpg',
                      double: '/content/problem-skin/reason_1.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_1.jpg',
                      double: '/content/problem-skin/reason_1.jpg'
                    }
                  }
                ]
              }
            ]
          },
          {
            title: 'Наследственность',
            description:
              'Если у вашей мамы, папы, дедушки, бабушки была тяжелая форма угревой болезни, то вы в группе риска.',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_2.jpg',
                      double: '/content/problem-skin/reason_2.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_2.jpg',
                      double: '/content/problem-skin/reason_2.jpg'
                    }
                  }
                ]
              }
            ]
          },
          {
            title: 'Проблема сбоев работы ЖКТ и неправильное питание',
            description:
              'Некоторые продукты могут провоцировать появление прыщей. Любимые нами дрожжевые булочки со сладким вареньем, запитые молоком выйдут боком.',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_3.jpg',
                      double: '/content/problem-skin/reason_3.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_3.jpg',
                      double: '/content/problem-skin/reason_3.jpg'
                    }
                  }
                ]
              }
            ]
          },
          {
            title: 'Некоторая косметика, которая обладает комедогенным свойством',
            description:
              'Бывают ингредиенты, которые вызывают нарушение процесса обновления ороговевших клеток, а это приводит к  гиперкератозу — утолщению рогового слоя кожи.',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_4.jpg',
                      double: '/content/problem-skin/reason_4.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_4.jpg',
                      double: '/content/problem-skin/reason_4.jpg'
                    }
                  }
                ]
              }
            ]
          },
          {
            title: 'Стресс',
            description: 'Он провоцирует все плохое в нашем организме, в том числе и акне',
            images: [
              {
                id: 432490,
                type: 'MEDIA_TYPE/IMAGE',
                title: '',
                sources: [
                  {
                    main: {
                      normal: '/content/problem-skin/reason_5.jpg',
                      double: '/content/problem-skin/reason_5.jpg'
                    },
                    fallback: {
                      normal: '/content/problem-skin/reason_5.jpg',
                      double: '/content/problem-skin/reason_5.jpg'
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'problem-skin_products',
        show: true,
        hasContainer: false,
        header: {
          title: 'AHA! Revolution',
          description: 'Специально для проблемной кожи мы разработали линейку',
          images: [
            {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/main_product_1.png',
                    double: '/content/problem-skin/main_product_1.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/main_product_1.png',
                    double: '/content/problem-skin/main_product_1.png'
                  }
                }
              ]
            },
            {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/main_product_2.png',
                    double: '/content/problem-skin/main_product_2.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/main_product_2.png',
                    double: '/content/problem-skin/main_product_2.png'
                  }
                }
              ]
            }
          ],
          style: {
            background: 'linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1;'
          }
        },
        products: [
          {
            product: {},
            title: 'Гель для умывания с гликолевой кислотой',
            properties: [
              'Комплекс ELFAMOIST обеспечивает мгновенное увлажнение, смягчает раздраженную кожу.',
              'Гель алоэ вера стимулирует кровообращение.',
              'Экстракт готу колы улучшает внутренний метаболизм, имеет лимфодренажное действие, укрепляет стенки сосудов.',
              'Экстракт лакричника тонизирует, обладает заживляющими, бактерицидными, себорегулирующими свойствами.'
            ]
          },
          {
            product: {},
            title: 'Пилинг-эксфолиант для лица с гликолевой кислотой',
            properties: [
              'Масло виноградных косточек повышает эластичность кожи.',
              'Гель алоэ вера стимулирует кровообращение.',
              'Комплекс ELFAMOIST обеспечивает мгновенное увлажнение, смягчает раздраженную кожу.',
              'Д-Ппантенол оказывает увлажняющее и смягчающее действие, успокаивает, способствует восстановлению эпидермального барьера.'
            ]
          },
          {
            product: {},
            title: 'Дневной крем для лица с гликолевой кислотой (SPF 15)',
            properties: [
              'Масло виноградных косточек повышает эластичность кожи.',
              'Гель алоэ вера стимулирует кровообращение.',
              'Экстракт горца японского обладает успокаивающим действием.',
              'Экстракт готу колы имеет лимфодренажное действие, укрепляет стенки сосудов.'
            ]
          },
          {
            product: {},
            title: 'Лосьон для лица с гелем алоэ и гликолевой кислотой',
            properties: [
              'Гель алоэ вера стимулирует кровообращение.',
              'Комплекс ELFAMOIST обеспечивает мгновенное и пролонгированное увлажнение до 30 часов',
              'Экстракт горца японского обладает успокаивающим действием.',
              'Экстракт шлемника байкальского препятствует преждевременному старению.'
            ]
          }
        ],
        set_product: {
          pruduct: {},
          style: {
            background: 'linear-gradient(107.59deg, #CFCFD0 1.17%, #F4F4F4 106.01%), #F1F1F1;'
          },
          images: [
            {
              id: 432490,
              type: 'MEDIA_TYPE/IMAGE',
              title: '',
              sources: [
                {
                  main: {
                    normal: '/content/problem-skin/top-banner@1x.png',
                    double: '/content/problem-skin/top-banner@2x.png'
                  },
                  fallback: {
                    normal: '/content/problem-skin/top-banner@1x.png',
                    double: '/content/problem-skin/top-banner@2x.png'
                  }
                }
              ]
            }
          ]
        }
      },
      {
        type: 'problem-skin_slider',
        title: 'Не уверена, что тебе нужны эти средства? Посмотри на результаты',
        images: [
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          },
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          }
        ]
      },
      {
        type: 'problem-skin_form',
        show: true
      },
      {
        type: 'problem-skin_product-plus-gift',
        show: true,
        product: {},
        title: 'Покупай весь набор прямо сейчас, и мы подарим тебе',
        gift_title: 'XXXXX',
        set_title: 'AHA! Revolution'
      },
      {
        type: 'problem-skin_about-company',
        images: [
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/reason_1.jpg',
                  double: '/content/problem-skin/reason_1.jpg'
                },
                fallback: {
                  normal: '/content/problem-skin/reason_1.jpg',
                  double: '/content/problem-skin/reason_1.jpg'
                }
              }
            ]
          }
        ],
        description: `Мы — MIXIT, российский амбициозный косметический бренд. Наша миссия — помогать девушкам решить их проблемы, а значит стать более уверенными в себе!
        Каждый день наши сотрудники взаимодействуют с тысячами девушек. И у многих схожие проблемы.
        В течение 6 лет мы наращивали свой опыт, разрабатывая уникальные формулы. Теперь мы точно знаем, как помочь твоей коже.`
      },
      {
        type: 'problem-skin_video',
        src: '',
        images: [
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: '',
            sources: [
              {
                main: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                },
                fallback: {
                  normal: '/content/problem-skin/top-banner@1x.png',
                  double: '/content/problem-skin/top-banner@2x.png'
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    slug: 'spb',
    render_conditions: {
      start_time: '2020-01-23T19:58:00+03:00',
      expire_time: '2020-09-23T19:58:00+03:00'
    },
    related_banner: {
      title: 'подробнее о Акциях в Санкт-Петербург',
      render_conditions: {
        show: false,
        start_time: '2020-01-23T19:58:00+03:00',
        expire_time: '2020-09-23T19:58:00+03:00',
        city_ids: {
          list: ['566']
        }
      }
    },
    page: {
      title: 'Клиентские дни',
      parent_page: {
        path: '/new_shops/',
        title: 'Новые магазины'
      }
    },
    colors: ['#DC296A', '#3D62D7'],
    light: true,
    background: {
      style: {
        opacity: 0.15
      },
      gradient:
        'linear-gradient(106.13deg, rgba(250, 167, 183, 0.9) 9.37%, rgba(123, 157, 243, 0.9) 100.49%)'
    },
    components: [
      {
        type: 'top_banner',
        show: true,
        hasContainer: true,
        images: [
          {
            id: 432490,
            type: 'MEDIA_TYPE/IMAGE',
            title: 'IWjas4ShAb8.jpg',
            sources: [
              {
                dimensions: {
                  width: 639,
                  height: 960
                },
                main: {
                  normal: '/content/landing/girl_1_@1x.jpg',
                  double: '/content/landing/girl_1_@2x.jpg'
                },
                fallback: {
                  normal: '/content/landing/girl_1_@1x.jpg',
                  double: '/content/landing/girl_1_@2x.jpg'
                }
              }
            ]
          },
          {
            id: 414131,
            type: 'MEDIA_TYPE/IMAGE',
            title: '8447 (1).jpg',
            sources: [
              {
                dimensions: {
                  width: 639,
                  height: 960
                },
                main: {
                  normal: '/content/landing/girl_2_@1x.jpg',
                  double: '/content/landing/girl_2_@2x.jpg'
                },
                fallback: {
                  normal: '/content/landing/girl_2_@1x.jpg',
                  double: '/content/landing/girl_2_@2x.jpg'
                }
              }
            ]
          }
        ],
        label: '14-15 марта',
        title: ['Клиентские', 'дни'],
        address: {
          title: 'Санкт-Петербург',
          date_list: [
            {
              date: '14/03',
              location: 'ТЦ Галерея, Лиговский пр-кт, дом № 30, литера А'
            },
            {
              date: '15/03',
              location: 'ТЦ МЕГА Дыбенко, Мурманское шоссе 12-й км, дом № 1'
            }
          ]
        },
        action: {
          show: true,
          description: 'Принеси этот купон на открытие и получи классный подарок!',
          title: 'Расспечатать купон'
        }
      },
      {
        type: 'gifts',
        show: true,
        hasContainer: true,
        list: [
          {
            title: 'Скидки',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/ddd_1x.png',
                    double: '/content/landing/ddd_2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/ddd_1x.png',
                    double: '/content/landing/ddd_2x.png'
                  }
                }
              ]
            }
          },
          {
            title: 'подарки',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/qqq_@1x.png',
                    double: '/content/landing/qqq_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/qqq_@1x.png',
                    double: '/content/landing/qqq_@2x.png'
                  }
                }
              ]
            }
          },
          {
            title: 'двойные бонусы на карту',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal: '/content/landing/bbb_@1x.png',
                    double: '/content/landing/bbb_@2x.png'
                  },
                  fallback: {
                    normal: '/content/landing/bbb_@1x.png',
                    double: '/content/landing/bbb_@2x.png'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        type: 'activity',
        show: true,
        hasContainer: true,
        list: [
          {
            date: '14 марта',
            specialist: 'косметолог',
            schedule: '14:00 – 19:00',
            location: 'ТЦ "Галерея"',
            list: [
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/faceIcon_@1x.png',
                        double: '/content/landing/faceIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/faceIcon_@1x.png',
                        double: '/content/landing/faceIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'диагностика состояния кожи с помощью дерматоскопа'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/goodsIcon_@1x.png',
                        double: '/content/landing/goodsIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/goodsIcon_@1x.png',
                        double: '/content/landing/goodsIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'подбор средств по типу кожи от нашего косметолога'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/saleIcon_@1x.png',
                        double: '/content/landing/saleIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/saleIcon_@1x.png',
                        double: '/content/landing/saleIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'скидка 15% на уходовые продукты'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/vvv_@1x.png',
                        double: '/content/landing/vvv_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/vvv_@1x.png',
                        double: '/content/landing/vvv_@2x.png'
                      }
                    }
                  ]
                },
                title: 'двойные бонусы на карту лояльности и подарки каждому'
              }
            ]
          },
          {
            date: '15 марта',
            specialist: 'визажист',
            schedule: '14:00-19:00',
            location: 'ТЦ "МЕГА Дыбенко"',
            list: [
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Иконка',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/lipstickIcon_@1x.png',
                        double: '/content/landing/lipstickIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/lipstickIcon_@1x.png',
                        double: '/content/landing/lipstickIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'роскошный мейк-ап от визажиста Mixit'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/saleIcon_@1x.png',
                        double: '/content/landing/saleIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/saleIcon_@1x.png',
                        double: '/content/landing/saleIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'скидка 20% на коллекцию Mixit ART'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/sertificatesIcon_@1x.png',
                        double: '/content/landing/sertificatesIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/sertificatesIcon_@1x.png',
                        double: '/content/landing/sertificatesIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'двойные бонусы на карту лояльности'
              },
              {
                image: {
                  id: 749762,
                  type: 'MEDIA_TYPE/IMAGE',
                  title: 'Молочко для тела',
                  sources: [
                    {
                      dimensions: {
                        width: 3200,
                        height: 3200
                      },
                      main: {
                        normal: '/content/landing/giftIcon_@1x.png',
                        double: '/content/landing/giftIcon_@2x.png'
                      },
                      fallback: {
                        normal: '/content/landing/giftIcon_@1x.png',
                        double: '/content/landing/giftIcon_@2x.png'
                      }
                    }
                  ]
                },
                title: 'подарки каждому'
              }
            ]
          }
        ]
      },
      {
        type: 'rules',
        title: ['Акции', 'на открытии'],
        hasContainer: true,
        show: false,
        list: [
          {
            title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  },
                  fallback: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  }
                }
              ]
            }
          },
          {
            title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  },
                  fallback: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  }
                }
              ]
            }
          },
          {
            title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  },
                  fallback: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  }
                }
              ]
            }
          },
          {
            title: 'Выложи фото в день открытия в Instagram - получи распечатанное фото в ПОДАРОК!',
            media: {
              id: 749762,
              type: 'MEDIA_TYPE/IMAGE',
              title: 'Молочко для тела',
              sources: [
                {
                  dimensions: {
                    width: 3200,
                    height: 3200
                  },
                  main: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  },
                  fallback: {
                    normal:
                      'https://mixit.ru/upload/iblock/9a8/9a83a25ee3a620b8af62e7d5b61e018f.png'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        type: 'bottom_banner',
        hasContainer: false,
        show: true,
        image: {
          id: 749762,
          type: 'MEDIA_TYPE/IMAGE',
          title: 'Молочко для тела',
          sources: [
            {
              dimensions: {
                width: 3200,
                height: 3200
              },
              main: {
                normal: '/content/landing/girl_3_@1x.jpg',
                double: '/content/landing/girl_3_@2x.jpg'
              },
              fallback: {
                normal: '/content/landing/girl_3_@1x.jpg',
                double: '/content/landing/girl_3_@2x.jpg'
              }
            }
          ]
        },
        title: ['Приходите за', 'яркими эмоциями'],
        main_title: 'в Mixit!'
      },
      {
        type: 'bottom_info',
        hasContainer: false,
        show: true,
        text: '*все предложения действуют в указанное время в указанных магазинах'
      },
      {
        type: 'group',
        show: false,
        hasContainer: true,
        title: ['товары', 'со скидкой'],
        slug: 'new'
      }
    ]
  }
];

export const dataList = [all, all_2, all_3];
// export const dataList = [all];
