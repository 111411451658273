export function normalizeSpecialOffers(specialOffers) {
  const { all, scenes } = specialOffers;

  const normalizedAll = normalizeAll(all);

  return { all: normalizedAll, scenes };
}

function normalizeAll(all) {
  const normalizedAll = {};

  for (let key in all) {
    const specialOffer = all[key];
    const normalizedSpecialOffer = normalizeSpecialOffer(specialOffer);

    normalizedAll[key] = normalizedSpecialOffer;
  }

  return normalizedAll;
}

function normalizeSpecialOffer(specialOffer) {
  const { expire_date, short_description, start_date, ...rest } = specialOffer;

  return {
    expireDate: expire_date,
    startDate: start_date,
    shortDescription: short_description,
    ...rest
  };
}
