import React, { Component } from 'react';
import cn from 'classnames';
import Loader from 'modules/core/components/loader/loader';
import Button from 'modules/core/components/button/button';
import IconReload from 'modules/core/components/icons/icon-reload/icon-reload';
import plural from 'modules/utils/plural';

import ProductContainer from '../../containers/product';
import ProductSetCardContainer from 'modules/product/containers/product-set-card';

import ProductGroupVideo from '../product-section-list/ProductGroupVideo/ProductGroupVideo';


import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import './product-list.css';

const defaultLoadingAmount = 24;
const GOODS_PLURALS = ['товар', 'товара', 'товаров'];

class ProductList extends Component {
  render() {
    const { group, products, listId, isLanding, microMarkingObject, singleProduct } = this.props;
    const UsedProductComponent = singleProduct ? ProductSetCardContainer : ProductContainer;

    console.log('singleProduct', singleProduct);



    if (!products) {
      return null;
    }

    // if(singleProduct){
    //   products.length = 1;
    // }

    return (
      <div
        className={cn('ProductList', {
          'ProductList--wide': this.props.wide,
          'ProductList--isLanding': isLanding,
          'ProductList--singleProduct': singleProduct,
        })}
        itemtype="http://schema.org/AggregateOffer"
        itemscope=""
        itemprop="offers"
      >
        {microMarkingObject && (
          <>
            <meta content={microMarkingObject.offerCount} itemprop="offerCount"></meta>
            <meta content={microMarkingObject.highPrice} itemprop="highPrice"></meta>
            <meta content={microMarkingObject.lowPrice} itemprop="lowPrice"></meta>
            <meta content="RUB" itemprop="priceCurrency"></meta>
          </>
        )}

        <div className="ProductList-content">
          {products.map(slug => (
            <HideErrorBoundary key={slug}>
              <div className="ProductList-item">
                <div className="ProductList-itemInnerWrapper">
                  <UsedProductComponent
                    slug={slug}
                    listId={listId}
                    isLanding={isLanding}
                    // allwaysSetBackground 
                    withAddToBasket
                    transparentBackground
                  />
                </div>
              </div>
            </HideErrorBoundary>
          ))}
        </div>
        {!singleProduct && (
          <div className="ProductList-footer">{this.renderFooter()}</div>
        )}
        {group && group.promoMedia && (<ProductGroupVideo group={group} />)}
      </div>
    );
  }

  renderFooter() {
    const { amount, total, isLoading, onLoadMore } = this.props;



    if (isLoading) {
      return (
        <div className="ProductList-loader">
          <Loader size="small" type="spin" />
        </div>
      );
    }

    console.log('amount', amount);
    console.log('total', total);
    console.log('onLoadMore', onLoadMore);

    if (amount < total && onLoadMore) {
      return (
        <div className="ProductList-loadMore">
          <Button
            title={this.getLoadButtonTitle()}
            iconPrepend={<IconReload />}
            onClick={onLoadMore}
            variant="primary"
          />
        </div>
      );
    }

    return;
  }

  getLoadButtonTitle() {
    const rest = this.getRest();

    if (rest < defaultLoadingAmount) {
      return `Еще ${rest} ${plural(rest, GOODS_PLURALS)}`;
    }

    return `Показать еще (${rest})`;
  }

  getRest() {
    const { amount, total } = this.props;
    return total - amount;
  }
}

export default ProductList;
