import { takeEvery, call, select } from 'redux-saga/effects';
import { mixitApi } from 'services/mixit';
import * as REVIEW from 'modules/review/types/review';
import * as fromAccount from 'modules/core/ducks/account';

export function* reviewRateWatcher() {
  yield takeEvery(REVIEW.RATE, rate);
}

function* rate(action) {
  const { id, type } = action.payload;
  const authorization = yield select(fromAccount.getAuthorization);

  console.log(type);

  try {
    yield call(
      mixitApi(authorization)
        .reviews(id)
        .rate().add,
      type
    );
  } catch (e) {}
}
