import React, { Component } from 'react';

import Loader from '../loader/loader';

import SectionSpecialOffer from '../section-special-offer/section-special-offer';

import SpecialOfferContainer from '../../containers/special-offer';

import HideErrorBoundary from '../hide-error-boundary/hide-error-boundary';

import './special-offer-card.css';

class SpecialOfferCard extends Component {
  render() {
    const { slug, error, isLoaded } = this.props;

    if (!isLoaded) {
      return (
        <div className="SpecialOfferCard">
          <div className="SpecialOfferCard-loader">
            <Loader />
          </div>
        </div>
      );
    }

    if (!isLoaded || error) {
      return null;
    }

    return (
      <div className="SpecialOfferCard">
        <HideErrorBoundary>
          <SectionSpecialOffer>
            <SpecialOfferContainer id={slug} />
          </SectionSpecialOffer>
        </HideErrorBoundary>

        {/* <HideErrorBoundary>
          <SectionDefault title="Другие акции">
            <Container>
              <SimilarSpecialOffersContainer is={slug} />
            </Container>
          </SectionDefault>
        </HideErrorBoundary> */}
      </div>
    );
  }
}

export default SpecialOfferCard;
