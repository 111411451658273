import React, { Component } from 'react';
import { getIndexLink } from 'routes/links';
import Helmet from 'react-helmet';

import { Page, PageContent } from '../components/page/index';
import SpecialOffer from '../components/deprecated-special-offer/deprecated-special-offer';
import Container from '../components/container/container';
import SectionDefault from '../components/section-default/section-default';

import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

class DeprecateSpecialOfferPage extends Component {
  render() {
    return (
      <Page
        title='Акция "Летний Бонус"'
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Акция "Летний Бонус"'
          }
        ]}
      >
        <PageContent>
          <Helmet>
            <title>Акция "Летний Бонус"</title>
            <meta name="description" content='Акция "Летний Бонус"' />
          </Helmet>
          <SpecialOffer />
          <SectionDefault>
            <Container>
              <FeedbackCall />
            </Container>
          </SectionDefault>
        </PageContent>
      </Page>
    );
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }
}

export default DeprecateSpecialOfferPage;
