export const AUTH_OPEN = 'core/dialogs/AUTH_OPEN';
export const AUTH_CLOSE = 'core/dialogs/AUTH_CLOSE';

// recovery password start
export const PASSWORD_RECOVERY_REQUEST_OPEN = 'core/dialogs/PASSWORD_RECOVERY_REQUEST_OPEN';
export const PASSWORD_RECOVERY_REQUEST_CLOSE = 'core/dialogs/PASSWORD_RECOVERY_REQUEST_CLOSE';

export const PASSWORD_RECOVERY_REQUEST_SUCCESS_OPEN =
  'core/dialogs/PASSWORD_RECOVERY_REQUEST_SUCCESS_OPEN';
export const PASSWORD_RECOVERY_REQUEST_SUCCESS_CLOSE =
  'core/dialogs/PASSWORD_RECOVERY_REQUEST_SUCCESS_CLOSE';

export const CONFIRM_PASSWORD_RECOVERY_OPEN = 'core/dialogs/CONFIRM_PASSWORD_RECOVERY_OPEN';
export const CONFIRM_PASSWORD_RECOVERY_CLOSE = 'core/dialogs/CONFIRM_PASSWORD_RECOVERY_CLOSE';

export const CONFIRM_PASSWORD_RECOVERY_SUCCESS_OPEN =
  'core/dialogs/CONFIRM_PASSWORD_RECOVERY_SUCCESS_OPEN';
export const CONFIRM_PASSWORD_RECOVERY_SUCCESS_CLOSE =
  'core/dialogs/CONFIRM_PASSWORD_RECOVERY_SUCCESS_CLOSE';

export const CONFIRM_PASSWORD_RECOVERY_ERROR_OPEN =
  'core/dialogs/CONFIRM_PASSWORD_RECOVERY_ERROR_OPEN';
export const CONFIRM_PASSWORD_RECOVERY_ERROR_CLOSE =
  'core/dialogs/CONFIRM_PASSWORD_RECOVERY_ERROR_CLOSE';

// recovery password end

// physical traits start

export const CONFIRM_PHYSICAL_TRAITS_OPEN = 'core/dialogs/CONFIRM_PHYSICAL_TRAITS_OPEN';
export const CONFIRM_PHYSICAL_TRAITS_CLOSE = 'core/dialogs/CONFIRM_PHYSICAL_TRAITS_CLOSE';

// physical traits end

export const CALLBACK_OPEN = 'core/dialogs/CALLBACK_OPEN';
export const CALLBACK_CLOSE = 'core/dialogs/CALLBACK_CLOSE';

export const SUBSCRIBE_CONFORMATION_OPEN = 'core/dialogs/SUBSCRIBE_OPEN';
export const SUBSCRIBE_CONFORMATION_CLOSE = 'core/dialogs/SUBSCRIBE_CLOSE';

export const SUBSCRIBE_SUGGESTION_OPEN = 'core/dialogs/SUBSCRIBE_SUGGESTION_OPEN';
export const SUBSCRIBE_SUGGESTION_CLOSE = 'core/dialogs/SUBSCRIBE_SUGGESTION_CLOSE';

export const CALLBACK_CONFORMATION_OPEN = 'core/dialogs/CALLBACK_CONFORMATION_OPEN';
export const CALLBACK_CONFORMATION_CLOSE = 'core/dialogs/CALLBACK_CONFORMATION_CLOSE';

export const ADD_TO_WAIT_LIST_CONFORMATION_OPEN = 'core/dialogs/ADD_TO_WAIT_LIST_CONFORMATION_OPEN';
export const ADD_TO_WAIT_LIST_CONFORMATION_CLOSE =
  'core/dialogs/ADD_TO_WAIT_LIST_CONFORMATION_CLOSE';

export const REGISTRATION_OPEN = 'core/dialogs/REGISTRATION_OPEN';
export const REGISTRATION_CLOSE = 'core/dialogs/REGISTRATION_CLOSE';

export const REPLY_CONFORMATION_OPEN = 'core/dialogs/REPLY_CONFORMATION_OPEN';
export const REPLY_CONFORMATION_CLOSE = 'core/dialogs/REPLY_CONFORMATION_CLOSE';

export const REVIEW_CONFORMATION_OPEN = 'core/dialogs/REVIEW_CONFORMATION_OPEN';
export const REVIEW_CONFORMATION_CLOSE = 'core/dialogs/REVIEW_CONFORMATION_CLOSE';

export const CITY_OPEN = 'core/dialogs/CITY_OPEN';
export const CITY_CLOSE = 'core/dialogs/CITY_CLOSE';
export const GIFT_OPEN = 'order/dialogs/GIFT_OPEN';
export const GIFT_CLOSE = 'order/dialogs/GIFT_CLOSE';
export const OPEN = 'core/dialogs/OPEN';
export const CLOSE = 'core/dialogs/CLOSE';

export const BONUSES_CONFIRMATION_CODE_OPEN = 'core/dialogs/BONUSES_CONFIRMATION_CODE_OPEN';
export const BONUSES_CONFIRMATION_CODE_CLOSE = 'core/dialogs/BONUSES_CONFIRMATION_CODE_CLOSE';

export const FEEDBACK_CONFIRMATION_OPEN = 'core/dialogs/FEEDBACK_CONFIRMATION_OPEN';
export const FEEDBACK_CONFIRMATION_CLOSE = 'core/dialogs/FEEDBACK_CONFIRMATION_CLOSE';

export const UTM_OPEN = 'core/dialogs/UTM_OPEN';
export const UTM_CLOSE = 'core/dialogs/UTM_CLOSE';

export const DIALOG_CHANGE_PROFILE_NAME_OPEN = 'core/dialogs/DIALOG_CHANGE_PROFILE_NAME_OPEN';
export const DIALOG_CHANGE_PROFILE_NAME_CLOSE = 'core/dialogs/DIALOG_CHANGE_PROFILE_NAME_CLOSE';

export const INGREDIENT_DIALOG_OPEN = 'core/dialogs/INGREDIENT_DIALOG_OPEN';
export const INGREDIENT_DIALOG_CLOSE = 'core/dialogs/INGREDIENT_DIALOG_CLOSE';
export const DIALOG_FAQ_QUESTION_OPEN = 'core/dialogs/DIALOG_FAQ_QUESTION_OPEN';
export const DIALOG_FAQ_QUESTION_CLOSE = 'core/dialogs/DIALOG_FAQ_QUESTION_CLOSE';

export const DIALOG_EMAIL_CONFIRM_OPEN = 'core/dialogs/DIALOG_EMAIL_CONFIRM_OPEN';
export const DIALOG_EMAIL_CONFIRM_CLOSE = 'core/dialogs/DIALOG_EMAIL_CONFIRM_CLOSE';
