import React from 'react';

import GroupListHeader from '../group-list-header/group-list-header';
import ProductList from 'modules/product/components/product-list/product-list';
import ProductSectionList from 'modules/product/components/product-section-list/product-section-list';

const Group = ({ group, listId, ...rest }) => {

  const hasCounter = Boolean(group.endDate);

  return group.type === 'SECTIONS' ? (
    <div>
      <ProductSectionList group={group} {...rest} hasCounter={hasCounter} />
    </div>
  ) : (
      <div>
        <GroupListHeader {...rest} hasCounter={hasCounter} />
        <ProductList group={group} {...rest} singleProduct={hasCounter} />
      </div>
    );

};

export default Group;
