import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import InstagramGroup from '../components/instagram-group/instagram-group';
import getPreparedInstagramPosts from './helpers/get-prepared-instagram-posts';

import * as fromInstagramPosts from 'modules/core/ducks/instagram-posts';

import actions from 'modules/core/ducks/actions/instagram-posts';

const mapStateToProps = createStructuredSelector({
  instagramLinks: fromInstagramPosts.getLinkList,
  isLoading: fromInstagramPosts.getIsLoading
});

const mapDispatchToProps = {
  requestLinkList: actions.request
};

const MAX_AMOUNT_OF_INSTAGRAM_LINKS = 4;

const { arrayOf, string } = PropTypes;

const DISABLE_INSTAGRAM = false;

class InstagramGroupContainer extends PureComponent {
  static propTypes = {
    instagramLinks: arrayOf(string)
  };

  static defaultProps = {
    instagramLinks: []
  };

  render() {
    const { instagramLinks, isLoading } = this.props;

    if (DISABLE_INSTAGRAM) {
      return null;
    }

    const preparedInstagramPosts = isLoading
      ? []
      : getPreparedInstagramPosts(instagramLinks)
          // .getLog('initial')
          .shuffleLinks()
          // .getLog('shuffleLinks')
          .spliceLinks(MAX_AMOUNT_OF_INSTAGRAM_LINKS)
          // .getLog('spliceLinks')
          .getList();

    return <InstagramGroup isLoading={isLoading} instagramPosts={preparedInstagramPosts} />;
  }

  componentDidMount() {
    const { requestLinkList } = this.props;

    // Defer request to improve FCP
    setTimeout(() => requestLinkList(), 1500); // REMOVE DELAY AFTER INTEGRATING SSR
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstagramGroupContainer);
