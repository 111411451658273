import { createReducer, handle } from 'modules/utils/dux';
import { createSelector } from '../../utils/debug-reselect';

import SPECIAL_OFFERS from './types/special-offers';

const specialOffersReducer = createReducer(
  handle(SPECIAL_OFFERS.REQUEST, state => {
    return {
      ...state,
      isLoading: true,
      error: null
    };
  }),

  handle(SPECIAL_OFFERS.RESPONSE, (state, { specialOffers }) => {
    return {
      ...state,
      all: { ...state.all, ...specialOffers.all },
      scenes: { ...state.scenes, ...specialOffers.scenes },
      isLoading: false,
      isLoaded: true
    };
  }),

  handle(SPECIAL_OFFERS.ERROR, (state, { error }) => {
    return {
      ...state,
      error,
      isLoading: false
    };
  }),

  handle(SPECIAL_OFFERS.SHOW_ITEM, state => {
    return {
      ...state,
      isLoading: true
    };
  })
);

export default specialOffersReducer({
  all: {},
  error: null,
  isLoading: false,
  isLoaded: false,
  scenes: {},
  list: {}
});

const getAll = state => state.core.specialOffers;

const getSlugFromRouteParams = (_, props) => {
  if (!props.match || !props.match.params) {
    return null;
  }

  return props.match.params.slug;
};

const getSlugFromProps = (_, { slug }) => slug;

const getScenes = createSelector(
  getAll,
  function _getScenes(all) {
    return all.scenes;
  }
);

export const getError = createSelector(
  getAll,
  function _getScenes(all) {
    return all.error;
  }
);

export const getSceneList = createSelector(
  getScenes,
  function _getSceneList(scenes) {
    return Object.values(scenes);
  }
);

export const getIsLoading = createSelector(
  getAll,
  function _getIsLoading(all) {
    return all.isLoading;
  }
);

export const getIsLoaded = createSelector(
  getAll,
  function _getIsLoaded(all) {
    return all.isLoaded;
  }
);

export const getSpecialOfferBySlug = createSelector(
  getAll,
  getSlugFromProps,
  function _getSpecialOfferBySlug(all, slug) {
    return all.all[slug];
  }
);

export const getSpecialOfferByRouteParams = createSelector(
  getAll,
  getSlugFromRouteParams,
  function _getSpecialOfferBySlug(all, slug) {
    return all.all[slug];
  }
);

export const getSpecialOfferSceneList = createSelector(
  getSpecialOfferBySlug,
  function _getSpecialOfferSceneListBySlug(specialOffer) {
    return specialOffer.scenes;
  }
);

export const getScenesBySpecialOffer = createSelector(
  getSpecialOfferSceneList,
  getSceneList,
  function _getSpecialOfferScenes(specialOfferSceneList, sceneList) {
    let _sceneList = [...sceneList];
    _sceneList = _sceneList.filter(item => specialOfferSceneList.includes(item.scene));
    return _sceneList;
  }
);

export const getSimilarSpecialOffersSlug = createSelector(
  getScenesBySpecialOffer,
  getSlugFromProps,
  function _getSimilarSpecialOffersSlug(scenesBySpecialOffer, currentSlug) {
    const similar = [];

    scenesBySpecialOffer.forEach(scene => {
      similar.push(...scene.list);
    });

    const uniqSimilar = similar.filter(
      (slug, i) => slug !== currentSlug && similar.indexOf(slug) === i
    );
    return uniqSimilar;
  }
);
