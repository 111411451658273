import { takeEvery, put, call } from 'redux-saga/effects';

import { mixitApi } from 'services/mixit';

import * as types from '../ducks/types/instagram-posts';
import actions from '../ducks/actions/instagram-posts';

export function* instagramWatcher() {
  yield takeEvery(types.REQUEST, requestLinkList);
}

function* requestLinkList() {
  const response = yield call(mixitApi().instagramReviews);

  const linkList = response.data;

  try {
    yield put(actions.response(linkList));
  } catch (e) {
    console.warn(e);
    yield put(actions.error('Не удалось загрузить Instagram посты'));
  }
}
