import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Container from 'modules/core/components/container/container';
import Button from 'modules/core/components/button/button';

import ReviewSummary from '../review-summary/review-summary';

import './section-review-header.css';

const { string, number, func, bool } = PropTypes;

class SectionReviewHeader extends Component {
  static propTypes = {
    title: string,
    rating: number,
    onClick: func,
    withoutContainer: bool
  };

  render() {
    const { withoutContainer } = this.props;

    return (
      <Fragment>
        {withoutContainer ? (
          this.renderHeader()
        ) : (
          <Container narrow>{this.renderHeader()}</Container>
        )}
      </Fragment>
    );
  }

  renderHeader() {
    const { rating, onClick } = this.props;
    return (
      <section className="SectionReviewHeader">
        <h3 className="SectionReviewHeader-title">Отзывы</h3>
        {!!rating && (
          <div className="SectionReviewHeader-summary">
            <ReviewSummary rating={rating} />
          </div>
        )}

        <div className="SectionReviewHeader-setReview">
          <Button title="Оставить отзыв" onClick={onClick} />
        </div>
      </section>
    );
  }
}

export default SectionReviewHeader;
