import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getSpecialOfferById } from '../ducks/deprecated-special-offers';

import plural from 'modules/utils/plural';
import getTimeDifference from 'modules/utils/get-time-difference';

import SpecialOfferPreview from '../components/special-offer-preview/special-offer-preview';

import { ToastConsumer } from 'modules/core/containers/toast-stack';
import textSlice from 'modules/utils/text-slicer';

const mapStateToProps = createStructuredSelector({
  preview: getSpecialOfferById
});

class SpecialOfferPreviewContainer extends Component {
  render() {
    const { preview } = this.props;

    const expireCaption = this.getExpireCaption();
    const status = this.getStatusSpacialOffer();
    const isPromocodeExists = this.getIsPromocodeExistFlag();
    const currentDescription = this.getSliceDescription();

    return (
      <ToastConsumer>
        {({ create, close }) => (
          <SpecialOfferPreview
            id={preview.id}
            title={preview.title}
            description={currentDescription}
            media={preview.media}
            promocode={preview.promocode}
            specialOffer={preview}
            status={status}
            expireCaption={expireCaption}
            isPromocodeExists={isPromocodeExists}
            link={preview.link}
            apologiesToastCreate={create}
            apologiesToastClose={close}
          />
        )}
      </ToastConsumer>
    );
  }

  getSliceDescription = () => {
    const { preview } = this.props;
    return textSlice(preview.description, 80);
  };

  getExpireMilliseconds() {
    const { preview } = this.props;
    const expireDate = new Date(preview.endDate);
    const currentDate = Date.now();

    return expireDate - currentDate;
  }

  getExpireCaption() {
    const expireMillisecond = this.getExpireMilliseconds();
    const expireDate = getTimeDifference(expireMillisecond);
    const expireWeek = 7;

    if (expireMillisecond < 0) {
      return 'Cрок действия акции истек';
    } else if (isNaN(expireMillisecond)) {
      return false;
    }

    if (expireDate.months) {
      return `${expireDate.months} ${plural(expireDate.months, [
        'месяц',
        'месяца',
        'месяцев'
      ])} до конца акции`;
    }

    if (expireDate.days >= expireWeek) {
      return `${expireDate.days} ${plural(expireDate.days, [
        'день',
        'дня',
        'дней'
      ])} до конца акции`;
    }

    if (expireDate.days && expireDate.days < expireWeek) {
      if (!expireDate.hours) {
        return `${expireDate.days} ${plural(expireDate.days, [
          'день',
          'дня',
          'дней'
        ])}  до конца акции`;
      }

      return `${expireDate.days} ${plural(expireDate.days, ['день', 'дня', 'дней'])} и ${
        expireDate.hours
      } ${plural(expireDate.hours, ['час', 'часа', 'часов'])}  до конца акции`;
    }

    if (expireDate.hours > 1) {
      return ` ${expireDate.hours} ${plural(expireDate.hours, [
        'час',
        'часа',
        'часов'
      ])}  до конца акции`;
    }

    return 'осталось меньше часа до конца акции';
  }

  getStatusSpacialOffer() {
    const expireMillisecond = this.getExpireMilliseconds();
    const expireDate = getTimeDifference(expireMillisecond);

    if (expireMillisecond < 0) {
      return 'ended';
    }

    if (!expireDate.months && !expireDate.days) {
      return 'expiring';
    }

    return 'default';
  }

  getIsPromocodeExistFlag() {
    const { preview } = this.props;
    const expireMillisecond = this.getExpireMilliseconds();

    const isPromocodeExists = !!preview.promocode && expireMillisecond > 0;

    return isPromocodeExists;
  }
}

export default connect(mapStateToProps)(SpecialOfferPreviewContainer);
