import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import getTimeDifference from 'modules/utils/get-time-difference';
import { getDatePeriod } from 'modules/utils/date-string';
import plural from 'modules/utils/plural';
import { getSpecialOfferById } from '../ducks/deprecated-special-offers';

import { ToastConsumer } from 'modules/core/containers/toast-stack';

import SpecialOffer from '../components/special-offer/special-offer';
import CountDown from './count-down';

const MapStateToProps = createStructuredSelector({
  specialOffer: getSpecialOfferById
});

const SCENE_TITLES = {
  site: 'на сайте',
  shop: 'в магазине'
};

class SpecialOfferContainer extends Component {
  render() {
    const { specialOffer } = this.props;

    // const metaCaption = this.formMetaCaption();

    if (!specialOffer) {
      return null;
    }

    const timeCaption = this.formTimeCaption();
    const isPromocodeExists = this.getIsPromocodeExistFlag();
    const isTimeLeftAligned = this.getTimeLeftModifierFlag();
    const isTimeColorGrey = this.getTimeLeftModifierFlag();

    return (
      <ToastConsumer>
        {({ create, close }) => (
          <SpecialOffer
            title={specialOffer.title}
            description={specialOffer.description}
            promocode={specialOffer.promocode}
            media={specialOffer.media}
            link={specialOffer.link}
            // metaCaption={metaCaption}
            timeCaption={timeCaption}
            isPromocodeExists={isPromocodeExists}
            isTimeLeftAligned={isTimeLeftAligned}
            isTimeColorGrey={isTimeColorGrey}
            apologiesToastCreate={create}
            apologiesToastClose={close}
          />
        )}
      </ToastConsumer>
    );
  }

  getExpireMilliseconds(expireDate) {
    const currentDate = new Date();
    const expireDateObject = new Date(expireDate);
    return expireDateObject - currentDate;
  }

  formSceneCaption() {
    const { specialOffer } = this.props;

    const sceneTitleList = specialOffer.scenes.map(scene => SCENE_TITLES[scene]);

    if (sceneTitleList.length > 1) {
      const lastSceneTitle = sceneTitleList.pop();

      return `${sceneTitleList.join(', ')} и ${lastSceneTitle}`;
    }

    const title = sceneTitleList[0];

    return title;
  }

  formMetaCaption() {
    const { specialOffer } = this.props;

    const timePeriodCaption = getDatePeriod(specialOffer.startDate, specialOffer.expireDate);
    const sceneCaption = this.formSceneCaption();

    const metaCaption = `Акция ${timePeriodCaption} ${sceneCaption}`;

    return metaCaption;
  }

  formTimeCaption() {
    const { specialOffer } = this.props;

    const expireMilliseconds = this.getExpireMilliseconds(specialOffer.endDate);

    if (expireMilliseconds < 0) {
      return <div className="SpecialOffer-timeCaption">Срок действия акции истек</div>;
    } else if (isNaN(expireMilliseconds)) {
      return false;
    }

    const expireDateObject = getTimeDifference(expireMilliseconds);

    if (expireDateObject.months) {
      return `До конца акции осталось ${expireDateObject.months} ${plural(expireDateObject.months, [
        'месяц',
        'месяца',
        'месяцев'
      ])}`;
    }

    return <CountDown endData={specialOffer.endDate} size="small" />;
  }

  getIsPromocodeExistFlag() {
    const { specialOffer } = this.props;
    const expireMillisecond = this.getExpireMilliseconds(specialOffer.endDate);

    const isPromocodeExists = !!specialOffer.promocode && expireMillisecond > 0;

    return isPromocodeExists;
  }

  getTimeLeftModifierFlag() {
    const { specialOffer } = this.props;

    const expireMilliseconds = this.getExpireMilliseconds(specialOffer.endDate);
    const expireDateObject = getTimeDifference(expireMilliseconds);

    return expireMilliseconds < 0 || expireDateObject.months;
  }
}

export default connect(MapStateToProps)(SpecialOfferContainer);
