import React, { Component } from 'react';

import Container from '../container/container';

import './section-special-offer.css';

class SectionSpecialOffer extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="SectionSpecialOffer">
        <Container min>{children}</Container>
      </div>
    );
  }
}

export default SectionSpecialOffer;
