import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconAngleArrowUp, IconAngleArrowDown } from 'modules/core/components/icons/index';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';
import Price from 'modules/ordering/components/price-formatted/price-formatted';

import TransactionOrderDetails from '../../../containers/transaction-order-details';

import dateString from 'modules/utils/date-string';

import './transaction.css';

const bonusTypeMarks = {
  income: '+',
  expense: '-'
};

function checkExtraBonusesExistence(bonusList) {
  if (!bonusList) {
    return false;
  }

  const BONUS_EXTRA_TYPE = 'BONUS_TYPE/EXTRA';

  const filteredBonusList = bonusList.filter(bonus => bonus.type === BONUS_EXTRA_TYPE);

  return filteredBonusList.length > 0;
}

class Transaction extends Component {
  render() {
    const { transaction, bonusType, isActive, isOpened, isOrderExists, onClick } = this.props;

    const ISOStringDate = new Date(transaction.date).toISOString();
    const formattedDate = dateString(transaction.date);

    const amount = transaction[`${bonusType}Summary`];

    const isExtraBonusesExist = checkExtraBonusesExistence(transaction.expense);

    return (
      <div
        className={cn('BonusHistoryTableMobile_Transaction', {
          'BonusHistoryTableMobile_Transaction--active': isActive
        })}
      >
        <div className="BonusHistoryTableMobile_Transaction-info">
          <div className="BonusHistoryTableMobile_Transaction-titleHolder">
            <div className="BonusHistoryTableMobile_Transaction-title">
              {transaction.title}
              {transaction.orderTotal && (
                <Fragment>
                  {' на '}
                  <Price number={transaction.orderTotal} />
                </Fragment>
              )}
            </div>

            <div
              className={cn(
                'BonusHistoryTableMobile_Transaction-bonuses',
                `BonusHistoryTableMobile_Transaction-bonuses--type-${bonusType}`
              )}
            >
              {!!transaction[bonusType] && bonusTypeMarks[bonusType]}
              {amount}
            </div>
          </div>

          <div className="BonusHistoryTableMobile_Transaction-dateHolder">
            <time className="BonusHistoryTableMobile_Transaction-date" dateTime={ISOStringDate}>
              {formattedDate}
            </time>
          </div>

          {isExtraBonusesExist && (
            <div className="BonusHistoryTableMobile_Transaction-extra">Экстрабонусы</div>
          )}
        </div>
        {isOrderExists && (
          <Fragment>
            <button className="BonusHistoryTableMobile_Transaction-button" onClick={onClick}>
              <span className="BonusHistoryTableMobile_Transaction-buttonText">
                {isOpened ? 'Скрыть чек' : 'Показать чек'}
              </span>

              <span className="BonusHistoryTableMobile_Transaction-buttonIcon">
                <IconContainer size="xsmall">
                  {isOpened ? <IconAngleArrowUp /> : <IconAngleArrowDown />}
                </IconContainer>
              </span>
            </button>

            {isOpened && (
              <HideErrorBoundary>
                <div className="BonusHistoryTableMobile_Transaction-orderDetails">
                  <TransactionOrderDetails
                    id={transaction.orderId}
                    total={transaction.orderTotal}
                  />
                </div>
              </HideErrorBoundary>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default Transaction;
