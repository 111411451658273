import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import { createReducer, createAction, createActionError } from 'modules/utils/dux';

import { getProductsModule } from '../meta';
import * as GROUPS from './types/groups';
import * as fromProducts from './products';
import { uniqBy } from 'modules/utils/uniq';

import { COMPARISON_FUNCTION } from './constants/sort-options';

/* TYPES */

/* REDUCERS */

const all = createReducer({
  [GROUPS.SHOW_ITEM]: (state, { slug }) => {
    return {
      ...state,
      [slug]: {
        ...state[slug],
        isLoading: true
      }
    };
  },

  [GROUPS.LOADING_CANCEL]: (state, { slug }) => {
    return {
      ...state,
      [slug]: {
        ...state[slug],
        isLoading: false
      }
    };
  },

  [GROUPS.ADD_ITEM]: (state, { slug, group }, error) => {
    if (error) {
      return {
        ...state,
        [slug]: {
          error: true
        }
      };
    }

    return {
      ...state,
      [slug]: normalizeGroup(group)
    };
  },

  [GROUPS.ADD_PRODUCTS]: (state, { slug, products, totalAmount }) => {
    slug = slug.toLowerCase();
    const previousProducts = (state[slug] && state[slug].products) ? state[slug].products : [];
    const currentProducts = getSlugs(products).filter(
      slug => previousProducts.indexOf(slug) === -1
    );


    return {
      ...state,
      [slug]: {
        ...state[slug],
        amount: totalAmount,
        isProductsLoading: false,
        products: [...previousProducts, ...currentProducts]
      }
    };
  },

  [GROUPS.LOAD_MORE]: (state, { slug }) => {
    const group = state[slug];

    return {
      ...state,
      [slug]: { ...group, isProductsLoading: true }
    };
  }
});

export default combineReducers({
  all: all({})
});

/* SELECTORS */
const getSlugFromProps = (_, props) => props.slug;

const getSortingFromProps = (_, props) => props.sorting;

const getCategoriesFromProps = (_, props) => props.categories;

export const getGroups = state => getProductsModule(state).groups;

export const getAll = createSelector(getGroups, categories => categories.all);

export const getItemBySlug = createSelector(
  getAll,
  getSlugFromProps,
  (all, slug) => all[slug] || null
);

const getMicroMarkingObjectCreator = () => productList => {
  if (!productList || !productList.length > 0) {
    return null;
  }

  const offerCount = productList.length;

  const highPrice = productList.reduce((highPrice, item) => {
    if (item.price.current > highPrice) {
      return item.price.current;
    }

    return highPrice;
  }, 0);

  const lowPrice = productList.reduce((lowPrice, item) => {
    if (item.price.current < lowPrice) {
      return item.price.current;
    }

    return lowPrice;
  }, productList[0].price.current);

  return { offerCount, highPrice, lowPrice };
};

export const getSortedItemBySlug = createSelector(
  getItemBySlug,
  getSortingFromProps,
  getCategoriesFromProps,
  fromProducts.getFindProductBySlug,
  getMicroMarkingObjectCreator,
  (item, sorting, categories, findProductBySlug, microMarkingObjectCreator) => {
    if (!item || !item.products) {
      return null;
    }

    const products = item.products.map(findProductBySlug);

    const filteredProducts =
      categories && categories.length > 0
        ? products.filter(item => categories.indexOf(item.category.slug) !== -1)
        : products;

    const sortedProducts = sorting
      ? filteredProducts.sort(COMPARISON_FUNCTION[sorting])
      : filteredProducts;

    return {
      ...item,
      products: sortedProducts.map(item => item.slug),
      microMarkingObject: microMarkingObjectCreator(sortedProducts)
    };
  }
);

export const getCategoriesBySlug = createSelector(
  getItemBySlug,
  fromProducts.getFindProductBySlug,
  (group, findProductBySlug) => {
    if (!group || !group.products) {
      return null;
    }

    return uniqBy(
      group.products
        .map(findProductBySlug)
        .map(product => product.category)
        .filter(category => !!category),
      item => item.slug
    );
  }
);

export const getAccessItem = createSelector(getAll, function _accessItem(categories) {
  return slug => {
    if (!slug || !categories[slug]) {
      return null;
    }

    return categories[slug];
  };
});

export const makeGetItem = getSlug =>
  createSelector(getAccessItem, getSlug, function _getItem(accessItem, slug) {
    return accessItem(slug);
  });

export const getCategoryBySlug = makeGetItem(getSlugFromProps);

export const getIsProductListLoading = createSelector(getCategoryBySlug, category => {
  if (!category) {
    return false;
  }
  return category.isProductsLoading;
});

export const getIsLoadingBySlug = createSelector(getItemBySlug, group => {
  if (!group) {
    return true;
  }

  return group.isLoading;
});

export const getNew = createSelector(getAll, all => all.new || null);
export const getNewIndex = createSelector(getAll, all => all.new_index || null);

export const getPopular = createSelector(getAll, all => {
  if (!all.bestsellers) {
    return null;
  }

  return all.bestsellers;
});

export const getSale = createSelector(getAll, all => {
  if (!all.sale) {
    return null;
  }

  return all.sale;
});

/* ACTIONS */

export const actions = {
  showItem(slug) {
    return createAction(GROUPS.SHOW_ITEM, { slug });
  },

  cancelLoading(slug) {
    return createAction(GROUPS.LOADING_CANCEL, { slug });
  },

  addFail(slug) {
    return createActionError(GROUPS.ADD_ITEM, { slug }, true);
  },

  addItem(slug, group) {
    return createAction(GROUPS.ADD_ITEM, { slug, group });
  },

  showNew() {
    const slug = 'new';
    return createAction(GROUPS.SHOW_ITEM, { slug });
  },

  showNewIndex() {
    const slug = 'new_index';
    return createAction(GROUPS.SHOW_ITEM, { slug });
  },

  showPopular() {
    const slug = 'bestsellers';
    return createAction(GROUPS.SHOW_ITEM, { slug });
  },

  showSale() {
    const slug = 'sale';
    return createAction(GROUPS.SHOW_ITEM, { slug });
  },

  addProducts({ slug, products = [], totalAmount, skipCount = 0 }) {
    return createAction(GROUPS.ADD_PRODUCTS, {
      slug,
      products,
      totalAmount,
      skipCount
    });
  },

  loadMoreProducts(slug, amount, filters = [], sort) {
    return createAction(GROUPS.LOAD_MORE, {
      slug,
      amount,
      filters,
      sort
    });
  }
};

/* HELPERS */

function normalizeGroup(group) {
  if (group.type === 'SECTIONS') {
    const allProducts = group.sections.reduce((resArr, item) => [...resArr, ...item.products], []);

    const products = allProducts.map(item => item.slug);

    return {
      ...group,
      products
    };
  }

  const products = group.products.map(item => item.slug);

  return {
    ...group,
    products
  };
}

export function getSlugs(products) {
  return products.map(({ slug }) => slug);
}
