import React, { Component } from 'react';
import Media from 'react-media';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Form } from 'modules/form/components/form';
import Input from 'modules/form/components/input/input';
import Field from 'modules/form/components/field/field';
import Checkbox from 'modules/form/components/checkbox/checkbox';
import Container from 'modules/core/components/container/container';

import Button from '../button/button';

import './new-subscribe.css';

const EMAIL_REQUIRED_ERROR = 'Введите свой email';
const EMAIL_FORMAT_ERROR = 'Неверный формат введенной почты';

const credentialsSchema = Yup.object().shape({
    email: Yup.string()
        .required(EMAIL_REQUIRED_ERROR)
        .email(EMAIL_FORMAT_ERROR)
});

class Subscribe extends Component {
    render() {
        const { accountEmail, isLoading, onSubmit } = this.props;

        const initialValues = {
            email: accountEmail || '',
            agreement: false
        };

        return (
            <Media query="(min-width: 841px)">
                {isDesktop => (
                    <div className="NewSubscribe">
                        {!isDesktop && <div className="NewSubscribe-media" />}

                        <Container>
                            <div className="NewSubscribe-title">
                                Узнавайте об акциях и специальных предложениях первыми
              </div>

                            <div className="NewSubscribe-form">
                                <Formik
                                    validationSchema={credentialsSchema}
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    render={({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <div className="NewSubscribe-formContent">
                                                <div className="NewSubscribe-formActionContent">
                                                    <div className="NewSubscribe-field">
                                                        <Field error={touched.email && errors.email}>
                                                            <Input
                                                                type="email"
                                                                placeholder="Введите e-mail"
                                                                name="email"
                                                                size={isDesktop ? 'normal' : 'small'}
                                                                state={touched.email && errors.email && 'error'}
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                disabled={isLoading}
                                                            />
                                                        </Field>
                                                    </div>

                                                    <div className="NewSubscribe-button">
                                                        <Button
                                                            title="Подписаться"
                                                            variant="dark"
                                                            type="submit"
                                                            size={isDesktop ? 'normal' : 'small'}
                                                            disabled={!values.agreement || isLoading}
                                                            expanded={!isDesktop}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="NewSubscribe-confirmation">
                                                    <div className="NewSubscribe-confirmationCheckbox">
                                                        <Checkbox
                                                            name="agreement"
                                                            value="agreement"
                                                            // label="Я согласен(-на) на обработку персональных данных и с условиями подписки"
                                                            checked={values.agreement}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            size={isDesktop ? 'normal' : 'small'}
                                                            variant="dark"
                                                        />
                                                    </div>

                                                    <div className="NewSubscribe-confirmationUnclickedLabel">
                                                        Я согласен(-на) на обработку{' '}
                                                        <a
                                                            className="NewSubscribe-confirmationLink"
                                                            href="https://mixit.ru/upload/personal_data.pdf"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            персональных данных
                            </a>{' '}
                            и с{' '}
                                                        <a
                                                            className="NewSubscribe-confirmationLink"
                                                            href="https://mixit.ru/upload/personal_data1.pdf"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            условиями подписки
                            </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                />
                            </div>
                        </Container>
                    </div>
                )}
            </Media>
        );
    }
}

export default Subscribe;
