import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { IconSearch } from 'modules/core/components/icons';
import IconContainer from 'modules/core/components/icon-container/icon-container';
// import AutocompleteProvider from 'modules/form/components/autocomplete/autocomplete-provider';
import DropdownSelect from 'modules/ordering/components/pick-point-dialog/dropdown-select/dropdown-select-thin';
import ProductAnalyticsProvider from 'modules/product/containers/product-analytics';

import './search-input.css';

class SearchInput extends Component {
  unlistenHistory = null;
  state = { value: '' };

  render() {
    const { placeholder, options, focused, onSubmit, onBlur, onChange } = this.props;

    const { value } = this.state;

    const isDropdownOpened = options.length !== 0 && focused;

    return (
      <div className="SearchInput" itemscope="" itemtype="https://schema.org/WebSite">
        <meta itemprop="url" content="https://mixit.ru/" />
        {/* <AutocompleteProvider
          key={this.state.key}
          placeholder={placeholder}
          options={options}
          onBlur={onBlur}
          onFocus={onFocus}
          onFilter={onChange}
          selectTitle={item => `${item.description}. ${item.title}`}
          size="small"
          onChange={onProductSelect}
          onInputChange={this.handleInputChange}
          onChange={onChange}
          appended={
            <button
              className="SearchField-button"
              type="button"
              onClick={onSubmit}
            >
              <IconContainer size="small">
                <IconSearch />
              </IconContainer>
            </button>
          }
        /> */}

        <form itemprop="potentialAction" itemscope="" itemtype="https://schema.org/SearchAction" onSubmit={onSubmit}>
          <meta itemprop="target" content="https://mixit.ru/search/{search_term_string}" />
          <DropdownSelect
            inputValue={value}
            size="small"
            placeholder={placeholder}
            appended={
              <button className="SearchField-button" type="button" onClick={onSubmit}>
                <IconContainer size="small">
                  <IconSearch />
                </IconContainer>
              </button>
            }
            isOpened={isDropdownOpened}
            onBlur={onBlur}
            onFocusIn={this.handleFocusIn}
            onFilter={onChange}
            onFilterQueryChange={this.handleFilterQueryChange}
            onSelect={this.handleSelect}
            onKeyDown={this.handleKeyDown}
            isQueryField
          >
            {options.map(option => {
              return (
                <DropdownSelect.Option value={option.slug}>
                  <ProductAnalyticsProvider
                    productId={option.slug}
                    listId="search-field"
                    // listId={`search-field-by-request_${value}`}
                    render={({ productRef, handleClick }) => (
                      <div className="SearchField-option" ref={productRef} onClick={handleClick()}>
                        {option.description}. {option.title}
                      </div>
                    )}
                  />
                </DropdownSelect.Option>
              );
            })}
          </DropdownSelect>
        </form>
      </div>
    );
  }

  componentDidMount() {
    const { history } = this.props;

    this.unlistenHistory = history.listen(location => {
      this.onRouteChanged(location);
    });
  }

  componentWillUnmount() {
    this.unlistenHistory();
  }

  setValue(value) {
    this.setState({ value });
  }

  resetValue() {
    this.setState({ value: '' });
  }

  checkSearchPage(location) {
    return location.pathname.split('/')[1] === 'search';
  }

  onRouteChanged(location) {
    if (this.checkSearchPage(location)) {
      return;
    }

    this.resetValue();
  }

  handleFilterQueryChange = value => {
    const { onChange } = this.props;

    this.setValue(value);
    onChange(value);
  };

  handleSelect = index => {
    const { options, onProductSelect } = this.props;

    const selectedOption = options[index];

    onProductSelect(selectedOption);
  };

  handleFocusIn = () => {
    const { onFocus } = this.props;

    onFocus();
  };

  handleKeyDown = e => {
    const { onSubmit } = this.props;
    const { keyCode } = e;
    const KEY_ENTER = 13;

    if (keyCode === KEY_ENTER) {
      onSubmit();
    }
  };
}

export default withRouter(SearchInput);
