import { mixitApi } from 'services/mixit';
import { takeEvery, put, call, select } from 'redux-saga/effects';
// import { delay } from 'redux-saga';

import * as GROUPS from '../ducks/types/groups';
import * as fromGroups from '../ducks/groups';
import * as fromRouter from 'modules/core/ducks/router/selectors';

import { matchPath } from 'react-router-dom';

import { getError404Link, getGroupBaseLink } from 'routes/links';
import { replace } from 'connected-react-router';

// import response from './seed';

function* showItem({ payload }) {
  const { slug } = payload;

  const group = yield select(fromGroups.getItemBySlug, { slug });

  if (group.products) {
    yield put(fromGroups.actions.cancelLoading(slug));
    return;
  }

  yield call(fetchGroup, slug);
}

function* fetchGroup(slug) {
  const isIndexGroup = slug.indexOf('_index') !== -1;

  const productGroupApi = mixitApi().productGroups(slug, isIndexGroup);

  const currentPathName = yield select(fromRouter.getPathName);

  const matchGroupPage = matchPath(currentPathName, {
    path: getGroupBaseLink()
  });

  const isGroupPage = Boolean(matchGroupPage);

  try {
    const groupResponse = yield call(productGroupApi.item, { request_source: 'site' });

    // const group = slug === 'dekor_prod' ? response.data : groupResponse.data;

    const group = groupResponse.data;

    // const group = response.data;

    const normalizedGroup = normalizeGroupData(group, isIndexGroup);

    // yield delay(2000);
    // throw new Error();
    yield put(fromGroups.actions.addItem(slug, normalizedGroup));
  } catch (e) {
    console.error(e);
    if (e.isFailed) {
      return;
    }

    if (isGroupPage) {
      yield put(replace(getError404Link()));
    } else {
      yield put(fromGroups.actions.addFail(slug));
    }
  }
}

function* loadMore({ payload }) {
  const { slug, amount } = payload;
  const productGroupApi = mixitApi().productGroups(slug);


  try {
    const groupResponse = yield call(productGroupApi.item, { limit: 24, offset: amount });
    const group = groupResponse.data;


    const normalizedGroup = normalizeGroupData(group);


    yield put(fromGroups.actions.addProducts(normalizedGroup));
  } catch (e) {
    console.error(e);
    yield put(fromGroups.actions.addFail(slug));
  }
}

export function* groupWatcher() {
  yield takeEvery(GROUPS.SHOW_ITEM, showItem);
  yield takeEvery(GROUPS.LOAD_MORE, loadMore);
}

function normalizeGroupData(data, isIndexGroup) {
  if (data.type === 'SECTIONS') {
    return normalizeSectionGroupData(data);
  }

  return normalizeDefaultGroupData(data, isIndexGroup);
}

function normalizeDefaultGroupData(data, isIndexGroup) {
  let { slug, title, products, total_amount, banner, type, description, promo_media,
    end_date
    // end_date="2020-09-10T15:46:30Z" 
    // end_date="2020-08-12T11:46:30+03:00" 
  } = data;
  // if(slug === "combined-skin-type") {
  //   end_date = "2020-08-12T11:46:30+03:00"
  // }

  return {
    title: title || '',
    slug: isIndexGroup ? slug + '_index' : slug,
    products,
    totalAmount: total_amount,
    banner,
    type,
    description,
    promoMedia: promo_media,
    // ...(end_date && {endDate: getMoscowTimeZonedDate(end_date)})
    ...(end_date && { endDate: end_date })
  };
}

function normalizeSectionGroupData(data) {
  const { slug, title, sections, total_amount, banner, type, promo_media, description, end_date } = data;

  return {
    title: title || '',
    slug,
    totalAmount: total_amount,
    banner,
    type,
    sections,
    promoMedia: promo_media,
    description,
    ...(end_date && { endDate: end_date })
  };
}

// function getMoscowTimeZonedDate(data) {
//   const MOSCOW_TIMEZONE = '+0300';
//   const isTimeZoned = data.includes('+');
//   if (isTimeZoned) {
//     return data;
//   }
//   return data + MOSCOW_TIMEZONE;
// }