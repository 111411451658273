import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconContainer from './../icon-container/icon-container';
import { IconCheck, IconCopy } from './../icons/';
import copyStringToClipboard from './helpers/copy-string-to-clipboard';

import './promo-code.css';

const { string, bool } = PropTypes;

const promoToIconSize = {
  normal: 'small',
  small: 'xsmall'
};

class PromoCode extends Component {
  state = {
    isBrowserSupportedCoping: true,
    isPromoCodeCopied: false
  };

  static propTypes = {
    code: string,
    size: string,
    inverted: bool,
    themeColor: string,
    withOverlay: bool
  };

  codeText = null;
  copiedStatusTimer = null;

  render() {
    const { buttonStyle,textStyle, codeStyle, code, size, inverted, themeColor, withOverlay, expanded, options = [] } = this.props;

    const stringWidth = this.getStringWidth(code.toUpperCase());

    const { isBrowserSupportedCoping, isPromoCodeCopied } = this.state;

    let promoStyle = { color: themeColor };

    if (!withOverlay) {
      delete promoStyle.color;
    }

    const optionClassNames = options.map(option=>`PromoCode--${option}`)

    return (
      <label
        title={
          isPromoCodeCopied
            ? 'Код скопирован'
            : isBrowserSupportedCoping
            ? 'Кликните чтобы скопировать'
            : 'Выделите и скопируйте код'
        }
        className={cn('PromoCode', ...optionClassNames, {
          [`PromoCode--size-${size}`]: size,
          'PromoCode--inverted': inverted,
          'PromoCode--onOverlay': withOverlay,
          'PromoCode--notSupporterCopy': !isBrowserSupportedCoping,
          'PromoCode--promoCodeCopied': isPromoCodeCopied,
          'PromoCode--expanded': expanded
        })}
        style={{...promoStyle, ...buttonStyle}}
      >
        <div className="PromoCode-holder">
          <div className="PromoCode-title" style={textStyle}>Промокод:</div>
          <div className="PromoCode-inputSizer" style={{ width: stringWidth }}>
            <input
              className="PromoCode-input"
              ref={this.setCodeTextRef}
              type="text"
              value={code}
              readOnly
              // size={code.length}
              onClick={this.handleClick}
              style={codeStyle}
            />
          </div>
        </div>
        {isBrowserSupportedCoping &&
          (isPromoCodeCopied ? (
            <div key="IconCheck" className="PromoCode-icon">
              <IconContainer size={promoToIconSize[size]}>
                <IconCheck />
              </IconContainer>
            </div>
          ) : (
            <div key="IconCopy" className="PromoCode-icon">
              <IconContainer size={promoToIconSize[size]}>
                <IconCopy />
              </IconContainer>
            </div>
          ))}
      </label>
    );
  }

  componentWillUnmount() {
    this.dummy.parentNode.removeChild(this.dummy);
  }

  getStringWidth(value) {
    if (!__BROWSER__) {
      return null;
    }

    let styles = {
      display: 'inline-block',
      position: 'absolute',
      top: '-1000px',
      left: '-1000px',
      zIndex: '-1',
      visibility: 'hidden',
      whiteSpace: 'nowrap',
      width: 'auto'
    };

    const textSizeValuableProperties = [
      'border-left-style',
      'border-left-width',
      'border-right-style',
      'border-right-width',
      'box-sizing',
      'font-family',
      'font-size',
      'font-stretch',
      'font-style',
      'font-variant',
      'font-weight',
      'letter-spacing',
      'padding-left',
      'padding-right'
    ];

    if (!this.dummy) {
      this.dummy = document.createElement('span');
      this.dummy.setAttribute('aria-hidden', true);
      Object.entries(styles).map(([key, value]) => (this.dummy.style[key] = value));
      document.body.appendChild(this.dummy);
    }

    this.dummy.innerHTML = value;

    if (this.codeText) {
      const inputComputedStyle = window.getComputedStyle(this.codeText);

      Array.from(textSizeValuableProperties).forEach(property => {
        this.dummy.style.setProperty(
          property,
          inputComputedStyle.getPropertyValue(property),
          inputComputedStyle.getPropertyPriority(property)
        );
      });
    }

    const { width } = this.dummy.getBoundingClientRect();
    return width;
  }

  setNotSupportingClick() {
    this.setState({
      isBrowserSupportedCoping: false
    });
  }

  setCopiedState() {
    this.setState({
      isPromoCodeCopied: true
    });
  }

  resetCopiedState = () => {
    this.setState({
      isPromoCodeCopied: false
    });
  };

  componentDidMount() {
    if (!document.execCommand) {
      this.setNotSupportingClick();
    }
  }

  copyToClipboard() {
    const { isBrowserSupportedCoping } = this.state;
    const { code } = this.props;
    // this.codeText.select();
    let copyStatus = true;
    if (isBrowserSupportedCoping) {
      copyStatus = copyStringToClipboard(code.toUpperCase());
      this.setCopiedState();
    }
    if (!copyStatus) {
      this.setNotSupportingClick();
    }
  }

  setCodeTextRef = element => {
    if (!element) {
      return;
    }
    this.codeText = element;
    this.forceUpdate();
  };

  handleClick = () => {
    const { isPromoCodeCopied } = this.state;

    if (isPromoCodeCopied) {
      return;
    }

    this.copyToClipboard();

    this.copiedStatusTimer = setTimeout(this.resetCopiedState, 2500);
  };
}

export default PromoCode;
