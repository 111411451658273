import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { IconAngleArrowRight, IconAngleArrowDown } from 'modules/core/components/icons/index';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';
import Price from 'modules/ordering/components/price-formatted/price-formatted';

import OrderDetailsContainers from '../../../containers/transaction-order-details';

import dateString from 'modules/utils/date-string';

import './transaction.css';

function checkExtraBonusesExistence(bonusList) {
  if (!bonusList) {
    return false;
  }

  const BONUS_EXTRA_TYPE = 'BONUS_TYPE/EXTRA';

  const filteredBonusList = bonusList.filter(bonus => bonus.type === BONUS_EXTRA_TYPE);

  return filteredBonusList.length > 0;
}

class Transaction extends Component {
  render() {
    const { transaction, isActive, isOpened, isOrderExists, onClick } = this.props;

    const ISOStringDate = new Date(transaction.date).toISOString();
    const formattedDate = dateString(transaction.date);

    const isExtraBonusesExist = checkExtraBonusesExistence(transaction.expense);

    return (
      <button
        className={cn('BonusHistoryTable_Transaction', {
          'BonusHistoryTable_Transaction--active': isActive
        })}
        disabled={!isOrderExists}
        onClick={onClick}
      >
        <div className="BonusHistoryTable_Transaction-dateCell">
          <time className="BonusHistoryTable_Transaction-date" dateTime={ISOStringDate}>
            {formattedDate}
          </time>
        </div>

        <div className="BonusHistoryTable_Transaction-titleCell">
          <div className="BonusHistoryTable_Transaction-title">
            {transaction.title}
            {transaction.orderTotal && (
              <Fragment>
                {' '}
                на сумму <Price number={transaction.orderTotal} />
              </Fragment>
            )}
          </div>

          {isExtraBonusesExist && (
            <div className="BonusHistoryTable_Transaction-extra">Экстрабонусы</div>
          )}

          {isOpened && (
            <HideErrorBoundary>
              <div className="BonusHistoryTable_Transaction-orderDetails">
                <OrderDetailsContainers id={transaction.orderId} total={transaction.orderTotal} />
              </div>
            </HideErrorBoundary>
          )}
        </div>

        <div className="BonusHistoryTable_Transaction-bonusesCell BonusHistoryTable_Transaction-bonusesCell--type-income">
          {!!transaction.incomeSummary && `+${transaction.incomeSummary}`}
        </div>

        <div className="BonusHistoryTable_Transaction-bonusesCell BonusHistoryTable_Transaction-bonusesCell--type-expense">
          {!!transaction.expenseSummary && `-${transaction.expenseSummary}`}
        </div>

        <div className="BonusHistoryTable_Transaction-iconCell">
          {isOrderExists && (
            <span className="BonusHistoryTable_Transaction-icon">
              <IconContainer size="xsmall">
                {isOpened ? <IconAngleArrowDown /> : <IconAngleArrowRight />}
              </IconContainer>
            </span>
          )}
        </div>
      </button>
    );
  }
}

export default Transaction;
