import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Media from 'react-media';
import Page from 'modules/core/components/page/page';
import { getIndexLink } from 'routes/links';
import Breadcrumbs from 'modules/core/components/breadcrumbs/breadcrumbs';
import CompactBreadcrumbs from 'modules/core/components/compact-breadcrumbs/compact-breadcrumbs';
import Container from 'modules/core/components/container/container';
import Layout from '../layout/layout';
import Sidebar from '../sidebar/sidebar';
import NavigationScene from '../navigation-scene/navigation-scene';
import * as routes from '../../constants/routes';
import SubscriptionSceneContainer from '../../containers/subscription-scene';
import FavoriteSceneContainer from '../../containers/favorite-scene';
import GeneralSceneContainer from '../../containers/general-scene';
import OrderListSceneContainer from '../../containers/order-list-scene';
import OrderSceneContainer from '../../containers/order-scene';

import BonusSceneContainer from '../../containers/bonus-scene';
import BonusHistorySceneContainer from '../../containers/bonus-history-scene';
import ChangePasswordSceneContainer from '../../containers/password-change-scene';

class ProfileScene extends Component {
  render() {
    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <Page>
            <div className="Page-breadcrumbs">
              {isMobile ? (
                <Switch>
                  <Route
                    path={routes.root.path}
                    exact
                    render={() => <CompactBreadcrumbs name="Главная" link={getIndexLink()} />}
                  />
                  <Route
                    path={`${routes.orderings.path}/:id`}
                    render={() => (
                      <CompactBreadcrumbs name="История заказов" link={routes.orderings.path} />
                    )}
                  />
                  <Route
                    render={() => <CompactBreadcrumbs name="Профиль" link={routes.root.path} />}
                  />
                </Switch>
              ) : (
                <Container>
                  <Breadcrumbs
                    pages={[
                      {
                        name: 'Главная',
                        link: getIndexLink()
                      },
                      {
                        name: 'Личный кабинет'
                      }
                    ]}
                  />
                </Container>
              )}
            </div>
            <br /> {/* FIX: sorry :( */}
            <Layout side={<Sidebar />} main={this.renderContent()} />
          </Page>
        )}
      </Media>
    );
  }

  renderContent() {
    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <Switch>
            <Route
              path={routes.root.path}
              exact
              render={() =>
                isMobile ? <NavigationScene /> : <Redirect to={routes.general.path} />
              }
            />
            <Route path={routes.general.path} exact render={() => <GeneralSceneContainer />} />
            <Route
              path={routes.subscriptions.path}
              exact
              render={() => <SubscriptionSceneContainer />}
            />
            <Route path={routes.favorites.path} exact render={() => <FavoriteSceneContainer />} />
            <Route path={routes.orderings.path} exact render={() => <OrderListSceneContainer />} />
            <Route path={routes.bonuses.path} exact render={() => <BonusSceneContainer />} />

            <Route path={routes.bonusHistory.path} render={() => <BonusHistorySceneContainer />} />
            <Route
              path={routes.changePassword.path}
              render={() => <ChangePasswordSceneContainer />}
            />
            <Route
              path={`${routes.orderings.path}/:id`}
              exact
              render={({ match }) => <OrderSceneContainer id={match.params.id} />}
            />
          </Switch>
        )}
      </Media>
    );
  }

  renderNavigation() {}
}

export default ProfileScene;
