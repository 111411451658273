import React, { Component } from 'react';

import './header.css';

class BonusHistoryHeader extends Component {
  render() {
    return (
      <div className="BonusHistoryHeader">
        <div className="BonusHistoryHeader-date">Дата</div>
        <div className="BonusHistoryHeader-title">Операция</div>
        <div className="BonusHistoryHeader-bonuses">Начислено/списано</div>
      </div>
    );
  }
}

export default BonusHistoryHeader;
