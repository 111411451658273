import React, { Component } from 'react';
import classNames from 'classnames';
import './favourite-icon.css';
import { IconHeart } from 'modules/core/components/icons';

class FavouriteIcon extends Component {
  render() {
    const { active, ...rest } = this.props;
    return (
      <span
        className={classNames('FavouriteIcon', {
          'FavouriteIcon--active': active
        })}
      >
        <IconHeart active={active} {...rest} />
      </span>
    );
  }
}

export default FavouriteIcon;
