import React, { Component } from 'react';
import cn from 'classnames';

import { IconDot } from 'modules/core/components/icons/index';

import './bonus-card-status.css';

class BonusCardStatus extends Component {
  render() {
    const { is } = this.props;

    return (
      <div
        className={cn('BonusCardStatus', {
          'BonusCardStatus--activated': is
        })}
      >
        <span className="BonusCardStatus-icon">
          <IconDot />
        </span>

        <span className="BonusCardStatus-title">{is ? 'Активирована' : 'Не активирована'}</span>
      </div>
    );
  }
}

export default BonusCardStatus;
