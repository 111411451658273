import React, { Component } from 'react';

import HappyMasterLanding from 'modules/happy-master-landing/happy-master-landing.jsx';
import Page from 'modules/core/components/page/page';
// import { getIndexLink } from 'routes/links';

class HappyMasterPage extends Component {
  render() {
    return (
      <Page>
        <HappyMasterLanding />
      </Page>
    );
  }

  componentDidMount() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }
}

export default HappyMasterPage;
