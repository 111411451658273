export const isFormOpened = openedFormList => id => {
  return openedFormList.indexOf(id) !== -1;
};

export const makeAddShowReplyForm = isFormOpened => {
  return review => {
    return {
      ...review,
      showReplyForm: isFormOpened(review.id)
    };
  };
};

//usage
// const addShowReplyForm = makeAddShowReplyForm(isFormOpened(activeFormList));

// const trasformedReview = addShowReplyForm(review);

// const list = reviewList.map(addShowReplyForm).map(addRating);
