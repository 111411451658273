import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter, matchPath } from 'react-router';
import { createStructuredSelector } from 'reselect';

// import { isPastDate, isFeatureDate } from 'modules/utils';
import * as fromAccount from 'modules/core/ducks/account';
import getIsMatchedCities from './helpers/get-is-matched-cities';
import getIsMatchedString from './helpers/get-is-matched-string-in-list';

const mapStateToProps = createStructuredSelector({
  isAuthorized: fromAccount.getIsAuthorized
});

function ConditionalRenderer({
  children = null,
  isAuthorized,
  location,
  config = {},
}) {

  const {
    show = true,

    renderPathes = null,
    ignoredPathes = null,

    // expireTime = null,
    // startTime = null,

    cityIds = [],
    ignoredCityIds = [],
    selectCityId = null,
    suggestedId = null,

    // what render if state is out of conditions
    elseRender = null,

    byAuthorized,
    byUnauthorized,

    isBrowser,
    isServer,
    queryMatchStrings = [],
    queryIgnoreStrings = []
  } = config;

  if (!show) {
    return <>{elseRender}</>;
  }

  // check queryStrings
  const isMatchedQueryString = queryMatchStrings.list
    ? getIsMatchedString(queryMatchStrings.list, location.search)
    : true;
  if (!isMatchedQueryString) {
    return <>{elseRender}</>;
  }
  const isMatchedIgmoredQueryString = queryIgnoreStrings.list
    ? getIsMatchedString(queryIgnoreStrings.list, location.search)
    : false;
  if (isMatchedIgmoredQueryString) {
    return <>{elseRender}</>;
  }


  // check globals
  if (isBrowser && !__BROWSER__) {
    return <>{elseRender}</>;
  }
  if (isServer && !__SERVER__) {
    return <>{elseRender}</>;
  }

  // check if authorized
  if (byAuthorized && !isAuthorized) {
    return <>{elseRender}</>;
  }
  if (byUnauthorized && isAuthorized) {
    return <>{elseRender}</>;
  }

  // check if current path is ignored
  const _ignoredPathes = get(ignoredPathes, 'list', false);
  if (Boolean(_ignoredPathes) && _ignoredPathes.length > 0) {
    const isMatchedIgnoredPath = _ignoredPathes.some(item => {
      return matchPath(location.pathname, { ...item });
    });
    if (isMatchedIgnoredPath) {
      return <>{elseRender}</>;
    }
  }

  // check if current path should render
  const _renderPathes = get(renderPathes, 'list', false);
  if (Boolean(_renderPathes) && _renderPathes.length > 0) {
    const isMatchedIgnoredPath = _renderPathes.some(item => {
      return matchPath(location.pathname, { ...item });
    });
    if (!isMatchedIgnoredPath) {
      return <>{elseRender}</>;
    }
  }

  // check dates period (start and end)
  // const isExpiredDate = expireTime ? isPastDate(expireTime) : false;
  // const isNotStarted = startTime ? isFeatureDate(startTime) : false;
  // if (isExpiredDate || isNotStarted) {
  //   return <>{elseRender}</>;
  // }

  // check cities
  const isMatchedCities = cityIds.list
    ? getIsMatchedCities(cityIds.list, selectCityId, suggestedId)
    : true;
  if (!isMatchedCities) {
    return <>{elseRender}</>;
  }

  // check ignored cities
  const isMatchedIgnoredCities = ignoredCityIds.list
    ? getIsMatchedCities(ignoredCityIds.list, selectCityId, suggestedId)
    : false;
  if (isMatchedIgnoredCities) {
    return <>{elseRender}</>;
  }

  return <>{children}</>;
}

const EnhancedConditionalRenderer = withRouter(connect(mapStateToProps)(ConditionalRenderer));

export default EnhancedConditionalRenderer;
