import React, { Component } from 'react';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import Transaction from './transaction/transaction';
import Header from './header';

import './bonus-transactions.css';

class BonusTransactions extends Component {
  static defaultProps = {
    transactions: null
  };

  render() {
    const { transactions } = this.props;

    const isTransactionsExist = transactions.length > 0;

    if (!isTransactionsExist) {
      return (
        <div className="BonusTransactions">
          <div className="BonusTransactions-emptyMessage">
            Пока нет начислений. Выполняйте задания и делайте покупки, чтобы заработать бонусы.
          </div>
        </div>
      );
    }

    return (
      <div className="BonusTransactions">
        <Header />

        <ul className="BonusTransactions-list">
          {transactions.map(transaction => (
            <HideErrorBoundary>
              <li key={transaction.id} className="BonusTransactions-item">
                <Transaction transaction={transaction} />
              </li>
            </HideErrorBoundary>
          ))}
        </ul>
      </div>
    );
  }
}

export default BonusTransactions;
