import React, { Component } from 'react';
import './button-favorite.css';
import { IconHeart } from '../icons';
import classNames from 'classnames';
import IconContainer from '../icon-container/icon-container';

class ButtonFavorite extends Component {
  render() {
    const { active, size, ...rest } = this.props;
    return (
      <button
        {...rest}
        type="button"
        className={classNames('ButtonFavorite', {
          'ButtonFavorite--active': active
        })}
      >
        <span className="ButtonFavorite-iconWrapper">
          <IconContainer inline>
            <IconHeart active={active} />
          </IconContainer>
        </span>
      </button>
    );
  }
}

export default ButtonFavorite;
