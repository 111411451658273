export default function initSpecialOffersRequest(createRequest) {
  return function _getSpecialOffersRequest() {
    return {
      all: createRequest(initAllRequest())
    };
  };
}

function initAllRequest() {
  return function getItemRequest() {
    return {
      method: 'GET',
      path: '/special-offers'
    };
  };
}
