import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import IconCross from 'modules/core/components/icons/icon-cross/icon-cross';
import FormGroup from 'modules/form/components/form-group/form-group';
import Tooltip from 'modules/core/components/tooltip/tooltip';
import Button from 'modules/core/components/button/button';
import Field from 'modules/form/components/field/field';
import Form from 'modules/form/components/form/form';
import isFunction from 'modules/utils/is-function';
import { Textarea } from 'modules/form';

import './reply-form.css';
import InfoSign from './info-sign/info-sign';

const { func, string } = PropTypes;

class ReplyForm extends Component {
  static propTypes = {
    onClose: func,
    onSubmit: func,
    onCommentChange: func,

    comment: string
  };

  commentField = null;

  render() {
    return (
      <div className="replyForm">
        <div className="replyForm-header">{this.renderHeader()}</div>
        <div className="replyForm-form">{this.renderForm()}</div>
      </div>
    );
  }

  renderHeader() {
    return (
      <Fragment>
        <div className="replyForm-label">
          <span className="replyForm-labelTex">Ваш комментарий</span>
          <span className="replyForm-labelIcon">
            <Tooltip content={this.renderLabelInfo()} showDelay={0}>
              <InfoSign />
            </Tooltip>
          </span>
        </div>
        <div className="replyForm-close">
          <Button
            data-testid="cross-button"
            flat
            variant="secondary"
            iconPrepend={<IconCross />}
            size="small"
            onClick={this.handleClose}
          />
        </div>
      </Fragment>
    );
  }

  renderLabelInfo() {
    return (
      <div className="replyForm-labelInfo">
        Комментарий будет опубликован после проверки модератором
      </div>
    );
  }

  renderForm() {
    const { onSubmit, comment } = this.props;

    return (
      <Form onSubmit={onSubmit}>
        <Form.Row>
          <Field>
            <Textarea
              data-testid="comment-field"
              onChange={this.handleCommentChange}
              placeholder="Ваш комментарий"
              value={comment}
              autosize
              opened
              required
              textareaRef={this.setCommentFieldRef}
            />
          </Field>
        </Form.Row>

        <Form.ActionRow>
          <Media query="(max-width: 840px)">
            {isSmallDevices => (
              <FormGroup right>
                <FormGroup.Item>
                  <Button
                    data-testid="cancel-button"
                    title="Отменить"
                    caption="Отменить комментирование"
                    size={isSmallDevices ? 'small' : 'normal'}
                    onClick={this.handleClose}
                  />
                </FormGroup.Item>
                <FormGroup.Item>
                  <Button
                    data-testid="submit-button"
                    title="Отправить"
                    variant="primary"
                    type="submit"
                    size={isSmallDevices ? 'small' : 'normal'}
                    caption="Отправить комментарий на модерацию"
                  />
                </FormGroup.Item>
              </FormGroup>
            )}
          </Media>
        </Form.ActionRow>
      </Form>
    );
  }

  componentDidMount() {
    this.commentField.focus();
  }

  renderTooltipIcon() {
    return (
      <IconContainer size="xsmall">
        <IconCross />
      </IconContainer>
    );
  }

  setCommentFieldRef = element => {
    if (!element) return;
    this.commentField = element;
  };

  handleCommentChange = e => {
    const { value } = e.target;
    const { onCommentChange } = this.props;

    if (!isFunction(onCommentChange)) {
      return;
    }
    onCommentChange(value);
  };

  handleClose = () => {
    const { onClose } = this.props;

    if (!isFunction(onClose)) {
      return;
    }
    onClose();
  };
}

export default ReplyForm;
