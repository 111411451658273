import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromReviews from '../ducks/reviews';
import * as fromAccount from 'modules/core/ducks/account';
import * as fromDialogs from 'modules/core/ducks/dialogs';

import ReviewActions from '../actions/review';

import ProductReview from './../components/product-review/product-review';
import {
  compareDates,
  compareUsefules,
  isFormOpened,
  makeAddShowReplyForm,
  getPageAmount,
  makeCreateListOnPage
} from './helpers';

const SORT_TYPES = {
  BY_USEFULES: 'BY_USEFULES',
  BY_DATE: 'BY_DATE'
};

const sortComparators = {
  [SORT_TYPES.BY_USEFULES]: compareUsefules,
  [SORT_TYPES.BY_DATE]: compareDates
};

const getSortOptions = () => [
  {
    type: SORT_TYPES.BY_USEFULES,
    title: 'По полезности'
  },
  {
    type: SORT_TYPES.BY_DATE,
    title: 'По дате'
  }
];

const getIdsList = (_, { idsList }) => idsList;

const mapStateToProps = createStructuredSelector({
  reviews: fromReviews.makeGetListByIds(getIdsList),
  isReplyConformationDialogOpened: fromDialogs.getIsReplyConformationDialogOpened,
  isReviewConformationDialogOpened: fromDialogs.getIsReviewConformationDialogOpened,
  isAuthorized: fromAccount.getIsAuthorized
});

const mapDispatchToProps = {
  reply: ReviewActions.reply,
  rate: ReviewActions.rate,
  create: ReviewActions.create,
  closeDialog: fromDialogs.actions.close,
  openAuthDialog: fromDialogs.actions.openAuthDialog
};

class ProductReviewContainer extends Component {
  static defaultProps = {
    reviewsOnPage: 10,
    reviewTopTitle: 'Самый полезный отзыв'
  };

  state = {
    currentPage: 1,
    activeSortOption: SORT_TYPES.BY_DATE,
    activeFormList: []
  };

  feedbackForm = null;
  productReview = null;

  render() {
    const { currentPage, activeSortOption, activeFormList } = this.state;

    const {
      reviews,
      reviewTopTitle,
      rating,
      reviewsOnPage,
      isReplyConformationDialogOpened,
      isReviewConformationDialogOpened,
      isAuthorized,
      openAuthDialog,
      ...rest
    } = this.props;

    const pageAmount = getPageAmount(reviews.length, reviewsOnPage);
    const createListOnPage = makeCreateListOnPage(currentPage, reviewsOnPage);

    const addShowReplyForm = makeAddShowReplyForm(isFormOpened(activeFormList));

    const finalReviewList = reviews.map(addShowReplyForm).sort(sortComparators[activeSortOption]);

    const listOnPage = createListOnPage(finalReviewList);

    const topReview = finalReviewList[0];

    return (
      <ProductReview
        productReviewRef={this.setProductReviewRef}
        rating={rating}
        reviewTop={topReview}
        reviewTopTitle={reviewTopTitle}
        pageAmount={pageAmount}
        currentPage={currentPage}
        list={listOnPage}
        sortOptionList={getSortOptions()}
        activeSortOption={activeSortOption}
        formRef={this.setFeedbackFormRef}
        showReviewSubmittedDialog={isReviewConformationDialogOpened}
        showReplySubmittedDialog={isReplyConformationDialogOpened}
        isAuthorized={isAuthorized}
        onReply={this.handleReply}
        onRate={this.handleRate}
        onSortChange={this.handleSortChange}
        onPageChange={this.handlePageChange}
        onReplyFormClose={this.handleReplyFormClose}
        onReplyFormSubmit={this.handleReplyFormSubmit}
        onReviewFormSubmit={this.handleReviewFormSubmit}
        onCreateReview={this.handleCreateReview}
        onReviewSubmittedDialogChange={this.handleReviewSubmittedDialogChange}
        onReplyConformationDialogChange={this.handleReplyConformationDialogChange}
        openAuthDialog={openAuthDialog}
        {...rest}
      />
    );
  }

  setActiveSortOption(activeSortOption) {
    this.setState({
      activeSortOption
    });
  }

  setCurrentPage(currentPage) {
    this.setState({
      currentPage
    });
  }

  closeReplyForm(id) {
    this.setState(state => ({
      activeFormList: state.activeFormList.filter(item => item !== id)
    }));
  }

  openReplyForm(id) {
    this.setState(state => {
      if (state.activeFormList.indexOf(id) !== -1) {
        return state;
      }
      return {
        ...state,
        activeFormList: [...state.activeFormList, id]
      };
    });
  }

  // ---------------------------------------------------

  getScrolledByWindow() {
    return window.pageYOffset;
  }

  getProductReviewTopPosition() {
    return this.productReview.getBoundingClientRect().top;
  }

  scrollToProductReviewTop(top) {
    window.scrollTo({
      top,
      behavior: 'smooth'
    });
  }

  scrollElementToTop() {
    const offsetY = 10;
    const productReviewTopPositionByWindow =
      this.getProductReviewTopPosition() + this.getScrolledByWindow() - offsetY;

    this.scrollToProductReviewTop(productReviewTopPositionByWindow);
  }

  setProductReviewRef = element => {
    if (!element) return;
    this.productReview = element;
  };

  // ---------------------------------------------------

  setFeedbackFormRef = element => {
    if (!element) return;
    this.feedbackForm = element;
  };

  handleCreateReview = () => {
    this.feedbackForm['feedback'].focus();
  };

  handleSortChange = sortOption => {
    this.setActiveSortOption(sortOption);
  };

  handlePageChange = page => {
    this.setCurrentPage(page);
    this.scrollElementToTop();
  };

  handleReply = id => {
    this.openReplyForm(id);
  };

  handleRate = ({ id, action }) => {
    this.props.rate(id, action);
  };

  handleReplyFormClose = id => {
    this.closeReplyForm(id);
  };

  handleReplyFormSubmit = ({ id, comment }) => {
    this.props.reply(id, comment);
    this.closeReplyForm(id);
  };

  handleReviewFormSubmit = review => {
    this.props.create(this.props.slug, review);
  };

  handleReviewSubmittedDialogChange = () => {
    const { closeDialog } = this.props;

    closeDialog();
  };

  handleReplyConformationDialogChange = () => {
    const { closeDialog } = this.props;

    closeDialog();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewContainer);
