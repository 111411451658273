import { put, takeEvery, select, take } from 'redux-saga/effects';
import * as fromBasketProducts from 'modules/ordering/ducks/basket-products';
import * as fromOrdersProducts from 'modules/ordering/ducks/orders-products';
import * as fromProducts from 'modules/product/ducks/products';
import * as fromCategories from 'modules/product/ducks/categories';
import * as fromOrders from 'modules/ordering/ducks/orders';
import * as PRODUCTS from 'modules/product/ducks/types/products';
import * as CATEGORIES from 'modules/product/ducks/types/categories';
import * as BASKET from 'modules/ordering/ducks/basket/types';
import * as ECOMMERCE from '../ducks/types/ecommerce';
import * as SEARCH from 'modules/product/ducks/types/search';
import * as FAVOURITES from 'modules/profile/types/favorites';
import { ACCOUNT } from '../ducks/types';
import * as ORDER from 'modules/ordering/ducks/types/orders';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getRouter } from 'modules/core/ducks/router/selectors';
import { getListWithRemoved } from 'modules/ordering/ducks/basket-products';
import {
  getGoodList as getLastOrderGoodList,
  getProductList as getLastOrderProductList
} from 'modules/ordering/ducks/last-order/selectors';

const mapProductToAnalyticsGoods = (product, amount = null, isGift = null) => {
  const category = [];
  const productCategory = product.category || {};

  if (productCategory.parent) {
    category.push(productCategory.parent.title);
  }

  category.push(productCategory.title);

  const price = choseProductPrice(product.price, isGift);

  const categoryName = category.join('/');

  return Object.assign(
    {},
    {
      id: product.id,
      name: product.title,
      externalId: product.externalId,
      price,
      category: categoryName,
      category_name: categoryName,
      category_id: productCategory.id
    },
    amount
      ? {
          quantity: amount
        }
      : undefined
  );
};

function choseProductPrice(price, isGift) {
  if (isGift) {
    return 0;
  }

  return price.current || price.default;
}

function* onRevealProducts(action) {
  const { list } = action.payload;

  if (!list) {
    return;
  }

  const saturatedList = [];

  for (let item of list) {
    try {
      const product = yield select(fromProducts.getItemBySlug, {
        slug: item.slug
      });
      saturatedList.push({
        ...item,
        product: mapProductToAnalyticsGoods(product, null, item.isGift)
      });
    } catch (e) {}
  }

  yield put({
    type: ECOMMERCE.PRODUCT_LIST_SHOW,
    payload: {
      products: saturatedList
    }
  });
}

function* onClickProduct(action) {
  try {
    const { slug, position, listId } = action.payload;
    const product = yield select(fromProducts.getItemBySlug, { slug });

    yield put({
      type: ECOMMERCE.PRODUCT_CLICK,
      payload: {
        position,
        listName: listId,
        product: mapProductToAnalyticsGoods(product)
      }
    });
  } catch (e) {}
}

function* onOverviewProduct(action) {
  try {
    const { product } = action.payload;

    yield put({
      type: ECOMMERCE.PRODUCT_VIEW,
      payload: {
        product: mapProductToAnalyticsGoods(product)
      }
    });
  } catch (e) {}
}

function* onShowProduct(action) {
  try {
    const { slug } = action.payload;
    const product = yield select(fromProducts.getItemBySlug, { slug });

    yield put({
      type: ECOMMERCE.PRODUCT_VIEW,
      payload: {
        product: mapProductToAnalyticsGoods(product)
      }
    });
  } catch (e) {}
}

function* onProductAdd(action) {
  try {
    const { product, amount } = action.payload;

    yield put({
      type: ECOMMERCE.PRODUCT_ADD_TO_BASKET,
      payload: {
        product: mapProductToAnalyticsGoods(product, amount)
      }
    });
  } catch (e) {}
}

function* onProductRemove(action) {
  try {
    const { product, amount } = action.payload;

    yield put({
      type: ECOMMERCE.PRODUCT_REMOVE_FROM_BASKET,
      payload: {
        product: mapProductToAnalyticsGoods(product, amount)
      }
    });
  } catch (e) {}
}

function* onProductChangeAmount(action) {
  const { previousAmount, amount, product } = action.payload;

  if (!previousAmount) {
    return;
  }

  const difference = amount - previousAmount;

  if (difference > 0) {
    yield put({
      type: ECOMMERCE.PRODUCT_ADD_TO_BASKET,
      payload: {
        product: mapProductToAnalyticsGoods(product, difference)
      }
    });
  }

  if (difference < 0) {
    yield put({
      type: ECOMMERCE.PRODUCT_REMOVE_FROM_BASKET,
      payload: {
        product: mapProductToAnalyticsGoods(product, difference)
      }
    });
  }
}

function* onShowCategory(action) {
  try {
    const { slug } = action.payload;
    const category = yield select(fromCategories.getCategoryBySlug, { slug });

    let id = category && category.id;
    let name = category && category.name;
    let title = category && category.title;
    let parentTitle = category && category.parent && category.parent.title;
    let categoryName = `${parentTitle ? parentTitle + '/' : ''}${title}`;

    if (!id) {
      const action = yield take(CATEGORIES.ADD_ITEM);
      const { category } = action.payload;

      id = category.id;
      name = category.name;
      title = category && category.title;
      parentTitle = category && category.parent && category.parent.title;
      categoryName = `${parentTitle ? parentTitle + '/' : ''}${title}`;
    }

    yield put({
      type: ECOMMERCE.CATEGORY_VIEW,
      payload: {
        category: { id, name, categoryName }
      }
    });
  } catch (e) {}
}

function* onBasketShow() {
  try {
    const goods = yield select(fromBasketProducts.getList);
    const products = goods.map(({ amount, product, gift }) =>
      mapProductToAnalyticsGoods(product, amount, gift)
    );

    yield put({
      type: ECOMMERCE.BASKET_SHOW,
      payload: {
        products
      }
    });
  } catch (e) {
    console.warn(e);
  }
}

function* onAuthReady(action) {
  try {
    const { account, isAuthorized } = action.payload;

    if (isAuthorized) {
      yield put({
        type: ECOMMERCE.USER_SET,
        payload: {
          id: account.id
        }
      });
    } else {
      yield put({
        type: ECOMMERCE.USER_RESET
      });
    }
  } catch (e) {}
}

function* onOrderPlace() {
  try {
    const goods = yield select(fromBasketProducts.getList);
    const products = goods.map(({ amount, product, gift }) =>
      mapProductToAnalyticsGoods(product, amount, gift)
    );

    yield put({
      type: ECOMMERCE.CHECKOUT,
      payload: {
        products
      }
    });
  } catch (e) {
    console.warn(e);
  }
}

function* onOrderSuccess(action) {
  const { id } = action.payload;
  try {
    const goods = yield select(fromOrdersProducts.getSerializedGoodsListById, {
      id
    });
    const promo = yield select(fromOrders.getPromoById, { id });
    const products = goods.map(({ amount, product, gift }) =>
      mapProductToAnalyticsGoods(product, amount, gift)
    );
    const payment = yield select(fromOrders.getPaymentById, { id });
    const delivery = yield select(fromOrders.getDeliveryById, { id });
    const total = yield select(fromOrders.getTotalCostById, { id });

    yield put({
      type: ECOMMERCE.TRANSACTION,
      payload: {
        deliveryMethod: delivery.deliveryMethod.name,
        paymentMethod: payment.paymentMethod.name,
        orderId: id,
        orderTotal: total,
        promoCoupon: promo ? promo.code : null,
        products
      }
    });
  } catch (e) {
    console.warn(e);
  }
}

function* onLocationChange(action) {
  const { payload } = action;
  try {
    const goods = yield select(fromBasketProducts.getList);
    const products = goods.map(({ amount, product, gift }) =>
      mapProductToAnalyticsGoods(product, amount, gift)
    );

    const slug = getCategorySlug(payload.location.pathname);

    const isStandardPage = yield getIsStandardPage(payload.location.pathname, slug);

    if (isStandardPage) {
      yield put({
        type: ECOMMERCE.PAGE_VIEW,
        payload: payload
      });
    }

    const categories = yield select(fromCategories.getAll);

    const productsAll = yield select(fromProducts.getProducts);

    const basketProducts = yield select(getListWithRemoved);

    if (
      /category/gi.test(payload.location.pathname) &&
      !!categories[slug] &&
      !!categories[slug].products
    ) {
      const productForPayload = categories[slug].products.map(item => productsAll[item]);
      yield put({
        type: ECOMMERCE.PAGE_VIEW,
        payload: formPayloadFromEcommerse(productForPayload, payload)
      });
    }

    if (/product/gi.test(payload.location.pathname) && !!productsAll[slug]) {
      yield put({
        type: ECOMMERCE.PAGE_VIEW,
        payload: formPayloadFromEcommerse([productsAll[slug]], payload)
      });
    }

    if (/basket/gi.test(payload.location.pathname) && basketProducts.length > 0) {
      yield put({
        type: ECOMMERCE.PAGE_VIEW,
        payload: formPayloadFromEcommerse(normilizeBasket(basketProducts), payload)
      });
    }

    if (/basket/gi.test(payload.location.pathname) && !(basketProducts.length > 0)) {
      yield put({
        type: ECOMMERCE.PAGE_VIEW,
        payload: { products: [], totalCost: 0, ...payload }
      });
    }

    if (/ordering/gi.test(payload.location.pathname) && slug === 'success') {
      const lastOrderGoodList = yield select(getLastOrderGoodList);

      const lastOrderProductList = yield select(getLastOrderProductList);

      yield put({
        type: ECOMMERCE.PAGE_VIEW,
        payload: formPayloadFromLastOrderEcommerse(lastOrderGoodList, lastOrderProductList, payload)
      });
    }

    if (/redirect-payment-page/gi.test(payload.location.pathname)) {
      const lastOrderGoodList = yield select(getLastOrderGoodList);

      const lastOrderProductList = yield select(getLastOrderProductList);

      yield put({
        type: ECOMMERCE.PAGE_VIEW,
        payload: formPayloadFromLastOrderEcommerse(lastOrderGoodList, lastOrderProductList, payload)
      });
    }

    yield put({
      type: ECOMMERCE.BASKET_DATA_SHOW,
      payload: {
        products
      }
    });
  } catch (e) {
    console.warn(e);
  }
}

function getIsStandardPage(pathname, slug) {
  return (
    !/category/gi.test(pathname) &&
    !/search/gi.test(pathname) &&
    !/product/gi.test(pathname) &&
    !/basket/gi.test(pathname) &&
    !/redirect-payment-page/gi.test(pathname) &&
    !(/ordering/gi.test(pathname) && slug === 'success')
  );
}

function getCategorySlug(pathname) {
  let normalPathName = pathname;

  const lastSymbol = pathname.slice(-1);

  if (lastSymbol === '/') {
    normalPathName = pathname.slice(0, -1);
  }

  const arr = [];

  let pos = 0;

  while (true) {
    let foundPos = normalPathName.indexOf('/', pos);
    if (foundPos === -1) break;

    arr.push(foundPos);
    pos = foundPos + 1;
  }

  return normalPathName.slice(arr[arr.length - 1] + 1);
}

function* onCategoryProductsLoad(action) {
  const { payload } = action;

  const route = yield select(getRouter);

  if (/category/gi.test(route.location.pathname)) {
    yield put({
      type: ECOMMERCE.PAGE_VIEW,
      payload: formPayloadFromEcommerse(payload.products, route)
    });
  }
}

function* onCategoryProductsSearch(action) {
  const { payload } = action;

  const route = yield select(getRouter);

  if (/search/gi.test(route.location.pathname)) {
    yield put({
      type: ECOMMERCE.PAGE_VIEW,
      payload: formPayloadFromEcommerse(Object.values(payload.entities.product), route)
    });
  }
}

function* onCategoryProductFetch(action) {
  const { payload } = action;

  const route = yield select(getRouter);

  const slug = getCategorySlug(payload.location.pathname);

  const productsAll = yield select(fromProducts.getProducts);

  if (/product/gi.test(route.location.pathname) && !productsAll[slug]) {
    yield put({
      type: ECOMMERCE.PAGE_VIEW,
      payload: formPayloadFromEcommerse([payload.product], route)
    });
  }
}

function formPayloadFromEcommerse(products, route) {
  const productIdArr = products.map(item => {
    if (!item.id) {
      return item.product.id;
    }

    return item.id;
  });

  const _productPriceArr = products.map(item => {
    if (item.price.discount && item.price.discount.numerical) {
      return +item.price.default - +item.price.discount.numerical;
    }

    if (item.price.discount && item.price.discount.percentage) {
      return Math.ceil((1 - +item.price.discount.percentage) * +item.price.default);
    }

    return +item.price.default;
  });

  const totalProductCost = _productPriceArr.reduce((sum, current) => sum + current);

  return { ...route, products: productIdArr, totalCost: totalProductCost };
}

const normilizeBasket = basketProduct => {
  return basketProduct.map(item => {
    return { id: item.product.id, price: { default: item.amount * item.price.current } };
  });
};

function formPayloadFromLastOrderEcommerse(goodList, productList, route) {
  const productIdArr = productList.map(item => item.id);

  const _productPriceArr = goodList.map(item => {
    return item.amount * +item.price.current;
  });

  const totalProductCost = _productPriceArr.reduce((sum, current) => sum + current);

  return { ...route, products: productIdArr, totalCost: totalProductCost };
}

function* onAddProductToFavourites({ payload }) {
  const { product } = payload;
  const { id } = product;

  try {
    yield put({
      type: ECOMMERCE.ADD_TO_FAVOURITE,
      payload: {
        id
      }
    });
    yield onClickFavourites({ payload });
  } catch (e) {}
}

function* onRemoveProductToFavourites({ payload }) {
  try {
    yield onClickFavourites({ payload });
  } catch (e) {}
}

function* onClickFavourites({ payload }) {
  const { product } = payload;
  const { id } = product;

  try {
    yield put({
      type: ECOMMERCE.CLICK_FAVOURITE,
      payload: {
        id
      }
    });
  } catch (e) {}
}

export function* watchAnalyticSources() {
  yield takeEvery(PRODUCTS.REVEAL, onRevealProducts);
  yield takeEvery(PRODUCTS.CLICK, onClickProduct);
  yield takeEvery(PRODUCTS.OVERVIEW_SHOWED, onOverviewProduct);
  yield takeEvery(PRODUCTS.SHOW_ITEM, onShowProduct);
  yield takeEvery(CATEGORIES.SHOW_ITEM, onShowCategory);
  yield takeEvery(BASKET.PRODUCT_ADD, onProductAdd);
  yield takeEvery(BASKET.PRODUCT_CHANGE_AMOUNT, onProductChangeAmount);
  yield takeEvery(BASKET.PRODUCT_REMOVE, onProductRemove);
  yield takeEvery(BASKET.SHOW, onBasketShow);
  yield takeEvery(ACCOUNT.AUTHORIZATION_READY, onAuthReady);
  yield takeEvery(ORDER.PLACE, onOrderPlace);
  yield takeEvery(ORDER.SUCCESS, onOrderSuccess);
  yield takeEvery(LOCATION_CHANGE, onLocationChange);
  yield takeEvery(CATEGORIES.ADD_PRODUCTS, onCategoryProductsLoad);
  yield takeEvery(SEARCH.RESPONSE, onCategoryProductsSearch);
  yield takeEvery(PRODUCTS.ADD_ITEM, onCategoryProductFetch);
  yield takeEvery(FAVOURITES.ADD_REQUEST, onAddProductToFavourites);
  yield takeEvery(FAVOURITES.REMOVE_REQUEST, onRemoveProductToFavourites);
  yield takeEvery(FAVOURITES.CLICK, onClickFavourites);
}
