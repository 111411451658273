import React, { Component } from 'react';
import cn from 'classnames';

import ProductContainer from '../../containers/product';
import ProductSetCardContainer from 'modules/product/containers/product-set-card';

// import Media from 'modules/core/components/media/media';
import ProductGroupVideo from './ProductGroupVideo/ProductGroupVideo';

import './product-section-list.css';

class ProductSectionList extends Component {
  render() {
    const { group, listId, isLanding, wide } = this.props;

    // const isHorizontal =
    //   !!group.promoMedia &&
    //   !!group.promoMedia.media &&
    //   !!(group.promoMedia.media.length > 0) &&
    //   this.getIsHorizontal(group.promoMedia.media[0]);

    return (
      <div
        className={cn('ProductSectionList', {
          'ProductSectionList--wide': wide,
          'ProductSectionList--isLanding': isLanding
        })}
      >
        <div className="ProductSectionList-groupContent">
          {group.sections.map(nestedGroup => {
            const iscCategoryGiftSet = nestedGroup && nestedGroup.products && nestedGroup.products[0] && nestedGroup.products[0].category.slug === "gift-sets";

            return (
              <div className="ProductSectionList-group">
                <h2 className="ProductSectionList-groupTitle">{nestedGroup.title}</h2>
                <div className={cn("ProductSectionList-groupContent", {
                  "ProductSectionList-groupContent--halfWidth": iscCategoryGiftSet
                })}>
                  {nestedGroup.products.map(item => {
                    // const iscCategoryGiftSet = item.category.slug === "gift-sets";
                    const UsedProductComponent = iscCategoryGiftSet ? ProductSetCardContainer : ProductContainer;

                    return (
                      <div className={cn("ProductSectionList-groupItem", {
                        "ProductSectionList-groupItem--halfWidth": iscCategoryGiftSet
                      })} key={item.slug}>
                        <div className="ProductSectionList-groupItemInnerWrapper">
                          <UsedProductComponent slug={item.slug} listId={listId} isLanding={isLanding} allwaysSetBackground isWideMedia />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>

        {group.promoMedia && (
          // <div className="ProductSectionList-mediaContent">
          //   <h2 className="ProductSectionList-groupTitle">{group.promoMedia.title}</h2>
          //   <div
          //     className={cn('ProductSectionList-mediaCotainer', {
          //       'ProductSectionList--mediaCotainerHorizontal': isHorizontal
          //     })}
          //   >
          //     <Media media={group.promoMedia.media[0]} nocaption darkened />
          //   </div>
          // </div>
          <ProductGroupVideo group={group} />
        )}
      </div>
    );
  }

  // getIsHorizontal = media => {
  //   if (
  //     !media.sources ||
  //     !(media.sources.length > 0) ||
  //     !media.sources[0].dimensions ||
  //     !media.sources[0].dimensions.width ||
  //     !media.sources[0].dimensions.height
  //   ) {
  //     return false;
  //   }

  //   if (media.sources[0].dimensions.width > media.sources[0].dimensions.height) {
  //     return true;
  //   }

  //   return false;
  // };
}

export default ProductSectionList;
