import React, { Component } from 'react';
import cn from 'classnames';

import numAbbr from './helpers/abbr-num';
import numberFormatter from './helpers/number-formatter';

import Button from 'modules/core/components/button/button';
import Loader from 'modules/core/components/loader/loader';

import FollowersCounter from '../../followers-counter/followers-counter';
import SocialButton from '../../button/button';
// import VKButton from '../../vk-button/vk-button';

import './instagram-social-network.css';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

const ICONS = {
  'SOCIAL/VK': 'vk',
  'SOCIAL/INSTAGRAM': 'instagram',
  'SOCIAL/FACEBOOK': 'facebook',
  'SOCIAL/YOUTUBE': 'youtube'
};
class InstagramSocialNetwork extends Component {
  state = {
    otherSocialNetworkCounters: null,
    instagramResponsedData: null
  };

  render() {
    const { otherSocialNetworkCounters, instagramResponsedData } = this.state;

    return (
      <div className="InstagramSocialNetwork">
        <h2 className="InstagramSocialNetwork-title">Присоединяйся к нашей армии подписчиков</h2>

        <div className="InstagramSocialNetwork-actionInstagram">
          <div className="InstagramSocialNetwork-instagramButton">
            {!instagramResponsedData ? (
              <div className="InstagramSocialNetwork-counterPreloader">
                <Loader type="spin" size="small" secondary />
              </div>
            ) : (
              <FollowersCounter
                button={
                  // item.name !== 'SOCIAL/VK' ? (
                  <SocialButton
                    social={ICONS[instagramResponsedData.name]}
                    href={instagramResponsedData.link}
                    component="a"
                    target="_blank"
                    size="mediumPlus"
                  />
                  // ) : (
                  //   <VKButton href="https://vk.com/mixit_ru" target="_blank" />
                  // )
                }
                amount={this.formAmountDesc(instagramResponsedData.subscribers_count)}
                lineDirection
              />
            )}
          </div>

          <div className="InstagramSocialNetwork-action">
            <Button
              title="@mixit_ru"
              href="https://instagram.com/mixit_ru"
              target="_blank"
              rel="noopener nofollow"
              component="a"
              variant="primary"
            />
          </div>
        </div>

        <div className="InstagramSocialNetwork-separator" />

        {!otherSocialNetworkCounters ? (
          <div className="InstagramSocialNetwork-counterPreloader">
            <Loader type="spin" size="small" secondary />
          </div>
        ) : (
          <ul className="InstagramSocialNetwork-counterList">
            {otherSocialNetworkCounters.map(this.renderFollowersCounter)}
          </ul>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.fetchCounters();
  }

  setCounters(otherSocialNetworkCounters, instagramResponsedData) {
    this.setState({ otherSocialNetworkCounters, instagramResponsedData });
  }

  async fetchCounters() {
    const requestQuery = 'https://mixit.ru/api/v3/social';
    try {
      const response = await fetch(requestQuery);
      const responseJSON = await response.json();
      const responsedData = responseJSON.data;
      const otherSocialNetworkResponsedData = this.filterOtherSocialCounters(responsedData);
      const instagramResponsedData = this.returnInstagramCounters(responsedData);
      this.setCounters(otherSocialNetworkResponsedData, instagramResponsedData);
    } catch (e) {
      console.warn('fetchCounters error', e);
    }
  }

  formAmountDesc(count) {
    const numberOutput = count > 10000 ? numAbbr(count, 1) : numberFormatter(count);
    return numberOutput;
  }

  renderFollowersCounter = (item, index) => {
    const count = item.subscribers_count;
    const numberOutput = this.formAmountDesc(count);

    return (
      <HideErrorBoundary>
        <li key={index} className={cn('InstagramSocialNetwork-counter')}>
          <FollowersCounter
            button={
              // item.name !== 'SOCIAL/VK' ? (
              <SocialButton
                social={ICONS[item.name]}
                href={item.link}
                component="a"
                target="_blank"
              />
              // ) : (
              //   <VKButton href="https://vk.com/mixit_ru" target="_blank" />
              // )
            }
            amount={numberOutput}
          />
        </li>
      </HideErrorBoundary>
    );
  };

  filterOtherSocialCounters(countersArr) {
    const _currentArr = [...countersArr];
    const resultArr = _currentArr.filter(item => item.title !== 'Instagram');
    return resultArr;
  }

  returnInstagramCounters(countersArr) {
    const _currentArr = [...countersArr];
    const resultArr = _currentArr.filter(item => item.title === 'Instagram');
    return resultArr[0];
  }
}

export default InstagramSocialNetwork;
