import React, { Component } from 'react';
import { number, func, bool } from 'prop-types';

import Loader from 'modules/core/components/loader/loader';

import Tabs from './tabs';

import OverviewVerticalSliderContainer from '../../containers/overview-vertical-slider';
import MediaQuery from 'react-media';

import ProductPrice from '../product-price/product-price';
import CardReviews from 'modules/core/components/card-reviews/card-reviews';
import FavoriteButton from 'modules/core/components/button-add-favorite/button-add-favorite';
import BuyForm from 'modules/ordering/components/buy-form/buy-form';
import VolumeChoice from 'modules/product/components/volume-choice/volume-choice';
import ColorChoice from 'modules/product/components/color-choice/color-choice';
import Media from 'modules/core/components/media/media';

import productShape from '../../prop-types/product-shape';
import productDetailsShape from '../../prop-types/product-details-shape';
import ReviewRating from 'modules/review/components/review-rating/review-rating';

import { getProductLink } from 'routes/links';

import './product-overview.css';

class ProductOverview extends Component {
  static propTypes = {
    product: productShape.isRequired,
    details: productDetailsShape.isRequired,
    amount: number.isRequired,

    isDetailsLoaded: bool.isRequired,
    isInBasket: bool.isRequired,

    onAmountChange: func,
    onAddToFavorite: func,
    onAddToBasket: func
  };

  render() {
    const {
      isInBasket,
      isDetailsLoaded,

      product,
      details,
      amount,

      onFavoriteToggle,
      onAddToBasket,
      onAmountChange,
      onAddToWaitingList,
      isBasketFetching
    } = this.props;

    if (!isDetailsLoaded) {
      return (
        <div className="ProductOverview">
          <div className="ProductOverview-loader">
            <Loader type="spin" />
          </div>
        </div>
      );
    }

    return (
      <MediaQuery query="(max-width:1100px)">
      {isTablet => (
      <div className="ProductOverview">
        <form className="ProductOverview-form" name="product-dialog">
          <div className="ProductOverview-grid">
            <div className="ProductOverview-media">
              {!isTablet ? (             
                 <OverviewVerticalSliderContainer mediaList={product.media} />
                ) : (
              <Media
              media={product.announcementMedia}
              // media={product.media[0]}
              stretch="horizontal"
              resizeWidth={480}
              // background="#fff"
              nocaption
          />
              ) }


            </div>
            <div className="ProductOverview-content">
              {/* <Breadcrumbs product={product} /> */}
              <h2 className="ProductOverview-title">{product.description}</h2>
              <div className="ProductOverview-priceRow">
                <div className="ProductOverview-price">
                  <ProductPrice price={product.price} />
                </div>
                <div className="ProductOverview-reviews">
                  {this.renderStarsRating()}
                  <CardReviews
                    rating={0}
                    productLink={getProductLink(product)}
                    reviews={product.rating.reviews_count}
                    onReviewClick={this.handleReviewClick}
                  />
                </div>
              </div>

              {this.renderVolumeChoice()}

              {this.renderColorChoice()}

              <Tabs
                productDetails={details}
                product={product}
                onReadMoreClick={this.handleReadMoreClick}
              />

              <div className="ProductOverview-buy">
                <BuyForm
                  isAvailable={product.isAvailable}
                  isInBasket={isInBasket}
                  isGift={product.isGift}
                  amountInBasket={product.amountInBasket}
                  amount={amount}
                  onAmountChange={onAmountChange}
                  onAddToBasket={onAddToBasket}
                  onAddToWaitingList={onAddToWaitingList}
                  isBasketFetching={isBasketFetching}
                />
              </div>
              <div className="ProductOverview-action">
                <FavoriteButton inFavorite={product.inFavorite} onClick={onFavoriteToggle} />
              </div>
            </div>
          </div>
        </form>
      </div>
                      )}
                      </MediaQuery>
    );
  }

  renderStarsRating() {
    const { product } = this.props;
    const value = product.rating.value || 0;

    return <ReviewRating rating={value} showRate />;
  }

  renderVolumeChoice() {
    const { weightList, currentWeight, onWeightChange } = this.props;

    if (!weightList || !weightList.length) return null;

    return (
      <div className="ProductOverview-choice">
        <VolumeChoice options={weightList} selected={currentWeight} onChange={onWeightChange} />
      </div>
    );
  }

  renderColorChoice() {
    const { colorList, onColorChange, currentColor } = this.props;

    if (!colorList || !colorList.length) return null;

    return (
      <div className="ProductOverview-choice">
        <ColorChoice options={colorList} selected={currentColor} onChange={onColorChange} />
      </div>
    );
  }

  handleReviewClick = () => {
    const { onClose } = this.props;

    onClose();
  };

  handleReadMoreClick = () => {
    const { onClose } = this.props;

    onClose();
  };
}

export default ProductOverview;
