import * as ProductAdapter from './product-adapter';
import * as CityAdapter from './city-adapter';
import * as OrderAdapter from 'modules/ordering/domain/order';

// import getMaskedPhone from 'modules/utils/get-masked-phone';

export function normalize(apiProfile) {
  if (!apiProfile) {
    return {};
  }

  const {
    first_name,
    last_name,
    birth_date,
    legacy_address,
    delivery_addresses,
    default_delivery_address,
    account,
    favorites,
    city,
    orders,
    phone,
    ...rest
  } = apiProfile;

  const normalizedFavorites = ProductAdapter.normalizeList(favorites);
  const normalizedCity = CityAdapter.normalize(city);
  const normalizedOrders = orders.map(OrderAdapter.normalize);
  const rawProducts = orders
    .map(order => order.basket.goods_list.map(item => item.product))
    .reduce((listA, listB) => listA.concat(listB), []);
  const ordersProducts = ProductAdapter.normalizeList(rawProducts);
  const orderIds = normalizedOrders.map(item => item.id);
  const ordersEntities = normalizedOrders.reduce(
    (all, item) => ({
      ...all,
      [item.id]: item
    }),
    {}
  );
  const normalizedPhone = normalizePhone(phone);

  const profile = {
    firstName: first_name,
    lastName: last_name,
    birthDate: birth_date,
    legacyAddress: normalizeLegacyAddress(legacy_address),
    deliveryAddresses: delivery_addresses,
    defaultDeliveryAdderss: default_delivery_address,
    favorites: normalizedFavorites.list,
    city: normalizedCity.result,
    orders: orderIds,
    phone: normalizedPhone,
    ...rest
  };

  const products = {
    ...ordersProducts.entities.products,
    ...normalizedFavorites.entities.products
  };

  return {
    profile,
    entities: {
      account,
      ...normalizedFavorites.entities,
      ...normalizedCity.entities,
      orders: ordersEntities,
      products
    }
  };
}

function normalizeLegacyAddress(legacyAddress) {
  if (!legacyAddress) {
    return null;
  }

  return {
    street: legacyAddress.street_title,
    building: legacyAddress.building,
    apartment: legacyAddress.apartment
  };
}

function normalizePhone(phone) {
  if (!phone.trim()) {
    return '';
  }

  const cleanedPhone = '+' + phone.replace(/\D/g, '');
  // const maskedPhone = getMaskedPhone(cleanedPhone);

  return cleanedPhone;
}

function serializeLegacyAddreess(legacyAddress) {
  if (!legacyAddress) {
    return null;
  }

  const { street, building, apartment } = legacyAddress;

  return {
    street_title: street,
    building: building,
    apartment
  };
}

function serializeCity(city) {
  if (!city) {
    return null;
  }

  const { id, fiasId, countryId, title } = city;

  return {
    id,
    title,
    fias_id: fiasId,
    country_id: countryId
  };
}

export function serialize(profile) {
  const { firstName, lastName, birthDate, city, legacyAddress, phone } = profile;

  const apiProfile = {
    phone,
    first_name: firstName,
    last_name: lastName,
    birth_date: birthDate,
    city: serializeCity(city),
    legacy_address: serializeLegacyAddreess(legacyAddress)
  };

  return apiProfile;
}
