import React, { Component } from 'react';
import Container from '../container/container';
// import SideNavigation from '../side-navigation/side-navigation';
import Content from '../content/content';
import Button from 'modules/core/components/button/button';
import { Link } from 'react-router-dom';
import { getProductLink } from 'routes/links';

import './special-offer-all.css';

class SpecialOfferAll extends Component {
  render() {
    return (
      <div className="SpecialOfferAll">
        <Container small>
          <section className="SpecialOfferAll-section">
            <div className="SpecialOfferAll-content">
              <Content
                html={`
                    <img
                    src="https://images.mindbox.ru/images/Mixit/2027/f3741112-2293-469a-9ac0-d125aa10a12a.png"
                    alt="Акция 'Летний Бонус'"
                  />
                  <p style="text-align: center">
                    При покупке от 2000 руб. на карту начисляется 200 экcтрабонусов, которыми можно оплатить
                    до 30% стоимости покупки с 1 по 30 сентября.
                  </p>
                  <p style="text-align: center">
                    Срок действия акции: <b> с 19 по 25 августа</b>.
                  </p>
                  <p style="text-align: center">
                    Экстрабонусы будут начислены, если сумма покупки превысит 2000 руб. после применения
                    всех скидок и промокодов. Начисление экстрабонусов будет произведено </br> <b> 1 сентября</b>.
                  </p>
                  <p style="text-align: center">
                    Все подробности акции уточняйте у продавцов-консультантов.
                  </p>
                      `}
                expanded
              />
            </div>
            <div className="SpecialOfferAll-rule">
              Для участия в акции нужно: иметь бонусную карту (полученную на сайте виртуально или в
              магазине), подтвердить email и телефон, а также заполнить обязательные поля.
            </div>
            <div className="SpecialOfferAll-button">
              <Button
                title="Получить карту"
                variant="primary"
                component="a"
                href="https://mixit.ru/top/bonus-activate/"
                size="small"
                target="_blank"
              />
            </div>
          </section>
          <section className="SpecialOfferAll-section">
            <div className="SpecialOfferAll-content">
              <Content
                html={`
                    <img
                    src="https://images.mindbox.ru/images/Mixit/2027/c7332eff-9b02-4e88-b9c6-24849e53861c.png"
                    alt="Акция -'Дальше больше'"
                  />
                  <p style="text-align: center">
                     Только в розничной сети магазинов MIXIT действует акция: </br> <b>«Дальше - больше!»</b>
                  </p>
                  <p>
                      Каждый покупатель, совершивший покупку:
                  </p> 
                  <p>
                    - от 2000 руб., получает Amaranth Shower Gel в подарок!</br>
                    - от 3000 руб., получает Amaranth Shower Gel + Amaranth Shampoo в подарок!</br>
                    - от 4000 руб., получает Amaranth Shower Gel + Amaranth Shampoo + Amaranth Hair Mask в подарок! 
                  </p>
                  <p>
                    Срок действия акции: <b>с 19 по 25 августа.</b> 
                  </p>
                  <p>
                     Все подробности про акцию узнавайте у продавцов-консультантов. Акция действует только при наличии подарков в магазине. Подарок не распространяется на товар со скидкой и на покупку подарочных сертификатов. Количество подарков ограничено!
                  </p>
                      `}
                expanded
              />
              <div className="SpecialOfferAll-gift">
                <img
                  src="https://images.mindbox.ru/images/Mixit/2027/b1380e3e-e686-4a27-878a-0e1a17ee079c.png"
                  alt="Твой подарок"
                  className="SpecialOfferAll-giftImage"
                />
                <div className="SpecialOfferAll-giftLinkList">
                  <Link
                    to={getProductLink({ slug: 'amaranth-shampoo-rich-royal-oils' })}
                    className="SpecialOfferAll-giftLink"
                    title="Посмотреть - Востанавливающий шампунь для всех типов волос"
                  >
                    Востанавливающий шампунь для всех типов волос
                  </Link>
                  <Link
                    to={getProductLink({ slug: 'amaranth-hair-mask' })}
                    className="SpecialOfferAll-giftLink"
                    title="Посмотреть - Востанавливающая маска для всех типов волос"
                  >
                    Востанавливающая маска для всех типов волос
                  </Link>
                  <Link
                    to={getProductLink({ slug: 'intensivno-pitayushchiy-gel-dlya-dusha' })}
                    className="SpecialOfferAll-giftLink"
                    title="Посмотреть - Интенсивно питающий гель для душа"
                  >
                    Интенсивно питающий гель для душа
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="SpecialOfferAll-section">
            <div className="SpecialOfferAll-content">
              <Link
                to={getProductLink({ slug: 'brush' })}
                className="SpecialOfferAll-giftLink"
                // target="_blank"
                title="Дарим щетку"
              >
                <img
                  src="https://images.mindbox.ru/images/Mixit/2027/57f7fc24-0218-4042-9f88-12d56389c7aa.png"
                  alt="Дарим щетку"
                  className="SpecialOfferAll-giftImage"
                />
              </Link>
              <Content
                html={`

                  <p style="text-align: center">
                    Только в розничной сети магазинов MIXIT действует акция: 
                  </p>
                  <p style="text-align: center">
                    <b>«ДАРИМ ЩЁТКУ ДЛЯ СУХОГО МАССАЖА» при покупке от 3000 руб.</b>.
                  </p>
                  <p style="text-align: center">
                    Срок действия акции: <b>с 19 по 25 августа. </b>.
                  </p>
                  <p style="text-align: center">
                    Все подробности про акцию узнавайте у продавцов-консультантов. Акция действует только при наличии щётки в магазине. Подарок не распространяется на товар со скидкой и на покупку подарочных сертификатов. Количество щёток ограничено!
                  </p>
                      `}
                expanded
              />
            </div>
          </section>
        </Container>
      </div>
    );
  }
}

export default SpecialOfferAll;
