import { Component } from 'react';

class TransactionOrderDetailsProduct extends Component {
  render() {
    const { title, categoryTitle, amount } = this.props;

    return `${title} - ${categoryTitle} - ${amount} шт.`;
  }
}

export default TransactionOrderDetailsProduct;
