import React from 'react';
import Container from 'modules/core/components/container/container';
import InstagramReviewSlider from 'modules/social/components/instagram-review-slider/instagram-review-slider';

import "./slider.css";

export default function ProblemSkin_Slider({data}) {
    return (
        <div className="ProblemSkin_Slider" style={{...data.style}}>
            <Container>
                <div className="ProblemSkin_Slider-title">{data.title}</div>
            </Container>
            <div className="ProblemSkin_Slider-carousel">
            <InstagramReviewSlider
                inLanding
                instagramPosts={data.images}
                renderItem={({...rest}) => {
                  return <Item {...rest}/>
                }}
              />
            </div>
        </div>
    )
} 

function Item({post}) {

    return (
        <div className="ProblemSkin_Slider-item">
            <img src={post.sources[0].main.normal} alt="" className="ProblemSkin_Slider-image"/>
        </div>
    )
}
