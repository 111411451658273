import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Bonuses from '../actions/bonuses';
import * as fromBonuses from '../ducks/bonuses';

import BonusScene from './../components/bonus-scene/bonus-scene';

const TRANSACTIONS_AMOUNT_LIMIT = 5;

function prepareTransactions(transactions, limit) {
  const filteredTransactions = transactions.filter(transaction => transaction.incomeSummary);

  const slicedTransactions = filteredTransactions.slice(0, limit);

  return slicedTransactions;
}

const mapStateToProps = createStructuredSelector({
  accountNumber: fromBonuses.getAccountNumber,
  defaultBonuses: fromBonuses.getDefaultBonuses,
  extraBonuses: fromBonuses.getExtraBonuses,
  transactions: fromBonuses.getTransactions,
  isBonusesLoaded: fromBonuses.getIsAllLoaded,
  isBonusesExist: fromBonuses.getIsBonusesExist,
  isActivated: fromBonuses.getIsActivated
});

const mapDispatchToProps = {
  loadBonuses: Bonuses.requestAll
};

class BonusSceneContainer extends Component {
  render() {
    const {
      accountNumber,
      defaultBonuses,
      extraBonuses,
      transactions,
      isBonusesLoaded,
      isBonusesExist,
      isActivated
    } = this.props;

    const preparedTransactions = prepareTransactions(transactions, TRANSACTIONS_AMOUNT_LIMIT);

    return (
      <BonusScene
        accountNumber={accountNumber}
        defaultBonuses={defaultBonuses}
        extraBonuses={extraBonuses}
        transactions={preparedTransactions}
        isBonusesLoaded={isBonusesLoaded}
        isBonusesExist={isBonusesExist}
        isActivated={isActivated}
      />
    );
  }

  componentDidMount() {
    const { isBonusesLoaded, loadBonuses } = this.props;

    if (!isBonusesLoaded) {
      loadBonuses();
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BonusSceneContainer);
