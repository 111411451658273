import React from 'react';
import Accordion from 'modules/core/components/accordion/accordion';
import AccordionItem from 'modules/core/components/accordion/accordion-item/accordion-item';
import Content from 'modules/core/components/content/content';

import './faq-list.css';

export default function FAQList({ list }) {
  return (
    <div className="FAQList">
      <ul className="FAQList-categoryHolder" itemscope="" itemtype="https://schema.org/FAQPage">
        {list.map(category => (
          <li key={category.slug} className="FAQList-categoryItem">
            <CategoryItem category={category} />
          </li>
        ))}
      </ul>
    </div>
  );
}

function CategoryItem({ category }) {
  return (
    <div className="FAQList-category">
      <div id={category.slug} className="FAQList-categoryTitle">
        {category.categoryTitle}
      </div>
      {/* <div style={{ display: 'none' }}>
        {category.list.map((question) => (
          <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
            <h3 itemprop="name">{question.subcategoryTitle}</h3>

            <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
              <div itemprop="text" dangerouslySetInnerHTML={{
                __html: question.subcategoryDescription
              }} />
            </div>
          </div>
        ))}
      </div> */}
      <div className="FAQList-categoryQuestions">
        <Accordion clickableHeadings animatedCollapsing fade>
          {category.list.map((question, index) => (
            // <div key={index} className="FAQList-question">
            <AccordionItem
              title={
                <div key={index} className="FAQList-questionTitle">
                  {question.subcategoryTitle}
                </div>
              }
              panel={
                <div key={index} className="FAQList-questionDescription">
                  <Content html={question.subcategoryDescription} expanded />

                </div>
              }
            />
            // </div>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
