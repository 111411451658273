import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { IconLike, IconDislike } from 'modules/core/components/icons';
import isFunction from 'modules/utils/is-function';

import './rate-button.css';

const { number, bool, func, oneOf } = PropTypes;

const VARIANT = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE'
};

class RateButton extends Component {
  static propTypes = {
    count: number,
    disabled: bool,
    onClick: func.isRequired,
    variant: oneOf(['LIKE', 'DISLIKE'])
  };

  static defaultProps = {
    count: 0,
    disabled: false
  };

  render() {
    const { count, onClick, variant, active, ...rest } = this.props;

    delete rest.active;

    return (
      <button
        className={cn('RateButton', {
          'RateButton--like': variant === VARIANT.LIKE,
          'RateButton--dislike': variant === VARIANT.DISLIKE,
          'RateButton--active': active
        })}
        onClick={this.handleClick}
        type="button"
        {...rest}
      >
        {this.renderIcon()}
        <span className="RateButton-text" data-testid="rate-count">
          {count}
        </span>
      </button>
    );
  }

  renderIcon() {
    const { active, variant } = this.props;

    const ICON_VARIANT = {
      LIKE: IconLike,
      DISLIKE: IconDislike
    };

    const Icon = ICON_VARIANT[VARIANT[variant]];

    return (
      <span className="RateButton-icon">
        <Icon filled={active} />
      </span>
    );
  }

  handleClick = () => {
    const { onClick, active } = this.props;

    if (active) {
      return;
    }

    if (!isFunction(onClick)) {
      return;
    }
    onClick();
  };
}

export default RateButton;
