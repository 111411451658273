import React, { Component } from 'react';
import './button-delete.css';

import IconContainer from '../icon-container/icon-container';
import { IconCross } from '../icons/index';

class ButtonDelete extends Component {
  render() {
    const { active, size, ...rest } = this.props;
    return (
      <button {...rest} type="button" className="ButtonDelete">
        <span className="ButtonDelete-iconWrapper">
          <IconContainer size={size}>
            <IconCross />
          </IconContainer>
        </span>
      </button>
    );
  }
}

export default ButtonDelete;
