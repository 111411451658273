import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tile from 'modules/core/components/tile/tile';

import './user-welcome.css';

const { shape, number, string } = PropTypes;

class UserWelcome extends Component {
  static propTypes = {
    /** Данные о пользователе */
    profile: shape({
      /** Полное имя */
      fullName: string,

      /** Фото пользователя */
      photo: string,

      /** Заполненность профиля */
      completeness: number
    }).isRequired,

    /** Данные о бонусном счёте */
    bonusAccount: shape({
      /** Остаток бонусов на счету */
      remained: number,
      available: number
    }).isRequired
  };

  render() {
    const { profile } = this.props;

    return (
      <div className="UserWelcome">
        <div className="UserWelcome-greeting">
          {profile.firstName}, добро пожаловать в личный кабинет!
        </div>

        {this.renderBonusesPanel()}
      </div>
    );
  }

  renderBonusesPanel() {
    const { bonusAccount } = this.props;

    if (!bonusAccount) {
      return;
    }

    return (
      <div className="UserWelcome-bonusesPanel">
        <Tile>
          <div className="UserWelcome-cardsHolder">
            <div className="UserWelcome-card UserWelcome-card--bonuses-remained">
              <div className="UserWelcome-cardTitle">Мои бонусы</div>
              <div className="UserWelcome-bonusesAmount">{bonusAccount.remained}</div>
            </div>
            <div className="UserWelcome-card UserWelcome-card--bonuses-available">
              <div className="UserWelcome-cardTitle">Доступно бонусов</div>
              <div className="UserWelcome-bonusesAmount">{bonusAccount.available}</div>
            </div>
          </div>
        </Tile>
      </div>
    );
  }
}

export default UserWelcome;
