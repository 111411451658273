export default function numberFormatter(price, delimeter = ' ') {
  let priceNumber = parseInt(price, 10);

  const isNegative = priceNumber < 0;

  let absPriceNumber = Math.abs(priceNumber);

  const priceArray = [];

  while (absPriceNumber > 1000) {
    let triplet = absPriceNumber % 1000;
    if (triplet < 100) {
      triplet = '0' + triplet;
      if (triplet < 10) {
        triplet = '0' + triplet;
      }
    }
    priceArray.unshift(triplet);
    absPriceNumber = Math.trunc(absPriceNumber / 1000);
  }

  priceArray.unshift(absPriceNumber);

  if (isNegative) {
    priceArray[0] *= -1;
  }

  return priceArray.join(delimeter);
}
