import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromDepricateSpecialOffers from '../ducks/deprecated-special-offers';
import SpecialOfferList from '../components/special-offer-list/special-offer-list';
import Loader from '../components/loader/loader';

const mapStateToProps = createStructuredSelector({
  orderList: fromDepricateSpecialOffers.getList,
  isLoaded: fromDepricateSpecialOffers.getIsListLoaded
});

class SpecialOfferTabsContainer extends Component {
  render() {
    const { orderList, isLoaded } = this.props;

    const orderIdList = this.getIdList(orderList);

    return isLoaded ? (
      <SpecialOfferList slugList={orderIdList} />
    ) : (
      <div style={{ position: 'relative', padding: '120px' }}>
        <Loader />
      </div>
    );
  }

  getIdList(orderList) {
    if (!orderList) {
      return null;
    }

    const _orderList = [...orderList];

    return _orderList.map(item => item.id);
  }
}

export default connect(mapStateToProps)(SpecialOfferTabsContainer);
