import React, { Component, Fragment } from 'react';
import contains from 'dom-helpers/query/contains';
import cn from 'classnames';
import ScrollTrigger from 'react-scroll-trigger';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';

import FooterContainer from '../../containers/footer-container';
import Preheader from '../preheader/preheader';
import MainHeader from '../main-header/main-header';
import MainHeaderMobileContainer from '../../containers/main-header-mobile';
import CatalogNavigation from '../catalog-navigation/catalog-navigation';
import NavigationDrawerContainer from '../../containers/navigation-drawer-container';

import AuthDialogContainer from '../../containers/auth-dialog';
import RegistrationDialogContainer from '../../containers/registration-dialog';

import PasswordRecoveryRequestDialogContainer from '../../containers/password-recovery-request-dialog';
import PasswordRecoveryRequestSuccessDialogContainer from '../../containers/password-recovery-request-success-dialog';

import ConfirmPasswordRecoveryDialogContainer from '../../containers/confirm-password-recovery-dialog';
import ConfirmPasswordRecoverySuccessDialogContainer from '../../containers/confirm-password-recovery-success-dialog';
import ConfirmPasswordRecoveryErrorDialogContainer from '../../containers/confirm-password-recovery-error-dialog';

import AcquaintanceDialogContainer from '../../containers/acquaintance-dialog';
import CityDialogContainer from 'modules/geography/containers/city-dialog';

import CallbackDialogContainer from 'modules/core/containers/callback-dialog';
import CallbackConformationDialogContainer from 'modules/core/containers/callback-conformation-dialog';
import PhysicalTraitsConfirmDialogContainer from 'modules/core/containers/physical-traits-conformation-dialog';

import EditProfileNameDialogContainer from 'modules/core/components/edit-user-name/edit-user-name-modal/edit-user-name-modal-container';

import SubscribeConformationDialogContainer from 'modules/core/containers/subscribe-conformation-dialog';
import IngredientDialogContainer from 'modules/ingredient/containers/ingredient-dialog-container';
import MobileCatalogNavigationContainer from 'modules/core/containers/mobile-catalog-navigation-container';
import UTMDialog from 'modules/core/containers/utm-dialog';
import AddToWaitListConfirmationDialogContainer from 'modules/core/containers/add-to-wait-list-confirmation-dialog';
import BonusesConfirmationCodeDialogContainer from 'modules/ordering/containers/BonusesConformationDialog';
import FeedbackConfirmDialogContainer from 'modules/core/containers/feedback-confirm-dialog';
import HideErrorBoundary from '../hide-error-boundary/hide-error-boundary';

// import SubscribeSuggestionContainer from '../../containers/subscribe-suggestion';
import DrawRollUp from '../../containers/draw-roll-up';

import UTMBanner from 'modules/core/containers/utm-banner-container';
import FAQQuestionDialogContainer from 'modules/core/containers/faq-question-dialog';
import SearchOverlayContainer from '../../containers/search-overlay';
import CookieToastContainer from '../../containers/cookie-toast';
import PasswordChangeToastContainer from '../../containers/password-change-toast';
import ContentScene from '../content-scene/content-scene';
import NoSsr from '../no-ssr/no-ssr';

import LandingBannerContainer from '../../containers/landing-banner-container';

import EmailConfirmDialogContainer from '../../containers/email-confirm-dialog';
// import HappyMasterNotification from '../happy-master-notification/happy-master-notification';
// import BasketWrongNotificationContainer from 'modules/ordering/containers/basket-wrong-notification';

// import CastomersDayMoscovNotification from '../../containers/castomers-day-moscov-notification-container';
// import CastomersDaySPNotification from '../../containers/castomers-day-sp-notification-container';

import './app.css';

const KEY_TAB = 9;
// const SHOW_HEADER_TRESHOLD_PX = 20;
const HEADER_ALWAYS_SHOW_TOP_PX = 200;

class App extends Component {
  state = {
    navigationOpened: false,
    isSearchOverlayOpened: false,
    inFooter: false
  };

  _drawerElement = null;
  _triggerElement = null;

  render() {
    const {
      navigationOpened,
      isSearchOverlayOpened,
      hideHeader,
      raisedHeader,
      inFooter
    } = this.state;

    const isBasketPage = __BROWSER__ && window.location.pathname.indexOf('/basket') >= 0;
    const isCheckoutPage = __BROWSER__ && window.location.pathname.indexOf('/ordering/') >= 0;

    const isRedirectPaymentPage =
      __BROWSER__ && window.location.pathname.indexOf('redirect-payment-page') >= 0;

    const isOrderingPage = isBasketPage || isCheckoutPage;
    const { isTablet, isMobile } = this.props;

    const isNotDesktop = isMobile || isTablet;

    const showMobileNavigation = isNotDesktop && navigationOpened;
    const showSearchOverlay = isNotDesktop && isSearchOverlayOpened;

    const noMobileSticky = Boolean(isCheckoutPage);
    const isHeaderHidden = hideHeader && !isOrderingPage;

    const { pathname } = this.props.location;

    const urlModificator = pathname.split('/')[1];

    return (
      <div
        className={cn('App', {
          'App--navigationOpened': showMobileNavigation,
          'App--searchOverlayOpened': showSearchOverlay,
          'App--hideHeader': isHeaderHidden,
          'App--raisedHeader': raisedHeader,
          'App--noMobileSticky': noMobileSticky,
          [`App--url-${urlModificator}`]: Boolean(urlModificator),
          'App--url-index': !Boolean(urlModificator)
        })}
      >
        {isNotDesktop && (
          <div className="App-navigation">
            <NavigationDrawerContainer
              drawerRef={this.setDrawerRef}
              isOpened={navigationOpened}
              onNavigationItemOpen={this.handleNavigationItemOpen}
              onUserOverviewClick={this.handleUserOverviewClick}
              closeDrawer={this.handleDrawerClose}
              onDialogOpened={() => this.closeDrawer()}
              onCatalogLinkClick={this.handleCatalogLinkClick}
            />
          </div>
        )}

        {showSearchOverlay && (
          <div className="App-searchOverlay">
            <SearchOverlayContainer onClose={this.handleSearchOverlayCancel} />
          </div>
        )}

        <div className="App-wrapper">
          {!isRedirectPaymentPage && (
            <header className="App-header">
              {/* {isOrderingPage && <BasketWrongNotificationContainer />} */}
              <LandingBannerContainer />
              {/* <CastomersDayMoscovNotification />
            <CastomersDaySPNotification /> */}
              {isMobile || isTablet ? (
                <Fragment>
                  <MainHeaderMobileContainer
                    navigationOpened={navigationOpened}
                    triggerRef={this.setTriggerRef}
                    onHamburgerClick={this.handleHamburgerClick}
                    onSearchClick={this.handleSearchClick}
                  />
                  <MobileCatalogNavigationContainer />
                </Fragment>
              ) : (
                <Fragment>
                  <Preheader />
                  <MainHeader />
                  <CatalogNavigation />
                </Fragment>
              )}
            </header>
          )}

          <section className="App-body">
            <ContentScene inFooter={inFooter} />
          </section>

          {!isRedirectPaymentPage && (
            <ScrollTrigger onEnter={this.handleFooterEnter} onExit={this.handleFooterExit}>
              <footer className="App-footer">
                <FooterContainer />
              </footer>
            </ScrollTrigger>
          )}

          {/* DIALOG SECTION */}
          <NoSsr>
            <Helmet>
              <script src="//code-ya.jivosite.com/widget/Uk3wSnLQ5R" async></script>
            </Helmet>
            <AuthDialogContainer />
            <RegistrationDialogContainer />

            <PasswordRecoveryRequestDialogContainer />
            <PasswordRecoveryRequestSuccessDialogContainer />

            <ConfirmPasswordRecoveryDialogContainer />
            <ConfirmPasswordRecoverySuccessDialogContainer />
            <ConfirmPasswordRecoveryErrorDialogContainer />

            <AcquaintanceDialogContainer />
            <CityDialogContainer />
            <CallbackDialogContainer isCallbackDialog/>
            <CallbackConformationDialogContainer />
            <SubscribeConformationDialogContainer />
            <UTMDialog />
            <AddToWaitListConfirmationDialogContainer />

            <BonusesConfirmationCodeDialogContainer />

            <FeedbackConfirmDialogContainer />
            <IngredientDialogContainer />

            <PhysicalTraitsConfirmDialogContainer />
            <FAQQuestionDialogContainer />

            {/* <SubscribeSuggestionContainer /> */}
            <DrawRollUp />

            {/* <HappyMasterNotification /> */}

            <CookieToastContainer />
            <PasswordChangeToastContainer />
            <EditProfileNameDialogContainer />

            <HideErrorBoundary>
              <UTMBanner />
            </HideErrorBoundary>

            <EmailConfirmDialogContainer />
          </NoSsr>
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.handleWindowTouchStart);
    window.addEventListener('touchend', this.handleWindowTouchEnd, true);
    window.addEventListener('keydown', this.handleKeydown, true);
    window.addEventListener('mousedown', this.handleMousedown, true);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.handleWindowTouchStart);
    window.removeEventListener('touchend', this.handleWindowTouchEnd);
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('mousedown', this.handleMousedown);
    window.removeEventListener('scroll', this.handleScroll);
  }

  setUsingKeyboard(keyCode) {
    if (keyCode === KEY_TAB) {
      document.body.classList.add('isTabUsing');
    }
  }

  resetUsingKeyboard() {
    document.body.classList.remove('isTabUsing');
  }

  hideHeader() {
    if (this.state.hideHeader) {
      return;
    }
    this.setState({
      hideHeader: true
    });
  }

  showHeader() {
    if (!this.state.hideHeader) {
      return;
    }
    this.setState({
      hideHeader: false
    });
  }

  raiseHeader() {
    if (this.state.raisedHeader) {
      return;
    }
    this.setState({
      raisedHeader: true
    });
  }

  dropHeader() {
    if (!this.state.raisedHeader) {
      return;
    }
    this.setState({
      raisedHeader: false
    });
  }

  openDrawer() {
    this.setState({
      navigationOpened: true
    });
  }

  closeDrawer() {
    this.setState({
      navigationOpened: false
    });
  }

  toggleMenu = () => {
    const isNavigationOpened = this.state.navigationOpened;

    if (isNavigationOpened) {
      this.closeDrawer();
    } else {
      this.openDrawer();
    }
  };

  openSearchMobile() {
    this.setState({
      isSearchOverlayOpened: true
    });
  }

  closeSearchMobile() {
    this.setState({
      isSearchOverlayOpened: false
    });
  }

  getNavigationDrawerScrollTopPosition() {
    return this._drawerElement.scrollTop;
  }

  scrollMenuItemToTop(triggerElementTopPosition) {
    const drawerScrollTop = this.getNavigationDrawerScrollTopPosition();
    const topPosition = drawerScrollTop + triggerElementTopPosition;

    setTimeout(() => {
      this._drawerElement.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      });
    });
  }

  handleSearchOverlayCancel = () => {
    this.closeSearchMobile();
  };

  handleSearchOverlaySubmit = () => {
    this.closeSearchMobile();
  };

  handleSearchClick = () => {
    this.openSearchMobile();
  };

  handleHamburgerClick = e => {
    e.stopPropagation();

    this.toggleMenu();
  };

  handleSearchOverlayClick = () => {
    this.closeSearchMobile();
  };

  handleSearchOverlayClose = () => {
    this.closeSearchMobile();
  };

  handleCatalogLinkClick = () => {
    this.closeDrawer();
  };

  handleUserOverviewClick = () => {
    this.closeDrawer();
  };

  handleNavigationItemOpen = triggerElementTopPosition => {
    this.scrollMenuItemToTop(triggerElementTopPosition);
  };

  handleWindowTouchStart = e => {
    const { touches, changedTouches } = e;

    if (touches.length > 1) {
      return;
    }

    const startTouch = changedTouches[0];
    this.startTouchX = startTouch.pageX;
    this.startTouchY = startTouch.pageY;
  };

  handleWindowTouchEnd = e => {
    const { navigationOpened } = this.state;
    const { touches, changedTouches, target } = e;
    const moveOffset = 5;

    if (touches.length > 1) {
      return;
    }

    const finishTouch = changedTouches[0];
    const finishTouchX = finishTouch.pageX;
    const finishTouchY = finishTouch.pageY;

    if (this.startTouchX < finishTouchX) {
      return;
    }

    if (Math.abs(this.startTouchX - finishTouchX) < Math.abs(this.startTouchY - finishTouchY)) {
      return;
    }

    const isClickOnDrawer =
      this._drawerElement &&
      (contains(this._drawerElement, target) || e.target === this._drawerElement) &&
      Math.abs(this.startTouchX - finishTouchX) <= moveOffset;

    if (navigationOpened && !isClickOnDrawer) {
      e.preventDefault();
    } else {
      return;
    }

    this.closeDrawer();
  };

  handleKeydown = ({ keyCode }) => {
    this.setUsingKeyboard(keyCode);
  };

  handleMousedown = () => {
    this.resetUsingKeyboard();
  };

  handleScroll = () => {
    if (window.scrollY > 0) {
      this.raiseHeader();
    } else {
      this.dropHeader();
    }

    if (window.scrollY < HEADER_ALWAYS_SHOW_TOP_PX) {
      this.showHeader();
    } else {
      if (window.scrollY > this._previousScroll) {
        this.hideHeader();
      } else {
        this.showHeader();
      }
    }

    this._previousScroll = window.scrollY;
  };

  setDrawerRef = element => {
    if (!element) {
      return;
    }

    this._drawerElement = element;
  };

  setTriggerRef = element => {
    if (!element) {
      return;
    }

    this._triggerElement = element;
  };

  handleFooterEnter = () => {
    this.setState({
      inFooter: true
    });
  };

  handleFooterExit = () => {
    this.setState({
      inFooter: false
    });
  };
}

export default withRouter(App);
