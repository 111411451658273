import React, {useEffect, useState, useRef } from 'react';
import cn from "classnames";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MediaQuery from 'react-media';

import Container from 'modules/core/components/container/container';

import Media from 'modules/core/components/media/media';
import Link from 'modules/core/components/link/short-link';
import Price from 'modules/ordering/components/price-formatted/price-formatted';

import Button from 'modules/core/components/button/button';
// import ButtonAddToBasket from 'modules/product/components/product/button-add-to-basket.jsx';
import AddToBasketWithToastConsumer from 'modules/product/components/product/AddToBasketWithToastConsumer';
import * as fromBasket from 'modules/ordering/ducks/basket';

import LandingActions from "modules/landing/ducks/actions"
import * as fromProducts from 'modules/product/ducks/products';
import ProductDialog from 'modules/product/components/product-dialog/product-dialog';

import "./products-header.css";
import "./products-list.css";
import "./products-item.css";
import "./products-set.css";

const mapStateToProps = createStructuredSelector({
    product: fromProducts.getItemBySlug,
    productDetails: fromProducts.getDetailsBySlug,
});

const mapDispatchToProps = {
    requestProduct: LandingActions.requestLendingProducts,
    addProductToBasket: fromBasket.actions.addProduct
  };

  function renderPrice(product) {
    return (
      <>
        {product.hasDiscount && (
          <span className="ProblemSkin_Products-Item_defaultPrice">
            <Price number={product.price.default} variant="previous" />
          </span>
        )}
        <span className="ProblemSkin_Products-Item_currentPrice">
          <Price number={product.price.current} />
        </span>
      </>
    );
  }

  function RenderList({slug, data, product, productDetails, requestProduct, addProductToBasket, listRef}) {
    
    // const productOptions = data.products.reduce((obj, item) => {
    //     obj[item["slug"]] = item
    //     return obj
    // }, {});

    useEffect(() => {
        requestProduct(slug)
        return;
    }, [requestProduct, slug]);
    
    const canListBeRendered = Boolean(product) && Boolean(productDetails);
    
    if(!canListBeRendered) {
        return "Loading..."  //TODO implement loader
    }
    
    // const products = productDetails.included.map(slug=>productOptions[slug])
    
    return (
        <MediaQuery query="(max-width:1200px)">
        {isTablet => (
        <>        
            <ul ref={listRef} className="ProblemSkin_Products-list">
                {data.products.map((product, index) => (
                    <li key={index} className="ProblemSkin_Products-item">
                        <ConnectedItem slug={product.slug} options={product} mirrored={index % 2} />
                    </li>
                ))}
            </ul>
            <div className="ProblemSkin_Products-setProduct">
                <Container>
                    <div className="ProblemSkin_Products-setProduct-container">
                        <div className="ProblemSkin_Products-setProduct_info">
                            <div className="ProblemSkin_Products-setProduct_title">Набор <br/> AHA! Revolution</div>
                            <div className="ProblemSkin_Products-setProduct_description">Возьми весь набор со скидкой!</div>
                            <div className="ProblemSkin_Products-setProduct_price">
                                {renderPrice(product)}
                            </div>
                            <div className="ProblemSkin_Products-setProduct_addToBasket">
                                {/* <ButtonAddToBasket expanded={false} size="medium" variant="black" title="Добавить в корзину"  onClick={()=>{}} /> */}
                                <AddToBasketWithToastConsumer  
                                    product={product}
                                    expanded={false} 
                                    size={isTablet ?   "small" : "medium"} 
                                    variant="black" 
                                    title={product.isInBasket ? "Добавить еще" :"Добавить в корзину"} 
                                    addProductToBasket={addProductToBasket} 
                                    />
                            </div>
                        </div>
                        <div className="ProblemSkin_Products-setProduct-visual">
                            <div className="ProblemSkin_Products-setProduct-imageHolder">
                                <Media
                                    media={product.announcementMedia}
                                    // media={product.media[0]}
                                    stretch="horizontal"
                                    resizeWidth={480}
                                    // background="#fff"
                                    nocaption
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

        </>
                )}
                </MediaQuery>
    )
    
}

const ConnectedRenderList = connect(mapStateToProps, mapDispatchToProps)(RenderList)

const mapStateToPropsItem = createStructuredSelector({
    product: fromProducts.getItemBySlug,
    // productDetails: fromProducts.getDetailsBySlug,

  });
  
  const mapDispatchToPropsItem = {
    // requestProduct: LandingActions.requestLendingProducts
    addProductToBasket: fromBasket.actions.addProduct
  };

  function ProductItem({slug, options, product, mirrored, addProductToBasket}) {
    const [isQuickDialogOpened, setQuickDialogOpened] = useState(false)
     return (
        <MediaQuery query="(max-width:1200px)">
         {isTablet => (
         <>
            <div className={cn("ProblemSkin_Products-Item", {
                "ProblemSkin_Products-Item--mirrored": mirrored,
                "ProblemSkin_Products-Item--hasDiscount": product.hasDiscount
            })}>
                <div className="ProblemSkin_Products-Item_imageHolder">
                    <div className="ProblemSkin_Products-Item_image">
                        <Media
                            className="Product-image"
                            media={product.announcementMedia}
                            stretch="horizontal"
                            resizeWidth={480}
                            background="#fff"
                            nocaption
                            // lazy
                            // onLoad={this.handleImageLoad}
                        />
                    </div>
                </div>
                <div className="ProblemSkin_Products-Item_info">
                    <div className="ProblemSkin_Products-Item_title">
                        {/* {product.description} */}
                        {options.title}
                    </div>
                    <ul className="ProblemSkin_Products-Item_properties">
                        {options.properties.map((option, key)=>(
                            <li key={key} className="ProblemSkin_Products-Item_property">{option}</li>
                        ))}
                    </ul>
                    <div className="ProblemSkin_Products-Item_showMore">
                        <Link style={{fontWeight: "bold"}} variant="hoverPurple" type="button" component="button" onClick={handleShowMoreClick}>
                            Показать больше
                        </Link>
                    </div>
                    <div className="ProblemSkin_Products-Item_footer">
                        <div className="ProblemSkin_Products-Item_price">
                            {renderPrice(product)}
                        </div>
                        <div className="ProblemSkin_Products-Item_addToBasket">
                            <AddToBasketWithToastConsumer  
                                product={product}
                                expanded={false} 
                                size={isTablet ?   "small" : "medium"} 
                                variant="black" 
                                title={product.isInBasket ? "Добавить еще" :"Добавить в корзину"}  
                                addProductToBasket={addProductToBasket}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ProductDialog
              product={product}
              show={isQuickDialogOpened}
            //   isInRecent={isInRecent}
              onClose={handleProductDialogClose}
            />
         </>
            )}
            </MediaQuery>
     )
     function handleShowMoreClick() {
        setQuickDialogOpened(true)
     }
     function handleProductDialogClose() {
       setQuickDialogOpened(false)
     }
  }

const ConnectedItem = connect(mapStateToPropsItem, mapDispatchToPropsItem)(ProductItem)

export default function ProblemSkin_Products({data}) {
    const listRef = useRef(null)
    const setSlug = data.set_product.slug
    
    return (
        <div className="ProblemSkin_Products" style={{...data.style}}>
            {renderHeader()}
            {true && <ConnectedRenderList listRef={listRef} data={data} slug={setSlug}/>}
        </div>
    )

    function renderHeader() {

        return (
            <MediaQuery query="(max-width:1200px)">
            {isTablet => (
            <div className="ProblemSkin_Products-header">
                <div className="ProblemSkin_Products-header_leftVisual"
                    style={{backgroundImage: `url(${data.header.images[0].sources[0].main.normal})`}}
                ></div>
                <div className="ProblemSkin_Products-header_content">
                    <div className="ProblemSkin_Products-header_description">{data.header.description}</div>
                    <div className="ProblemSkin_Products-header_title">{data.header.title}</div>
                    <div className="ProblemSkin_Products-header_button">
                        <Button 
                            variant="black" 
                            size={isTablet ?   "small" : "medium"} 
                            title={data.header.button_title} 
                            onClick={scrollToList} />
                    </div>
                </div>
                <div className="ProblemSkin_Products-header_rightVisual"
                    style={{backgroundImage: `url(${data.header.images[1].sources[0].main.normal})`}}
                ></div>
            </div>
                                    )}
                                    </MediaQuery>
        )
    }

    function scrollToList() {
        // console.log("scrollToList -> listRef.current", listRef.current.offsetTop)
        // window.scrollTo({ behavior: 'smooth', top: listRef.current.offsetTop })
        // window.scrollTo(0, listRef.current.offsetTop)
        if(listRef){
            listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }
} 

