import { takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import qs from 'qs';

import { cookieUtils } from 'modules/utils';

export function* watchQueryParams() {
    // yield takeEvery(LOCATION_CHANGE, handleLocationChange);
    yield takeEvery(LOCATION_CHANGE, handleMatchQueryParams);
}

function* handleMatchQueryParams({ payload }) {
    const queryObj = yield qs.parse(payload.location.search, { ignoreQueryPrefix: true })

    if (queryObj.admitad_uid) {
        cookieUtils.setCookie('admitad_uid', queryObj.admitad_uid, 30);
    } else {
        if (Object.keys(queryObj).length > 0) {
            cookieUtils.deleteCookie('admitad_uid');
        }
        // console.log('queryObj', Object.keys(queryObj).length);
        // // cookieUtils.deleteCookie('admitad_uid');
    }
}