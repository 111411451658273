import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { getProductLink } from 'routes/links';

import AddToWaitListDialog from 'modules/core/components/add-to-wait-list-dialog/add-to-wait-list-dialog';
import ButtonFavorite from 'modules/core/components/button-favorite/button-favorite';
import Tooltip from 'modules/core/components/tooltip/tooltip';
import Link from 'modules/core/components/link/short-link';

import Price from 'modules/ordering/components/price-formatted/price-formatted';

import ProductDialog from '../product-dialog/product-dialog';
import ButtonFavoriteTooltipInner from './button-favorite-tooltip-inner';
import ActionGroup from './action-group';

import productShape from '../../prop-types/product-shape';

import './product.css';
import ButtonDelete from 'modules/core/components/button-delete/button-delete';
import brokenProduct from './broken-product.png';
import brokenProduct2x from './broken-product@2x.png';
import Media from 'modules/core/components/media/media';

import ProductFeatures from '../product-features/product-features';

import isFunction from 'modules/utils/is-function';
import ProductAnalyticsProvider from 'modules/product/containers/product-analytics';

import TemporaryLabelIcon from './temporary-label-icon';

import CosmopolitenIcon from 'modules/core/components/icons/cosmopoliten-icon/cosmopoliten-icon';
import IconCosmopolitenTwentiethYear from 'modules/core/components/icons/cosmopoliten-twentieth-year-icon/cosmopoliten-twentieth-year-icon';
import cosmopolitenProductSlugList from '../../data/cosmopoliten';
import ReviewRating from 'modules/review/components/review-rating/review-rating';

import choseFeaturesList from '../../data/chose';

const { oneOf, func } = PropTypes;

class Product extends Component {
  static propTypes = {
    /**
     * Product variant.
     *
     * By value 'default' it looks like a product in slider or product on category bage.
     * When it is hovered, a shadow appears.
     * Actions are hidden.
     *
     * By value 'upsell' it looks like a product on basket page.
     * Actions are visible.
     */
    variant: oneOf(['default', 'upsell', 'favourite']),

    /**
     * Function of adding product to favorites
     */
    toggleFavorite: func.isRequired,

    /**
     * Product data
     */
    product: productShape.isRequired
  };

  static defaultProps = {
    variant: 'default',
    isInRecent: false
  };

  state = {
    isDialogOpen: false,
    isOverlayShowed: false
  };

  render() {
    const {
      slug,
      product,
      variant,
      isInRecent,
      isAddToWaitListDialogOpened,
      isWaitingListRequestLoading,
      listId,
      onLinkClick,
      onDialogFormSubmit,
      onAddToWaitListDialogClose,
      addProductToBasket,
      createToast,
      isBasketPlacing,
      isBasketVerifying,
      isBasketFetching,
      isCosmopolitanIconExist,
      isLanding
    } = this.props;

    const { isDialogOpen } = this.state;

    if (!product) {
      return null;
    }

    return (
      <ProductAnalyticsProvider
        productId={slug}
        listId={listId}
        render={({ productRef, handleClick }) => (
          <div
            className={classNames('Product', {
              'Product--variant-upsell': variant === 'upsell',
              'Product--variant-favourite': variant === 'favourite',
              'Product--inFavorite': product.inFavorite,
              'Product--inBasket': product.inBasket,
              'Product--hasDiscount': product.hasDiscount,
              'Product--notAvailable': !product.isAvailable,
              'Product--isLanding': isLanding
            })}
            ref={productRef}
          >
            <div className="Product-visualGroup">
              <div className="Product-metaPanel">
                {isCosmopolitanIconExist && (
                  <div className="Product-cosmopolitenLabel">
                    <CosmopolitenIcon />
                  </div>
                )}
                {slug && cosmopolitenProductSlugList.includes(slug) && (
                  <div className="Product-cosmopolitenLabel--2020">
                    <IconCosmopolitenTwentiethYear />
                  </div>
                )}
                {this.renderLabelList()}
                {this.renderQuickActionList()}
              </div>
              <RouterLink
                to={getProductLink(product)}
                className="Product-imageHolder"
                onClick={handleClick(onLinkClick)}
              >
                <Media
                  className="Product-image"
                  media={product.announcementMedia}
                  stretch="horizontal"
                  resizeWidth={480}
                  background="#fff"
                  nocaption
                  lazy
                  onLoad={this.handleImageLoad}
                />
              </RouterLink>
            </div>

            <div className="Product-contentGroup">
              <div className="Product-raiting">{this.renderStarsRating()}</div>
              <div className="Product-titleHolder">
                <h3 className="Product-title">
                  <RouterLink
                    className="Product-link"
                    to={getProductLink(product)}
                    onClick={handleClick(onLinkClick)}
                  >
                    {product.title}
                  </RouterLink>
                </h3>
              </div>
              <div className="Product-descriptionHolder">
                <div className="Product-description">{product.description}</div>
              </div>
              {this.getIsAvailable() ? (
                <div className="Product-priceHolder">{this.renderPrice()}</div>
              ) : (
                  <div className="Product-availabilityHolder">
                    <span className="Product-notAvailableCaption">Нет в наличии</span>
                  </div>
                )}
            </div>

            <ActionGroup
              product={product}
              isUpsellVariant={this.getIsUpsellVariant()}
              isFavoriteVariant={this.getIsFavoriteVariant()}
              isWaitingListRequestLoading={isWaitingListRequestLoading}
              addProductToBasket={addProductToBasket}
              createToast={createToast}
              onButtonViewClick={handleClick(this.handleButtonViewClick)}
              onButtonAddToWaitingListClick={this.handleButtonAddToWaitingListClick}
              isBasketPlacing={isBasketPlacing}
              isBasketVerifying={isBasketVerifying}
              isBasketFetching={isBasketFetching}
            />

            <ProductDialog
              product={product}
              show={isDialogOpen}
              isInRecent={isInRecent}
              onClose={this.handleProductDialogClose}
            />

            <AddToWaitListDialog
              show={isAddToWaitListDialogOpened}
              onClose={onAddToWaitListDialogClose}
              onFormSubmit={onDialogFormSubmit}
            />
          </div>
        )}
      />
    );
  }

  renderStarsRating() {
    const { product } = this.props;
    const value = product.rating.value || 0;

    return <ReviewRating rating={value} />;
  }

  renderLabelList = () => {
    const { product, IsBfkFeature } = this.props;
    return (
      <div className="Product-labelList">
        <ProductFeatures size="small" isGift={product.isGift} features={this.getFeatures(product)} />
        {IsBfkFeature ? (
          <div className="Product-temporaryLabelIcon">
            <TemporaryLabelIcon size="small" />
          </div>
        ) : null}
      </div>
    );
  };

  renderQuickActionList() {
    return (
      <div className="Product-quickActionList">
        {this.getIsFavoriteVariant() ? this.renderButtonDelete() : this.renderButtonFavorite()}
      </div>
    );
  }

  renderButtonFavorite() {
    const { product, isAuthorized, onButtonFavoriteClick } = this.props;

    if (!isAuthorized) {
      return (
        <Tooltip
          content={this.getTooltipContent()}
          showDelay={300}
          hideDelay={100}
          alignment="right"
        >
          <ButtonFavoriteTooltipInner>
            <ButtonFavorite onClick={onButtonFavoriteClick} active={product.inFavorite} />
          </ButtonFavoriteTooltipInner>
        </Tooltip>
      );
    }

    return (
      <div className="Product-favoriteAction">
        <ButtonFavorite onClick={onButtonFavoriteClick} active={product.inFavorite} />
      </div>
    );
  }

  renderButtonDelete() {
    const { onRemoveFromFavorites } = this.props;
    return (
      <div className="Product-favoriteAction">
        <ButtonDelete onClick={onRemoveFromFavorites} />
      </div>
    );
  }

  renderPrice() {
    const { product } = this.props;

    return (
      <Fragment>
        {product.hasDiscount && (
          <span className="Product-previousPrice">
            <Price number={product.price.default} variant="previous" />
          </span>
        )}
        <span className="Product-currentPrice">
          <Price number={product.price.current} />
        </span>
      </Fragment>
    );
  }

  getTooltipContent() {
    const { onAuthorizationClick } = this.props;
    return (
      <span className="Product-tooltip">
        Пожалуйста,{' '}
        <Link component="button" onClick={onAuthorizationClick}>
          авторизуйтесь
        </Link>
        , чтобы добавить в избранное
      </span>
    );
  }

  getIsAvailable() {
    const { product } = this.props;
    return product.isAvailable;
  }

  getIsUpsellVariant() {
    const { variant } = this.props;
    return variant === 'upsell';
  }

  getIsFavoriteVariant() {
    const { variant } = this.props;
    return variant === 'favourite';
  }

  overlayShow() {
    this.setState({
      isOverlayShowed: true
    });
  }

  overlayHide() {
    this.setState({
      isOverlayShowed: false
    });
  }

  showFullImage() {
    this.setState({ isFullImageLoad: true });
  }

  handleButtonViewClick = () => {
    this.setState({
      isDialogOpen: true
    });

    if (isFunction(this.props.onQuickViewClick)) {
      this.props.onQuickViewClick();
    }
  };

  handleProductDialogClose = () => {
    this.setState({
      isDialogOpen: false
    });
  };

  handleMouseOver = () => {
    this.overlayShow();
  };

  handleMouseOut = () => {
    this.overlayHide();
  };

  handleImgLoadError = event => {
    const { target } = event;

    target.src = brokenProduct;
    target.srcSet = `${brokenProduct2x} 2x`;
  };

  handleButtonAddToWaitingListClick = () => {
    const { onButtonAddToWaitingListClick } = this.props;

    onButtonAddToWaitingListClick();
  };

  handleImageLoad = () => {
    this.showFullImage();
  };

  getFeatures = product => {
    if (choseFeaturesList.includes(product.slug)) {
      return [...product.features, {
        description: `Данный товар с пометкой "Выбор покупателя"`,
        title: "Выбор 2020",
        type: "FEATURE/CONSUMERS_CHOICE"
      }]
    }

    return product.features;
  }
}

export default Product;
