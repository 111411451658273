import { createAction, createActionError } from 'modules/utils/dux';
import * as PROFILE from '../types/profile';

export default {
  request() {
    return createAction(PROFILE.REQUEST);
  },

  response(error, profile, entities) {
    if (error) {
      return createActionError(PROFILE.RESPONSE, { message: error });
    }

    return createAction(PROFILE.RESPONSE, { profile, entities });
  },

  filterFavorites(option) {
    return createAction(PROFILE.FAVORITES_FILTER, { option });
  },

  changeFullName(fullName) {
    const { firstName, lastName } = getSeparateFullName(fullName);
    return createAction(PROFILE.FULL_NAME_CHANGE, {
      firstName,
      lastName
    });
  },

  changePhoto(photo) {
    return createAction(PROFILE.PHOTO_CHANGE, { photo });
  },

  changePhotoResponse(photo) {
    return createAction(PROFILE.PHOTO_CHANGE_RESPONSE, { photo });
  },

  phoneChange(phone) {
    return createAction(PROFILE.PHONE_CHANGE, { phone });
  },

  addressChange(address) {
    return createAction(PROFILE.ADDRESS_CHANGE, { address });
  },

  birthDateChange(birthDate) {
    return createAction(PROFILE.BIRTH_DATE_CHANGE, { birthDate });
  },

  cityChange(cityId) {
    return createAction(PROFILE.CITY_CHANGE, { cityId });
  },

  change({ fullName, phone, address, birthDate }) {
    const { firstName, lastName } = getSeparateFullName(fullName);
    return createAction(PROFILE.CHANGE, {
      firstName,
      lastName,
      phone,
      address,
      birthDate
    });
  },

  // physicalTraits actions
  physicalTraitsShow() {
    return createAction(PROFILE.PHYSICAL_TRAITS_SHOW);
  },
  physicalTraitsInitialResponse(list) {
    return createAction(PROFILE.PHYSICAL_TRAITS_INITIAL_RESPONSE, list);
  },
  physicalTraitsChange(list) {
    return createAction(PROFILE.PHYSICAL_TRAITS_CHANGE, list);
  },
  physicalTraitsTemporarySave(list) {
    return createAction(PROFILE.PHYSICAL_TRAITS_TEMPORARY_SAVE, list);
  },
  physicalTraitsResponse(list) {
    return createAction(PROFILE.PHYSICAL_TRAITS_RESPONSE, list);
  },
  physicalTraitsResetChangesLoading() {
    return createAction(PROFILE.PHYSICAL_TRAITS_RESET_CHANGES_LOADING);
  },
  physicalTraitsError(message) {
    return createAction(PROFILE.PHYSICAL_TRAITS_ERROR, message);
  },
  physicalTraitsConfirmDialogClose() {
    return createAction(PROFILE.PHYSICAL_TRAITS_CONFIRM_DIALOG_CLOSE);
  },

  openProfileDropdown() {
    return createAction(PROFILE.DROPDOWN_OPEN);
  },

  closeProfileDropdown() {
    return createAction(PROFILE.DROPDOWN_CLOSE);
  }
};

function getSeparateFullName(fullName) {
  const spacePosition = fullName.indexOf(' ');

  if (spacePosition < 0) {
    return {
      firstName: fullName,
      lastName: ''
    };
  }

  const firstName = fullName.substring(0, spacePosition);
  const lastName = fullName.substring(spacePosition + 1);

  return {
    firstName,
    lastName
  };
}
