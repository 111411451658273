import React, { Component } from 'react';

const deprecated = (DeprecatedComponent, message = '') =>
  class extends Component {
    constructor(props) {
      super(props);

      if (process.env.NODE_ENV === 'development') {
        console.warn(
          `Component %c${DeprecatedComponent.name}%c is %cdeprecated%c.`,
          'background-color: #f9f9f9; color: red; padding: 4px; border-radius: 3px;',
          '',
          'font-weight: bold;',
          '',
          message
        );
      }
    }

    render() {
      return React.createElement(DeprecatedComponent, this.props);
    }
  };

export default deprecated;
