import React, { Component } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromProfile from '../ducks/profile';
import * as fromBonuses from '../ducks/bonuses';

import UserOverview from '../components/user-overview/user-overview';

const mapStateToProps = createStructuredSelector({
  profile: fromProfile.getAll,
  isBonusesLoaded: fromBonuses.getIsAllLoaded,
  isBonusesExist: fromBonuses.getIsBonusesExist,
  isBonusesActivated: fromBonuses.getIsActivated
});

class UserOverviewContainer extends Component {
  render() {
    const { profile, isBonusesLoaded, isBonusesExist, isBonusesActivated, ...rest } = this.props;

    const isBonusesShowed = isBonusesLoaded && isBonusesExist && isBonusesActivated;

    return <UserOverview profile={profile} isBonusesShowed={isBonusesShowed} {...rest} />;
  }
}

export default connect(mapStateToProps)(UserOverviewContainer);
