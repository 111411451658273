const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

export default function dateToString(date, shortCurrentYear) {
  const _date = new Date(date);
  const day = _date.getDate();
  const month = months[_date.getMonth()];
  const year = _date.getFullYear();

  if (isThisYear(year) && shortCurrentYear) {
    return `${day} ${month}`;
  } else {
    return `${day} ${month} ${year} года`;
  }
}

function isThisYear(year) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return year === currentYear;
}

export function getDatePeriod(startDate, finalDate) {
  const _startDate = new Date(startDate);
  const startDay = _startDate.getDate();
  let startMonth = months[_startDate.getMonth()];

  const _finalDate = new Date(finalDate);
  const finalDay = _finalDate.getDate();
  const finalMonth = months[_finalDate.getMonth()];

  if (startMonth === finalMonth) {
    return `c ${startDay} по ${finalDay} ${finalMonth}`;
  } else {
    return `c ${startDay} ${startMonth} по ${finalDay} ${finalMonth}`;
  }
}

export const monthToString = mounth => {
  return months[mounth];
};
