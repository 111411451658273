import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import BonusDeadline from '../bonus-deadline/bonus-deadline';

import plural from 'modules/utils/plural';

import './bonus-card.css';

const DEADLINES_AMOUNT = 3;

const TITLE_PLURAL_OPTIONS = ['бонус', 'бонуса', 'бонусов'];

const { oneOf, number } = PropTypes;

class BonusCard extends Component {
  static propTypes = {
    bonuses: number.isRequired,
    type: oneOf(['default', 'extra']).isRequired
  };

  render() {
    const { summary, bonusList, type } = this.props;

    const isDeadlinesExist = bonusList.length !== 0;

    const slicedBonusList = isDeadlinesExist && bonusList.slice(0, DEADLINES_AMOUNT);

    const isExtra = type === 'extra';

    return (
      <div className={cn('BonusCard', `BonusCard--type-${type}`)}>
        <h3 className="BonusCard-title">
          <div className="BonusCard-amount">{summary}</div>

          <div className="BonusCard-currency">
            {isExtra && 'экстра'}
            {plural(summary, TITLE_PLURAL_OPTIONS)}
          </div>
        </h3>

        {isDeadlinesExist ? (
          <ul className="BonusCard-deadlineList">
            {slicedBonusList.map(bonus => {
              const { expire, value } = bonus;

              if (value < 0) {
                return null;
              }

              const deadline = { expire, value };

              return (
                <HideErrorBoundary>
                  <li className="BonusCard-deadlineItem" key={deadline.id}>
                    <BonusDeadline deadline={deadline} />
                  </li>
                </HideErrorBoundary>
              );
            })}
          </ul>
        ) : (
          <span className="BonusCard-deadlineEmpty" role="presentation" />
        )}
      </div>
    );
  }
}

export default BonusCard;
