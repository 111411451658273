import React, { Component } from 'react';

import SpecialOfferPreviewContainer from '../../containers/special-offer-preview';

import HideErrorBoundary from '../hide-error-boundary/hide-error-boundary';

import './special-offer-list.css';

class SpecialOfferList extends Component {
  render() {
    const { slugList } = this.props;

    if (!slugList) {
      return null;
    }

    return (
      <ul className="SpecialOfferList">
        {slugList.map(slug => (
          <HideErrorBoundary key={slug}>
            <li className="SpecialOfferList-item">
              <SpecialOfferPreviewContainer id={slug} />
            </li>
          </HideErrorBoundary>
        ))}
      </ul>
    );
  }
}

export default SpecialOfferList;
