import React, { Component } from 'react';
import './link.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import deprecated from '../../../../enhancers/deprecated';

class Link extends Component {
  static propTypes = {
    /**
     * if component receive "href" property it become tag "a" and its attribute href get content - string
     */
    href: PropTypes.string,

    /**
     *
     */
    bold: PropTypes.bool,

    /**
     * gives name of tag. If there isn't this parameter, tag becomes - "a" (if it has "href" parameter)
     * or button (by default)
     */
    tag: PropTypes.string
  };

  static defaultProps = {
    href: null,
    tag: 'button'
  };

  render() {
    const { onClick, type, bold, noUnderline } = this.props;
    const LinkElement = this.props.href ? 'a' : this.props.tag || 'button';

    return (
      <LinkElement
        type={type}
        onClick={onClick}
        href={this.props.href}
        className={classNames('Link', {
          'Link--bold': bold,
          'Link--noUnderline': noUnderline
        })}
      >
        {this.props.children}
      </LinkElement>
    );
  }
}

export default deprecated(Link, 'Use Link from ./short-link.jsx instead');
