import React, { Component } from 'react';
import { string } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromProducts from '../ducks/products';
import * as fromAccount from 'modules/core/ducks/account';
import { actions as Dialog } from 'modules/core/ducks/dialogs';
import ProductOverview from '../components/product-overview/product-overview';
import FavoritesAction from 'modules/profile/actions/favorites';
import { VARIANT_OPTION } from '../ducks/products/constants';

const getSlugFromProps = (_, props) => props.slug;

const mapStateToProps = createStructuredSelector({
  product: fromProducts.makeGetDerivedItem(getSlugFromProps),
  details: fromProducts.makeGetDetails(getSlugFromProps),
  isDetailsLoaded: fromProducts.makeGetIsDetailsLoaded(getSlugFromProps),
  isInBasket: fromProducts.makeGetIsInBasket(getSlugFromProps),
  // variantSet: fromProducts.makeGetVariantSet(getSlugFromProps),
  // defaultVariant: fromProducts.makeGetDefaultVariant(getSlugFromProps),
  // currentVariant: getCurrentVariant,

  weightList: fromProducts.makeOptionListGetter(VARIANT_OPTION.VOLUME)(getSlugFromProps),
  defaultWeight: fromProducts.makeDefaultOptionGetter(VARIANT_OPTION.VOLUME)(getSlugFromProps),

  colorList: fromProducts.makeOptionListGetter(VARIANT_OPTION.COLOR)(getSlugFromProps),
  defaultColor: fromProducts.makeDefaultOptionGetter(VARIANT_OPTION.COLOR)(getSlugFromProps),

  sizeList: fromProducts.makeOptionListGetter(VARIANT_OPTION.SIZE)(getSlugFromProps),
  defaultSize: fromProducts.makeDefaultOptionGetter(VARIANT_OPTION.SIZE)(getSlugFromProps),
  isAuthorized: fromAccount.getIsAuthorized,
  isBasketFetching: fromBasket.getIsBasketFetching
});

const mapDispatchToProps = {
  overviewShowed: fromProducts.actions.overviewShowed,
  addToBasket: fromBasket.actions.addProduct,
  addToFavorites: FavoritesAction.add,
  onFavouriteClick: FavoritesAction.click,
  removeFromFavorites: FavoritesAction.remove,
  openAuthorizationDialog: Dialog.openAuthDialog
};

class ProductOverviewContainer extends Component {
  static propTypes = {
    slug: string.isRequired
  };

  state = {
    amount: 1,
    currentWeight: this.props.defaultWeight,
    currentSize: this.props.defaultSize,
    currentColor: this.props.defaultColor
  };

  render() {
    const { amount, currentWeight, currentSize, currentColor } = this.state;

    const { onClose } = this.props;

    return (
      <ProductOverview
        {...this.props}
        amount={amount}
        currentWeight={currentWeight}
        currentSize={currentSize}
        currentColor={currentColor}
        onAmountChange={this.handleAmountChange}
        onAddToBasket={this.handleAddToBasket}
        onFavoriteToggle={this.handleToggleFavorite}
        onAddToWaitingList={this.handleAddToWaitingList}
        onClose={onClose}
      />
    );
  }

  componentDidMount() {
    const { product, isInRecent } = this.props;
    this.props.overviewShowed(product, isInRecent);
  }

  handleToggleFavorite = () => {
    const {
      product,
      removeFromFavorites,
      addToFavorites,
      isAuthorized,
      openAuthorizationDialog,
      onFavouriteClick,
      onClose
    } = this.props;

    if (!isAuthorized) {
      openAuthorizationDialog();
      onFavouriteClick(product);
      onClose();
      return;
    }

    if (product.inFavorite) {
      removeFromFavorites(product);
    } else {
      addToFavorites(product);
    }
  };

  resetAmount() {
    this.setState({
      amount: 1
    });
  }

  changeAmount(amount) {
    this.setState({
      amount
    });
  }

  handleAddToBasket = () => {
    this.props.addToBasket(this.props.product, this.state.amount);
    this.resetAmount();
  };

  // handleFavoriteToggle = () => {
  //   const { isAuthorized, openAuthorizationDialog, onClose } = this.props;

  //   if (!isAuthorized) {
  //     openAuthorizationDialog();
  //     onClose();
  //     return;
  //   }
  //   this.props.toggleFavorite(this.props.product);
  // };

  handleAmountChange = amount => {
    this.changeAmount(amount);
  };

  handleAddToWaitingList = () => {
    console.warn('Not implemented!');
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductOverviewContainer);
