import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { IconAngleArrowRight } from 'modules/core/components/icons/index';
import IconContainer from 'modules/core/components/icon-container/icon-container';

import { getProfileBonusHistory } from 'routes/links';

class BonusTransactionsHeader extends Component {
  render() {
    return (
      <div className="BonusTransactions-header">
        <div className="BonusTransactions-headerTitle">Последние начисления</div>

        <RouterLink
          to={{ pathname: getProfileBonusHistory() }}
          className="BonusTransactions-headerLink"
        >
          <span className="BonusTransactions-headerLinkText">Все</span>

          <span className="BonusTransactions-headerLinkIcon">
            <IconContainer size="xsmall">
              <IconAngleArrowRight />
            </IconContainer>
          </span>
        </RouterLink>
      </div>
    );
  }
}

export default BonusTransactionsHeader;
