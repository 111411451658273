import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Item from './item';
import ItemLarge from './item-large';

import './square-grid.css';

const { any } = PropTypes;

class SquareGrid extends Component {
  static Item = Item;
  static ItemLarge = ItemLarge;

  static propTypes = {
    children: any.isRequired
  };

  render() {
    const { children } = this.props;

    return <div className="SquareGrid">{children}</div>;
  }
}

export default SquareGrid;
