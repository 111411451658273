import React from 'react';
import Content from 'modules/core/components/content/content';

import CallbackDialogContainer from 'modules/core/containers/callback-dialog';

import "./form-section.css";

export default function ProblemSkin_FormSection({data}) {
    return (
        <div className="ProblemSkin_FormSection" style={{...data.style}}>
            <div className="ProblemSkin_FormSection-container">
                <div className="ProblemSkin_FormSection-topText">
                    <Content html={data.top_text} expanded/>
                </div>
                <div className="ProblemSkin_FormSection-form">
                    <CallbackDialogContainer marketingLandingData={{...data, header: Header}} />
                </div>
            </div>
        </div>
    )
} 

function Header({data}) {
    return (
        <>        
            <div className="ProblemSkin_FormSection-bottomText">{data.bottom_text}</div>
        </>                
    )
}
